export const MODAL_DEBUG_INVOICES = 'debug-invoices'
export const MODAL_MERGE_ACCOUNTS = 'merge-accounts'
export const MODAL_DEPENDENCIES = 'dependencies'
export const MODAL_MONETIZATION_RESET = 'reset'
export const MODAL_FUND_WALLET = 'fund-wallet'
export const TRADEMARKS_MODAL = 'trademarks'
export const MODAL_AGREEMENT = 'agreement'
export const MODAL_WALLETS = 'wallets'
export const MODAL_LOADING = 'loading'
export const MODAL_REPORT = 'report'
export const MODAL_COOKIE = 'cookie'
export const MODAL_LOGIN = 'login'
export const MODAL_START = 'start'
export const MODAL_AGE = 'age'
export const MODAL_MFA = 'mfa'
