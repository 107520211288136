<template>
  <div class="tw-text-dark-text tw-ml-auto md:tw-hidden">
    <div class="tw-relative tw-ml-3">
      <div
        ref="clickOutside"
        class="tw-flex tw-items-center tw-justify-center tw-relative"
      >
        <button
          v-if="isLoggedIn"
          class="tw-size-10 tw-border-2 tw-rounded-full"
          :class="
            userDropdownActive ? 'tw-border-primary' : 'tw-border-transparent'
          "
          @click="showUserDropdown"
        >
          <avatar-icon
            class="tw-h-full"
            :user="user"
            inherit-size
            :has-notification="hasNotifications"
          />
          <span
            v-if="isAccountClosed"
            class="tw-absolute tw--bottom-1.5 tw--right-2 tw-py-px tw-px-1 tw-rounded tw-bg-red-500 tw-text-[10px] tw-text-white"
            >Closed</span
          >
        </button>
        <base-button
          v-else
          primary
          small
          class="sm:tw-mr-3"
          @click="showUserLogin()"
        >
          Log in
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { useNavMenus, useMessagePoll, useModal } from '@composables'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { authStore, notificationStore } from '@stores'
import { ref, computed } from 'vue'
export default {
  components: {
    AvatarIcon,
  },
  setup() {
    const clickOutside = ref(null)

    const { unreadCount: unreadNotificationCount } = notificationStore()
    const { unreadThreadIds: messageCount } = useMessagePoll()
    const { isLoggedIn, user, isAccountClosed } = authStore()
    const { showUserLogin } = useModal()

    const { showUserDropdown, showMenu, menuComponent, MENU_COMPONENTS } =
      useNavMenus()

    const userDropdownActive = computed(
      () =>
        showMenu.value &&
        menuComponent.value === MENU_COMPONENTS.PROFILE_DROPDOWN
    )

    const hasNotifications = computed(
      () => messageCount.value.length > 0 || unreadNotificationCount.value > 0
    )

    return {
      userDropdownActive,
      showUserDropdown,
      hasNotifications,
      isAccountClosed,
      showUserLogin,
      clickOutside,
      isLoggedIn,
      user,
    }
  },
}
</script>
