<template>
  <div
    class="tw-flex tw-cursor-pointer tw-flex-col tw-w-full tw-button-transition tw-bg-theme-1--hover tw-group tw-overflow-hidden"
    @click="update"
  >
    <div class="tw-p-2 tw-h-18 md:tw-h-auto tw-space-y-1 tw-relative">
      <div
        v-if="isUnread"
        class="tw-absolute tw-top-0 tw-left-0 md:tw-left-auto md:tw-right-0 tw-h-full tw-w-2 tw-bg-primary tw-text-primary-text"
      />
      <div
        v-else
        class="tw-absolute tw-top-0 tw-left-0 md:tw-left-auto md:tw-right-0 tw-h-full tw-w-2 tw-bg-theme-2 tw-bg-opacity-10 tw-text-primary-text"
      />
      <div
        v-if="otherParticipants.length > 1"
        class="tw-inline-block tw-top-3 tw-ml-1.5 tw-absolute tw-size-12"
      >
        <avatar-icon
          show-status
          inherit-size
          class="tw-border-2 tw-border-theme tw-border-theme-1--group-hover tw--top-1 tw-flex tw-shrink-0 tw-size-8 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mt-0.5 tw-mr-2 tw-ml-3"
          :user="otherParticipants[0]"
        />
        <avatar-icon
          show-status
          inherit-size
          class="tw-border-2 tw-border-theme tw-border-theme-1--group-hover tw-absolute tw--top-5 tw-flex tw-shrink-0 tw-size-8 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mt-0.5 tw-mr-2"
          :user="otherParticipants[1]"
        />
      </div>
      <div v-else class="tw-inline-block tw-ml-2 tw-absolute tw-size-12">
        <avatar-icon
          show-status
          inherit-size
          class="tw-flex tw-shrink-0 tw-size-10 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mt-0.5 tw-mr-2"
          :user="otherParticipants[0]"
        />
      </div>
      <div class="tw-inline-block tw-ml-14 tw-w-9/12">
        <div class="tw-flex">
          <span
            class="tw-w-4/6 tw-truncate tw-font-bold"
            :class="{ 'tw-opacity-70': !isUnread }"
          >
            {{ participantsText }}
          </span>
          <span class="tw-w-2/6 tw-text-right">
            {{ lastUpdated }}
          </span>
        </div>
        <span class="tw-flex tw-util-truncate-two-lines tw-opacity-70">
          {{ lastMessage }}
        </span>
      </div>
    </div>
    <div class="tw-w-full tw-h-px tw-border-t tw-border-theme-1" />
  </div>
</template>

<script>
import { useMessenger, useNavMenus, useThread } from '@composables'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { timeSince } from '@helpers/utils.js'
import { MESSAGE_LEFT_TEXT } from '@config'
import { computed, toRefs } from 'vue'
import { authStore } from '@stores'

export default {
  components: {
    AvatarIcon,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    isUnread: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:message'],
  setup(props) {
    const { message } = toRefs(props)
    const { updateThreadId } = useThread()
    const { closeAllMenus } = useNavMenus()
    const { changeComponent, showHide, CONVERSATION_COMPONENTS } =
      useMessenger()
    const userId = authStore().getUserId()

    const lastUpdated = computed(() =>
      timeSince(message.value.last_message.date_published)
    )

    const otherParticipants = computed(
      () => message.value.participants?.filter((p) => p.id !== userId) || []
    )

    const participantsText = computed(() =>
      otherParticipants.value.map((p) => p.username).join(', ')
    )

    const lastMessage = computed(() => {
      if (message.value.last_message.message === MESSAGE_LEFT_TEXT) {
        return `${
          message.value.last_message.user?.username || 'user'
        } has left.`
      }
      return message.value.last_message.message
    })

    function update() {
      updateThreadId(message.value.id)
      closeAllMenus()
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_THREAD)
      showHide(true)
    }

    return {
      otherParticipants,
      participantsText,
      lastMessage,
      lastUpdated,
      timeSince,
      update,
    }
  },
}
</script>
