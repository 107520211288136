<template>
  <div
    ref="target"
    class="tw-flex tw-flex-col tw-space-y-2 tw-input--text-size tw-min-h-11 tw-justify-center"
    :class="{
      'tw-border-2 tw-border-danger': errors.length && !teamInput,
      'tw-bg-theme-1': !teamInput,
    }"
  >
    <search-box
      v-if="teamInput"
      :id="searchId"
      full-width
      :disabled="disabled"
      :auto-select="false"
      :errors="errors"
      :placeholder="!usernameOnly ? 'Display name or email' : 'Display name'"
      :max="formValidation[!usernameOnly ? 'email' : 'display_name'].max"
      :results="
        errors.length
          ? []
          : userLookup.map((u) => ({
              name: u.username,
              user_id: u.user_id,
            }))
      "
      :status="status"
      no-margin
      @input="lookupUser($event.target.value)"
      @update="clearInput"
      @selected:item="addRecipient"
    />
    <div
      v-else
      class="tw-w-full tw-rounded tw-justify-start tw-flex tw-flex-nowrap tw-relative tw-px-3 tw-items-center"
      :class="{ 'tw-px-1': userLookupRecipients.length > 0 }"
    >
      <span
        class="tw-mr-1 tw-input--height tw-flex tw-items-center tw-relative"
      >
        To:
      </span>

      <div class="tw-flex tw-flex-wrap tw-w-full">
        <div
          v-for="user in userLookupRecipients"
          :key="user.id"
          class="tw-rounded tw-space-x-1 tw-justify-center tw-items-center tw-flex tw-mr-1 tw-h-6 tw-bg-theme-1"
        >
          <span>{{ user.username }}</span>

          <base-button
            text-link-hover
            danger
            xsmall
            icon="times"
            :sr-only="`remove ${user.username}`"
            @click="removeUserFromRecipients(user)"
          >
          </base-button>
        </div>

        <div class="tw-flex tw-flex-1 tw-min-w-24">
          <input
            ref="inputTarget"
            v-model="username"
            :disabled="disabled"
            type="text"
            class="tw-w-full tw-px-1 focus:tw-outline-none tw-border-none tw-bg-theme-1 tw-placeholder-input"
            :class="{ 'tw-cursor-not-allowed': disabled }"
            :placeholder="
              teamInput && !usernameOnly
                ? 'Display name or email'
                : 'Display name'
            "
            :maxlength="
              formValidation[
                teamInput && !usernameOnly ? 'email' : 'display_name'
              ].max
            "
            @input="lookupUser($event.target.value)"
            @keypress.enter="$emit('enter')"
          />

          <div
            v-if="show && !username.includes('@')"
            class="tw-absolute tw-z-1 tw-top-full tw-left-0 tw-rounded-b tw-w-full tw-util-scrollbar tw-overflow-y-scroll tw-max-h-56 tw-bg-theme-1"
          >
            <div v-if="isLoading" class="tw-flex tw-p-2">
              <base-loading :status="status" />
              <span class="tw-ml-2">Searching for "{{ username }}"</span>
            </div>

            <div
              v-for="user in userLookup"
              :key="user.id"
              class="tw-flex tw-cursor-pointer tw-flex-row tw-bg-theme-2--hover tw-px-3 tw-h-12"
              @click="addRecipient(user)"
            >
              <span class="tw-p-2 tw-text-theme tw-self-center tw-font-bold">
                {{ user.username }}
              </span>
            </div>

            <span v-if="noResultsFound" class="tw-flex tw-p-2 tw-font-bold">
              No user found for "{{ username }}"
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userConfig as formValidation } from '@config/validation.js'
import { computed, ref, nextTick, onMounted, toRefs } from 'vue'
import { useAsync, useStatus, useSearch } from '@composables'
import SearchBox from '@components/List/SearchBox.vue'
import { lookupUserRequest } from '@services'
import { onClickOutside } from '@vueuse/core'
import { throttle } from '@helpers/utils.js'
import { authStore } from '@stores'

const MAX_USERS_TO_SHOW = 10
const MIN_SEARCH_LEN = 2

export default {
  components: {
    SearchBox,
  },
  props: {
    recipients: {
      type: Array,
      default: () => [],
    },
    teamInput: {
      type: Boolean,
      default: false,
    },
    usernameOnly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchId: {
      type: String,
      default: 'search-box',
    },
  },
  emits: ['add:recipient', 'remove:recipient', 'input', 'enter', 'clear'],
  setup(props, { emit }) {
    const { teamInput, recipients, searchId } = toRefs(props)
    const userLookup = ref([])
    const username = ref('')
    const target = ref(null)
    const inputTarget = ref(null)
    const show = ref(false)
    const userId = authStore().getUserId()
    const { setSearch } = useSearch(searchId.value)

    const userLookupRecipients = computed(() =>
      recipients.value.filter((u) => u.user_id !== userId)
    )

    const {
      error: lookupUserError,
      loading: isLoading,
      run: runLookupUser,
      data: usersData,
    } = useAsync(() => lookupUserRequest(username.value))

    const noResultsFound = computed(() => {
      if (
        isLoading.value ||
        !username.value ||
        MIN_SEARCH_LEN > username.value.length
      ) {
        return false
      }

      return usersData.value?.data?.length === 0
    })

    const { status } = useStatus({
      loading: isLoading,
    })

    const throttleFetch = throttle(fetchLookupUser, 400, {
      leading: false,
    })

    onClickOutside(target, () => {
      show.value = false
      if (!teamInput.value) {
        username.value = ''
      }
    })

    function lookupUser(input) {
      username.value = input
      show.value = true
      emit('input', input)
      if (input.length >= MIN_SEARCH_LEN && !username.value.includes('@')) {
        throttleFetch()
      }
    }

    async function fetchLookupUser() {
      await runLookupUser()

      if (!lookupUserError.value) {
        userLookup.value = usersData.value.data
          .filter((u) => u.user_id != userId && u.username.length)
          .slice(0, MAX_USERS_TO_SHOW)
      }
    }

    function addRecipient(user) {
      if (teamInput.value) {
        user.username = user.name
        setSearch(user.name)
        userLookup.value = []
      }

      username.value = teamInput.value ? user.username : ''
      show.value = false
      emit('add:recipient', user)
    }

    function removeUserFromRecipients(user) {
      emit('remove:recipient', user)
    }

    function clearInput(input) {
      if (!input) emit('clear')
    }

    onMounted(() => {
      nextTick(() => {
        if (!recipients.value.length) {
          inputTarget.value?.focus()
        }
      })
    })

    return {
      removeUserFromRecipients,
      userLookupRecipients,
      formValidation,
      noResultsFound,
      addRecipient,
      inputTarget,
      userLookup,
      lookupUser,
      clearInput,
      isLoading,
      username,
      target,
      status,
      show,
    }
  },
}
</script>
