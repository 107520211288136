export const LS_MESSAGE_POLL = 'message'
export const LS_RECENTGAMES = 'recent-games'
export const LS_INVITE = 'invite'
export const LS_ACCESSTOKEN = 'access-token'
export const LS_MONACCESSTOKEN = 'monetization-access-token'
export const LS_UPDATEDAT = 'updated-at'
export const LS_USER = 'user'
export const LS_TEAMS = 'teams'
export const LS_AGREEMENT_POPUP = 'agreement-popup'
export const LS_RATINGS = 'ratings'
export const LS_SUBSCRIPTIONS = 'subscriptions'
export const LS_USER_SUBSCRIPTIONS = 'user-subscriptions'
export const LS_PURCHASES = 'purchases'
export const LS_NOTIFICATIONS = 'notifications'
export const LS_MESSAGES = 'messages'
export const LS_COOKIE = 'cookies'
export const LS_PENDING_EMAIL = 'pending-email'
export const LS_CACHE_END_TS = 'cache-end-ts'
export const LS_KARMA = 'karma'
export const LS_STATUS_BAR = 'status-bar'
export const LS_REVENUE_TEAMS = 'revenue-teams'
export const LS_TEST_ENV = 'seen-test-banner'
export const LS_AUTO_TRANSLATE = 'auto-translate'
export const LS_METRICS = 'metrics'
export const LS_NEW_LABEL = 'new-label'
export const LS_MONETIZATION_ONBOARDING = 'monetization-onboarding'
export const LS_REUSE_PERMISSIONS = 'reuse-permissions'
export const LS_REGISTERED_INTEREST = 'registered-interest'

export const SS_REDIRECT = 'redirect'
export const SS_CONNECTING = 'connecting'
export const SS_TILIA_CALLBACK = 'tilia-callback'
export const SS_TRANSACTION_KEY = 'transaction-key'
export const SS_ACCOUNT_REFRESHED = 'account-refreshed'
export const SS_SUBSCRIPTIONS_REFRESHED = 'subscriptions-refreshed'
export const SS_GAME_SSO_CONFIG = 'game_sso'
export const SS_INBOX_REFRESHED = 'inbox-refreshed'
