<template>
  <div
    v-if="isLoggedIn"
    :class="
      sidebar
        ? 'tw-hidden md:tw-flex tw-flex-col tw-items-center tw-space-y-1'
        : 'tw-flex tw-items-center tw-space-x-6'
    "
  >
    <div v-if="isMonRegistered" :class="wrapStyles">
      <tooltip-hover
        text="Virtual currency balance"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
        @click="showWalletsModal"
      >
        <button class="tw-group tw-block tw-relative">
          <div :class="buttonStyles">
            <div class="tw-flex tw-items-center">
              <font-awesome-icon icon="wallet" fixed-width />
            </div>
          </div>
        </button>
      </tooltip-hover>
    </div>

    <div :class="wrapStyles">
      <tooltip-hover
        text="Notifications"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
        @click="showNotificationDropdown"
      >
        <button class="tw-group tw-block tw-relative">
          <div :class="buttonStyles">
            <div class="tw-flex tw-items-center">
              <font-awesome-icon icon="bell" fixed-width />
            </div>
          </div>
          <span class="sr-only">{{
            unreadNotificationCount
              ? 'You have unread notifications'
              : 'You have no new notifications'
          }}</span>
        </button>
      </tooltip-hover>

      <base-badge
        v-if="unreadNotificationCount"
        border
        color="danger"
        :text="unreadNotificationCountText"
        class="tw-absolute tw-right-px xs:tw-top-0 xs:tw-right-0.5"
      />
    </div>

    <div :class="wrapStyles">
      <tooltip-hover
        text="Chat"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
        @click="showMessagesDropdown"
      >
        <button class="tw-group tw-block tw-relative">
          <div :class="buttonStyles">
            <div class="tw-flex tw-items-center">
              <font-awesome-icon icon="comment" fixed-width />
            </div>
          </div>
          <span class="sr-only">{{
            messageCount?.length
              ? 'You have unread messages'
              : 'You have no new messages'
          }}</span>
        </button>
      </tooltip-hover>

      <base-badge
        v-if="messageCount?.length"
        border
        color="danger"
        :text="messageCount.length"
        class="tw-absolute tw-right-px xs:tw-top-0 xs:tw-right-0.5"
      />
    </div>
  </div>
</template>

<script>
import { useMessagePoll, useNavMenus, useTheming } from '@composables'
import TooltipHover from '@components/Message/TooltipHover.vue'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_WALLETS } from '@config/globalModals.js'
import { notificationStore, authStore } from '@stores'
import { computed } from 'vue'

export default {
  components: {
    TooltipHover,
  },
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { unreadCount: unreadNotificationCount } = notificationStore()
    const { isLoggedIn, isMonRegistered } = authStore()
    const { lowDangerWCAGContrast } = useTheming()

    const { showNotificationDropdown, showMessagesDropdown } = useNavMenus()

    const { unreadThreadIds: messageCount } = useMessagePoll()

    const wrapStyles = 'tw-flex tw-relative'

    const buttonStyles =
      'tw-flex tw-items-center tw-justify-center tw-size-11 tw-px-3 tw-global--border-radius tw-text-h5 group-hover:md:tw-bg-primary-hover group-focus:md:tw-bg-primary-hover'

    const unreadNotificationCountText = computed(() =>
      unreadNotificationCount.value < 100
        ? unreadNotificationCount.value
        : '99+'
    )

    function showWalletsModal() {
      setGlobalModal({ modal: MODAL_WALLETS })
    }

    return {
      unreadNotificationCountText,
      showNotificationDropdown,
      unreadNotificationCount,
      lowDangerWCAGContrast,
      showMessagesDropdown,
      showWalletsModal,
      isMonRegistered,
      messageCount,
      buttonStyles,
      wrapStyles,
      isLoggedIn,
    }
  },
}
</script>
