import { ref, readonly } from 'vue'

const mfaCode = ref('')

export default function () {
  function setMfaCode(code) {
    mfaCode.value = code
  }

  function clearMfaCode() {
    mfaCode.value = ''
  }

  return { mfaCode: readonly(mfaCode), setMfaCode, clearMfaCode }
}
