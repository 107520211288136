import { API_RESULT_LIMIT } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get } = createClient()

// Function will not scale well
export async function requestAll(url, queryObj) {
  if (queryObj) {
    url = `${url}?${qs.stringify({ ...queryObj, _limit: API_RESULT_LIMIT })}`
  }

  let { data } = await get(url)

  const offsets = _getOffsets(data.result_total)
  const query = url.includes('?') ? '&_offset=' : '?_offset='

  const result = await Promise.all(
    offsets.map((offs) => get(`${url}${query}${offs}`))
  )

  result.forEach((res) => {
    data = mergeApiResultData(data, res.data)
  })

  return data
}

export async function requestAllLazy(url, dataRef, loadingRef, queryObj = {}) {
  if (loadingRef) {
    loadingRef.value = true
  }

  if (!dataRef.value?.result_total) {
    const { data } = await get(`${url}?${qs.stringify(queryObj)}`)

    dataRef.value = data
  }

  fetchRemainingData(url, dataRef, loadingRef, queryObj)
  return dataRef.value
}

async function fetchRemainingData(url, dataRef, loadingRef, queryObj) {
  try {
    const offsets = _getOffsets(dataRef.value.result_total)

    const result = await Promise.all(
      offsets.map((_offset) =>
        get(
          `${url}?${qs.stringify({
            ...queryObj,
            _offset,
          })}`
        )
      )
    )

    result.forEach((res) => {
      dataRef.value = mergeApiResultData(dataRef.value, res.data)
    })
    dataRef.value.result_total = dataRef.value.data.length
  } catch (err) {
    console.error(err)
  } finally {
    if (loadingRef) {
      loadingRef.value = false
    }
  }
}

export function mergeApiResultData(source, target) {
  return {
    ...source,
    data: [...source.data, ...target.data],
    result_count: source.result_count + target.result_count,
  }
}

function _getOffsets(total) {
  const offsets = []
  let t = total

  while (t > 0) {
    if (t > API_RESULT_LIMIT) {
      offsets.push(API_RESULT_LIMIT * (offsets.length + 1))
      t -= API_RESULT_LIMIT
    } else {
      t = 0
    }
  }

  return offsets
}
