<template>
  <div class="tw-w-full">
    <slot name="header" :set-invalid="setInvalid" />

    <div v-bind="$attrs">
      <loading-table
        v-if="parsedError || loading"
        :cols="loadingCols"
        :rows="loadingRows"
        :small-row="smallRow"
        :alt-background="altBackground"
        :has-error="!!parsedError"
        :error-text="parsedError"
      />

      <div
        v-else-if="itemList.length"
        ref="tableDiv"
        v-dragscroll.x="!touchScreen"
        :class="noScroll ? 'tw-overflow-visible' : 'tw-overflow-x-auto'"
        @scroll="checkScroll"
      >
        <table
          ref="target"
          class="tw-w-full tw-border-separate"
          style="border-spacing: 0px"
        >
          <thead class="tw-table-header-group">
            <tr class="tw-font-normal">
              <table-header
                v-for="{
                  headerTooltip,
                  actionCell,
                  cellClass,
                  sortable,
                  label,
                  type,
                  id,
                } in headers"
                :key="id"
                :sort="sort"
                :sort-options="getSortOption(label)"
                :has-right-scroll="hasRightScroll"
                :has-left-scroll="hasLeftScroll"
                :action-cell="actionCell"
                :sortable="sortable"
                :disable-sort="disableSort"
                :label="label || ''"
                :tooltip="headerTooltip"
                :checkbox="type === 'checkbox'"
                :all-checked="allChecked"
                :class="[
                  cellClass,
                  altBackground ? 'tw-bg-dark-3' : 'tw-bg-dark',
                ]"
                @update:sort="(sort) => updateTable('sort', sort)"
                @toggle:checked="toggleCheckAll"
              />
            </tr>
          </thead>
          <tbody>
            <table-row
              v-for="(item, index) in itemList"
              :key="item[rowId]"
              :alt-background="altBackground"
              :has-right-scroll="hasRightScroll"
              :has-left-scroll="hasLeftScroll"
              :item="item"
              :row-schema="rowSchema.config"
              :row-index="index"
              :small-row="smallRow"
              @click="(cell) => $emit('click:row', item)"
            />

            <tr
              v-show="
                showPagination &&
                itemList.length &&
                !loading &&
                (itemTotal > limit || simpleHasNext)
              "
            >
              <td :colspan="headers.length">
                <pagination-container
                  :id="tableId"
                  table-pagination
                  :limits="false"
                  @update="(action) => updateTable('pagination', action)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <no-table-data
        v-else
        class="tw-h-full tw-min-h-[inherit]"
        :text="noDataText"
      />
    </div>

    <div
      v-if="bulkActions?.length"
      class="tw-fixed tw-bottom-0 tw-inset-x-0 md:tw-left-15 tw-flex tw-justify-center tw-px-4 tw-py-2 tw-bg-dark-1 tw-transition-transform tw-z-1"
      :class="checkedIds.length ? 'tw-translate-y-0' : 'tw-translate-y-full'"
    >
      <div
        class="tw-flex tw-w-full tw-gap-2 tw-max-w-[50rem] tw-overflow-x-auto"
      >
        <base-button hollow icon="times" @click="clearCheckedIds">
          {{ checkedIds.length }} selected
        </base-button>
        <div class="tw-flex tw-gap-2 tw-ml-auto">
          <base-button
            v-for="a in bulkActions"
            :key="a.action"
            :primary="a.primary"
            :danger="a.danger"
            :icon="a.icon"
            @click="onBulkAction(a.action)"
          >
            {{ a.text }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiErrorsToArray, isFunction, isArray, clone } from '@helpers/utils.js'
import { inject, ref, toRefs, computed, watch, provide, nextTick } from 'vue'
import PaginationContainer from '@components/List/PaginationContainer.vue'
import LoadingTable from '@components/Loading/LoadingTable.vue'
import NoTableData from '@components/Table/NoTableData.vue'
import TableHeader from '@components/Table/TableHeader.vue'
import { useBrowse, useBulkAction } from '@composables'
import { onClickOutside, onKeyUp } from '@vueuse/core'
import TableRow from '@components/Table/TableRow.vue'
import { useBreakpoints } from '@vueuse/core'
import { dragscroll } from 'vue-dragscroll'
import { BREAKPOINTS } from '@config'

export default {
  components: {
    PaginationContainer,
    LoadingTable,
    NoTableData,
    TableHeader,
    TableRow,
  },
  directives: {
    dragscroll,
  },
  props: {
    tableId: {
      type: String,
      required: true,
    },
    rowSchema: {
      type: Object,
      required: true,
    },
    // Required so devs explicitly confirm
    // they need to handle fetch events
    fetch: {
      type: Boolean,
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: String,
      default: 'id',
    },
    items: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
    error: {
      type: [Array, Object, String],
      default: null,
    },
    noDataText: {
      type: String,
      default: 'No results found',
    },
    limit: {
      type: Number,
      default: 10,
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    searchKey: {
      type: String,
      default: null,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    loadingCols: {
      type: Number,
      default: 4,
    },
    loadingRows: {
      type: Number,
      default: 6,
    },
    altBackground: {
      type: Boolean,
      default: false,
    },
    smallRow: {
      type: Boolean,
      default: false,
    },
    bulkActions: {
      type: Array,
      default: () => [],
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'fetch:paginate-next',
    'fetch:paginate-prev',
    'fetch:query',
    'bulk:action',
    'click:row',
  ],
  setup(props, { emit }) {
    const {
      searchKey,
      itemTotal,
      rowSchema,
      tableId,
      simple,
      items,
      fetch,
      error,
      query,
      limit,
    } = toRefs(props)

    const { smallerOrEqual } = useBreakpoints(BREAKPOINTS)
    const lgAndDown = smallerOrEqual('lg')
    const hasRightScroll = ref(false)
    const hasLeftScroll = ref(false)
    const simpleHasNext = ref(false)
    const tableDiv = ref(null)
    const target = ref(null)

    const touchScreen = 'ontouchstart' in document.documentElement

    const { checkedIds, toggleChecked, clearCheckedIds, setCheckedIds } =
      useBulkAction()

    provide('toggleChecked', toggleChecked)
    provide('isSmallerScreen', lgAndDown)
    provide('checkedIds', checkedIds)

    const headers = computed(() =>
      rowSchema.value.config.map((cell) => (isFunction(cell) ? cell({}) : cell))
    )

    const sortOptions = computed(() =>
      clone(headers.value).map((r) => ({
        label: r.label,
        key: r.key,
        default: r.key && r.key === rowSchema.value.defaultSort,
        ...r.sort,
      }))
    )

    const parsedError = computed(() =>
      (isArray(error.value) ? error.value : apiErrorsToArray(error.value)).join(
        ', '
      )
    )

    const allChecked = computed(() =>
      itemList.value.every((i) =>
        checkedIds.value.includes(i.resource_id ?? i.id)
      )
    )

    const {
      allResultsRetrieved,
      updateTable,
      setInvalid,
      itemList,
      range,
      sort,
    } = useBrowse({
      id: tableId.value,
      items,
      total: itemTotal,
      sortOptions: sortOptions.value,
      limit,
      query: query.value,
      fetch,
      simple: simple.value,
      searchKey: searchKey.value,
    })

    const disableSort = computed(
      () => rowSchema.value.disableSortFetch && !allResultsRetrieved.value
    )

    if (fetch.value) {
      setInvalid()
    }

    const updateActiveId = inject('updateActiveId', () => {})
    onClickOutside(target, () => {
      updateActiveId(null)
    })

    onKeyUp('Escape', () => {
      updateActiveId(null)
    })

    function getSortOption(label) {
      return sortOptions.value.find((option) => option.label === label)
    }

    watch(
      items,
      (_items) => {
        if (simple.value) simpleHasNext.value ||= _items?.nextPageUrl
        clearCheckedIds()
      },
      { immediate: true }
    )

    function onBulkAction(action) {
      emit('bulk:action', action, checkedIds.value)
    }

    function toggleCheckAll() {
      setCheckedIds(
        allChecked.value
          ? checkedIds.value.filter(
              (id) =>
                !itemList.value.some(
                  (item) => item.id === id || item.resource_id === id
                )
            )
          : [
              ...new Set([
                ...checkedIds.value,
                ...itemList.value.map((item) => item.resource_id || item.id),
              ]),
            ]
      )
    }

    function checkScroll() {
      const scrollLeftMax =
        tableDiv.value?.scrollWidth - tableDiv.value?.clientWidth - 4

      hasRightScroll.value =
        tableDiv.value?.scrollWidth > tableDiv.value?.clientWidth &&
        tableDiv.value?.scrollLeft < scrollLeftMax

      hasLeftScroll.value = tableDiv.value?.scrollLeft > 0
    }

    window.addEventListener('resize', () => {
      checkScroll()
    })

    watch(
      tableDiv,
      () => {
        // timeout required otherwise scrollWidth will be incorrect on page load
        nextTick(() => setTimeout(checkScroll, 500))
      },
      { immediate: true }
    )

    return {
      clearCheckedIds,
      toggleCheckAll,
      hasRightScroll,
      hasLeftScroll,
      getSortOption,
      simpleHasNext,
      onBulkAction,
      updateTable,
      touchScreen,
      parsedError,
      checkScroll,
      disableSort,
      checkedIds,
      setInvalid,
      allChecked,
      itemList,
      tableDiv,
      headers,
      target,
      // required for unit test
      range,
      sort,
    }
  },
}
</script>
