<template>
  <teleport v-if="topModal" to="body">
    <login-modal
      v-if="topModal.modal === MODAL_LOGIN"
      show
      :modal-data="topModal.data"
      @close:loginmodal="clearGlobalModals"
      @login:success="handleLogin"
    />

    <loading-modal
      :show="topModal.modal === MODAL_LOADING"
      :status="topModal.data?.status"
      :message="topModal.data?.message"
      :button-text="topModal.data?.buttonText"
    />

    <report-modal
      :show="!!reportModal"
      :resource="reportModal?.data?.resource"
      :resource-id="reportModal?.data?.resourceId"
      :dmca="!!reportModal?.data?.dmca"
      :has-back-button="globalModals.length > 1"
      @close:report="clearGlobalModals"
    />

    <terms-modal
      v-if="topModal.modal === MODAL_AGREEMENT"
      show
      global
      :agreement-type="topModal.data?.agreementType"
      :agreement-version-id="topModal.data?.versionId"
      :back-button="globalModals.length > 1"
      @close:modal="closeAgreementModals"
    />

    <trademarks-modal
      v-if="topModal.modal === TRADEMARKS_MODAL"
      show
      @close:modal="clearGlobalModals"
    />

    <merge-and-close-account-modal
      v-if="topModal.modal === MODAL_MERGE_ACCOUNTS"
      :code="topModal.data?.merge_code"
      :message="topModal.data?.message"
      :on-cancel="topModal.data?.onCancel"
      :on-success="topModal.data?.onSuccess"
      @close:modal="clearGlobalModals"
    />

    <dependencies-modal
      v-if="topModal.modal === MODAL_DEPENDENCIES && topModal.data.dependencies"
      :mod-name-id="topModal.data?.modNameId"
      :dependencies="topModal.data.dependencies"
    />

    <cookie-settings-modal
      v-if="topModal.modal === MODAL_COOKIE"
      :modal="globalModals.length > 1"
      :has-back-button="globalModals.length > 1"
      @close:modal="clearGlobalModals"
    />

    <wallet-balance-modal
      v-if="topModal.modal === MODAL_WALLETS"
      :reset="topModal.data?.reset"
      @close:modal="clearGlobalModals"
    />

    <start-modal
      :show="topModal.modal === MODAL_START"
      @close:modal="clearGlobalModals"
    />

    <base-modal
      :show="topModal.modal === MODAL_AGE"
      has-close-btn
      heading="Confirm Age"
      @close:modal="closeAgeModal"
    >
      <confirm-age-form modal :modal-data="topModal.data" />
    </base-modal>

    <reset-modal
      :show="topModal.modal === MODAL_MONETIZATION_RESET"
      :user="topModal.data?.user"
      :mod="topModal.data?.mod"
      :game="topModal.data?.game"
      :reset-type="topModal.data?.resetType"
      :wallet="topModal.data?.wallet"
      :invoice-id="topModal.data?.invoiceId"
      warning
      size="md"
      @close:modal="closeGlobalModal"
    />

    <debug-invoices-modal
      v-if="topModal.modal === MODAL_DEBUG_INVOICES"
      :generate="topModal.data?.generate"
      :clear-pending="topModal.data?.clearPending"
      :game="topModal.data?.game"
      @close:modal="clearGlobalModals"
    />

    <fund-wallet-modal
      v-if="topModal.modal === MODAL_FUND_WALLET"
      :game="topModal.data?.game"
      @close:modal="clearGlobalModals"
    />

    <mfa-modal
      :show="topModal.modal === MODAL_MFA"
      :type="topModal.data?.type"
      @close:mfamodal="clearGlobalModals"
    />
  </teleport>
</template>

<script>
import { LOGIN_ROUTE, GAME_BROWSE_ROUTE } from '@config/routeNames.js'
import { AGREEMENT_TYPE_OPTIONS } from '@config/options.js'
import { SS_REDIRECT } from '@config/localstorage.js'
import { computed, defineAsyncComponent } from 'vue'
import { getQueryFromPath } from '@helpers/utils.js'
import * as MODALS from '@config/globalModals.js'
import { useRoute } from '@composables'
import {
  clearGlobalModals,
  closeGlobalModal,
  globalModals,
} from '@composables/useModal.js'

export default {
  components: {
    DebugInvoicesModal: defineAsyncComponent(
      () => import('@components/Modals/Monetization/DebugInvoicesModal.vue')
    ),
    FundWalletModal: defineAsyncComponent(
      () => import('@components/Modals/Monetization/FundWalletModal.vue')
    ),
    MergeAndCloseAccountModal: defineAsyncComponent(
      () => import('@components/Modals/MergeAndCloseAccountModal.vue')
    ),
    ResetModal: defineAsyncComponent(
      () => import('@components/Modals/Monetization/ResetModal.vue')
    ),
    CookieSettingsModal: defineAsyncComponent(
      () => import('@components/Modals/CookieSettingsModal.vue')
    ),
    WalletBalanceModal: defineAsyncComponent(
      () => import('@components/Modals/WalletBalanceModal.vue')
    ),
    DependenciesModal: defineAsyncComponent(
      () => import('@components/Modals/DependenciesModal.vue')
    ),
    TrademarksModal: defineAsyncComponent(
      () => import('@components/Modals/TrademarksModal.vue')
    ),
    StartModal: defineAsyncComponent(
      () => import('@home_components/Header/StartModal.vue')
    ),
    LoadingModal: defineAsyncComponent(
      () => import('@components/Modals/LoadingModal.vue')
    ),
    ConfirmAgeForm: defineAsyncComponent(
      () => import('@components/Mod/ConfirmAgeForm.vue')
    ),
    ReportModal: defineAsyncComponent(
      () => import('@components/Modals/ReportModal.vue')
    ),
    LoginModal: defineAsyncComponent(
      () => import('@components/Modals/LoginModal.vue')
    ),
    TermsModal: defineAsyncComponent(
      () => import('@components/Modals/TermsModal.vue')
    ),
    MfaModal: defineAsyncComponent(
      () => import('@components/Modals/MfaModal.vue')
    ),
  },
  setup() {
    const { replace, routeName } = useRoute()

    const topModal = computed(() => globalModals.value.slice(-1)[0])

    const reportModal = computed(() =>
      globalModals.value.find((m) => m.modal === MODALS.MODAL_REPORT)
    )

    function getAgreementHeadline(agreementType) {
      return (
        AGREEMENT_TYPE_OPTIONS.find((t) => t.value === agreementType)?.text ||
        ''
      )
    }

    function handleLogin() {
      const redirectURL = sessionStorage.getItem(SS_REDIRECT)
      if (redirectURL) {
        const query = getQueryFromPath(redirectURL)
        sessionStorage.removeItem(SS_REDIRECT)
        replace({ path: redirectURL, query })
      } else if (routeName.value === LOGIN_ROUTE) {
        replace({ name: GAME_BROWSE_ROUTE })
      }
    }

    function closeAgeModal() {
      if (
        globalModals.value.some(
          (modal) =>
            modal.modal === MODALS.MODAL_AGE && modal.data?.type === 'game'
        ) &&
        routeName.value.includes('mod-browse')
      ) {
        replace({ name: GAME_BROWSE_ROUTE })
      }
      clearGlobalModals()
    }

    function closeAgreementModals() {
      while (topModal.value?.modal === MODALS.MODAL_AGREEMENT) {
        closeGlobalModal()
      }
    }

    return {
      closeAgreementModals,
      getAgreementHeadline,
      clearGlobalModals,
      closeGlobalModal,
      closeAgeModal,
      globalModals,
      reportModal,
      handleLogin,
      ...MODALS,
      topModal,
    }
  },
}
</script>
