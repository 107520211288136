<template>
  <div class="tw-grid" :class="[columns, xSpacing, ySpacing]">
    <slot />
  </div>
</template>

<script>
import { computed } from 'vue'

const sizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl']
const columnCounts = [0, 1, 2, 3, 4, 5, 6]
const gaps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 16, 20]

const props = {
  cols: {
    type: Number,
    default: 1,
    validator: (val) => val !== 0 && columnCounts.includes(val),
  },
  xGap: {
    type: Number,
    default: 6,
    validator: (val) => gaps.includes(val),
  },
  yGap: {
    type: Number,
    default: 6,
    validator: (val) => gaps.includes(val),
  },
}

for (let size of sizes) {
  props[`${size}Cols`] = {
    type: Number,
    default: 0,
    validator: (val) => columnCounts.includes(val),
  }
  props[`${size}XGap`] = {
    type: Number,
    default: 0,
    validator: (val) => gaps.includes(val),
  }
  props[`${size}YGap`] = {
    type: Number,
    default: 0,
    validator: (val) => gaps.includes(val),
  }
}

export default {
  props,
  setup(props) {
    const columns = computed(() =>
      [
        `tw-grid-cols-${props.cols}`,
        ...sizes
          .filter((size) => props[`${size}Cols`])
          .map((size) => `${size}:tw-grid-cols-${props[`${size}Cols`]}`),
      ].join(' ')
    )

    const xSpacing = computed(() =>
      [
        `tw-gap-x-${props.xGap}`,
        ...sizes
          .filter((size) => props[`${size}XGap`])
          .map((size) => `${size}:tw-gap-x-${props[`${size}XGap`]}`),
      ].join(' ')
    )

    const ySpacing = computed(() =>
      [
        `tw-gap-y-${props.yGap}`,
        ...sizes
          .filter((size) => props[`${size}YGap`])
          .map((size) => `${size}:tw-gap-y-${props[`${size}YGap`]}`),
      ].join(' ')
    )

    return {
      xSpacing,
      ySpacing,
      columns,
    }
  },
}
</script>
