<template>
  <loading-select v-if="inputLoading" :label="label"></loading-select>

  <div
    v-else
    ref="target"
    class="tw-text-theme tw-relative"
    :class="fullWidth ? 'tw-block tw-w-full' : 'tw-inline-block'"
  >
    <div
      :class="[
        inlineLabel
          ? 'tw-flex tw-items-center tw-whitespace-nowrap'
          : 'tw-flex tw-flex-col',
        { 'tw-opacity-50 tw-cursor-not-allowed': disabled },
      ]"
      class="tw-group"
    >
      <div
        v-if="tip || label"
        class="tw-flex"
        :class="{
          'tw-mr-2': plain,
          'tw-text-xs': small,
        }"
      >
        <base-input-label
          v-if="label"
          :class="{
            'group-hover:tw-cursor-pointer': inlineLabel && !disabled,
            'group-hover:tw-pointer-events-none': disabled,
          }"
          :id-for="inputId"
          :required="required"
          :label="label"
          :tip="tip"
          :tip-hover="tipHover"
          :margin-bottom="!plain"
          :label-between="labelBetween"
          @mousedown.prevent="inlineLabel && openDropdown()"
        />
      </div>

      <div
        ref="select"
        class="tw-outline-none"
        :class="
          disabled
            ? 'group-hover:tw-pointer-events-none'
            : 'group-hover:tw-cursor-pointer'
        "
        :tabindex="disabled ? '-1' : '0'"
        @keydown="handleKeyPress"
        @change="valueChange"
        @mousedown.prevent="openDropdown"
      >
        <base-button
          v-if="icon"
          :no-bg="noIconBg && !show"
          :hollow="show"
          :primary="show"
          :icon="icon"
        />

        <div
          v-else-if="plain"
          ref="select"
          class="tw-flex tw-text-nowrap tw-items-center tw-gap-x-2 tw-font-medium"
          :class="{
            'tw-justify-end': selectRight,
            'tw-input--height-small': small,
            'tw-input--height-large': !small && !plain,
          }"
          :tabindex="disabled ? '-1' : '0'"
        >
          <span :class="{ 'tw-text-xs': small, 'tw-h6': large }">
            <base-badge
              v-if="selectionCountOnly"
              :text="selectedValues.length"
            />
            <template v-else>
              {{
                finalOptions.find((option) => isSelected(option.value))?.text
              }}
            </template>
          </span>
          <div
            class="tw-text-theme group-hover:tw-text-primary-hover tw-mt-1 lg:tw-mt-0.5"
          >
            <font-awesome-icon :icon="show ? 'angle-up' : 'angle-down'" />
          </div>
        </div>

        <div
          v-if="!icon && !plain"
          class="tw-flex tw-group tw-overflow-hidden tw-relative tw-duration-200 tw-ease-in-out tw-bg-input-hover tw-border-2 tw-global--border-radius"
          :class="[
            borderStyles,
            {
              'tw-w-full': fullWidth,
              'focus-within:tw-border-primary dark:focus-within:tw-border-primary':
                !disabled,
              [`tw-w-${width}`]: width,
            },
            small ? 'tw-input--height-small' : 'tw-input--height-large',
          ]"
          style="transition-property: background-color"
        >
          <slot name="prepend" />
          <select
            :id="inputId"
            ref="select"
            :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
            :placeholder="placeholder"
            :disabled="disabled || readonly"
            :aria-describedby="`${inputId}Help`"
            class="tw-max-w-full tw-bg-transparent tw-font-medium tw-util-truncate-one-line"
            :class="[
              inputBaseStyles,
              { 'tw-util-truncate-one-line': selectedValues?.length > 0 },
            ]"
          >
            <option
              v-if="showPlaceholder || selectedValues?.length > 0"
              value=""
              disabled
              selected
            >
              {{ selectedValues?.length > 0 ? selectionString : placeholder }}
            </option>
            <option
              v-for="option in finalOptions"
              :key="option.value"
              :value="option.value"
              :disabled="!!option.disabled"
              :selected="isSelected(option.value) && !selectedValues"
              class="tw-hidden"
            >
              {{ option.text }}
              <template v-if="option.count"> ({{ option.count }}) </template>
            </option>
          </select>
          <div
            class="tw-text-theme group-hover:tw-text-primary-hover"
            :class="caretStyles"
          >
            <font-awesome-icon :icon="show ? 'angle-up' : 'angle-down'" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="show"
      ref="dropdown"
      class="tw-absolute tw-cursor-default tw-min-w-55 tw-shadow-sm tw-overflow-auto tw-util-scrollbar tw-max-h-72 tw-bg-theme-1 tw-z-4 tw-border-2 tw-border-primary tw-font-medium tw-global--border-radius tw-leading-none"
      :class="[
        {
          [`tw-w-${width}`]: width,
          'tw-w-full': fullWidth,
          'tw-text-nowrap': icon || textNoWrapFit,
          'tw-bottom-12': up,
          'tw-mt-1': !plain,
          'tw-mt-2': plain && !small,
        },
        right ? 'tw-right-0' : 'tw-inset-x-0',
      ]"
    >
      <search-box
        v-if="showSearch && searchId"
        :id="searchId"
        no-margin
        full-width
        alt-background
        class="tw-p-2"
        :throttle="0"
        :placeholder="searchPlaceholder"
        @keydown="handleKeyPress"
      />

      <template v-if="!selectedValues">
        <div
          v-for="(option, index) in finalOptions"
          :key="option.value"
          class="tw-py-dropdown-item tw-input--px"
          :class="[
            { 'tw-bg-primary tw-text-primary-text': index === focusedIndex },
            option.disabled
              ? 'tw-cursor-not-allowed tw-opacity-50'
              : 'hover:tw-text-primary-text hover:tw-bg-primary-hover',
          ]"
          @click="
            !option.disabled &&
            selectItem(option.value, !selectedValues, option.newLabel)
          "
        >
          <template v-if="option.desc && option.desc !== option.text">
            <span>
              {{ option.text ? option.text : '&nbsp;' }}
              <template v-if="option.count"> ({{ option.count }}) </template>
            </span>
            <p>
              {{ option.desc.replace('.', '') }}
            </p>
          </template>
          <template v-else>
            {{ option.text ? option.text : '&nbsp;' }}
            <template v-if="option.count"> ({{ option.count }}) </template>
          </template>
          <span
            v-if="showNewLabel(option.newLabel)"
            class="tw-relative tw-ml-1 tw-py-px tw-px-1 tw-rounded tw-bg-red-500 tw-text-[10px] tw-text-white"
            >NEW</span
          >
        </div>
      </template>
      <template v-else>
        <div v-for="option in finalOptions" :key="option.value">
          <base-checkbox
            dark-bg
            no-radius
            :tabindex="disabled ? '-1' : '0'"
            custom-label-styles="tw-p-2 tw-input--pl hover:tw-text-primary-text hover:tw-bg-primary-hover"
            :label="option.text"
            :background="false"
            :model-value="
              selectedValues.includes(option.value) ||
              (selectedValues.length < 1 && option.value === defaultValue)
            "
            @input="selectItem(option.value, !selectedValues)"
          />
        </div>
      </template>
    </div>

    <div
      v-if="hasErrors || hint"
      class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors :errors="errors" align="left" />

      <base-input-hint v-if="hint" :id="`${inputId}Help`">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, onMounted, nextTick, inject, watch } from 'vue'
import LoadingSelect from '@components/Loading/LoadingSelect.vue'
import SearchBox from '@components/List/SearchBox.vue'
import { useToggle, useSearch } from '@composables'
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { onClickOutside } from '@vueuse/core'
import { genHtmlId } from '@helpers/utils.js'
import { newLabelStore } from '@stores'

export default {
  components: {
    LoadingSelect,
    SearchBox,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Number, String],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
      default: '',
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    right: {
      type: Boolean,
      default: false,
    },
    selectRight: {
      type: Boolean,
      default: false,
    },
    noIconBg: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: '',
    },
    plain: {
      type: Boolean,
      default: false,
    },
    up: {
      type: Boolean,
      default: false,
    },
    textNoWrapFit: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    selectedValues: {
      type: Array,
      default: undefined,
    },
    selectionCountOnly: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    searchId: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'change'],
  setup(props, { emit }) {
    const {
      selectedValues,
      placeholder,
      modelValue,
      disabled,
      searchId,
      options,
      errors,
      id,
    } = toRefs(props)
    const target = ref()
    const select = ref()
    const dropdown = ref()
    const focusedIndex = ref(-1)
    const { show, open, close } = useToggle()
    const inputId = id.value ? id.value : genHtmlId()
    const readonly = inject('saving', false)
    const { showNewLabel, checkNavNewLabel } = newLabelStore()

    const finalOptions = computed(() =>
      searchId.value ? filteredOptions.value : options.value
    )
    const filteredOptions = ref(options.value)
    if (searchId.value) {
      const { search: searchValue, clearSearch } = useSearch(searchId.value)
      watch(
        [options, searchValue],
        ([_options, _search], [_oldOptions]) => {
          focusedIndex.value = -1
          filteredOptions.value = _options.filter((o) =>
            o.text.toLowerCase().includes(_search?.toLowerCase() || '')
          )

          if (_oldOptions !== _options) clearSearch()
        },
        { immediate: true }
      )
    }

    const caretStyles =
      'tw-pointer-events-none tw-absolute tw-w-8 tw-inset-y-0 tw-right-1 tw-flex tw-items-center tw-justify-center tw-button-transition'

    const showPlaceholder = computed(
      () => modelValue.value === '' && placeholder.value !== ''
    )

    const selectionString = computed(() =>
      finalOptions.value
        .filter((o) => selectedValues.value.includes(o.value))
        .map((o) => o.text)
        .join(', ')
    )

    const hasErrors = computed(() => !!errors.value?.length)

    const inputBaseStyles = computed(() => {
      const classList =
        ' tw-flex tw-flex-1 tw-input--pl tw-border-none tw-outline-none'
      return disabled.value
        ? `${classList} tw-cursor-default tw-pr-10`
        : `${classList} tw-cursor-pointer tw-button-transition tw-leading-normal tw-appearance-none tw-pr-12`
    })

    const borderStyles = computed(() => {
      if (show.value) {
        return 'tw-border-primary'
      }
      return hasErrors.value
        ? 'tw-border-danger tw-border-2'
        : 'tw-input--border-color'
    })

    function isSelected(option) {
      if (option || option === 0) {
        return option.toString() === modelValue.value.toString()
      }
      return false
    }

    onMounted(_resetFocus)

    function _resetFocus() {
      focusedIndex.value = finalOptions.value.findIndex(
        (option) => option.value.toString() === modelValue.value.toString()
      )
    }

    function _closeAndResetFocus() {
      close()
      _resetFocus()
    }

    function _scrollTo() {
      if (focusedIndex.value >= 0) {
        dropdown.value?.children[focusedIndex.value].scrollIntoView({
          block: 'nearest',
        })
      }
    }

    function openDropdown() {
      if (!show.value) {
        open()
        _resetFocus()

        // Scroll to focussed index after dropdown has opened
        nextTick(_scrollTo)
        select.value.focus()
      } else {
        _closeAndResetFocus()
      }
    }

    function selectItem(value, closeDropdown = true, newLabel) {
      emit('input', value)
      emit('change', value)

      checkNavNewLabel(newLabel?.value)
      if (closeDropdown) close()

      focusedIndex.value = finalOptions.value.findIndex(
        (option) => option.value.toString() === value.toString()
      )
      select.value.focus()
    }

    function valueChange(e) {
      const value = e.target.value
      selectItem(value, false)
      _scrollTo()
    }

    function changeIndex(dir) {
      focusedIndex.value =
        (focusedIndex.value + dir + finalOptions.value.length) %
        finalOptions.value.length

      if (!show.value && finalOptions.value.length)
        selectItem(finalOptions.value[focusedIndex.value].value)

      _scrollTo()
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          _closeAndResetFocus()
          break

        case 27: //escape
          if (show.value) {
            e.preventDefault()
            e.stopPropagation()
            _closeAndResetFocus()
          }
          break

        case 13: //return
        case 32: //space
          e.preventDefault()
          if (show.value) {
            if (focusedIndex.value >= 0) {
              selectItem(finalOptions.value[focusedIndex.value].value)
            }
          } else {
            openDropdown()
          }
          break

        case 38: //up
          e.preventDefault()
          changeIndex(-1)
          break

        case 40: //down
          e.preventDefault()
          changeIndex(1)
          break
      }
    }

    onClickOutside(target, _closeAndResetFocus)

    return {
      showPlaceholder,
      inputBaseStyles,
      selectionString,
      filteredOptions,
      handleKeyPress,
      finalOptions,
      focusedIndex,
      openDropdown,
      UI_TEST_ENVS,
      borderStyles,
      showNewLabel,
      caretStyles,
      valueChange,
      isSelected,
      selectItem,
      MODIO_ENV,
      hasErrors,
      readonly,
      dropdown,
      inputId,
      target,
      select,
      show,
    }
  },
}
</script>
