import { clone, setBits, isObj, isEqual } from '@helpers/utils.js'
import { LS_REVENUE_TEAMS } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { useRevenueCalc } from '@composables'
import { monetizationStore } from '@stores'
import { ref, computed, watch } from 'vue'
import { Duration } from 'luxon'
import pick from 'lodash/pick'
import {
  MAX_REVENUE_SHARE_MEMBERS,
  MIN_REVENUE_SHARE_AMOUNT,
  SECTION_MARKETPLACE,
} from '@config/monetization.js'

const teamState = ref({})
const remainingState = ref({})
const revenueTeamsState = useLocalStorage(LS_REVENUE_TEAMS, '{}')
const tempRevenueMembers = ref([])
const lastUpdatedState = {}
const initialPassedMin = ref(false)
const initialRevenueMembers = ref([])
let loadingMembers = {}

const gameCompareFields = ['user', 'split']
const modCompareFields = ['user', 'entity', 'split']

const revenueTeamsObj = ref(
  isObj(JSON.parse(revenueTeamsState.value))
    ? JSON.parse(revenueTeamsState.value)
    : {}
)

function _sortTeam(_team, _members) {
  return _team
    .filter((teamMember) =>
      _members.every((revMember) => revMember.user?.id !== teamMember.user.id)
    )
    .sort((a, b) => a.user.username.localeCompare(b.user.username))
}

/**
 * @param {'game' | 'mod'} type
 * @param {string} nameId
 */
export default function (type, nameId, editingTemp = false, loadingRef = null) {
  const storeKey = `${type}-${nameId}`
  const revenueMembers = computed(() => revenueTeamsObj.value[storeKey] || [])
  const remainingTotal = computed(() => remainingState.value[storeKey] || 0)
  const hasData = computed(() => !!revenueTeamsObj.value[storeKey])

  const teamMembers = computed(() =>
    _sortTeam(teamState.value[storeKey] || [], tempRevenueMembers.value)
  )

  const dirty = computed(() => {
    const compareFields = type === 'game' ? gameCompareFields : modCompareFields
    return !isEqual(
      initialRevenueMembers.value.map((m) => pick(m, compareFields)),
      tempRevenueMembers.value.map((m) => pick(m, compareFields))
    )
  })

  const belowMinAmount = computed(() => {
    const { calculateSplit, basePrice } = useRevenueCalc(
      SECTION_MARKETPLACE,
      false,
      type === 'mod'
    )

    const minimumValue = Math.min(
      ...(editingTemp ? tempRevenueMembers : revenueMembers).value.map(
        (obj) => obj.split
      )
    )

    return (
      calculateSplit(minimumValue) < MIN_REVENUE_SHARE_AMOUNT &&
      basePrice.value > 0
    )
  })

  if (loadingRef) {
    loadingMembers = loadingRef
  }

  if (editingTemp) {
    tempRevenueMembers.value = []
    initialRevenueMembers.value = []

    watch(
      revenueMembers,
      (_members) => {
        if (_members.length) {
          tempRevenueMembers.value = clone(_members)
          _setInitialMembers(_members)
        }
      },
      {
        immediate: true,
      }
    )
  }

  const lockedItemsCount = computed(
    () => tempRevenueMembers.value.filter((i) => i.locked).length
  )

  const lockedItemTotal = computed(() =>
    tempRevenueMembers.value
      .filter((i) => i.locked)
      .map((i) => i.split)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  )

  function addTeamMembers(users) {
    teamState.value[storeKey] = _mapUserData(
      users.filter((u) => u.invite_pending === 0)
    )
  }

  function addTeamMember(id, entityName) {
    if (
      (type === 'mod' || monetizationStore().teamData?.game?.isKyc) &&
      tempRevenueMembers.value.length === MAX_REVENUE_SHARE_MEMBERS
    )
      return

    tempRevenueMembers.value.push(
      entityName
        ? _setSplit({ entity: { id, name: entityName } })
        : _setSplit(teamState.value[storeKey].find((m) => m.user.id === id))
    )
  }

  function removeRevenueUser(id, isEntity) {
    tempRevenueMembers.value = tempRevenueMembers.value.filter((u) =>
      isEntity ? u.entity?.id !== id : u.user?.id !== id
    )

    if (tempRevenueMembers.value.length === 1) {
      tempRevenueMembers.value[0].split = 100
    }
  }

  function setRevenueMembers(team, updateInitial) {
    const teamObj = { ...revenueTeamsObj.value }
    teamObj[storeKey] = team
    revenueTeamsState.value = JSON.stringify(teamObj)
    revenueTeamsObj.value = teamObj
    _setLastUpdated()

    if (updateInitial) {
      _setInitialMembers(team)
    }
  }

  function updateTeamMemberMonStatus(userId, status) {
    const memberIndex = teamState.value[storeKey].findIndex(
      (m) => m.user.id === userId
    )
    if (memberIndex >= 0) {
      const stateCopy = clone(teamState.value[storeKey])
      stateCopy[memberIndex].user.monetization_status = setBits(
        stateCopy[memberIndex].user.monetization_status,
        status
      )
      teamState.value[storeKey] = stateCopy
    }
  }

  function updateSplit(split) {
    if (_isMemberLocked(split.index)) {
      return
    }

    // after input has been deducted whats left
    const remainingUnlocked = 100 - lockedItemTotal.value - split.value
    // how many unlocked items not included active slider
    const unlockedItemsCount =
      tempRevenueMembers.value.length - lockedItemsCount.value - 1
    // what whole number is left
    remainingState.value[storeKey] = Math.floor(
      remainingUnlocked / unlockedItemsCount
    )
    // record any left over value whole number
    const leftOver = remainingUnlocked % unlockedItemsCount

    if (remainingTotal.value >= 0) {
      // update current item split
      tempRevenueMembers.value[split.index].split = split.value + leftOver
      // update the remainingTotal unlocked items
      tempRevenueMembers.value.forEach((u, i) => {
        if (i !== split.index && !u.locked) {
          u.split = remainingTotal.value
        }
      })
    }
  }

  function updateSplitLock(split) {
    // set locked status in revenueMembers array
    tempRevenueMembers.value[split.index].locked = split.locked
    // if only one item or if all other items locked toggle lock for everything
    if (
      tempRevenueMembers.value.length === 1 ||
      tempRevenueMembers.value.length - 1 === lockedItemsCount.value
    ) {
      tempRevenueMembers.value.forEach((u) => {
        u.locked = split.locked
      })
    }
  }

  function _setSplit(user) {
    user.split = tempRevenueMembers.value.length === 0 ? 100 : 0
    user.entity ||= {}
    user.user ||= {}

    if (
      tempRevenueMembers.value.length &&
      tempRevenueMembers.value.every((u) => u.locked)
    ) {
      user.locked = true
    }

    return user
  }

  function _mapUserData(users) {
    return users.map((u) => ({
      split: 0,
      locked: false,
      ...u,
    }))
  }

  function _isMemberLocked(index) {
    return !!tempRevenueMembers.value[index]?.locked
  }

  function requiresUpdate() {
    return (
      Duration.fromMillis(
        Date.now() - (lastUpdatedState[storeKey] || 0)
      ).shiftTo('days').days >= 1
    )
  }

  function _setLastUpdated() {
    lastUpdatedState[storeKey] = Date.now()
  }

  function updateInitialPassedMin() {
    initialPassedMin.value = belowMinAmount.value
  }

  function clearRevenueTeams() {
    revenueTeamsState.value = '{}'
    revenueTeamsObj.value = {}
  }

  function _setInitialMembers(_members) {
    initialRevenueMembers.value = clone(_members)
  }

  return {
    updateTeamMemberMonStatus,
    updateInitialPassedMin,
    tempRevenueMembers,
    setRevenueMembers,
    clearRevenueTeams,
    removeRevenueUser,
    initialPassedMin,
    updateSplitLock,
    belowMinAmount,
    requiresUpdate,
    addTeamMembers,
    revenueMembers,
    remainingTotal,
    loadingMembers,
    addTeamMember,
    updateSplit,
    teamMembers,
    hasData,
    dirty,
  }
}
