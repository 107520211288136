import { LS_METRICS, SS_REDIRECT, SS_CONNECTING } from '@config/localstorage.js'
import { msToSecs, asyncSleep } from '@helpers/utils.js'
import { useSuggestions, addToast } from '@composables'
import { ALL_LOGIN_METHODS } from '@config/auth.js'
import qs from 'qs'
import {
  getAllUserTeamSummaryRequest,
  getAllNotificationsRequest,
  getMyUserFollowingsRequest,
  getMySubscriptionsRequest,
  getAccountProfileRequest,
  getMyPurchasesRequest,
  getUserInboxRequest,
  getAllUserRatings,
  createClient,
} from '@services'
import {
  LOGIN_EXCHANGE_CODE_ENDPOINT,
  LOGIN_REQUEST_CODE_ENDPOINT,
  INITIAL_LOGIN_TIME_SECONDS,
  MONETIZATION_BASE_URL,
  REPLICATION_DELAY_MS,
  LOGOUT_ENDPOINT,
  AUTH_BASE_URL,
} from '@config'
import {
  userSubscriptionStore,
  subscriptionStore,
  notificationStore,
  purchasesStore,
  myTeamsStore,
  messageStore,
  ratingStore,
  authStore,
} from '@stores'

const {
  setUserConnections,
  setInitialLogin,
  setUpdatedAt,
  user: myUser,
  setUserInfo,
  isLoggedIn,
  clearUser,
  setToken,
  setUser,
} = authStore()
const { setMyTeams } = myTeamsStore()
const { setMySubscriptions } = subscriptionStore()
const { setMyPurchases } = purchasesStore()
const { setUserSubscriptions } = userSubscriptionStore()
const { setUserRatings } = ratingStore()
const { addNotifications } = notificationStore()
const { addMessages } = messageStore()
const { post, get } = createClient()

/* API request to update local storage data */
export async function updateLocalStorage(user) {
  const promises = [
    getMySubscriptionsRequest(),
    getMyUserFollowingsRequest(),
    getAllUserRatings(),
    getAllUserTeamSummaryRequest(),
    getAllNotificationsRequest(),
    getUserInboxRequest(),
  ]
  if (MONETIZATION_BASE_URL) {
    promises.push(getMyPurchasesRequest())
  }

  const response = await Promise.allSettled(promises)

  if (response.some((promise) => promise.reason?.code === 401)) {
    // unauthorised
    clearUser()
    addToast({
      title: 'Login failed',
      isError: true,
      text: 'Failed to login. Please wait several minutes and try again. If the problem persists, visit our help center at support.mod.io',
    })
    throw new Error()
  }

  if (response[0].status === 'fulfilled')
    setMySubscriptions(response[0].value.data)
  if (response[1].status === 'fulfilled')
    setUserSubscriptions(response[1].value.data)
  if (response[2].status === 'fulfilled') setUserRatings(response[2].value.data)
  if (response[3].status === 'fulfilled') setMyTeams(response[3].value.data)
  if (response[4].status === 'fulfilled') addNotifications(response[4].value)
  if (response[5].status === 'fulfilled') addMessages(response[5].value)

  // set user required to HERE to kept login modal visible
  // until local storage data requests complete
  if (user) {
    setUser(user)
  }
  setUpdatedAt()

  // Must update after user has been set
  if (MONETIZATION_BASE_URL) {
    if (response[6].status === 'fulfilled')
      setMyPurchases(response[6].value.data)
  }
}

/* API request to request code */
export async function requestCodeRequest({ email } = {}) {
  const { data } = await post(
    LOGIN_REQUEST_CODE_ENDPOINT,
    qs.stringify({ email }),
    { withCredentials: true }
  )
  return data
}

/* API request to get MFA code */
export async function getMfaCodeRequest() {
  const { data } = await post('/me/verify')

  return data
}

/* API request to login using code */
export async function loginRequest({ code } = {}) {
  const {
    data: { user, access_token },
  } = await post(LOGIN_EXCHANGE_CODE_ENDPOINT, qs.stringify({ code }), {
    withCredentials: true,
  })

  await _boot({ user, access_token })
}

/* API request to logout */
export async function logoutRequest() {
  if (!isLoggedIn.value) return

  try {
    await post(LOGOUT_ENDPOINT)
  } catch (err) {
    console.error(err)
  } finally {
    localStorage.removeItem(LS_METRICS)
    clearUser()
  }
}

/* API request to retrieve oauth login redirect url */
export async function oAuthUrlRequest(
  service,
  connect,
  storeRedirect = true,
  sbx = null,
  gameId = null
) {
  let url = _getUrl(service, connect)
  if (!url) return

  if (
    window.location.href &&
    storeRedirect &&
    !sessionStorage.getItem(SS_REDIRECT)
  ) {
    sessionStorage.setItem(
      SS_REDIRECT,
      window.location.pathname + window.location.search + window.location.hash
    )
  }

  if (connect) {
    sessionStorage.setItem(SS_CONNECTING, 'true')
  }

  if (sbx) {
    url += `?sbx=${sbx}`
  } else if (gameId) {
    url += `?game_id=${gameId}`
  }

  const { data } = await get(`${AUTH_BASE_URL}${url}`, {
    withCredentials: true,
  })

  if (data.url) {
    return data.url
  }
}

/* API request code login user with oauth */
export async function oAuthLoginRequest(service, path) {
  try {
    const url = _getUrl(service)
    if (!url || !service || !path) {
      throw new Error()
    }

    const query = path.replace(/[^?]*/, '')

    const {
      data: { user, access_token },
    } = await get(`${AUTH_BASE_URL}${url}${query}`, {
      withCredentials: true,
    })

    if (user && access_token) {
      return await _boot({ user, access_token })
    } else {
      throw new Error()
    }
  } catch (error) {
    clearUser()
    throw new Error(
      error.message ||
        'We experienced an issue attempting to log you in and our team has been notified. Please try again later.'
    )
  }
}

/* API request connect account with oauth */
export async function connectAccountRequest(service, path, gameId = null) {
  try {
    let url = _getUrl(service, true)
    if (!url) {
      throw new Error('Unable to connect account')
    }

    let query = path.replace(/[^?]*/, '')
    if (gameId) {
      query += `${query.includes('?') ? '&' : '?'}game_id=${gameId}`
    }

    const { data } = await get(`${AUTH_BASE_URL}${url}${query}`, {
      withCredentials: true,
    })

    if (data?.user) {
      setUser(data.user)
    } else if (data?.merge_code?.length === 5) {
      const { merge_code, message } = data
      const connections = myUser.value.connections
      setUserConnections([...connections, { service, merge_code, message }])
      return data
    } else if (data?.merge_code) {
      return data
    }
  } catch (error) {
    throw new Error(error)
  }
}

export async function connectDeviceRequest(code) {
  const { data } = await post(
    `${AUTH_BASE_URL}/device/connect`,
    qs.stringify({ code })
  )

  return data
}

async function _boot({ user, access_token }) {
  // handle replication delay
  await asyncSleep(REPLICATION_DELAY_MS)

  try {
    clearUser()
    setToken(access_token)

    await updateLocalStorage(user)

    _checkInitialLogin(user)
    _checkMonetizationEmail(user)

    useSuggestions().clearSuggestions()
  } catch (error) {
    console.error(error)
  }
}

function _checkInitialLogin(user) {
  if (!user?.info?.date_joined) return

  const secondsSinceJoined =
    Math.floor(msToSecs(Date.now())) - user.info.date_joined

  setInitialLogin(
    secondsSinceJoined <= INITIAL_LOGIN_TIME_SECONDS || !user.info.display_name
  )
}

async function _checkMonetizationEmail(user) {
  if (!user?.info?.monetization_status) return

  try {
    const data = await getAccountProfileRequest()
    if (data?.email_blocked) {
      setUserInfo({ ...myUser.value.info, monetization_email_blocked: true })
    }
  } catch (error) {
    console.error(error)
  }
}

function _getUrl(type, connect = false) {
  const loginMethod = ALL_LOGIN_METHODS.find((method) => method.type === type)

  if (connect && loginMethod?.connectUrl) {
    return loginMethod.connectUrl
  } else if (!connect && loginMethod?.url) {
    return loginMethod.url
  } else {
    throw new Error('Invalid login service')
  }
}
