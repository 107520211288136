import { isString, isNumber } from '@helpers/utils.js'
import { computed, ref } from 'vue'

const state = ref({})

export default function (id, param, fullText) {
  if (!id) {
    throw new Error('useSearch requires id')
  }

  if (param) setSearch(isString(param) && param.trim())
  else clearSearch()

  function setSearch(param, paramText = '') {
    state.value[id] = isString(param) && param.trim()
    // optional text to display to user in place of param
    state.value[`${id}-text`] = paramText.trim()
  }

  const search = computed(() => {
    return state.value && state.value[id]
  })

  const searchText = computed(() => {
    return state.value[`${id}-text`]
  })

  function clearSearch() {
    delete state.value[id]
    delete state.value[`${id}-text`]
  }

  function _match(haystack, needle) {
    if (isNumber(haystack)) {
      // ignore type check
      return haystack == needle
    }

    haystack = haystack.toLowerCase()
    needle = needle.toLowerCase()
    if (fullText) {
      return needle.split(' ').some((word) => haystack.includes(word))
    }

    return haystack.includes(needle)
  }

  function _isIdSearch() {
    // NaN === NaN is false
    if (
      !id.includes('guide-browse') &&
      Number(search.value) === parseInt(search.value)
    ) {
      return search.value > 10000
    }
    return false
  }

  function inMemorySearch(data, searchKey, isGuides = false) {
    if (!searchKey && _isIdSearch()) searchKey = 'id'
    if (!search.value) return data

    const words = search.value.split('.')

    return isGuides
      ? data.filter(
          (item) =>
            words.some((word) => _match(item['name'], word)) ||
            words.some((word) => _match(item['summary'], word)) ||
            words.some((word) => _match(item['description'], word))
        )
      : data.filter((item) =>
          words.some((word) => _match(item[searchKey || 'name'], word))
        )
  }

  const getSearch = computed(() => {
    if (state.value[id]) {
      const key = _isIdSearch() ? 'id' : '_q'
      return { [key]: search.value }
    }
    return null
  })

  return {
    inMemorySearch,
    clearSearch,
    searchText,
    getSearch,
    setSearch,
    search,
  }
}
