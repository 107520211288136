import { ref, readonly } from 'vue'

const authorsState = ref([])

export default function () {
  function setAuthors(value) {
    authorsState.value = value
  }

  return {
    authors: readonly(authorsState),
    setAuthors,
  }
}
