import { useDebug, useCacheBust, useMonetization } from '@composables'
import { authStore, gameStore, modStore, guideStore } from '@stores'
import axios from 'axios'
import https from 'https'
import {
  MONETIZATION_BASE_URL,
  METRIC_GUIDE_HEADER,
  METRIC_GAME_HEADER,
  MODIO_DEBUG_HEADER,
  METRIC_MOD_HEADER,
  PRIVATE_BASE_URL,
  MODIO_DEBUG,
  ENV,
} from '@config'

export function create() {
  if (ENV === 'development') {
    axios.defaults.httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    })
  }

  axios.defaults.baseURL = PRIVATE_BASE_URL
  axios.defaults.headers.get['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.delete['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.common['Accept'] = 'application/json'

  return axios
}

function _setHeaders(config) {
  const { getScope } = useMonetization()
  const {
    getUserLanguage,
    monAccessToken,
    isSuperAdmin,
    accessToken,
    platform,
    portal,
  } = authStore()

  const isMonEndpoint = config.url.includes(MONETIZATION_BASE_URL)

  if (accessToken.value) {
    config.headers['Accept-Language'] = getUserLanguage()
    if (config.anonymous !== true) {
      if (isMonEndpoint) {
        config.headers.Authorization = `Bearer ${
          monAccessToken.value?.[
            getScope(config.meta?.gameEndpoint, config.meta?.modEndpoint)
          ]?.monetization_bearer
        }`
      } else {
        config.headers.Authorization = `Bearer ${accessToken.value}`
      }
    }
  }

  if (config.external !== true) {
    config.headers['X-Modio-Origin'] = 'web'
  }

  if (portal.value) {
    config.headers['X-Modio-Portal'] = portal.value
  }

  if (!isMonEndpoint && platform.value) {
    config.headers['X-Modio-Platform'] = platform.value
  }

  if (
    MODIO_DEBUG &&
    !isMonEndpoint &&
    isSuperAdmin() &&
    config.external !== true
  ) {
    const { debugHeader } = useDebug()

    if (debugHeader.value) config.headers[MODIO_DEBUG_HEADER] = 'verify'
  }

  return config
}

function _handleApiCache(config) {
  const { setCacheLimit, isCacheBusting, urlMatch, cacheEndTs } = useCacheBust()
  if (config.method !== 'get' && urlMatch(config)) {
    setCacheLimit()
  } else {
    if (isCacheBusting()) {
      config.url = config.url.includes('?')
        ? `${config.url}&ts=${cacheEndTs.value}`
        : `${config.url}?ts=${cacheEndTs.value}`
    }
  }

  return config
}

function _handleMetricsIds(config) {
  if (config.metrics?.enabled !== true) {
    return config
  }
  const { getGameId } = gameStore()
  const { getModId } = modStore()
  const { getGuideId } = guideStore()
  config.headers[METRIC_GAME_HEADER] = getGameId(config.metrics.gameNameId)
  if (config.metrics.modNameId) {
    config.headers[METRIC_MOD_HEADER] = getModId(
      config.metrics.modNameId,
      config.metrics.gameNameId
    )
  } else if (config.metrics.guideNameId) {
    config.headers[METRIC_GUIDE_HEADER] = getGuideId(
      config.metrics.guideNameId,
      config.metrics.gameNameId
    )
  }
  return config
}

// Do something before request is sent
axios.interceptors.request.use(function (config) {
  config = _handleMetricsIds(config)
  config = _handleApiCache(config)
  config = _setHeaders(config)
  return config
})
