<template>
  <div
    :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
    class="tw-flex tw-items-center tw-space-x-1"
    :class="{
      'tw-disabled-panel': inputLoading,
      'tw-justify-center': !label,
    }"
  >
    <base-loading
      v-if="loading"
      status="loading"
      class="tw-flex tw-items-center tw-h-6 tw-text-primary-hover"
    />
    <label
      :for="inputId"
      :class="[
        disabled || loading ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
        {
          'tw-flex tw-gap-2 tw-font-medium focus-within:tw-text-primary': label,
        },
      ]"
      @keydown="handleKeyPress"
    >
      <div
        class="tw-relative tw-input--focus-within tw-rounded-full tw-h-fit"
        :class="{ 'tw-opacity-50': disabled || loading }"
      >
        <input
          :id="inputId"
          :checked="checked"
          type="checkbox"
          class="tw-absolute tw-opacity-0 tw-size-0"
          :disabled="disabled || loading || readonly"
          @click.prevent="onChange"
        />
        <div
          class="tw-block tw-w-10 tw-h-5 tw-rounded-full"
          :class="checkTheme"
        />
        <div
          class="tw-shadow-md tw-absolute tw-left-1 tw-top-1 tw-size-3 tw-rounded-full tw-transition tw-transform tw-pointer-events-none"
          :class="[
            checked
              ? 'tw-translate-x-5 tw-bg-[--primary-text]'
              : 'tw-translate-x-0.5 tw-bg-[var(--input-text-color,white)]',
            { 'tw-opacity-50': inputLoading },
          ]"
        />
      </div>
      <span
        v-if="label"
        :class="{
          'tw-opacity-90 hover:tw-opacity-100 focus-within:tw-opacity-100':
            !disabled,
        }"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { toRefs, computed, inject } from 'vue'
import { genHtmlId } from '@helpers/utils.js'

export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    testId: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { modelValue, id, altBg } = toRefs(props)
    const readonly = inject('saving', false)

    const inputId = id.value ? id.value : genHtmlId()

    const checked = computed(
      () => modelValue.value === 1 || modelValue.value === true
    )

    const checkTheme = computed(() => {
      if (checked.value) {
        return 'game-primary-button-color hover:game-primary-button-color-hover'
      }
      return altBg.value ? 'tw-bg-grey' : 'game-input-background-color'
    })

    function onChange() {
      emit('input', Number(!modelValue.value))
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          break
        case 13: //return
        case 32: //space
          e.preventDefault()
          onChange()

          break
      }
    }

    return {
      handleKeyPress,
      UI_TEST_ENVS,
      checkTheme,
      MODIO_ENV,
      onChange,
      readonly,
      checked,
      inputId,
    }
  },
}
</script>
