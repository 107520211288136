<template>
  <loading-image v-if="inputLoading && altLoading" rounded />
  <div
    v-else-if="inputLoading"
    class="tw-animate-pulse tw-rounded-full tw-bg-theme"
    :class="avatarSize"
  ></div>

  <div v-else class="tw-relative">
    <div
      v-if="avatarUrl"
      class="tw-overflow-hidden tw-rounded-full"
      :class="!inheritSize ? avatarSize : 'tw-w-full tw-h-full'"
    >
      <object
        :data="avatarUrl"
        type="image/jpeg"
        class="tw-object-cover tw-object-center tw-button-transition tw-size-full tw-pointer-events-none"
      >
        <avatar-icon-fallback
          :user="user"
          :size="size"
          :inherit-size="inheritSize"
          :avatar-size="avatarSize"
          :custom-font-size="customFontSize"
        />
      </object>
    </div>

    <avatar-icon-fallback
      v-else
      :user="user"
      :size="size"
      :inherit-size="inheritSize"
      :avatar-size="avatarSize"
      :custom-font-size="customFontSize"
    />

    <div
      v-if="showStatus && onlineStatus"
      class="tw-absolute tw-rounded-full tw-border-theme-3"
      :class="[onlineIconStyles, onlineStatus ? 'tw-bg-success' : 'tw-bg-grey']"
    />

    <div
      v-if="hasNotification"
      class="tw-absolute tw-rounded-full tw-border-danger tw-bg-danger"
      :class="onlineIconStyles"
    />
  </div>
</template>

<script>
import AvatarIconFallback from '@components/Users/AvatarIconFallback.vue'
import LoadingImage from '@components/Loading/LoadingImage.vue'
import { isOnlineUser } from '@helpers/utils.js'
import { toRefs, computed } from 'vue'
export default {
  components: {
    AvatarIconFallback,
    LoadingImage,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      },
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    hasNotification: {
      type: Boolean,
      default: false,
    },
    inheritSize: {
      type: Boolean,
      default: false,
    },
    customFontSize: {
      type: String,
      default: '',
    },
    altLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { user, size, inheritSize } = toRefs(props)

    const avatarUrl = computed(
      () =>
        user.value &&
        user.value.avatar.thumb_100x100?.search('avatar_100x100') === -1 &&
        user.value.avatar.thumb_100x100
    )
    const avatarSize = computed(() => {
      if (inheritSize.value) {
        return ''
      } else if (size.value === 'sm') {
        return 'tw-size-7'
      } else if (size.value === 'lg') {
        return 'tw-size-12'
      } else if (size.value === 'xl') {
        return 'tw-size-20 sm:tw-size-28'
      }
      return 'tw-size-9'
    })

    const onlineIconStyles = computed(() => {
      if (size.value === 'xl') {
        return 'tw-h-6 tw-w-6 tw--top-2 tw--right-2 tw-border-2'
      }
      return 'tw-h-3 tw-w-3 tw--top-px tw--right-px tw-border-1.5'
    })

    const onlineStatus = computed(() => isOnlineUser(user.value?.date_online))

    return {
      onlineIconStyles,
      onlineStatus,
      avatarSize,
      avatarUrl,
    }
  },
}
</script>
