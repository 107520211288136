<template>
  <div class="tw-flex tw-items-center tw-space-x-2">
    <base-button primary small @click="getUserStatus"> monStatus </base-button>

    <base-button primary small @click="authenticate">
      authenticate me
    </base-button>

    <base-button primary small :disabled="!gameNameId" @click="authGame">
      authenticate game
    </base-button>
  </div>
</template>

<script>
import { useMonetization, useRoute } from '@composables'
import { isString } from '@helpers/utils.js'

export default {
  setup() {
    const { getUserStatus, authenticateGame, authenticate } = useMonetization()

    const { getGameId } = useRoute()
    const gameNameId = getGameId()

    const divStyles =
      'tw-text-xs tw-px-2 tw-h-8 tw-flex tw-items-center tw-justify-center tw-font-bold'

    function setColour(val) {
      if (isString(val)) {
        return 'tw-bg-info tw-text-info-text'
      }
      return val
        ? 'tw-bg-success tw-text-success-text'
        : 'tw-bg-danger tw-text-danger-text'
    }

    function authGame() {
      authenticateGame(gameNameId.value)
    }

    return {
      authenticateGame,
      getUserStatus,
      authenticate,
      gameNameId,
      setColour,
      divStyles,
      authGame,
    }
  },
}
</script>
