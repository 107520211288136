<template>
  <div :class="{ 'tw-size-full': inheritSize }">
    <span
      class="tw-font-extrabold tw-rounded-full tw-flex tw-items-center tw-justify-center tw-button-transition tw-bg-theme-1 tw-text-theme tw-pointer-events-none"
      :class="inheritSize ? 'tw-size-full' : avatarSize"
    >
      <span :class="customFontSize || initialSize">
        {{ userInitials }}
      </span>
    </span>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    customFontSize: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      },
    },
    avatarSize: {
      type: String,
      default: '',
    },
    inheritSize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { user, size } = toRefs(props)

    const userInitials = computed(() => {
      const name =
        user.value?.display_name_portal ||
        user.value?.username ||
        user.value?.info?.display_name

      if (name) return name.substring(0, 1).toUpperCase()

      return ''
    })

    const initialSize = computed(() =>
      size.value === 'xl' ? 'tw-text-h3' : 'tw-text-h6'
    )

    return {
      userInitials,
      initialSize,
    }
  },
}
</script>
