<template>
  <div
    ref="target"
    class="tw-relative"
    :class="fullWidth ? 'tw-w-full' : 'tw-w-full xs:tw-max-w-80'"
  >
    <base-input
      :test-id="testId"
      :placeholder="placeholder"
      :label="label"
      :focus="focus"
      :dropdown-border="show"
      :class="{ 'tw-ml-2': !noMargin }"
      :model-value="useSearchText ? searchText : search"
      :alt-bg="altBackground"
      :input-loading="inputLoading"
      :errors="errors"
      :max="max"
      :disabled="disabled"
      @keyup="(key) => $emit('update:key', key)"
      @click="results.length && open()"
      @input="(input) => updateInput(input)"
      @keydown="handleKeyPress"
    >
      <template v-if="hasSearchIcon" #prepend>
        <font-awesome-icon icon="search" />
      </template>
      <template #append>
        <slot name="append" />
        <base-loading v-if="status" :status="status" />
        <base-button
          v-else-if="!searchEmpty && hasCloseIcon"
          text-link-hover
          danger
          icon="times"
          @click="clear"
        />
      </template>
    </base-input>

    <ul
      v-if="results.length && show && (search !== '' || showDropdownEmptyInput)"
      ref="dropdown"
      class="tw-absolute tw--mt-1.5 tw-inset-x-0 tw-cursor-default tw-shadow-sm tw-global--border-radius-b tw-overflow-auto tw-max-h-60 tw-bg-theme-1 tw-z-10 tw-border-2 tw-border-primary tw-border-t-0"
      :class="{
        'tw-ml-2': !noMargin,
        'tw-w-full': fullWidth,
      }"
    >
      <li
        v-for="(item, index) in results"
        :key="index"
        :class="{
          'tw-bg-primary tw-text-primary-text': index === focusedIndex,
        }"
        class="tw-p-2 tw-input--pl hover:tw-text-primary-text hover:tw-bg-primary-hover focus:tw-text-primary-text focus:tw-bg-primary-hover"
        @click="select(item)"
      >
        <span v-decode="item.name || item.text" />
      </li>
    </ul>
  </div>
</template>

<script>
import { useSearch, usePagination, useToggle } from '@composables'
import { toRefs, computed, watch, ref, nextTick } from 'vue'
import { isString, throttle } from '@helpers/utils.js'
import { onClickOutside } from '@vueuse/core'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    testId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    label: {
      type: String,
      default: '',
    },
    throttle: {
      type: Number,
      default: 1000,
    },
    status: {
      type: String,
      default: null,
    },
    results: {
      type: Array,
      default: () => [],
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 0,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    useSearchText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    altBackground: {
      type: Boolean,
      default: false,
    },
    showDropdownEmptyInput: {
      type: Boolean,
      default: false,
    },
    hasSearchIcon: {
      type: Boolean,
      default: true,
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
    autoSelect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['selected:item', 'update:input', 'update:key', 'update'],
  setup(props, { emit }) {
    const { id, throttle: throttleProp, results, autoSelect } = toRefs(props)
    const { clearSearch, setSearch, search, searchText } = useSearch(id.value)
    const { resetPagination } = usePagination(id.value)
    const { close, open, show } = useToggle()
    const focusedIndex = ref(-1)
    const dropdown = ref(null)
    const target = ref(null)

    const focus = computed(() => search.value !== undefined)

    const throttleInput = throttle(() => {
      emit('update', search.value)
    }, throttleProp.value)

    const searchEmpty = computed(
      () =>
        !search.value || (isString(search.value) && search.value.trim() === '')
    )

    watch(results, (_results) => {
      if (_results.length) {
        if (_results.length > 1 || _results[0].name !== search.value) {
          open()
        } else {
          // automatically select exact match
          autoSelect.value && select(_results[0])
        }
      }
    })

    function updateInput(input) {
      const trimmed = input?.trim()
      if (trimmed !== search.value || (search.value && !!trimmed)) {
        setSearch(input, input)
        emit('update:input', input)
        throttleInput()
      }
    }

    function clear() {
      resetPagination()
      clearSearch()
      emit('update')
    }

    function select(item) {
      emit('selected:item', item)
      focusedIndex.value = -1

      // Wait until selected event is handled then close.
      nextTick(close)
    }

    function changeIndex(dir) {
      focusedIndex.value =
        (focusedIndex.value + dir + results.value.length) %
          results.value.length || 0

      _scrollTo()
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          focusedIndex.value = -1
          close()
          break

        case 27: //escape
          if (show.value) {
            e.preventDefault()
            e.stopPropagation()
            focusedIndex.value = -1
            close()
          }
          break

        case 13: //return
          e.preventDefault()
          if (show.value) {
            if (focusedIndex.value >= 0) {
              select(results.value[focusedIndex.value])
            }
          } else {
            open()
          }
          break

        case 38: //up
          e.preventDefault()
          changeIndex(-1)
          break

        case 40: //down
          e.preventDefault()
          changeIndex(1)
          break
      }
    }

    function _scrollTo() {
      if (focusedIndex.value >= 0) {
        dropdown.value?.children[focusedIndex.value].scrollIntoView({
          block: 'nearest',
        })
      }
    }

    onClickOutside(target, close)

    return {
      handleKeyPress,
      focusedIndex,
      searchEmpty,
      updateInput,
      searchText,
      dropdown,
      search,
      select,
      target,
      clear,
      focus,
      close,
      open,
      show,
    }
  },
}
</script>
