<template>
  <div
    class="tw-w-full"
    :class="[
      vertical ? 'tw-border-l' : 'tw-border-t',
      alt ? 'tw-border-theme-2' : 'tw-border-theme-1',
    ]"
  />
</template>
<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
