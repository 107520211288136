import { THEME_DEFAULT, THEME_HOME } from '@config/colorsTheme.js'
import { GAME_CARD, MOD_CARD, GUIDE_CARD } from '@config/card.js'
import * as routes from '@config/routeNames.js'
import { useSuggestions } from '@composables'
import * as seo from '@config/seo.js'
import { authStore } from '@stores'
import { MODIO_ENV } from '@config'
import {
  AGREEMENT_TYPE_MONETIZATION,
  AGREEMENT_TYPE_PRIVACY,
  AGREEMENT_TYPE_COOKIES,
  AGREEMENT_TYPE_REFUND,
  AGREEMENT_TYPE_GAME,
  AGREEMENT_TYPE_AUP,
  AGREEMENT_TYPE_TOU,
  AGREEMENT_TYPE_API,
  STATUS_BANNED,
} from '@config/options.js'

const { user, setUserInfo } = authStore()
const { handleSuggestion } = useSuggestions()

export const publicRoutes = [
  {
    path: '/',
    name: routes.HOME_ROUTE,
    component: () =>
      import(/* webpackChunkName: 'homepage' */ '@home_views/Homepage.vue'),
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          content: seo.HOMEPAGE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.HOMEPAGE_DESCRIPTION,
        },
      ],
      noSidebar: true,
      theme: THEME_HOME,
    },
  },
  {
    path: '/g',
    name: routes.GAME_BROWSE_ROUTE,
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/GameBrowse.vue'),
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          content: seo.GAME_BROWSE_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.GAME_BROWSE_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
      },
      error: [
        {
          status: 403,
          errorRef: 11007,
          method: 'get',
          action: () => {
            if (user.value?.info) {
              setUserInfo({ ...user.value.info, status: STATUS_BANNED })
            }
          },
        },
      ],
      theme: THEME_DEFAULT,
    },
  },
  {
    path: '/g/:game',
    name: routes.MOD_BROWSE_ROUTE,
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) =>
            game.name ? `${game.name} ${game.ugc_name}` : '',
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) => game.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ game }) => game.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ game }) => game.name,
        },
      ],
      widget: {
        component: 'ItemWidget',
        props: {
          card: GAME_CARD,
        },
      },
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: {
          label: routes.UGC_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
      },
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 404,
          errorRef: 14006,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 403,
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/ModBrowse.vue'),
  },
  {
    path: '/g/:game/marketplace',
    name: routes.MOD_BROWSE_ROUTE_MARKETPLACE,
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) =>
            game.name ? `${game.name} marketplace ${game.ugc_name}` : '',
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) => game.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ game }) => game.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ game }) => game.name,
        },
      ],
      scope: [
        'global:monetization',
        'game:monetization',
        'game:marketplace',
        'game:marketplaceweb',
      ],
      widget: {
        component: 'ItemWidget',
        props: {
          card: GAME_CARD,
        },
      },
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: {
          label: routes.UGC_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
      },
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 404,
          errorRef: 14006,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 403,
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/ModBrowse.vue'),
  },
  {
    path: '/g/:game/token-packs',
    name: routes.MOD_BROWSE_ROUTE_TOKEN_PACKS,
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) => `${game.name} virtual currency packs`,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) => game.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ game }) => game.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ game }) => game.name,
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: {
          label: routes.UGC_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/ModBrowse.vue'),
  },
  {
    path: '/g/:game/partner-program',
    name: routes.MOD_BROWSE_ROUTE_PARTNER,
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) => `${game.name} partner program`,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) => game.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ game }) => game.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ game }) => game.name,
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: {
          label: routes.UGC_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/ModBrowse.vue'),
  },
  {
    path: '/g/:game/m/:mod',
    name: routes.MOD_VIEW_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ mod, game }) => `${mod.name} for ${game.name}`,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ mod }) => mod.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ mod }) => mod.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ mod }) => mod.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ mod }) => mod.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ mod }) => mod.name,
        },
      ],
      widget: {
        component: 'ItemWidget',
        props: {
          card: MOD_CARD,
          browseId: 'mod-widget',
        },
      },
      error: [
        {
          status: '*',
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        mods: {
          label: routes.UGC_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: {
          label: routes.MOD_PARAM,
          to: { name: routes.MOD_VIEW_ROUTE },
        },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/ModView.vue'),
  },
  {
    path: '/g/:game/u/:user',
    name: routes.USER_GAME_ROUTE,
    redirect: { name: routes.USER_GAME_MOD_BROWSE_ROUTE },
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ user, game }) =>
            `${user.username} creator for ${game.name}`,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ user }) => user?.avatar?.thumb_100x100,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ user }) => user?.avatar?.thumb_100x100,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ user }) => user.username,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ user }) => user.username,
        },
      ],
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          redirect: routes.FOUROHFOUR_ROUTE,
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'user-view' */ '@views/UserView.vue'),
    children: [
      {
        name: routes.USER_GAME_INFO_ADMIN,
        path: 'info',
        component: () =>
          import(
            /* webpackChunkName: 'user-view' */ '@components/Users/UserInfoAdmin.vue'
          ),
      },
      {
        name: routes.USER_GAME_MOD_BROWSE_ROUTE,
        path: '',
        component: () =>
          import(/* webpackChunkName: 'game' */ '@views/ModBrowse.vue'),
      },
      {
        name: routes.USER_GAME_GUIDE_BROWSE_ROUTE,
        path: 'r',
        component: () =>
          import(/* webpackChunkName: 'game' */ '@views/GuideBrowse.vue'),
      },
      {
        name: routes.USER_GAME_CREATOR_STATS,
        path: 'stats',
        component: () =>
          import(
            /* webpackChunkName: 'user-view' */ '@components/Users/UserStats.vue'
          ),
      },
    ],
  },
  {
    path: '/g/:game/m/add/',
    name: routes.MOD_ADD_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) =>
            seo.MOD_ADD_ROUTE_TITLE.replace(':game-name', game.name),
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) =>
            seo.MOD_ADD_ROUTE_DESCRIPTION_META.replace(':game-name', game.name),
        },
      ],
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 403,
          errorRef: 14007,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        game: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        mods: {
          label: routes.UGC_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: { label: 'Add', to: { name: routes.MOD_ADD_ROUTE } },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'mod-add' */ '@views/ModAdd.vue'),
  },
  {
    path: '/g/:game/r',
    name: routes.GUIDE_BROWSE_ROUTE,
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) => `${game.name} guides`,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.GUIDE_BROWSE_ROUTE_DESCRIPTION_META,
          dynamicContent: ({ game }) =>
            game.name
              ? seo.GUIDE_BROWSE_ROUTE_DESCRIPTION_META.replace(
                  ':game.name',
                  game.name
                )
              : '',
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ game }) => `${game.name} guides`,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ game }) => `${game.name} guides`,
        },
      ],
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        tail: {
          label: 'Guides',
          to: { name: routes.GUIDE_BROWSE_ROUTE },
        },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/GuideBrowse.vue'),
  },
  {
    path: '/g/:game/r/:guide',
    name: routes.GUIDE_VIEW_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ guide, game }) =>
            `${guide.name} guide for ${game.name}`,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ guide }) => guide.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ guide }) => guide.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ guide }) => guide.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ guide }) => guide.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ guide }) => guide.name,
        },
      ],
      error: [
        {
          status: '*',
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
      widget: {
        component: 'ItemWidget',
        props: {
          card: GUIDE_CARD,
          browseId: 'guide-widget',
        },
      },
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        guides: {
          label: 'Guides',
          to: { name: routes.GUIDE_BROWSE_ROUTE },
        },
        tail: {
          label: routes.GUIDE_PARAM,
          to: { name: routes.GUIDE_VIEW_ROUTE },
        },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'game' */ '@views/GuideView.vue'),
  },
  {
    path: '/g/:game/r/add/',
    name: routes.GUIDE_ADD_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) =>
            seo.GUIDE_ADD_ROUTE_TITLE.replace(':game-name', game.name),
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) =>
            seo.GUIDE_ADD_ROUTE_DESCRIPTION_META.replace(
              ':game-name',
              game.name
            ),
        },
      ],
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        name: {
          label: routes.GAME_PARAM,
          to: { name: routes.MOD_BROWSE_ROUTE },
        },
        guides: {
          label: 'Guides',
          to: { name: routes.GUIDE_BROWSE_ROUTE },
        },
        tail: { label: 'Add', to: { name: routes.GUIDE_ADD_ROUTE } },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'guide-add' */ '@views/GuideAdd.vue'),
  },
  {
    path: '/gamescom',
    redirect: '/',
  },
  // {
  //   path: '/gamescom',
  //   name: routes.GAMESCOM_ROUTE,
  //   meta: {
  //     seo: [
  //       {
  //         tag: 'title',
  //         content: seo.GAMESCOM_ROUTE_TITLE,
  //       },
  //       {
  //         tag: 'meta',
  //         name: { attribute: 'name', value: 'description' },
  //         content: seo.GAMESCOM_ROUTE_DESCRIPTION_META,
  //       },
  //     ],
  //     breadcrumb: {
  //       head: { label: 'gamescom', to: { name: routes.GAMESCOM_ROUTE } },
  //     },
  //     noSidebar: true,
  //     theme: THEME_DEFAULT,
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: 'about' */ '@views/Gamescom.vue'),
  // },
  {
    path: '/about',
    name: routes.ABOUT_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.ABOUT_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.ABOUT_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
      },
      noSidebar: true,
      theme: THEME_DEFAULT,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/About.vue'),
  },
  {
    path: '/brand',
    name: routes.BRAND_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.BRAND_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.BRAND_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'Brand', to: { name: routes.BRAND_ROUTE } },
      },
      noSidebar: true,
      theme: THEME_DEFAULT,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Brand.vue'),
  },
  {
    path: '/careers',
    name: routes.CAREERS_ROUTE,
    meta: {
      noSidebar: true,
      seo: [
        {
          tag: 'title',
          content: seo.CAREERS_ROUTE_TITE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.CAREERS_ROUTE_DESCRIPTION_META,
        },
      ],
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@home_views/Careers.vue'),
  },
  {
    path: '/case-studies/crypt-of-the-necrodancer',
    name: routes.NECRODANCER_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.NECRODANCER_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.NECRODANCER_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
        tail: {
          label: 'Crypt of the Necrodancer',
          to: { name: routes.NECRODANCER_ROUTE },
        },
      },
      noSidebar: true,
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@views/Necrodancer.vue'),
  },
  {
    path: '/case-studies/snowrunner',
    name: routes.SNOWRUNNER_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.SNOWRUNNER_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.SNOWRUNNER_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
        tail: {
          label: 'SnowRunner',
          to: { name: routes.SNOWRUNNER_ROUTE },
        },
      },
      noSidebar: true,
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@views/Snowrunner.vue'),
  },
  {
    path: '/case-studies/baldurs-gate-3',
    name: routes.BG3_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.BG3_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.BG3_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
        tail: {
          label: "Baldur's Gate 3",
          to: { name: routes.BG3_ROUTE },
        },
      },
      noSidebar: true,
      theme: THEME_DEFAULT,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/BG3.vue'),
  },
  {
    path: '/terms/:id?',
    name: routes.TERMS_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.TERMS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.TERMS_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Terms', to: { name: routes.TERMS_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_TOU,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/privacy/:id?',
    name: routes.PRIVACY_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.PRIVACY_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.PRIVACY_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Privacy', to: { name: routes.PRIVACY_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_PRIVACY,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/aup/:id?',
    name: routes.AUP_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.AUP_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.AUP_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'AUP', to: { name: routes.AUP_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_AUP,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/gameterms/:id?',
    name: routes.GAME_TERMS_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.GAME_TERMS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.GAME_TERMS_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Terms', to: { name: routes.GAME_TERMS_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_GAME,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/monetization/:id?',
    name: routes.MONETIZATION_TERMS_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.MONETIZATION_TERMS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.MONETIZATION_TERMS_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: {
          label: 'Monetization',
          to: { name: routes.MONETIZATION_TERMS_ROUTE },
        },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_MONETIZATION,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/apiterms/:id?',
    name: routes.API_TERMS_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.API_TERMS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.API_TERMS_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Terms', to: { name: routes.API_TERMS_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_API,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/cookies/:id?',
    name: routes.COOKIES_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.COOKIES_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.COOKIES_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Cookies', to: { name: routes.COOKIES_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_COOKIES,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/refund/:id?',
    name: routes.REFUND_POLICY_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.REFUND_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.REFUND_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'AgreementWidget',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Refund', to: { name: routes.REFUND_POLICY_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: AGREEMENT_TYPE_REFUND,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/trademarks',
    name: routes.TRADEMARKS_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.TRADEMARKS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.TRADEMARKS_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'Trademarks', to: { name: routes.TRADEMARKS_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/contact',
    name: routes.CONTACT_ROUTE,
    meta: {
      noSidebar: true,
      seo: [
        {
          tag: 'title',
          content: seo.CONTACT_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.CONTACT_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
        tail: { label: 'Contact', to: { name: routes.CONTACT_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: (route) => ({
      subject: route.query.subject,
    }),
    component: () =>
      import(/* webpackChunkName: 'about' */ '@views/Contact.vue'),
  },
  {
    path: '/docs',
    name: routes.DOCS_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.DOCS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.DOCS_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
        tail: { label: 'Docs', to: { name: routes.DOCS_ROUTE } },
      },
      noSidebar: true,
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@home_views/Docs.vue'),
  },
  {
    path: '/pricing',
    name: routes.PRICING_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.PRICING_ROUTE_TITLE,
        },
      ],
      breadcrumb: {
        head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
        tail: { label: 'Pricing', to: { name: routes.PRICING_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@views/Pricing.vue'),
  },
  {
    path: '/u/:user/',
    name: routes.USER_ROUTE,
    redirect: { name: routes.USER_MOD_BROWSE_ROUTE },
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ user }) => user.username,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ user }) => user?.avatar?.thumb_100x100,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ user }) => user?.avatar?.thumb_100x100,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ user }) => user.username,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ user }) => user.username,
        },
      ],
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          redirect: routes.FOUROHFOUR_ROUTE,
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'user-view' */ '@views/UserView.vue'),
    children: [
      {
        name: routes.USER_INFO_ADMIN,
        path: 'info',
        component: () =>
          import(
            /* webpackChunkName: 'user-view' */ '@components/Users/UserInfoAdmin.vue'
          ),
      },
      {
        name: routes.USER_MOD_BROWSE_ROUTE,
        path: '',
        component: () =>
          import(/* webpackChunkName: 'game' */ '@views/ModBrowse.vue'),
      },
      {
        name: routes.USER_GUIDE_BROWSE_ROUTE,
        path: 'r',
        component: () =>
          import(/* webpackChunkName: 'game' */ '@views/GuideBrowse.vue'),
      },
      {
        name: routes.USER_CREATOR_STATS,
        path: 'stats',
        component: () =>
          import(
            /* webpackChunkName: 'user-view' */ '@components/Users/UserStats.vue'
          ),
      },
    ],
  },
  {
    path: '/report/:resource?/:id?',
    name: routes.REPORT_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.REPORT_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.REPORT_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'ReportAdd',
      },
      breadcrumb: {
        head: { label: 'Legal', to: { name: routes.LEGAL_ROUTE } },
        tail: { label: 'DMCA', to: { name: routes.REPORT_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    props: {
      agreementType: 'report',
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/teaminvite',
    name: routes.TEAM_INVITE_ROUTE,
    component: () =>
      import(/* webpackChunkName: 'teaminvite' */ '@views/TeamInvite.vue'),
  },
  {
    path: '/notifications/unsubscribe',
    name: routes.UNSUBSCRIBE_ROUTE,
    meta: {
      breadcrumb: {
        head: { label: 'Profile', to: { name: routes.PROFILE_ACCOUNT_ROUTE } },
        tail: {
          label: 'Notification settings',
          to: { name: routes.UNSUBSCRIBE_ROUTE },
        },
      },
    },
    component: () =>
      import(
        /* webpackChunkName: 'teaminvite' */ '@views/UserNotificationSettings.vue'
      ),
  },
  {
    path: '/status',
    name: routes.STATUS_ROUTE,
    meta: {
      noSidebar: true,
      seo: [
        {
          tag: 'title',
          content: seo.STATUS_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.STATUS_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'Status', to: { name: routes.STATUS_ROUTE } },
      },
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@views/Status.vue'),
  },
  {
    path: '/login',
    name: routes.LOGIN_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.LOGIN_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.LOGIN_ROUTE_DESCRIPTION_META,
        },
      ],
      widget: {
        component: 'LoginWidget',
      },
      theme: THEME_DEFAULT,
    },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Login.vue'),
  },
  {
    path: '/legal',
    name: routes.LEGAL_ROUTE,
    meta: { theme: THEME_DEFAULT },
    component: () => import(/* webpackChunkName: 'about' */ '@views/Legal.vue'),
  },
  {
    path: '/logout',
    name: routes.LOGOUT_ROUTE,
    meta: { theme: THEME_DEFAULT },
    component: () =>
      import(/* webpackChunkName: 'about' */ '@views/Logout.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: routes.FOUROHFOUR_ROUTE,
    component: () => import(/* webpackChunkName: "404" */ '@views/404.vue'),
  },
]

if (!['production', 'test'].includes(MODIO_ENV)) {
  publicRoutes.push(
    {
      path: '/styles',
      name: routes.STYLE_GUIDE_ROUTE,
      meta: {
        breadcrumb: {
          head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
          tail: { label: 'Design', to: { name: routes.STYLE_GUIDE_ROUTE } },
        },
      },
      component: () =>
        import(/* webpackChunkName: 'style-guide' */ '@views/StyleGuide.vue'),
    },
    {
      path: '/icons',
      name: routes.ICONS_ROUTE,
      meta: {
        breadcrumb: {
          head: { label: 'About', to: { name: routes.ABOUT_ROUTE } },
          tail: { label: 'Icons', to: { name: routes.ICONS_ROUTE } },
        },
        theme: THEME_DEFAULT,
      },
      component: () =>
        import(/* webpackChunkName: 'icons' */ '@views/Icons.vue'),
    }
  )
}
