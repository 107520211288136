import { GUIDE_FILTERS_TO_HIDE } from '@config/guides.js'
import { ITEM_TYPES } from '@config/options.js'
import { MOD_FILTERS_TO_HIDE } from '@config'
import { useRoute } from '@composables'
import { computed } from 'vue'
import {
  GUIDE_ADMIN_EDIT_ROUTE,
  MOD_ADMIN_ROUTE,
  USER_ROUTE,
} from '@config/routeNames.js'

export default function (game, item, itemType) {
  const { routeName } = useRoute()

  const hideTags = computed(
    () =>
      !item.value ||
      !game.value ||
      routeName.value.includes(MOD_ADMIN_ROUTE) ||
      routeName.value.includes(GUIDE_ADMIN_EDIT_ROUTE)
  )

  const tagName = computed(() => {
    if (hideTags.value && !routeName.value.includes(USER_ROUTE)) return ''

    const filteredTagOptions = getTagOptions()
    const allTagNames = filteredTagOptions.flatMap((tagGroup) => tagGroup.tags)

    if (itemType.value === ITEM_TYPES.GUIDE || !game.value) {
      return item.value.tags.find((tag) => allTagNames.includes(tag.name))
    }

    const typeOption = filteredTagOptions?.find(
      (x) => x.name.toLowerCase() === 'type'
    )

    let tagMatch = item.value.tags.find((tag) =>
      typeOption?.tags?.includes(tag.name)
    )

    if (!tagMatch) {
      tagMatch = item.value.tags.find((tag) => allTagNames.includes(tag.name))
    }

    return tagMatch
  })

  const subTagName = computed(() => {
    if (
      hideTags.value ||
      itemType.value === ITEM_TYPES.GUIDE ||
      !tagName.value
    ) {
      return ''
    }

    const filteredTagOptions = getTagOptions()

    const typeOption = filteredTagOptions?.find((tagOption) => {
      const parentTagName = tagName.value?.name?.toLowerCase()
      const optionName = (
        tagOption.name_localized ?? tagOption.name
      ).toLowerCase()

      return (
        tagOption.name.toLowerCase().includes(parentTagName) ||
        parentTagName.includes(optionName)
      )
    })

    const tagMatch = item.value.tags.find((tag) =>
      typeOption?.tags?.includes(tag.name)
    )

    return tagMatch !== tagName.value ? tagMatch : ''
  })

  function getTagOptions() {
    const toHide =
      (itemType.value === ITEM_TYPES.MOD
        ? MOD_FILTERS_TO_HIDE[game.value?.name_id]
        : GUIDE_FILTERS_TO_HIDE) || []

    return itemType.value === ITEM_TYPES.MOD && game.value
      ? game.value.tag_options?.filter(
          (tagOption) =>
            !toHide.includes(tagOption.name.toLowerCase()) && !tagOption.hidden
        )
      : [
          {
            tags: item.value.tags
              .filter((tag) => !toHide.includes(tag.name))
              .map((tag) => tag.name),
          },
        ]
  }

  return {
    subTagName,
    tagName,
  }
}
