import { clone, isObjEmpty, cleanHTMLEntities } from '@helpers/utils.js'
import { convertToDecimal } from '@helpers/monetizationUtils.js'
import Serializer, { extractCardImage } from './Serializer.js'
import modDefaults, { modStatsDefaults } from '@types/mod.js'
import { PLACEHOLDER_MOD_CARD_IMAGE } from '@config'

const transformations = [
  _addDefaultModStats,
  _addPopularKey,
  _extractPrice,
  _addRatingKey,
  _addGameNameId,
  _clean,
  extractCardImage(PLACEHOLDER_MOD_CARD_IMAGE),
]

const modSerializer = new Serializer({
  transformations,
  defaults: modDefaults,
})

function _addPopularKey(mod) {
  const modCopy = { ...mod }
  if (modCopy.stats && modCopy.stats.popular) {
    modCopy.stats.popular = mod.stats.popularity_rank_position
  }
  return modCopy
}

function _addDefaultModStats(mod) {
  const modCopy = { ...mod }
  if (isObjEmpty(modCopy.stats)) {
    modCopy.stats = clone(modStatsDefaults)
    modCopy.stats.mod_id = mod.id
  }
  return modCopy
}

function _addRatingKey(mod) {
  const modCopy = { ...mod }
  if (modCopy.stats && modCopy.stats.rating) {
    modCopy.stats.rating = mod.stats.ratings_total
  }
  return modCopy
}

// update api price to decimal format for display
function _extractPrice(mod) {
  if (mod.price) {
    mod.price_dollars = convertToDecimal(mod.price)
  }
  return mod
}

function _clean(mod) {
  mod.name = cleanHTMLEntities(mod.name)
  mod.summary = cleanHTMLEntities(mod.summary)
  return mod
}

function _addGameNameId(mod) {
  if (!mod.game_name_id) {
    mod.game_name_id = mod.profile_url.match(/(\/g\/)([\w-]+)/)?.[2] || ''
  }
  return mod
}

export default modSerializer
