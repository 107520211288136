<template>
  <base-modal :show="show" size="md" @close:modal="$emit('close:modal')">
    <template #header>
      <div class="tw-flex tw-justify-center">
        <base-icon icon="modio_unreg_full" class="tw-w-40 tw-fill-current" />
      </div>
    </template>

    <span
      v-if="message"
      class="tw-block tw-text-center"
      :class="{ 'tw-mb-6': showButton }"
    >
      {{ message }}
    </span>
    <div
      v-if="showButton"
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-0 xs:tw-px-8 sm:tw-px-12 tw-text-white tw-mb-6"
    >
      <base-button :[buttonType]="true" full-width :status="status">
        {{ buttonText }}
      </base-button>
    </div>

    <term-privacy-modal-link v-if="showTerms" />
  </base-modal>
</template>

<script>
import TermPrivacyModalLink from '@components/Modals/TermPrivacyModalLink.vue'

export default {
  components: {
    TermPrivacyModalLink,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Loading',
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    status: {
      type: String,
      default: null,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    showTerms: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close:modal'],
}
</script>
