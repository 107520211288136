<template>
  <button
    class="tw-group tw-outline-none hover:tw-bg-theme-1 focus:tw-bg-theme-1"
    @click="goTo"
  >
    <div
      class="tw-flex tw-text-left tw-pl-3 md:tw-pl-2 tw-pr-2 md:tw-pr-3 tw-py-2 tw-relative tw-bg-theme-1--hover tw-transition-colors tw-border-b tw-border-theme-1"
    >
      <div
        class="tw-absolute tw-top-0 tw-left-0 md:tw-left-auto md:tw-right-0 tw-h-full tw-w-2"
        :class="readStyles"
      />
      <div
        class="tw-size-10 tw-shrink-0 tw-mb-2 tw-mr-3 tw-ml-1 md:tw-ml-0 tw-relative"
      >
        <div
          class="tw-size-full tw-rounded-full tw-bg-no-repeat tw-bg-cover tw-bg-center"
          :style="`background-image: url(${logo})`"
        />
        <div
          class="tw-p-2.5 tw-size-6 tw-rounded-full tw-absolute tw--bottom-2 tw--right-2 tw-flex tw-items-center tw-justify-center tw-border-2 tw-border-theme group-hover:tw-border-dark-1 group-focus:tw-border-dark-1 tw-bg-primary tw-text-primary-text"
        >
          <font-awesome-icon v-if="icon" :icon="icon" />
        </div>
      </div>
      <div class="tw-space-y-2 tw-pt-0.5">
        <p
          v-decode="notification.message"
          class="tw-break-words tw-font-normal tw-leading-normal"
          :class="{ 'tw-opacity-70': notification.read }"
          style="word-break: break-word"
        />
        <span class="tw-opacity-70 tw-text-xs">{{ time }} ago</span>
      </div>
    </div>
  </button>
</template>

<script>
import { markNotificationAsReadRequest } from '@services'
import { useAsync, useRoute } from '@composables'
import { timeSince } from '@helpers/utils.js'
import { notificationStore } from '@stores'
import { computed, toRefs } from 'vue'
import {
  MOD_ADMIN_SETTINGS_ROUTE,
  PROFILE_CONTENT_ROUTE,
  GUIDE_VIEW_ROUTE,
  MOD_VIEW_ROUTE,
} from '@config/routeNames.js'
import {
  NOTIFICATION_COMMENT_REPLY_ON_OWNED_THREAD,
  NOTIFICATION_COMMENT_ON_TEAM_RESOURCE,
  NOTIFICATION_TEAM_RESOURCE_PENDING,
  NOTIFICATION_MOD_PLATFORM_APPROVED,
  NOTIFICATION_MOD_PLATFORM_DENIED,
  NOTIFICATION_PREVIEW_INVITE,
  NOTIFICATION_MOD_MODERATION,
  NOTIFICATION_MOD_SUBSCRIBER,
  NOTIFICATION_TEAM_INVITE,
  CARD_IMAGE_KEY,
} from '@config'
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { notification } = toRefs(props)
    const { push } = useRoute()
    const time = timeSince(notification.value.date_added)

    const { markNotificationAsRead, rollback } = notificationStore()
    const { run, error } = useAsync((notificationId) =>
      markNotificationAsReadRequest(notificationId)
    )

    const logo = computed(
      () => notification.value?.metadata?.logo[CARD_IMAGE_KEY]
    )

    const icon = computed(() => {
      if (_commentType()) {
        return 'comment-dots'
      } else if (_teamType()) {
        return 'user-friends'
      } else if (_previewType()) {
        return 'search'
      } else if (_moderationType() || _subscriptionType()) {
        return 'cog'
      } else {
        return null
      }
    })

    const readStyles = computed(() =>
      notification.value.read
        ? 'tw-bg-theme-2'
        : 'tw-bg-primary tw-text-primary-text'
    )

    function goTo() {
      const gameNameId = notification.value.metadata.game_name_id
      const resourceType = notification.value.metadata.resource_type
      const resourceNameId = notification.value.metadata.resource_name_id

      if (!notification.value.read) {
        _markNotificationAsRead(notification.value.id)
      }

      if (_commentType()) {
        switch (resourceType) {
          case 'mods':
            push({
              name: MOD_VIEW_ROUTE,
              params: { game: gameNameId, mod: resourceNameId },
              hash: `#${notification.value.metadata.comment_id}`,
            })
            break
          case 'articles':
            push({
              name: GUIDE_VIEW_ROUTE,
              params: { game: gameNameId, guide: resourceNameId },
              hash: `#${notification.value.metadata.comment_id}`,
            })
            break
          default:
            console.error('unknown resource type')
        }
      } else if (_subscriptionType() || _previewType()) {
        push({
          name: MOD_VIEW_ROUTE,
          params: { game: gameNameId, mod: resourceNameId },
        })
      } else if (_moderationType()) {
        push({
          name: MOD_ADMIN_SETTINGS_ROUTE,
          params: { game: gameNameId, mod: resourceNameId },
        })
      } else if (_teamType()) {
        push({
          name: PROFILE_CONTENT_ROUTE,
        })
      }
    }

    async function _markNotificationAsRead(notificationId) {
      markNotificationAsRead(notificationId)

      await run(notificationId)

      if (error.value) {
        rollback()
      }
    }

    function _commentType() {
      return (
        notification.value.type <= NOTIFICATION_COMMENT_REPLY_ON_OWNED_THREAD ||
        notification.value.type === NOTIFICATION_COMMENT_ON_TEAM_RESOURCE
      )
    }

    function _previewType() {
      return notification.value.type === NOTIFICATION_PREVIEW_INVITE
    }

    function _moderationType() {
      return (
        notification.value.type === NOTIFICATION_MOD_MODERATION ||
        notification.value.type === NOTIFICATION_MOD_PLATFORM_APPROVED ||
        notification.value.type === NOTIFICATION_MOD_PLATFORM_DENIED
      )
    }

    function _subscriptionType() {
      return notification.value.type === NOTIFICATION_MOD_SUBSCRIBER
    }

    function _teamType() {
      return (
        notification.value.type === NOTIFICATION_TEAM_INVITE ||
        notification.value.type === NOTIFICATION_TEAM_RESOURCE_PENDING
      )
    }

    return {
      readStyles,
      goTo,
      logo,
      icon,
      time,
    }
  },
}
</script>
