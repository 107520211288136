import { computed, nextTick, ref, watch } from 'vue'
import { MODIO_ENV, TEST_ENV } from '@config'
import { Duration, DateTime } from 'luxon'
import { authStore } from '@stores'
import {
  GAME_PLATFORM_LABELS,
  STATUS_NOT_ACCEPTED,
  STATUS_ACCEPTED,
  STATUS_DELETED,
  VISIBLE_HIDDEN,
  VISIBLE_PUBLIC,
} from '@config/options.js'
import {
  isObjEmpty,
  secsToMs,
  msToSecs,
  isArray,
  isEqual,
  clone,
} from '@helpers/utils.js'

export const SUBSCRIBED_MODS_FILTER_OPTION = 'My Subscriptions'
export const PURCHASED_MODS_FILTER_OPTION = 'My Purchases'
export const ADMIN_MODS_FILTER_OPTION = 'Admin Options'
export const ADMIN_GUIDES_FILTER_OPTION = 'Admin Options'
export const GUIDE_FILTER_OPTION = 'Guide filter'
export const MY_GAMES_FILTER_OPTION = 'My Games'
export const PLATFORM_OPTION = 'Platforms'
export const MY_FILTER_NAME = 'Filter'
export const DATE_FILTER = 'Date live'

const PENDING_FILTER = { text: 'Show pending', value: 'pending' }
const DELETED_FILTER = { text: 'Show deleted', value: 'deleted' }
const HIDDEN_FILTER = { text: 'Show hidden', value: 'hidden' }

const ADMIN_FILTERS = [HIDDEN_FILTER, PENDING_FILTER, DELETED_FILTER]
const ADMIN_FILTERS_GUIDES = [PENDING_FILTER, DELETED_FILTER]

const GAME_FILTER_ITEM = [
  {
    id: MY_GAMES_FILTER_OPTION.replace(' ', '-'),
    hidden: true,
    name: MY_FILTER_NAME,
    selected: MODIO_ENV === TEST_ENV ? [MY_GAMES_FILTER_OPTION] : [],
    tags: [MY_GAMES_FILTER_OPTION],
    type: 'dropdown',
  },
]

const DATE_FILTER_ITEMS = [
  'Last day',
  'Last week',
  'Last month',
  'Last 3 months',
  'Last year',
]

const DATE_FILTER_ITEM = {
  id: DATE_FILTER.replace(' ', '-'),
  hideInTagsForm: true,
  name: DATE_FILTER,
  selected: [],
  tags: DATE_FILTER_ITEMS,
  type: 'dropdown',
}

const MOD_FILTER_ITEM = [
  {
    id: SUBSCRIBED_MODS_FILTER_OPTION.replace(' ', '-'),
    hidden: true,
    hideInTagsForm: true,
    name: MY_FILTER_NAME,
    selected: [],
    tags: [SUBSCRIBED_MODS_FILTER_OPTION],
    type: 'dropdown',
  },
  {
    id: PURCHASED_MODS_FILTER_OPTION.replace(' ', '-'),
    hidden: true,
    hideInTagsForm: true,
    name: MY_FILTER_NAME,
    selected: [],
    tags: [PURCHASED_MODS_FILTER_OPTION],
    type: 'dropdown',
  },
  {
    id: ADMIN_MODS_FILTER_OPTION.replace(' ', '-'),
    hideInTagsForm: true,
    statusOption: true,
    name: ADMIN_MODS_FILTER_OPTION,
    selected: [],
    tags: ADMIN_FILTERS.map((f) => f.text),
    type: 'checkboxes',
  },
  DATE_FILTER_ITEM,
]

const state = ref({})
const loadingRef = ref({})
const hasBeenReset = ref({})

export default function (
  id,
  {
    showEmpty = false,
    showHidden = false,
    tagsForm = false,
    showStatusOptions = false,
  } = {}
) {
  if (!id) {
    throw new Error('useFilter requires id')
  }
  if (loadingRef.value[id] === undefined) {
    loadingRef.value[id] = true
  }

  const { platform } = authStore()

  const filter = computed(() => state.value[id] || [])

  const loading = computed(() => loadingRef.value[id])

  const activeFilters = computed(() => {
    return _getSelected(filter.value).map(({ id, filter, tag, type }) => ({
      id,
      filter,
      tag,
      active: true,
      type,
    }))
  })

  const isFiltering = computed(() => _getSelected(filter.value).length > 0)

  const getFilter = computed(() => (state.value[id] ? _getQueryObj() : null))

  const sideNavFilters = computed(() => {
    return state.value[id]
      ? state.value[id]
          .filter(
            ({ hidden, groupDeleted, name, hideInTagsForm, statusOption }) => {
              const show = !hidden || showHidden
              const showOption = !statusOption || showStatusOptions
              return (
                show &&
                showOption &&
                !groupDeleted &&
                name !== MY_FILTER_NAME &&
                (!tagsForm || !hideInTagsForm) &&
                (!platform.value || name !== PLATFORM_OPTION)
              )
            }
          )
          .map((tag) => (showEmpty ? tag : _removeTagsWithNoRecords(tag)))
      : []
  })

  function _removeTagsWithNoRecords(tag) {
    if (tag.tag_count_map || tag.count) {
      const temp = clone(tag)
      const tags = temp.tags.filter(
        (t) =>
          (tag.tag_count_map?.[t] || 0) !== 0 || (tag.count?.[t] || 0) !== 0
      )
      temp.tags = tags
      return temp
    }
    return tag
  }

  function updateFilter(option) {
    const updateTags = [...state.value[id]]
    const index = updateTags.findIndex((tag) => tag.id === option.id)

    if (index !== -1) {
      const tag = { ...updateTags[index] }

      switch (option.type) {
        case 'dropdown':
          tag.selected = _handleDropboxes(tag, option)
          break
        case 'checkboxes':
          tag.selected = _handleCheckboxes(tag, option)
          break
      }

      updateTags.splice(index, 1, tag)
    }

    if (isEqual(state.value[id], updateTags)) return

    state.value[id] = [...updateTags]
  }

  function resetFilter() {
    const newFilters = state.value?.[id]?.map((filter) => ({
      ...filter,
      selected: [],
    }))

    state.value[id] = newFilters || []
    hasBeenReset.value[id] = true
  }

  function setFilter(options) {
    // set filter after the next dom update
    setFilterLoadingDone()

    return nextTick(() => {
      if (!isArray(options)) {
        state.value[id] = []
      } else {
        state.value[id] = [...options]
      }
    })
  }

  function setFilterLoadingDone() {
    // set loadingRef to false after the next dom update
    nextTick(() => (loadingRef.value[id] = false))
  }

  function isFiltered() {
    return filter.value && filter.value.length !== 0
  }

  function inMemoryFilter(items, cloneData = true) {
    let resultsCopy = cloneData ? clone(items) : items

    activeFilters.value
      .filter(
        (f) =>
          f.tag !== SUBSCRIBED_MODS_FILTER_OPTION &&
          f.tag !== PURCHASED_MODS_FILTER_OPTION &&
          f.filter !== ADMIN_MODS_FILTER_OPTION
      )
      .forEach(({ tag, filter }) => {
        if (filter === PLATFORM_OPTION) {
          const _platform = Object.entries(GAME_PLATFORM_LABELS).find(
            ([, value]) => value === tag
          )[0]
          resultsCopy = resultsCopy.filter(
            (item) =>
              !item.platforms ||
              item.platforms.some(({ platform }) => platform === _platform)
          )
        } else if (filter === DATE_FILTER) {
          resultsCopy = resultsCopy.filter((item) => {
            const dur = Duration.fromMillis(
              Date.now() - secsToMs(item.date_live)
            )
            switch (tag) {
              case DATE_FILTER_ITEMS[0]:
                return dur.as('days') <= 1
              case DATE_FILTER_ITEMS[1]:
                return dur.as('days') <= 7
              case DATE_FILTER_ITEMS[2]:
                return dur.as('months') <= 1
              case DATE_FILTER_ITEMS[3]:
                return dur.as('months') <= 3
              case DATE_FILTER_ITEMS[4]:
                return dur.as('years') <= 1
            }
          })
        } else {
          resultsCopy = resultsCopy.filter((item) => {
            return (
              !item.tags ||
              item.tags.some(
                ({ name }) => name.toLowerCase() === tag.toLowerCase()
              )
            )
          })
        }
      })

    return resultsCopy ? resultsCopy : []
  }

  function watchFilters(callback, immediate = true) {
    watch(
      filter,
      (newValue) => {
        callback(newValue)
      },
      { immediate }
    )
  }

  function _handleDropboxes(tag, options) {
    return tag.selected.includes(options.value) ? [] : [options.value]
  }

  function _handleCheckboxes(tag, options) {
    return tag.selected.includes(options.value)
      ? tag.selected.filter((item) => item !== options.value)
      : [...tag.selected, options.value]
  }

  function _getSelected(filters) {
    const selected = []

    filters
      .filter((filter) => filter.selected.length !== 0)
      .forEach((filtered) => {
        filtered.selected.forEach((tagName) => {
          selected.push({
            id: filtered.id,
            filter: filtered.name,
            tag: tagName,
            type: filtered.type,
          })
        })
      })

    return selected
  }

  function _getQueryObj() {
    const selected = _getSelected(filter.value)
    if (selected.length === 0) {
      return {}
    }

    const queryObj = {}
    queryObj['tags-in'] = selected
      .filter(
        (_selected) =>
          _selected.tag !== SUBSCRIBED_MODS_FILTER_OPTION &&
          _selected.tag !== PURCHASED_MODS_FILTER_OPTION &&
          _selected.filter !== ADMIN_MODS_FILTER_OPTION &&
          _selected.filter !== PLATFORM_OPTION &&
          _selected.filter !== DATE_FILTER
      )
      .map((_selected) => _selected.tag)
      .join(',')

    queryObj['platforms'] = selected
      .filter((_selected) => _selected.filter === PLATFORM_OPTION)
      .map((_selected) => {
        return Object.entries(GAME_PLATFORM_LABELS).find(
          ([, value]) => value === _selected.tag
        )[0]
      })
      .join(',')

    queryObj.date_live = selected.find(
      (_selected) => _selected.filter === DATE_FILTER
    )?.tag

    mapStatusFilters(queryObj, selected)

    return queryObj
  }

  return {
    setFilterLoadingDone,
    inMemoryFilter,
    sideNavFilters,
    activeFilters,
    updateFilter,
    watchFilters,
    resetFilter,
    isFiltering,
    isFiltered,
    setFilter,
    getFilter,
    loading,
    filter,
  }
}

export function normalizeGameFilters(filterQuery) {
  // default game filter item
  return filterQuery && !isObjEmpty(filterQuery)
    ? _mergeFilterQuery(GAME_FILTER_ITEM, filterQuery)
    : GAME_FILTER_ITEM
}

export function normalizeModFilters(options, filterQuery) {
  // default mod filter item
  const filters = [...MOD_FILTER_ITEM, ...options]
  return filterQuery && !isObjEmpty(filterQuery)
    ? _mergeFilterQuery(filters, filterQuery)
    : filters
}

export function normalizeGuideFilters(tags, tagCountMap, filterQuery) {
  const filters = [
    {
      id: ADMIN_GUIDES_FILTER_OPTION.replace(' ', '-'),
      hideInTagsForm: true,
      statusOption: true,
      name: ADMIN_GUIDES_FILTER_OPTION,
      selected: [],
      tags: ADMIN_FILTERS_GUIDES.map((f) => f.text),
      type: 'checkboxes',
    },
    DATE_FILTER_ITEM,
    ..._getGuideFilterOption(tags, tagCountMap),
  ]
  return filterQuery && !isObjEmpty(filterQuery)
    ? _mergeFilterQuery(filters, filterQuery)
    : filters
}

function _getGuideFilterOption(tags, tag_count_map) {
  return [
    {
      id: 'T-0',
      hidden: false,
      name: GUIDE_FILTER_OPTION,
      selected: [],
      tags,
      type: 'checkboxes',
      tag_count_map,
    },
  ]
}

export function getAPIFilter(query) {
  if (!query.admin) return query

  query['status-in'] = [STATUS_ACCEPTED]

  query.admin.split(',').forEach((q) => {
    switch (q) {
      case HIDDEN_FILTER.value:
        query['visible-in'] = `${VISIBLE_HIDDEN},${VISIBLE_PUBLIC}`
        break

      case PENDING_FILTER.value:
        query['status-in'].unshift(STATUS_NOT_ACCEPTED)
        break

      case DELETED_FILTER.value:
        query['status-in'].push(STATUS_DELETED)
        break
    }
  })

  if (query['status-in'].length > 1) {
    query['status-in'] = query['status-in'].join(',')
  } else {
    delete query['status-in']
  }
  delete query.admin
  return query
}

export function getDateFilter(query) {
  if (!query.date_live) return query

  let dateTime = new DateTime({}).set({ minute: 0, second: 0, millisecond: 0 })
  switch (query.date_live) {
    case DATE_FILTER_ITEMS[0]:
      dateTime = dateTime.minus({ days: 1 })
      break
    case DATE_FILTER_ITEMS[1]:
      dateTime = dateTime.minus({ days: 7 })
      break
    case DATE_FILTER_ITEMS[2]:
      dateTime = dateTime.minus({ months: 1 }).set({ hour: 0 })
      break
    case DATE_FILTER_ITEMS[3]:
      dateTime = dateTime.minus({ months: 3 }).set({ hour: 0 })
      break
    case DATE_FILTER_ITEMS[4]:
      dateTime = dateTime.minus({ years: 1 }).set({ hour: 0 })
      break
  }

  query['date_live-min'] = msToSecs(dateTime.ts)
  delete query.date_live

  return query
}

function _mergeFilterQuery(options, filterQuery) {
  const filters = clone(options)

  Object.entries(filterQuery).forEach(([key, queryString]) => {
    const tags = queryString.split(',').map((t) => t.toLowerCase())

    switch (key) {
      case 'tags-in':
        filters.forEach((f) => {
          if (
            f.id.startsWith('T-') ||
            f.id === MY_GAMES_FILTER_OPTION.replace(' ', '-')
          ) {
            f.tags.forEach((optionTag) => {
              if (tags.includes(optionTag.toLowerCase())) {
                f.selected.push(optionTag)
              }
            })
          }
        })
        break

      case 'platforms': {
        const filter = filters.find((f) => f.name === PLATFORM_OPTION)
        if (filter) {
          filter.tags.forEach((platformLabel) => {
            const tag = Object.entries(GAME_PLATFORM_LABELS).find(
              ([, label]) => label === platformLabel
            )
            if (tag && tags.includes(tag[0])) {
              filter.selected.push(tag[1])
            }
          })
        }
        break
      }

      case 'admin': {
        const filter = filters.find((f) => f.name === ADMIN_MODS_FILTER_OPTION)
        if (filter) {
          filter.selected.push(
            ...ADMIN_FILTERS.filter((a) => tags.includes(a.value)).map(
              (a) => a.text
            )
          )
        }
        break
      }

      case 'date_live': {
        const filter = filters.find((f) => f.name === DATE_FILTER)
        if (filter) {
          filter.selected = [queryString.split(',')[0]]
        }
        break
      }
    }
  })

  return filters
}

function mapStatusFilters(queryObj, selected) {
  queryObj.admin = []

  selected.forEach((f) => {
    if (f.filter === ADMIN_MODS_FILTER_OPTION) {
      const filter = ADMIN_FILTERS.find((f2) => f.tag === f2.text)
      if (filter) {
        queryObj.admin.push(filter.value)
      }
    }
  })

  if (queryObj.admin.length) {
    queryObj.admin = queryObj.admin.join(',')
  } else {
    delete queryObj.admin
  }
}
