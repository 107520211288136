<template>
  <nav
    v-if="!routeMeta.noSidebar"
    class="tw-fixed tw-top-0 tw-left-0 tw-z-24 tw-w-full md:tw-hidden"
  >
    <div class="tw-px-4 sm:tw-px-6 md:tw-bg-dark-3" :class="headerBg">
      <div
        class="tw-flex tw-items-center tw-justify-between tw-h-16 tw-relative"
      >
        <mob-menu class="tw-flex md:tw-hidden" />

        <router-link
          :to="{ name: HOME_ROUTE }"
          class="tw-block md:tw-hidden tw-outline-none tw-text-current focus:tw-text-primary focus:tw-outline-current tw-mx-auto tw-w-24 tw-h-8"
          :class="
            MODIO_ENV === 'test'
              ? 'tw-hidden xxs:tw-block'
              : 'tw-absolute tw-inset-x-0'
          "
          @click="closeMobMenu"
        >
          <base-icon
            icon="modio_unreg_full"
            class="tw-flex md:tw-hidden tw-fill-current tw-size-full"
          />
        </router-link>

        <nav-menu />
      </div>
    </div>
  </nav>
</template>

<script>
import MobMenu from '@components/Sidebar/Mobile/MobMenu.vue'
import { useNavMenus, useRoute } from '@composables'
import NavMenu from '@components/Navbar/NavMenu.vue'
import { HOME_ROUTE } from '@config/routeNames.js'
import { MODIO_ENV } from '@config'
import { computed } from 'vue'
export default {
  components: {
    NavMenu,
    MobMenu,
  },
  setup() {
    const { closeMobMenu } = useNavMenus()
    const { getGameId, routeMeta } = useRoute()

    const headerBg = computed(() =>
      getGameId().value
        ? 'tw-bg-primary tw-text-primary-text'
        : 'tw-bg-mob-menu tw-text-dark-text'
    )

    return {
      closeMobMenu,
      HOME_ROUTE,
      MODIO_ENV,
      routeMeta,
      headerBg,
    }
  },
}
</script>
