import { getQueryFromPath } from '@helpers/utils.js'
import { useRoute } from '@composables'
import { computed, ref } from 'vue'

const state = ref({})

function _replaceState(option, replace, noHash = false) {
  const query = getQueryFromPath(window.location.href)
  if (noHash) {
    history.pushState(
      history.state,
      null,
      `/${option.toLowerCase().replace(/ /g, '-')}`
    )
  } else {
    replace({
      hash: `#${option.toLowerCase().replace(/ /g, '-')}`,
      query: query,
    })
  }
}

export default function (
  key,
  options,
  onTabUpdated,
  initialTab,
  noHash = false
) {
  const { routeHash, replace } = useRoute()

  const tab = computed(() => state.value[key])

  // can acquire current tab with key
  if (!options && !onTabUpdated) {
    return {
      updateTab,
      tab,
    }
  }

  let hashTabMatch = options.find(
    (o) =>
      o.value?.toLowerCase().replace(/ /g, '-') ===
      routeHash.value.replace('#', '')
  )

  const matchValue = hashTabMatch?.value || hashTabMatch?.text

  state.value[key] =
    matchValue ||
    (!noHash &&
      tab.value &&
      options.find((o) => o.value === tab.value)?.value) ||
    initialTab ||
    options?.[0]?.value

  if (tab.value && !noHash) {
    _replaceState(tab.value, replace, noHash)
  }

  function updateTab(newTab) {
    if (tab.value !== newTab) {
      state.value[key] = newTab
      onTabUpdated && onTabUpdated(newTab)
      _replaceState(newTab, replace, noHash)
    }
  }

  return {
    updateTab,
    tab,
  }
}
