<template>
  <inline-message full-height info :text="message" :has-close="false" />
</template>

<script>
import InlineMessage from '@components/Message/InlineMessage.vue'
import { toRefs, computed } from 'vue'

const DEFAULT_MESSAGE = 'No data available'
const DEFAULT_MESSAGE_METRICS =
  'Display will populate once sufficient data has been collected'

export default {
  components: {
    InlineMessage,
  },
  props: {
    metrics: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: DEFAULT_MESSAGE,
    },
  },
  setup(props) {
    const { text, metrics } = toRefs(props)
    const message = computed(() =>
      metrics.value && text.value === DEFAULT_MESSAGE
        ? DEFAULT_MESSAGE_METRICS
        : text.value
    )

    return {
      DEFAULT_MESSAGE,
      message,
    }
  },
}
</script>
