<template>
  <span
    class="tw-leading-none tw-px-1 tw-h-4 tw-min-w-2 tw-box-content tw-text-center tw-pointer-events-none"
    :class="[
      colorComp,
      borderComp,
      rounded === 'full' ? 'tw-rounded-full' : 'tw-rounded',
      size === 'small' ? 'tw-text-xxs' : 'tw-text-xs',
    ]"
  >
    <span class="tw-leading-4" :class="{ 'tw-opacity-70': color === 'dark' }">
      {{ text }}
    </span>
  </span>
</template>

<script>
import { useTheming } from '@composables'
import { toRefs, computed } from 'vue'

export default {
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      validator: (val) => ['small', 'large'].includes(val),
      default: 'small',
    },
    color: {
      type: String,
      validator: (val) => ['primary', 'dark', 'danger'].includes(val),
      default: 'primary',
    },
    rounded: {
      type: String,
      validator: (val) => ['full', 'small'].includes(val),
      default: 'full',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { color, border } = toRefs(props)
    const { lowDangerWCAGContrast } = useTheming()

    const colorComp = computed(() => {
      switch (color.value) {
        default:
          return 'tw-bg-primary tw-text-primary-text'

        case 'dark':
          return 'tw-bg-dark-3'

        case 'danger':
          return lowDangerWCAGContrast.value
            ? 'tw-bg-white tw-text-black'
            : 'tw-bg-danger tw-text-danger-text'
      }
    })

    const borderComp = computed(() => {
      if (border.value) {
        switch (color.value) {
          case 'dark':
            return 'tw-border tw-border-dark-1'

          case 'danger':
            return 'md:tw-border md:tw-border-primary'
        }
      }
      return ''
    })

    return {
      borderComp,
      colorComp,
    }
  },
}
</script>
