import { watchEffect, onMounted, readonly, ref } from 'vue'
import { GAME_INFO_HEADER_HEIGHT } from '@config'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { useScroll } from '@vueuse/core'

export default function useScrollTop(offsetEl) {
  const _hasScrolled = ref(false)
  onMounted(() => {
    const { y } = useScroll(document.getElementById(SCROLL_TO_ID))
    watchEffect(() => {
      _hasScrolled.value =
        y.value >
        GAME_INFO_HEADER_HEIGHT +
          (offsetEl?.value?.getBoundingClientRect?.()?.height ?? 0)
    })
  })

  return {
    hasScrolled: readonly(_hasScrolled),
  }
}
