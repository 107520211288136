import { AGREEMENT_TYPE_OPTIONS } from '@config/options.js'
import DOMPurify from 'dompurify'
import {
  CLEAN_COMMENT_LINKS_BELOW_KARMA,
  MODIO_ENV,
  SITE_URL,
  TEST_ENV,
} from '@config'

export default function format(input, commentKarma = 1) {
  // Extract mentions
  const mentionRegex =
    /<a href="https:\/\/(mod\.io|web-staging\.moddemo\.io|mod\.io\.dev\.internal)\/(u|g)\/[\w_-]+(\/(m|r)\/[\w_-]+)?">[\w_-]+<\/a>/g
  const mentions = input.match(mentionRegex)
  let i = 0
  let formattedInput = input.replace(mentionRegex, () => `@${i++}`)

  formattedInput = formattedInput.replace(/</g, '&lt;').replace(/>/g, '&gt;')

  if (commentKarma > CLEAN_COMMENT_LINKS_BELOW_KARMA) {
    formattedInput = formattedInput
      .replace(
        /(^|[\s>]+)([\w]+?:\/\/[\w#$%&~/.\-;:=,?!@[\]+]*)/gi,
        '$1<a target="_blank" rel="noreferrer noopener" href="$2">$2</a>'
      )
      .replace(
        /(^|[\s>]+)((www|ftp)\.[\w#$%&~/.\-;:=,?!@[\]+]*)/gi,
        '$1<a target="_blank" rel="noreferrer noopener" href="https://$2">$2</a>'
      )
      .replace(
        /(^|[\s>]+)([a-z0-9\-_.]+)@([^[,<\s]+)/gi,
        '$1<a href="mailto:$2@$3">$2@$3</a>'
      )
  }

  // mod.io links
  formattedInput = formattedInput.replace(
    /(?:>)https:\/\/(?:mod\.io|web-staging\.moddemo\.io|mod\.io\.dev\.internal)\/(?:u|g)\/([\w_-]+(\/(m|r|u)\/[\w_-]+)?)\/?(<)/g,
    (_, p1) => `>@${p1.split('/').slice(-1)}<`
  )

  // Youtube embed
  formattedInput = formattedInput.replace(
    /<a target="_blank" rel="noreferrer noopener" href="https?:\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)[^"]*">[^<]+<\/a>/gi,
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>'
  )

  // Add mentions back in
  formattedInput = formattedInput.replace(/@\d+/g, (match) => {
    const index = Number(match.slice(1))
    if (mentions?.[index]) {
      return mentions[index].replace(/>([\w_-]+)</, '>@$1<')
    }
    return match
  })

  return DOMPurify.sanitize(formattedInput, {
    ADD_ATTR: ['target', 'frameborder', 'allowfullscreen'],
    ADD_TAGS: ['iframe'],
  })
}

export function formatReport(text) {
  let formattedInput = text.replace(/</g, '&lt;').replace(/>/g, '&gt;')

  // mod.io links
  formattedInput = formattedInput.replace(
    /https:\/\/(?:mod\.io|web-staging\.moddemo\.io|mod\.io\.dev\.internal)\/(?:u|g)\/([\w_-]+(\/(m|r|u)\/[\w_-]+)?)(?:[^\s]*)/g,
    (p0, p1, p2) =>
      `<a href="${p0}" target="_blank" class="tw-link">@${(p2 || p1)
        ?.split('/')
        .pop()}</a>`
  )

  return formattedInput
}

export function formatAgreement(
  text,
  noLinks = false,
  modal = true,
  widget = false
) {
  let result = text
  if (noLinks) {
    return ('' + text).replace(/(<a [^>]*>)([^<]*)(<\/a>)/gi, '<u>$2</u>')
  }

  if (MODIO_ENV !== TEST_ENV) {
    AGREEMENT_TYPE_OPTIONS.forEach(({ value, paths }) => {
      paths.forEach((path) => {
        const href = `https://mod.io/${path}`
        const regex = new RegExp(
          `<a\\s+href=["]${href}["][^>]*>(.*?)<\\/a>`,
          'gi'
        )
        result = result?.replace(
          regex,
          modal
            ? `<a href="${href}" @click.prevent="openAgreementModal(${value})">$1</a>`
            : widget
              ? `<base-link to="${SITE_URL}/${path}/widget">$1</base-link>`
              : `<a href="${href}" @click.prevent="changeTab('${paths[0]}')">$1</a>`
        )
      })
    })
  }

  const regexCookies = new RegExp(
    `<a\\s+href=["]https://mod.io/me/privacy#cookies["][^>]*>(.*?)<\\/a>`,
    'gi'
  )
  result = result?.replace(
    regexCookies,
    '<base-button primary @click="openCookiesModal">Cookie settings</base-button>'
  )

  const regexReport = new RegExp(
    `<a\\s+href=["]https://mod.io/report["][^>]*>(.*?)<\\/a>`,
    'gi'
  )
  result = result?.replace(
    regexReport,
    `<a href="https://mod.io/report" @click.prevent="openReportModal">$1</a>`
  )

  // removes any existing target & rel attributes to prevent vue compile warning
  result = result
    ?.replace(new RegExp('target=["](.*?)["]', 'gi'), '')
    ?.replace(new RegExp('rel=["](.*?)["]', 'gi'), '')

  return ('' + result).replace(
    /<a\s+href=/gi,
    '<a target="_blank" rel="noreferrer noopener" href='
  )
}

export function formatDescription(text) {
  const div = document.createElement('div')
  div.innerHTML = text
  // Add link to all images that aren't direct descendants of a link
  div.querySelectorAll('img').forEach((n) => {
    if (n.parentElement.tagName !== 'A') {
      n.outerHTML = `<a href="${n.src}" target="_blank" rel="noreferrer noopener">${n.outerHTML}</a>`
    }
  })
  return div.innerHTML
}
