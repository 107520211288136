import { ref, readonly } from 'vue'

export default function () {
  const checkedIdsState = ref([])

  function toggleChecked(id) {
    const index = checkedIdsState.value.indexOf(id)
    if (index >= 0) checkedIdsState.value.splice(index, 1)
    else checkedIdsState.value.push(id)
  }

  function clearCheckedIds() {
    checkedIdsState.value = []
  }

  function setCheckedIds(val) {
    checkedIdsState.value = val
  }

  return {
    checkedIds: readonly(checkedIdsState),
    clearCheckedIds,
    setCheckedIds,
    toggleChecked,
  }
}
