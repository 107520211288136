<template>
  <div v-for="index in 4" :key="index">
    <div
      class="tw-flex tw-space-x-2 tw-px-2 tw-py-4 tw-animate-pulse tw-relative"
    >
      <div
        class="tw-absolute tw-top-0 tw-left-0 md:tw-left-auto md:tw-right-0 tw-h-full tw-w-2 tw-bg-theme-1"
      ></div>
      <div class="tw-size-10 tw-shrink-0 tw-relative">
        <div class="tw-size-full tw-rounded-full tw-bg-theme-1"></div>
      </div>
      <div class="tw-w-full tw-space-y-2 tw-pt-1">
        <div class="tw-flex tw-items-center tw-h-3" :class="twSizeContent()">
          <div class="tw-w-full tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
        </div>
        <div class="tw-flex tw-items-center tw-h-3" :class="twSize()">
          <div class="tw-w-full tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-h-px tw-bg-theme-1"></div>
  </div>
</template>

<script>
export default {
  setup() {
    const sizes = ['1/6', '1/5', '1/4']
    const content = ['3/6', '3/5', '3/4']

    function twSize() {
      let obj = sizes[Math.floor(Math.random() * sizes.length)]
      if (obj === '1/6') {
        return 'tw-w-1/6'
      } else if (obj === '1/5') {
        return 'tw-w-1/5'
      } else {
        return 'tw-w-1/4'
      }
    }
    function twSizeContent() {
      let obj = content[Math.floor(Math.random() * content.length)]
      if (obj === '3/6') {
        return 'tw-w-3/6'
      } else if (obj === '3/5') {
        return 'tw-w-3/5'
      } else {
        return 'tw-w-3/4'
      }
    }
    return {
      twSizeContent,
      twSize,
    }
  },
}
</script>
