<template>
  <div
    class="tw-fixed tw-bottom-3 tw-right-6 tw-flex tw-flex-col tw-items-end tw-z-50 tw-space-y-2"
  >
    <monetization-access v-if="MONETIZATION_DEBUG || statusBar" />

    <div class="tw-flex tw-items-center tw-h-12 tw-space-x-2">
      <monetization-admin v-if="MONETIZATION_DEBUG || statusBar" />

      <div class="tw-flex">
        <input
          :value="primaryColor"
          type="color"
          class="tw-size-8 tw-text-black"
          @input="(input) => updatePrimaryColor(input.target.value)"
        />

        <input
          :value="darkColor"
          type="color"
          class="tw-size-8 tw-text-black"
          @input="(input) => updateDarkColor(input.target.value)"
        />

        <input
          :value="successColor"
          type="color"
          class="tw-size-8 tw-text-black"
          @input="(input) => updateSuccessColor(input.target.value)"
        />

        <input
          :value="warningColor"
          type="color"
          class="tw-size-8 tw-text-black"
          @input="(input) => updateWarningColor(input.target.value)"
        />

        <input
          :value="dangerColor"
          type="color"
          class="tw-size-8 tw-text-black"
          @input="(input) => updateDangerColor(input.target.value)"
        />
      </div>

      <base-button small secondary @click="snowRunner"> SR </base-button>

      <base-button small secondary @click="modio"> mod </base-button>
    </div>
  </div>
</template>

<script>
import MonetizationAccess from '@components/Monetization/Panels/Test/MonetizationAccess.vue'
import MonetizationAdmin from '@components/Monetization/Panels/Test/MonetizationAdmin.vue'
import { useTheming, useStatusBar } from '@composables'
import { MONETIZATION_DEBUG } from '@config'

import {
  DEFAULT_SUCCESS,
  DEFAULT_WARNING,
  DEFAULT_DANGER,
  DEFAULT_PRIMARY,
  DEFAULT_LIGHT,
  DEFAULT_DARK,
} from '@config/colorsTheme.js'
export default {
  components: {
    MonetizationAccess,
    MonetizationAdmin,
  },
  setup() {
    const { statusBar } = useStatusBar()
    const {
      updateSuccessColor,
      updateWarningColor,
      updateDangerColor,
      updatePrimaryColor,
      updateDarkColor,
      primaryColor,
      successColor,
      warningColor,
      updateTheme,
      dangerColor,
      lightColor,
      darkColor,
      themeVars,
    } = useTheming()

    function snowRunner() {
      updateTheme({
        danger: '#ff000e',
        dark: '#000000',
        light: '#f2f2f2',
        primary: '#d2421e',
        success: '#68D391',
        warning: '#d6af2e',
      })
    }

    function modio() {
      updateTheme({
        danger: DEFAULT_DANGER,
        dark: DEFAULT_DARK,
        light: DEFAULT_LIGHT,
        primary: DEFAULT_PRIMARY,
        success: DEFAULT_SUCCESS,
        warning: DEFAULT_WARNING,
      })
    }

    return {
      updateSuccessColor,
      updateWarningColor,
      updatePrimaryColor,
      MONETIZATION_DEBUG,
      updateDangerColor,
      updateDarkColor,
      primaryColor,
      successColor,
      warningColor,
      dangerColor,
      snowRunner,
      lightColor,
      darkColor,
      themeVars,
      statusBar,
      modio,
    }
  },
}
</script>
