<template>
  <loading-radio-group
    v-if="inputLoading"
    :background="background"
    :options="options"
    :alt-bg="altBg"
  />
  <div
    v-else
    class="tw-flex"
    :class="[
      wrapClass,
      optionList || horizontal
        ? `tw-flex-row ${horizontal && 'tw-flex-wrap'}`
        : 'tw-flex-col',
    ]"
  >
    <base-input-label v-if="required" :required="required"></base-input-label>
    <div
      v-for="(option, index) in options"
      :key="index"
      :data-testid="
        UI_TEST_ENVS.includes(MODIO_ENV) && testId ? `${testId}-${index}` : ''
      "
      :class="{
        'tw-flex tw-items-center tw-text-center tw-flex-1':
          optionList || horizontal,
        'tw-mb-5 md:tw-mb-3 last:tw-mb-0': !optionList && !horizontal,
        'tw-mb-5 md:tw-mb-3 tw-mr-8 first:tw-ml-0': !optionList && horizontal,
      }"
    >
      <label
        class="tw-group tw-global--border-radius tw-flex tw-items-center tw-button-transition tw-relative tw-input--text-size tw-font-medium"
        :class="[
          inputBgStyles,
          disabled
            ? 'tw-cursor-not-allowed tw-opacity-50'
            : 'tw-cursor-pointer',
          background ? 'tw-input--px tw-input--min-height' : '',
          {
            'tw-flex tw-items-center tw-justify-center tw-text-center tw-flex-1 tw-input--px':
              optionList,
            'tw-capitalize': capsCase,
            'tw-input--height-large': optionList && !small,
            'tw-input--height-small': optionList && small,
          },
          errorStyles,
        ]"
      >
        <!-- hidden input is used for tabbing / focus -->
        <input
          class="tw-absolute tw-opacity-0 tw-size-0"
          :checked="modelValue === options[index]"
          type="radio"
          :name="`${inputId}RadioGroup`"
          :disabled="readonly"
          :tabindex="disabled ? '-1' : '0'"
          @click="updateCheckbox(index)"
        />
        <span
          class="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-button-transition tw-border-primary"
          :class="[
            isChecked(index),
            hasHover(index),
            optionList
              ? 'tw-absolute tw-top-0 tw-left-0 tw-size-full tw-global--border-radius'
              : 'tw-size-5 tw-rounded-full tw-mr-3',
          ]"
        >
          <span v-if="optionList">{{
            capsCase ? option.toLowerCase() : option
          }}</span>
        </span>

        <span v-if="!optionList">{{
          capsCase ? option.toLowerCase() : option
        }}</span>

        <span
          v-if="appendMap?.[option]"
          class="tw-ml-auto tw-pl-1 tw-text-right tw-opacity-50"
        >
          {{ appendMap[option].toLocaleString() }}
        </span>
      </label>
      <div
        v-if="modelValue === options[index] && $slots.trailing"
        class="tw-flex tw-mt-5 tw-ml-[2.375rem]"
      >
        <slot name="trailing" />
      </div>
    </div>

    <div
      v-if="hasErrors || hint"
      class="tw-w-full tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors :errors="errors" align="left" />

      <base-input-hint v-if="hint">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import LoadingRadioGroup from '@components/Loading/LoadingRadioGroup.vue'
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { toRefs, computed, inject } from 'vue'
import { genHtmlId } from '@helpers/utils.js'
export default {
  components: {
    LoadingRadioGroup,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    appendMap: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    background: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    wrapClass: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    capsCase: {
      type: Boolean,
      default: false,
    },
    optionList: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    hoverHighlight: {
      type: Boolean,
      default: true,
    },
    testId: {
      type: String,
      default: '',
    },
    hollow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const {
      hoverHighlight,
      background,
      modelValue,
      disabled,
      options,
      hollow,
      filter,
      errors,
      altBg,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()
    const readonly = inject('saving', false)

    const hasErrors = computed(() => !!errors.value?.length)

    const errorStyles = computed(() => {
      if (hasErrors.value) {
        return background.value
          ? 'tw-border-danger tw-border-2'
          : 'tw-text-danger'
      }
      return ''
    })

    const inputBgStyles = computed(() => {
      if (disabled.value) {
        return background.value ? 'tw-bg-theme-1' : ''
      }
      return background.value
        ? 'tw-bg-input-hover tw-input--focus-within'
        : 'tw-opacity-90 hover:tw-opacity-100'
    })

    function hasHover(index) {
      if (
        disabled.value ||
        modelValue.value === options.value[index] ||
        !hoverHighlight.value
      ) {
        return ''
      }

      if (altBg.value) {
        return background.value ? '' : 'tw-bg-theme--group-hover'
      } else if (filter.value) {
        return ''
      } else {
        return background.value ? '' : 'tw-bg-theme-2--group-hover'
      }
    }

    function isChecked(index) {
      if (modelValue.value === options.value[index]) {
        if (hollow.value) return 'tw-bg-none tw-border'
        return 'tw-bg-primary tw-text-primary-text'
      } else if (altBg.value) {
        return background.value
          ? ''
          : 'tw-bg-theme tw-opacity-40 group-hover:tw-opacity-100'
      } else if (filter.value) {
        return background.value
          ? 'tw-bg-theme'
          : 'tw-bg-theme-text tw-opacity-20 group-hover:tw-opacity-50 group-focus:tw-opacity-50'
      } else {
        return background.value ? 'tw-bg-theme' : 'tw-bg-theme-1'
      }
    }

    function updateCheckbox(value) {
      if (!disabled.value) {
        emit('input', value)
      }
    }

    return {
      updateCheckbox,
      inputBgStyles,
      UI_TEST_ENVS,
      errorStyles,
      MODIO_ENV,
      hasErrors,
      isChecked,
      hasHover,
      readonly,
      inputId,
    }
  },
}
</script>
