import {
  MONETIZATION_TERMS_ROUTE,
  REFUND_POLICY_ROUTE,
  TRADEMARKS_ROUTE,
  GAME_ADD_ROUTE,
  CAREERS_ROUTE,
  CONTACT_ROUTE,
  PRIVACY_ROUTE,
  COOKIES_ROUTE,
  STATUS_ROUTE,
  BRAND_ROUTE,
  ABOUT_ROUTE,
  TERMS_ROUTE,
  DOCS_ROUTE,
  AUP_ROUTE,
} from '@config/routeNames.js'

export const NAV_LINKS = [
  { label: 'Company' },
  { link: ABOUT_ROUTE, name: 'About' },
  { link: 'https://blog.mod.io', name: 'Blog', external: true },
  { link: CAREERS_ROUTE, name: 'Careers' },
  { link: CONTACT_ROUTE, name: 'Contact' },
  { link: 'https://newsletter.mod.io', name: 'Newsletter', external: true },
  { link: STATUS_ROUTE, name: 'Status' },
  { link: BRAND_ROUTE, name: 'Brand' },

  { label: 'Studios' },
  { link: GAME_ADD_ROUTE, name: 'Add your game' },
  { link: DOCS_ROUTE, name: 'Documentation' },

  { label: 'Legal' },
  { link: TERMS_ROUTE, name: 'Terms', modal: true },
  { link: AUP_ROUTE, name: 'Acceptable Use', modal: true },
  { link: PRIVACY_ROUTE, name: 'Privacy', modal: true },
  { link: COOKIES_ROUTE, name: 'Cookies', modal: true },
  { link: REFUND_POLICY_ROUTE, name: 'Refund', modal: true },
  { link: MONETIZATION_TERMS_ROUTE, name: 'Monetization', modal: true },
  {
    link: TRADEMARKS_ROUTE,
    name: 'Trademarks',
    modal: true,
  },
  {
    link: 'report/dmca',
    name: 'DMCA',
    modal: true,
  },
]

export const SOCIAL_LINKS = [
  {
    link: 'https://discord.mod.io',
    name: 'Discord',
    icon: ['fab', 'discord'],
  },
  {
    link: 'https://x.com/modiohq',
    name: 'X',
    baseIcon: 'x-twitter_logo',
  },
  {
    link: 'https://facebook.com/modiohq',
    name: 'Facebook',
    icon: ['fab', 'facebook-f'],
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/modiohq/',
    icon: ['fab', 'instagram'],
  },
  {
    title: 'Bluesky',
    link: 'https://bsky.app/profile/mod.io',
    baseIcon: 'bluesky',
  },
  {
    link: 'https://github.com/modio',
    name: 'Github',
    icon: ['fab', 'github'],
  },
]

export const COPYRIGHT_TEXT =
  'mod.io. All trademarks are property of their respective owners.'
