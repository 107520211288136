import modSerializer from '@serializers/modSerializer.js'
import { store, myTeamsStore, modStore } from '@stores'
import { KEYS_COGS_MOD } from '@config/storeKeys.js'
import { clone } from '@helpers/utils.js'
import { NEW_RESOURCE_ID } from '@config'
import { readonly, ref } from 'vue'

const { updateStore, getStore, createStore } = store()

// used for resetting the current mod being edited
const cogsModCloneState = ref(null)

export default function ({ clear = false } = {}) {
  const cogsMod = getStore(KEYS_COGS_MOD)
  if (clear) {
    clearCogsMod()
  }

  const { addMod, removeMod } = modStore()
  const { addTeamLeader, updateModTeam } = myTeamsStore()

  function addNewMod(gameNameId) {
    createStore(
      KEYS_COGS_MOD,
      modSerializer.getNew({
        id: NEW_RESOURCE_ID,
        game_name_id: gameNameId,
      })
    )
  }

  function addCogMod(gameNameId, mod, save = true, transform = true) {
    const _mod = transform ? modSerializer.transform(mod) : mod
    updateStore(KEYS_COGS_MOD, _mod)
    cogsModCloneState.value = clone(_mod)

    if (save) saveMod(gameNameId)
  }

  function saveMod(
    gameNameId,
    {
      newMod = null,
      updateTeam = false,
      updateTeamLeader = false,
      removeDuplicates = false,
    } = {}
  ) {
    if (newMod) addCogMod(gameNameId, newMod, false)
    else if (removeDuplicates) {
      removeMod(gameNameId, cogsMod.value.name_id, !cogsMod.value.price)
    }

    if (updateTeam || updateTeamLeader) updateModTeam(cogsMod.value, gameNameId)

    if (updateTeamLeader && cogsMod.value?.name_id) {
      addTeamLeader('mod', cogsMod.value.id, cogsMod.value.name_id)
    }

    addMod(gameNameId, cogsMod.value)
    cogsModCloneState.value = clone(cogsMod.value)
  }

  function updateModField(field, value) {
    if (!cogsMod.value) return

    const updatedMod = { ...cogsMod.value, [field]: value }
    updateStore(KEYS_COGS_MOD, updatedMod)
  }

  function updateModObjField(key, input, value) {
    if (!cogsMod.value) return

    const updatedMod = clone(cogsMod.value)

    updatedMod[key][input] = value
    updateStore(KEYS_COGS_MOD, updatedMod)
  }

  function clearCogsMod() {
    cogsModCloneState.value = null
    createStore(KEYS_COGS_MOD, null)
  }

  return {
    cogsModClone: readonly(cogsModCloneState),
    updateModObjField,
    updateModField,
    clearCogsMod,
    addNewMod,
    addCogMod,
    saveMod,
    cogsMod,
  }
}
