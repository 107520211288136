export default {
  en: {
    language: 'Language',

    // Nav
    browse: 'Browse',
    library: 'Library',
    account: 'My account',
    back: 'Go Back',
    more: 'more',
    add_mod: 'Add {ugc}',
    edit_mod: 'Edit on mod.io',
    help_center: 'help center',

    // Guides
    guide: 'Guide | Guides',
    views: 'Views',
    table_of_contents: 'Table of contents',
    reading_time: 'Reading time',
    date_added: 'Date added',
    date_updated: 'Date updated',
    minutes_read: 'Min read',
    published: 'Published',

    // Users
    joined: 'Joined',
    last_online: 'Last online',
    online: 'Online',
    followers: 'Followers',
    partner: 'Partner',
    profile_hidden: `This user's profile is hidden`,
    follow: 'Follow',
    unfollow: 'Unfollow',

    // Mods
    mod: 'Mod | Mods',
    level: 'Level | Levels',
    content: 'Content',
    subscribed: 'Subscribed',
    tags: 'Tags',
    summary: 'Summary',
    contributors: 'Contributors',
    description: 'Description',
    dependencies: 'Dependencies',
    of: 'of',
    ago: 'ago',
    and: 'and',
    by: 'By',
    reset_filters: 'Reset Filters',
    show_more: 'Show More',
    show_less: 'Show Less',
    no_ugc_found: 'No {ugc_name} found',
    no_subscriptions: 'You have not subscribed to any {ugc_name}',
    mod_disclaimer_heading: 'Legal disclaimer',
    mod_disclaimer_privacy: 'Privacy Policy',
    thumbs_up_received: 'Thumbs up received',
    thumbs_up: 'Thumbs up',
    total_downloads: 'Total downloads',
    releases: 'Releases',
    bytes: 'bytes',

    // Actions
    login: 'Login',
    logout: 'Log out',
    search: 'Search',
    clear_all: 'Clear all',
    download: 'Download',
    subscribe: 'Subscribe',
    unsubscribe: 'Unsubscribe',
    resubscribe: 'Resubscribe',
    activate: 'Activate',
    deactivate: 'Deactivate',
    like: 'Like',
    dislike: 'Dislike',
    report: 'Report a problem',
    submit: 'Submit',
    cancel: 'Cancel',
    select: 'Select',
    previous: 'Previous',
    next: 'Next',
    confirm: 'Confirm',
    allow: 'Allow',

    // Filters
    all: 'All',
    available: 'available',
    filesize: 'File size',
    downloads: 'Downloads',
    todays_downloads: "Today's downloads",
    subscribers: 'Subscribers',
    rating: 'Rating',
    sort_by: 'Sort by',
    creator: 'Creator',
    apply: 'Apply',
    reset: 'Reset',
    featured: 'Featured',
    toggle_filters: 'Toggle filters',
    hidden: 'Hidden',
    public: 'Public',
    pending: 'Pending',
    deleted: 'Deleted',
    last_day: 'Last day',
    last_week: 'Last week',
    last_month: 'Last month',
    last_3_months: 'Last 3 months',
    last_year: 'Last year',

    // Login
    signup: 'Log in or sign up with Email',
    login_with: 'Log in with {service}',
    email_placeholder: 'Email address',
    code_placeholder: '5 digit code',
    have_code: 'I have a code',
    email_code: 'Email code',
    send_new_code: 'Send new code',
    check_email: 'Please check your email for your code and enter it here.',
    login_disclaimer: 'User-generated content for {game} is managed by mod.io.',
    terms_disclaimer: ` By pressing "Log in", you agree to the {studioterms}{terms} and {privacy} of mod.io and confirm you are {min_age} years of age or older.`,
    terms_disclaimer_studio: '{terms} of {studio}, the ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' and ',
    code_error: 'Unable to request code.',
    or: 'or',
    login_prompt:
      'The page you are trying to access requires authentication. Please log in to continue.',

    // Terms
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    aup: 'Acceptable Use Policy',
    monetization: 'Monetization Terms',
    game: 'Game Terms',
    api: 'API Access Terms',
    effective: 'Effective from',

    // Report
    report_heading: 'Report this mod',
    report_intro:
      'You can report issues with content, violations of mod.io {terms} or submit a {dmca} using the form below.',
    report_details:
      'Make sure you include all relevant information to facilitate the resolution of your report. Please note that this information will be shared with game moderators and may be shared with the user who posted the allegedly infringing content.',
    report_not_working_prompt:
      '"Not working" reports will be shared directly with the content creator.',
    dmca: 'DMCA complaint',
    reason: 'Reason for reporting',
    urls: 'Offending URLs',
    urls_placeholder: 'URLs (1 per line)',
    company_or_name: 'Company or your Name',
    email: 'Email',
    address: 'Address',
    country: 'Country',
    country_placeholder: 'Select a country',
    details: 'Details of Infringement',
    details_placeholder:
      'In order to help with resolving your report, please provide as much detail and context as possible.',
    dmca_1:
      "{mod_io} respects the intellectual property rights of others, and we ask that everyone using our internet sites and services do the same. Anyone who believes that their work has been reproduced in one of our internet sites or services in a way that constitutes copyright infringement may notify mod.io's copyright agent using the form below.",
    dmca_2:
      'Submitting a claim of copyright infringement is a serious legal matter. Before you proceed, you might consider contacting the individual directly to address the complaint. It might be a simple misunderstanding and possible to address without involving proper legal process.',
    dmca_3:
      'Be aware that under Section 512(f) of the Digital Millennium Copyright Act, any person who knowingly materially misrepresents that material or activity is infringing may be liable for damages. Please also note that the information provided in this legal notice may be shared with third-parties or made public.',
    claim_check:
      'By checking the following boxes and submitting this claim, I state UNDER PENALTY OF PERJURY that',
    copyright_owner: 'I am the Copyright Owner',
    copyright_auth_owner:
      "I am authorized to act on the copyright owner's behalf in this situation.",
    authorization: 'Authorization',
    authorization_check:
      'I have a good faith belief that the use of the material noted in this claim is not authorized by the copyright owner, its agent, or the law.',
    acknowledgement: 'Acknowledgement',
    acknowledgement_check:
      'I acknowledge that under Section 512(f) of the DMCA any person who knowingly materially misrepresent that material or activity is infringing may be liable for damages.',
    required: 'required',
    signature: 'Signature',
    signature_placeholder:
      'Type your full name into this box, acting as your digital signature.',
    report_success_title: 'Your report has been received',
    report_success_text:
      'If your message requires a response, please be patient as we endeavour to reply to all reports within 48 hours of receiving them.',
    report_error_title: 'Failed to submit report',

    // Table
    name: 'Name',
    added: 'Added',
    updated: 'Updated',
    file: 'File',
    version: 'Version',
    scan: 'Scan',
    actions: 'Actions',
    locally_installed: 'Locally installed',

    // Releases
    download_from_game_client: 'Download {mod} from game client',
    release_notes: 'Release Notes',
    no_release_notes: 'No release notes provided',
    previous_releases: 'Previous releases',
    view_previous_releases: 'View previous releases',
    no_previous_releases: 'There are no previous releases',
    no_files_published: 'No files published',
    download_files_manually: 'Download files manually',
    platforms: 'Platform | Platforms',

    // Ratings
    overwhelmingly_positive: 'Overwhelmingly positive',
    overwhelmingly_negative: 'Overwhelmingly negative',
    very_positive: 'Very positive',
    very_negative: 'Very negative',
    mostly_positive: 'Mostly positive',
    mostly_negative: 'Mostly negative',
    negative: 'Negative',
    positive: 'Positive',
    mixed: 'Mixed',
    unrated: 'Unrated',

    // Sort
    sort_comments: 'Comments',
    sort_total_mods: 'Total Mods',
    sort_trending: 'Trending',
    sort_highest_rated: 'Highest Rated',
    sort_most_popular: 'Popular',
    sort_most_subscribers: 'Most Subscribers',
    sort_recently_added: 'Recent',
    sort_last_updated: 'Last Updated',
    sort_alphabetical: 'Alphabetical',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Not Working',
    report_rude_content: 'Rude Content',
    report_illegal_content: 'Illegal Content',
    report_stolen_content: 'Stolen Content',
    report_false_information: 'False Information',
    report_other: 'Other',

    // Report not working reason
    report_crash: 'Crashes game',
    report_no_load: 'Does not load',
    report_conflicts: 'Conflicts with other {ugc}',
    report_missing_deps: 'Missing dependencies',
    report_install_issues: 'Installation issues',
    report_bugs: 'Buggy behavior',
    report_incompatability: 'Incompatible with game version',
    report_corrupt: 'Corrupted files',

    // Errors
    error_ref: 'Error code: ',
    login_fail: 'Failed to login',
    login_fail_message: 'Failed to login. Please wait a minute and try again.',
    subscribe_fail: 'Failed to subscribe',
    unsubscribe_fail: 'Failed to unsubscribe',
    error: 'Error',
    default_error_message: 'An error has occurred please try again soon.',
    follow_error: 'Failed to follow',
    unfollow_error: 'Failed to unfollow',

    filter: 'Filter',
    page_not_found: 'Page not found!',
    take_me_home: 'Take me home',
    previous_version: 'Previous version',
    next_version: 'Next version',
    agreement_not_found: 'Agreement not found',
    loading: 'Loading',
    no_results_found: 'No results found',
    validation_required: 'Field is required',
    validation_checked: 'Field must be checked',
    validation_email: 'Field must be an email',
    validation_min: 'Minimum {max} characters',
    validation_max: 'Maximum {max} characters',
    validation_url: 'Field must be a URL',
    validation_size: 'Field must be {size} characters',
    validation_alpha_num: 'Field can only contain numbers or letters',
    validation_name: 'Field can only contain letters and name-related symbols',
    validation_image: 'File must be a valid jpeg or png image',
    validation_youtube: 'Field must be a valid Youtube URL',
    validation_sketchfab: 'Field must be a valid Sketchfab URL',
    validation_zip: 'File must be a ZIP',
    validation_filesize: 'Filesize must not exceed {size}',

    form_chars_remaining: '{count} characters remaining.',
    form_not_enough_chars: '{count} more characters required.',

    // Comments
    comment: 'comment | comments',
    team_member: 'Team member',
    author: 'Author',
    good_karma: 'Good karma',
    bad_karma: 'Bad karma',
    reply: 'Reply',
    replies: 'reply | replies',
    save: 'Save',
    delete: 'Delete',
    edit: 'Edit',
    post: 'Post',
    pinned: 'Pinned',
    pin: 'Pin',
    unpin: 'Unpin',
    locked: 'Locked',
    lock: 'Lock',
    unlock: 'Unlock',
    add_comment: 'Add a comment...',
    comment_cant_reply_locked: 'Cannot reply as comment thread is locked',
    comment_cant_edit_locked: 'Cannot edit as comment thread is locked',
    comment_max_pin_limit: 'Maximum pinned comment limit reached',
    comment_below_threshold: 'Comment below threshold. Click to view.',
    comment_login_prompt: 'Log in to join the discussion!',
    comment_error_add: 'Failed to add comment',
    comment_error_edit: 'Failed to edit comment',
    comment_error_delete: 'Failed to delete comment',
    comment_error_rate: 'Failed to rate comment',
    comment_error_update: 'Failed to update comment',
    comments_error_fetch: 'Failed to load comments',
    discussion: 'Discussion',
    load_more_comments: 'Load more comments',
    comment_confirm_delete: 'Are you sure you want to delete this comment?',
    comment_deleted: 'The comment was successfully deleted',

    // Mod admin
    mod_profile: '{ugc} profile',
    media: 'Media',
    file_manager: 'File manager',
    step: 'STEP {step}',
    save_next: 'Save & Next',
    auth_required: 'Authentication required',
    tools_only_1:
      'You cannot upload {ugc} for {game} here, they must be uploaded using tools the game developer has created, so the files can be verified. For more information, see {guides} written for this game. ',
    tools_only_2: 'Here are the instructions provided by the game developer:',
    tools_only_3:
      'We recommend you return to the {ugc} list and browse the links from there. If the problem persists, head to our {help_center} for more information.',
    unsaved_heading: 'You have unsaved changes',
    unsaved_confirm:
      'There are changes that have not been saved, do you still want to leave?',

    headline_mod_profile: 'Create your {ugc} profile',
    headline_media: 'Add media to your {ugc} profile',
    headline_files: 'Upload your {ugc} file',
    headline_permissions: 'Set reuse permissions for your {ugc}',
    headline_dependencies: 'Add any {ugc} dependencies',

    // Basics
    basics: 'Basics',
    logo: 'Logo',
    logo_prompt_1: "Image file which will represent your {ugc}'s logo.",
    logo_prompt_2: '512x288 or larger',
    logo_prompt_3: '16:9 aspect ratio recommended',
    logo_prompt_4: '8MB maximum',
    summary_placeholder:
      'Tell us about the changes your {ugc} makes to the game in 1 paragraph.',
    homepage: 'Homepage',
    profile_url: 'Profile URL',
    visibility: 'Visibility',
    visibility_tip:
      'Once live, public {ugc} are visible to everyone. Hidden {ugc} are only visible to team members and subscribers.',
    upload_images: 'Upload images',
    mature_content: 'Contains mature content',
    mature_content_alcohol: 'Alcohol',
    mature_content_drugs: 'Drugs',
    mature_content_violence: 'Violence',
    mature_content_explicit: 'Explicit',
    community: 'Community',
    allow_comments: 'Allow comments',
    allow_dependants: 'Allow dependants',
    create_mod: 'Create {ugc}',
    error_mod_create: 'Failed to create {ugc}',
    error_mod_update: 'Failed to update {ugc}',
    error_tags_update: 'Failed to update tags',
    success_create_mod: 'Successfully created {modname}',
    success_update_mod: 'Successfully updated {modname}',
    description_tip:
      "Optional description for your {ugc}, which can include details such as 'About', 'Features', 'Usage', 'FAQ', etc. HTML supported and encouraged",

    // Media
    models: 'models',
    media_zip_hint_1:
      'Upload up to {count} images or a single zip archive of images to add to the gallery.',
    media_zip_hint_2:
      'Only valid jpg and png images in the zip file will be processed.',
    error_media_update: 'Failed to update media',
    error_media_add_links: 'Failed to add media links',
    error_media_remove_links: 'Failed to remove media links',
    success_media_updated: 'Updated media successfully',

    // File manager
    add_file: 'Add a file',
    edit_file: 'Edit file',
    select_zip: 'Select zip file',
    upload: 'Upload',
    file_id: 'File ID:',
    virus_none: 'No virus detected',
    virus_waiting: 'Waiting to scan',
    virus_scanning: 'Scan in progress',
    virus_too_large: 'File too large to scan',
    virus_not_found: 'File not found',
    virus_error: 'Error scanning file',
    virus_found: 'Virus detected',
    virus_risk:
      'This file contains an executable and care should be taken upon opening',
    version_placeholder: 'Version number i.e. 1.0.0',
    release_notes_placeholder: 'Enter each change on a separate line',
    game_moderator: 'Game moderator',
    metadata_blob: 'Metadata blob',
    metadata_blob_placeholder:
      'Enter specific metadata such as the version of the game this release is compatible with, how to install it, etc. UTF-8 valid data is supported.',
    select_platforms: 'Platforms (select all that apply)',
    file_pending: 'Pending approval',
    file_approved: 'Approved',
    file_approved_live: 'Approved and live',
    file_approved_not_live: 'Approved but not live',
    file_denied: 'Denied',
    file_targeted: 'Targeted',
    live_file: 'This file is currently live.',
    file_is_live: 'File is live',
    make_file_live: 'Make this release live',
    make_file_live_platforms: 'Make this release live for approved platforms',
    file_processing:
      'Your file is being processed. You can continue to browse the site but please do not close the tab or browser window until it has finished.',
    file_instructions_1:
      'ZIP and upload the base folder of your {ugc}, or if it is a collection of files which live in a pre-existing game folder, you should ZIP those files:',
    file_instructions_2: 'Must be a ZIP file',
    file_instructions_3: 'Cannot exceed 20GB in filesize',
    file_instructions_4:
      'Filenames cannot contain any of the following characters: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc} which overwrite files are not supported unless the game manages this',
    file_instructions_6:
      '{ugc} which span multiple game directories are not supported unless the game manages this',
    file_terms: 'I agree to the {terms} and {privacy}.',
    error_get_files: 'Failed to get files',
    error_upload_file: 'Failed to upload file',
    error_update_file: 'Failed to update file',
    error_file_required: 'File is required',
    error_platform_required: 'You must select at least one platform',
    success_file_uploaded: 'File uploaded successfully',
    success_file_moderation:
      'A moderator will review platforms that require moderation',
    success_file_updated: 'File updated successfully',
    error_no_approved_platforms: 'No platforms to set live',
    error_no_approved_platforms_text:
      'This file has no approved platforms that are not currently live',

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Reuse permissions',
      allow_redistribution:
        'Allow others to upload my {ugc} assets on other sites, but I must be credited as the original creator',
      allow_porting:
        'Allow others to convert my {ugc} assets to work in other games, but I must be credited as the original creator',
      allow_patching_no_credit:
        'Allow others to modify my {ugc} assets (including releasing bug fixes or improvements) and release it as a new {ugc}',
      require_credit: 'I must be credited as the original creator',
      require_permission: 'Require permission from me first',
      require_repackage_no_credit:
        'Allow others to use my {0} assets in their own {1}',
      allow_resell:
        'Allow others to use my {ugc} assets in any {ugc}/files in any commercial works',
      original_assets:
        'My {ugc} contains 100% original work, or assets which can be redistributed',
      error_update: 'Failed to update reuse permissions',
      success_update: 'Successfully updated reuse permissions',
      prompt:
        "Set permissions on how your {ugc} assets can be used for other's content creation. By granting permission for others to use or modify your files, you acknowledge that such authorization cannot be retroactively revoked with respect to works already created.",
    },

    // Dependencies form
    dependencies_prompt:
      "Select {0} required for this {1} to function correctly (if a {1} isn't shown, it means it hasn't allowed dependencies, and you should contact the creator to turn it on):",
    remove_dependency: 'Remove dependency',
    dependants: 'Dependants',
    error_update_dependencies: 'Failed to update dependencies',
    success_update_dependencies: 'Successfully updated dependency settings',
    dependants_warning_1:
      'Once allowed, please be aware that {ugc} with dependants connected:',
    dependants_warning_2: 'Cannot disable dependants',
    dependants_warning_3: 'Cannot be deleted',
    dependants_warning_4: 'Cannot be monetized',
    dependants_confirm_enable:
      'This is to ensure ongoing compatibility of connected {ugc}. Please confirm to enable.',
    dependants_confirm_disable:
      'Are you sure you want to disallow dependants? This means other {0} will be unable to add your {1} as a dependency.',
  },
  fr: {
    language: 'Langue',

    // Nav
    browse: 'Parcourir',
    library: 'Bibliothèque',
    account: 'Mon compte',
    back: 'Retourner',
    more: 'plus',
    add_mod: 'Ajouter {ugc}',
    edit_mod: 'Modifier sur mod.io',
    help_center: "centre d'aide",

    // Guides
    guide: 'Guide | Guides',
    views: 'Vues',
    table_of_contents: 'Table des matières',
    reading_time: 'Temps de lecture',
    date_added: 'Date ajoutée',
    date_updated: 'Date de mise à jour',
    minutes_read: 'Minute de lecture',
    published: 'Publié',

    // Users
    joined: 'Rejoint',
    last_online: 'Dernière connexion',
    online: 'En ligne',
    followers: 'Suiveurs',
    partner: 'Partenaire',
    profile_hidden: 'Le profil de cet utilisateur est masqué',
    follow: 'Suivre',
    unfollow: 'Ne plus suivre',

    // Mods
    mod: 'Mod | Mods',
    level: 'Niveau | Les niveaux',
    content: 'Contenu',
    subscribed: 'Abonné',
    tags: 'Tags',
    summary: 'Résumé',
    contributors: 'Contributeurs',
    description: 'Description',
    dependencies: 'Dépendances',
    of: 'sur',
    ago: 'il y a',
    and: 'et',
    by: 'Par',
    reset_filters: 'Réinitialiser les filtres',
    show_more: 'Montre plus',
    show_less: 'Afficher moins',
    no_ugc_found: 'Aucun {ugc_name} trouvé',
    no_subscriptions: "Vous n'êtes abonné à aucun {ugc_name}",
    mod_disclaimer_heading: 'Mentions légales',
    mod_disclaimer_privacy: "Charte d'utilisation des données personnelles",
    thumbs_up_received: 'Bravo reçu',
    thumbs_up: 'Pouces vers le haut',
    total_downloads: 'Total des téléchargements',
    releases: 'Sorties',
    bytes: 'octets',

    // Actions
    login: 'Connexion',
    logout: 'Déconnexion',
    search: 'Recherche',
    clear_all: 'Réinitialiser',
    download: 'Télécharger',
    subscribe: "S'abonner",
    unsubscribe: 'Se désabonner',
    resubscribe: 'Se réabonner',
    activate: 'Activer',
    deactivate: 'Désactiver',
    like: "J'aime",
    dislike: "Je n'aime pas",
    report: 'Signaler un problème',
    submit: 'Envoyer',
    cancel: 'Annuler',
    select: 'Sélectionner',
    previous: 'Précédent',
    next: 'Suivant',
    confirm: 'Confirmer',
    allow: 'Autoriser',

    // Filters
    all: 'Tous',
    available: 'disponible(s)',
    filesize: 'Taille du fichier',
    downloads: 'Téléchargements',
    todays_downloads: 'Téléchargements du jour',
    subscribers: 'Abonnés',
    rating: 'Notation',
    sort_by: 'Trier par',
    creator: 'Créateur',
    apply: 'Appliquer',
    reset: 'Réinitialiser',
    featured: 'En vedette',
    toggle_filters: 'Faire basculer les filtres',
    hidden: 'Caché',
    public: 'Publique',
    pending: 'En attente',
    deleted: 'Supprimé',
    last_day: 'Dernier jour',
    last_week: 'La semaine dernière',
    last_month: 'Mois dernier',
    last_3_months: '3 derniers mois',
    last_year: `L'année dernière`,

    // Login
    signup: 'Connectez-vous ou inscrivez-vous avec votre adresse e-mail',
    login_with: 'Connectez-vous avec {service}',
    email_placeholder: 'Adresse e-mail',
    code_placeholder: 'Code à 5 chiffres',
    have_code: "J'ai un code",
    email_code: 'Envoyer un code par e-mail',
    send_new_code: 'Envoyer un nouveau code',
    check_email:
      'Veuillez consulter votre boîte e-mail et saisissez ici le code reçu.',
    login_disclaimer:
      "Le contenu généré par l'utilisateur pour {game} est géré par mod.io.",
    terms_disclaimer: `En appuyant sur « Connectez-vous », vous acceptez les {studioterms}{terms} et la {privacy} de mod.io et confirmez que vous avez {min_age} ans ou plus.`,
    terms_disclaimer_studio: '{terms} de {studio}, les ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' et la ',
    code_error: 'Demande de code impossible.',
    or: 'ou',
    login_prompt:
      "La page à laquelle vous essayez d'accéder nécessite une authentification. Veuillez vous connecter pour continuer.",

    // Terms
    terms: "Conditions d'utilisation",
    privacy: 'Politique de confidentialité',
    aup: "Politique d'utilisation acceptable",
    monetization: 'Conditions de monétisation',
    game: 'Conditions du jeu',
    api: "Conditions d'accès à l'API",
    effective: 'En vigueur à partir de',

    // Report
    report_heading: 'Signaler ce mod',
    report_intro:
      'Vous pouvez signaler des problèmes de contenu, des violations des {terms} de mod.io ou soumettre un {dmca} en utilisant le formulaire ci-dessous.',
    report_details:
      "Assurez-vous d'inclure toutes les informations pertinentes pour faciliter la résolution de votre rapport. Veuillez noter que ces informations seront partagées avec les modérateurs du jeu et pourront être partagées avec l'utilisateur qui a publié le contenu présumé en infraction.",
    report_not_working_prompt:
      'Les rapports « Ne fonctionne pas » seront partagés directement avec le créateur de contenu.',
    reason: 'Raison du signalement',
    urls: 'URL incriminées',
    urls_placeholder: 'URL (1 par ligne)',
    company_or_name: 'Société ou votre nom',
    email: 'Adresse e-mail',
    address: 'Adresse',
    country: 'Pays',
    country_placeholder: 'Sélectionnez un pays',
    details: "Détails de l'infraction",
    details_placeholder:
      'Afin de vous aider à résoudre votre rapport, veuillez fournir autant de détails et de contexte que possible.',
    dmca_1:
      "{mod_io} respecte les droits de propriété intellectuelle d'autrui et nous demandons à tous ceux qui utilisent nos sites et services Internet de faire de même. Toute personne qui estime que son travail a été reproduit sur l'un de nos sites Internet ou services d'une manière qui constitue une violation du droit d'auteur peut en informer l'agent des droits d'auteur de mod.io en utilisant le formulaire ci-dessous.",
    dmca_2:
      "Soumettre une réclamation pour infraction au droit d'auteur est une affaire juridique sérieuse. Avant de poursuivre, vous pouvez envisager de contacter directement la personne pour résoudre la plainte. Il peut s'agir d'un simple malentendu qui puisse être résolu sans impliquer une procédure judiciaire appropriée.",
    dmca_3:
      "Sachez qu'en vertu de l'article 512 (f) de la loi Digital Millennium Copyright Act, toute personne qui déclare faussement, sciemment et/ou matériellement que le matériel ou l'activité est en infraction peut être tenue responsable des dommages. Veuillez également noter que les informations fournies dans la présente notice légale peuvent être partagées avec des tiers ou rendues publiques.",
    claim_check:
      'En cochant les cases suivantes et en soumettant cette réclamation, je déclare SOUS PEINE DE PARJURE que',
    copyright_owner: "Je suis le titulaire du droit d'auteur",
    copyright_auth_owner:
      "J'ai l'autorisation d'agir au nom du titulaire du droit d'auteur dans cette situation.",
    authorization: 'Autorisation',
    authorization_check:
      "Je crois en toute bonne foi que l'utilisation du matériel indiqué dans cette réclamation n'est pas autorisée par le titulaire du droit d'auteur, son agent ou la loi.",
    acknowledgement: 'Reconnaissance',
    acknowledgement_check:
      "Je reconnais qu'en vertu de l'article 512 (f) du DMCA, toute personne qui déclare faussement, sciemment et/ou matériellement que le matériel ou l'activité est en infraction peut être tenue responsable des dommages.",
    required: 'requis',
    signature: 'Signature',
    signature_placeholder:
      'Saisissez votre nom complet dans cette case, agissant comme votre signature numérique.',
    report_success_title: 'Votre signalement a été reçu',
    report_success_text:
      'Si votre message nécessite une réponse, veuillez patienter car nous nous efforçons de répondre à tous les signalements dans les 48 heures suivant leur réception.',
    report_error_title: "Échec de l'envoi du signalement",

    // Table
    name: 'Nom',
    added: 'Ajouté',
    updated: 'Mis à jour',
    file: 'Fichier',
    version: 'Version',
    scan: 'Analyse antivirus',
    actions: 'Actions',
    locally_installed: 'Installé localement',

    // Releases
    download_from_game_client: 'Télécharger {mod} depuis le client du jeu',
    release_notes: 'Notes de version',
    no_release_notes: 'Aucune note de version fournie',
    previous_releases: 'Versions précédentes',
    view_previous_releases: 'Voir les versions précédentes',
    no_previous_releases: 'Aucune version précédente',
    no_files_published: 'Aucun fichier publié',
    download_files_manually: 'Télécharger des fichiers manuellement',
    platforms: 'Plateforme | Plateformes',

    // Ratings
    overwhelmingly_positive: 'Extrêmement positif',
    overwhelmingly_negative: 'Extrêmement négatif',
    very_positive: 'Très positif',
    very_negative: 'Très négatif',
    mostly_positive: 'Plutôt positif',
    mostly_negative: 'Plutôt négatif',
    negative: 'Négatif',
    positive: 'Positif',
    mixed: 'Mixte',
    unrated: 'Non classé',

    // Sort
    sort_comments: 'Commentaires',
    sort_total_mods: 'Mods totaux',
    sort_trending: 'Tendance',
    sort_highest_rated: 'Les mieux notés',
    sort_most_popular: 'Le plus populaire',
    sort_most_subscribers: "Le plus d'abonnés",
    sort_recently_added: 'Récemment ajouté',
    sort_last_updated: 'Dernière mise à jour',
    sort_alphabetical: 'Alphabétique',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Ne fonctionne pas',
    report_rude_content: 'Contenu inapproprié',
    report_illegal_content: 'Contenu illégal',
    report_stolen_content: 'Contenu volé',
    report_false_information: 'Informations fausses',
    report_other: 'Autre',

    // Report not working reasons
    report_crash: 'Jeu de crash',
    report_no_load: 'Ne charge pas',
    report_conflicts: "Conflits avec d'autres {ugc}",
    report_missing_deps: 'Dépendances manquantes',
    report_install_issues: "Problèmes d'installation",
    report_bugs: 'Comportement bogué',
    report_incompatability: 'Incompatible avec la version du jeu',
    report_corrupt: 'Fichiers corrompus',

    // Errors
    error_ref: "Code d'erreur : ",
    login_fail: 'Échec de la connexion',
    login_fail_message:
      'Échec de la connexion. Veuillez attendre une minute et réessayer.',
    subscribe_fail: "Échec de l'abonnement",
    unsubscribe_fail: 'Échec de la désabonnement',
    error: 'Erreur',
    default_error_message:
      "Une erreur s'est produite, veuillez réessayer bientôt.",
    follow_error: 'Impossible de suivre',
    unfollow_error: "Échec de l'abandon du suivi",

    agreement_not_found: 'Accord introuvable',
    form_chars_remaining: '{count} caractères restants.',
    form_not_enough_chars: '{count} plus de caractères requis.',
    filter: 'Filtre',
    loading: 'Chargement',
    next_version: 'Version suivante',
    no_results_found: 'Aucun résultat trouvé',
    page_not_found: 'Page non trouvée!',
    previous_version: 'La version précédente',
    take_me_home: 'Emmène moi chez toi',
    validation_alpha_num:
      'Le champ ne peut contenir que des chiffres ou des lettres',
    validation_size: 'Le champ doit contenir {size} caractères',
    validation_url: 'Le champ doit être une URL',
    validation_min: 'Caractères minimum {min}',
    validation_max: 'Caractères maximum {max}',
    validation_email: 'Le champ doit être un e-mail',
    validation_checked: 'Le champ doit être vérifié',
    validation_required: 'Champ requis',
    validation_name:
      'Le champ ne peut contenir que des lettres et des symboles liés au nom',
    validation_image: 'Le fichier doit être une image JPEG ou PNG valide',
    validation_youtube: 'Le champ doit être une URL Youtube valide',
    validation_sketchfab: 'Le champ doit être une URL Sketchfab valide',
    validation_zip: 'Le fichier doit être au format ZIP',
    validation_filesize: 'La taille du fichier ne doit pas dépasser {size}',

    comment: 'commentaire | commentaires',
    team_member: "Membre de l'équipe",
    author: 'Auteur',
    good_karma: 'Bon karma',
    bad_karma: 'Mauvais karma',
    reply: 'Répondre',
    replies: 'réponse | réponses',
    save: 'Sauvegarder',
    delete: 'Supprimer',
    edit: 'Modifier',
    post: 'Poste',
    pinned: 'Épinglé',
    pin: 'Épinglette',
    unpin: 'Désépingler',
    locked: 'Fermé à clé',
    lock: 'Verrouiller',
    unlock: 'Débloquer',
    add_comment: 'Ajouter un commentaire...',
    comment_cant_reply_locked:
      'Impossible de répondre car le fil de commentaires est verrouillé',
    comment_cant_edit_locked:
      'Impossible de modifier car le fil de commentaires est verrouillé',
    comment_max_pin_limit: 'Limite maximale de commentaires épinglés atteinte',
    comment_below_threshold:
      'Commentaire en dessous du seuil. Cliquez pour voir.',
    comment_login_prompt: 'Connectez-vous pour rejoindre la discussion!',
    comment_error_add: "Échec de l'ajout de commentaires",
    comment_error_edit: 'Échec de la modification du commentaire',
    comment_error_delete: 'Échec de la suppression des commentaires',
    comment_error_rate: "Impossible d'évaluer le commentaire",
    comment_error_update: 'Impossible de mettre à jour le commentaire',
    comments_error_fetch: 'Échec du chargement de plus de commentaires',
    discussion: 'Discussion',
    load_more_comments: 'Chargez plus de commentaires',
    comment_confirm_delete:
      'êtes-vous sûr de vouloir supprimer ce commentaire?',
    comment_deleted: 'Le commentaire a été supprimé avec succès',

    // Mod admin
    mod_profile: 'Profil {ugc}',
    media: 'Médias',
    file_manager: 'Gestionnaire de fichiers',
    step: 'ÉTAPE {step}',
    save_next: 'Enregistrer et suivre',
    auth_required: 'Authentification requise',
    tools_only_1:
      "Vous ne pouvez pas télécharger {ugc} pour {game} ici, ils doivent être chargés à l'aide des outils créés par le développeur du jeu, afin que les fichiers puissent être vérifiés. Pour plus d'informations, consultez les {guides} écrits pour ce jeu. ",
    tools_only_2: 'Voici les instructions fournies par le développeur du jeu :',
    tools_only_3:
      "Nous vous recommandons de revenir à la liste {ugc} et de parcourir les liens qui s'y trouvent. Si le problème persiste, rendez-vous dans notre {help_center} pour plus d'informations.",
    unsaved_heading: 'Vous avez des modifications non enregistrées',
    unsaved_confirm:
      "Certaines modifications n'ont pas été enregistrées. Voulez-vous toujours partir ?",

    headline_mod_profile: 'Créez votre profil {ugc}',
    headline_media: 'Ajoutez des médias à votre profil {ugc}',
    headline_files: 'Téléchargez votre fichier {ugc}',
    headline_permissions:
      'Définir les autorisations de réutilisation pour votre {ugc}',
    headline_dependencies: 'Ajoutez des dépendances {ugc}',

    // Basics
    basics: 'Principes de base',
    logo: 'Logo',
    logo_prompt_1: 'Fichier image qui représentera le logo de votre {ugc}.',
    logo_prompt_2: '512 x 288 ou plus',
    logo_prompt_3: "Format d'image 16:9 recommandé",
    logo_prompt_4: '8 Mo maximum',
    summary_placeholder:
      'Parlez-nous des modifications que votre {ugc} apporte au jeu en un seul paragraphe.',
    homepage: "Page d'accueil",
    profile_url: 'URL du profil',
    visibility: 'Visibilité',
    visibility_tip:
      "Une fois en ligne, les {ugc} publics sont visibles par tous. Les {ugc} cachés ne sont visibles que par les membres de l'équipe et les abonnés.",
    upload_images: 'Téléchargez des images',
    mature_content: 'Contient du contenu réservé aux adultes',
    mature_content_alcohol: 'Alcool',
    mature_content_drugs: 'Médicaments',
    mature_content_violence: 'Violence',
    mature_content_explicit: 'Explicite',
    community: 'Communauté',
    allow_comments: 'Autoriser les commentaires',
    allow_dependants: 'Autoriser les personnes à charge',
    create_mod: 'Créer {ugc}',
    error_mod_create: 'Impossible de créer {ugc}',
    error_mod_update: 'Impossible de mettre à jour {ugc}',
    error_tags_update: 'Impossible de mettre à jour les balises',
    success_create_mod: '{modname} créé avec succès',
    success_update_mod: '{modname} a été mis à jour avec succès',
    description_tip:
      'Description facultative de votre {ugc}, qui peut inclure des détails tels que « À propos », « Fonctionnalités », « Utilisation », « FAQ », etc. Le HTML est pris en charge et encouragé',

    // Media
    models: 'des modèles',
    media_zip_hint_1:
      "Téléchargez jusqu'à {count} images ou une seule archive zip d'images à ajouter à la galerie.",
    media_zip_hint_2:
      'Seules les images JPG et PNG valides du fichier zip seront traitées.',
    error_media_update: 'Impossible de mettre à jour le média',
    error_media_add_links: "Impossible d'ajouter des liens multimédia",
    error_media_remove_links: 'Impossible de supprimer les liens multimédia',
    success_media_updated: 'Médias mis à jour avec succès',

    // File manager
    add_file: 'Ajouter un fichier',
    edit_file: 'Modifier le fichier',
    select_zip: 'Sélectionnez le fichier zip',
    upload: 'Téléverser',
    file_id: 'ID du fichier :',
    virus_none: 'Aucun virus détecté',
    virus_waiting: 'En attente de numérisation',
    virus_scanning: 'Scan en cours',
    virus_too_large: 'Fichier trop volumineux pour être scanné',
    virus_not_found: 'Fichier introuvable',
    virus_error: 'Erreur lors de la numérisation du fichier',
    virus_found: 'Virus détecté',
    virus_risk:
      "Ce fichier contient un exécutable et des précautions doivent être prises lors de l'ouverture",
    version_placeholder: "Numéro de version, c'est-à-dire 1.0.0",
    release_notes_placeholder:
      'Entrez chaque modification sur une ligne distincte',
    game_moderator: 'Modérateur de jeu',
    metadata_blob: 'Blob de métadonnées',
    metadata_blob_placeholder:
      "Entrez des métadonnées spécifiques telles que la version du jeu avec laquelle cette version est compatible, comment l'installer, etc. Les données valides UTF-8 sont prises en charge.",
    select_platforms:
      "Plateformes (sélectionnez toutes les options qui s'appliquent)",
    file_pending: "En attente d'approbation",
    file_approved: 'Approuvé',
    file_approved_live: 'Approuvé et en ligne',
    file_approved_not_live: 'Approuvé mais pas en ligne',
    file_denied: 'Refusé',
    file_targeted: 'Ciblé',
    live_file: 'Ce fichier est actuellement en ligne.',
    file_is_live: 'Le fichier est en ligne',
    make_file_live: 'Diffuser cette version en direct',
    make_file_live_platforms:
      'Diffuser cette version pour les plateformes approuvées',
    file_processing:
      "Votre dossier est en cours de traitement. Vous pouvez continuer à naviguer sur le site, mais veuillez ne pas fermer l'onglet ou la fenêtre du navigateur avant d'avoir terminé.",
    file_instructions_1:
      "Compressez et chargez le dossier de base de votre {ugc}, ou s'il s'agit d'une collection de fichiers qui se trouvent dans un dossier de jeu préexistant, vous devez compresser ces fichiers au format ZIP:",
    file_instructions_2: 'Doit être un fichier ZIP',
    file_instructions_3: 'La taille du fichier ne peut pas dépasser 20 Go',
    file_instructions_4:
      'Les noms de fichiers ne peuvent contenir aucun des caractères suivants: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc} dont les fichiers de remplacement ne sont pas pris en charge à moins que le jeu ne gère cela',
    file_instructions_6:
      "{ugc} qui s'étend sur plusieurs répertoires de jeux ne sont pas pris en charge à moins que le jeu ne gère cela",
    file_terms: "J'accepte les {terms} et {privacy}.",
    error_get_files: "Impossible d'obtenir les fichiers",
    error_upload_file: 'Impossible de charger le fichier',
    error_update_file: 'Impossible de mettre à jour le fichier',
    error_file_required: 'Le fichier est obligatoire',
    error_platform_required: 'Vous devez sélectionner au moins une plateforme',
    success_file_uploaded: 'Le fichier a été chargé avec succès',
    success_file_moderation:
      'Un modérateur passera en revue les plateformes nécessitant une modération',
    success_file_updated: 'Fichier mis à jour avec succès',
    error_no_approved_platforms: 'Aucune plateforme à mettre en ligne',
    error_no_approved_platforms_text:
      "Ce fichier ne contient aucune plateforme approuvée qui n'est pas actuellement en ligne",

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Réutiliser les autorisations',
      allow_redistribution:
        "Autoriser d'autres personnes à télécharger mes ressources {ugc} sur d'autres sites, mais je dois être crédité en tant que créateur d'origine",
      allow_porting:
        "Autoriser d'autres personnes à convertir mes ressources {ugc} pour les utiliser dans d'autres jeux, mais je dois être crédité en tant que créateur d'origine",
      allow_patching_no_credit:
        'Permettre aux autres de modifier mes ressources {ugc} (y compris en publiant des corrections de bugs ou des améliorations) et de les publier en tant que nouveau {ugc}',
      require_credit: 'Je dois être crédité en tant que créateur original',
      require_permission: "Demande-moi d'abord mon autorisation",
      require_repackage_no_credit:
        'Autoriser les autres à utiliser mes {0} actifs pour leurs propres besoins {1}',
      allow_resell:
        "Autoriser d'autres personnes à utiliser mes actifs {ugc} dans n'importe quel fichier {ugc} /dans toute œuvre commerciale",
      original_assets:
        'Mon {ugc} contient des œuvres originales à 100 %, ou des ressources qui peuvent être redistribuées',
      error_update:
        'Impossible de mettre à jour les autorisations de réutilisation',
      success_update: 'Autorisations de réutilisation mises à jour avec succès',
      prompt:
        "Définissez des autorisations sur la manière dont vos ressources {ugc} peuvent être utilisées pour la création de contenu par d'autres personnes. En autorisant d'autres personnes à utiliser ou à modifier vos fichiers, vous reconnaissez que cette autorisation ne peut pas être révoquée rétroactivement en ce qui concerne les œuvres déjà créées.",
    },

    // Dependencies form
    dependencies_prompt:
      "Sélectionnez {0} comme requis pour que {1} fonctionne correctement (si un {1} n'est pas affiché, cela signifie qu'il n'autorise pas les dépendances, et vous devez contacter le créateur pour l'activer) :",
    remove_dependency: 'Supprimer la dépendance',
    dependants: 'Personnes à charge',
    error_update_dependencies: 'Impossible de mettre à jour les dépendances',
    success_update_dependencies:
      'Paramètres de dépendance mis à jour avec succès',
    dependants_warning_1:
      'Une fois autorisé, sachez que {ugc} avec des personnes à charge connectées :',
    dependants_warning_2: 'Impossible de désactiver les personnes à charge',
    dependants_warning_3: 'Ne peut pas être supprimé',
    dependants_warning_4: 'Ne peut pas être monétisé',
    dependants_confirm_enable:
      "Cela permet de garantir la compatibilité permanente de connected {ugc}. Veuillez confirmer pour l'activer.",
    dependants_confirm_disable:
      "Êtes-vous sûr de vouloir interdire l'accès aux personnes à charge ? Cela signifie que les autres {0} ne pourront pas ajouter votre {1} en tant que dépendance.",
  },
  de: {
    language: 'Sprache',

    // Nav
    browse: 'Durchsuchen',
    library: 'Bibliothek',
    account: 'Mein Konto',
    back: 'Geh zurück',
    more: 'mehr',
    add_mod: '{ugc} hinzufügen',
    edit_mod: 'Auf mod.io bearbeiten',
    help_center: 'hilfecenter',

    // Guides
    guide: 'Leitfaden | Führer',
    views: 'Ansichten',
    table_of_contents: 'Inhaltsverzeichnis',
    reading_time: 'Lesezeit',
    date_added: 'Datum hinzugefügt',
    date_updated: 'Datum aktualisiert',
    minutes_read: 'Minutenablesung',
    published: 'Veröffentlicht',

    // Users
    joined: 'Beigetreten',
    last_online: 'Zuletzt Online',
    online: 'Online',
    followers: 'Anhänger',
    partner: 'Partner',
    profile_hidden: 'Das Profil dieses Benutzers ist ausgeblendet',
    follow: 'Folgen',
    unfollow: 'Nicht mehr folgen',

    // Mods
    mod: 'Mod | Mods',
    level: 'Ebene | Ebenen',
    content: 'Inhalt',
    subscribed: 'Abonniert',
    tags: 'Tags',
    summary: 'Zusammenfassung',
    contributors: 'Mitwirkende',
    description: 'Beschreibung',
    dependencies: 'Abhängigkeiten',
    of: 'von',
    ago: 'vor',
    and: 'und',
    by: 'Von',
    reset_filters: 'Filter zurücksetzen',
    show_more: 'Zeig mehr',
    show_less: 'Weniger anzeigen',
    no_ugc_found: 'Kein {ugc_name} gefunden',
    no_subscriptions: 'Sie haben kein {ugc_name} abonniert',
    mod_disclaimer_heading: 'Rechtlicher Hinweis',
    mod_disclaimer_privacy: 'Datenschutzerklärung',
    thumbs_up_received: 'Daumen hoch erhalten',
    thumbs_up: 'Daumen hoch',
    total_downloads: 'Gesamtzahl der Downloads',
    releases: 'Veröffentlichungen',
    bytes: 'Bytes',

    // Actions
    login: 'Anmelden',
    logout: 'Abmelden',
    search: 'Suchen',
    clear_all: 'Alle löschen',
    download: 'Herunterladen',
    subscribe: 'Abonnieren',
    unsubscribe: 'Nicht mehr abonnieren',
    resubscribe: 'Erneut abonnieren',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
    like: 'Gefällt mir',
    dislike: 'Gefällt mir nicht',
    report: 'Ein Problem melden',
    submit: 'Absenden',
    cancel: 'Abbrechen',
    select: 'Wählen',
    previous: 'Bisherige',
    next: 'Weiter',
    confirm: 'Bestätigen',
    allow: 'Erlauben',

    // Filters
    all: 'Alle',
    available: 'verfügbar',
    filesize: 'Dateigröße',
    downloads: 'Downloads',
    todays_downloads: 'Heutige Downloads',
    subscribers: 'Abonnenten',
    rating: 'Bewertung',
    sort_by: 'Sortieren nach',
    creator: 'Creator',
    apply: 'Anwenden',
    reset: 'Zurücksetzen',
    featured: 'Vorgestellt',
    toggle_filters: 'Filter umschalten',
    hidden: 'Versteckt',
    public: 'Öffentlich',
    pending: 'Ausstehend',
    deleted: 'Gelöscht',
    last_day: 'Letzter Tag',
    last_week: 'Letzte Woche',
    last_month: 'Letzten Monat',
    last_3_months: 'Letzte 3 Monate',
    last_year: 'Letztes Jahr',

    // Login
    signup: 'Anmelden oder mit E-Mail-Adresse registrieren',
    login_with: 'Anmelden Sie sich mit {service} an',
    email_placeholder: 'E-Mail-Adresse',
    code_placeholder: '5-stelliger Code',
    have_code: 'Ich habe einen Code',
    email_code: 'Code per E-Mail senden',
    send_new_code: 'Neuen Code senden',
    check_email:
      'Bitte prüfen Sie Ihr E-Mail-Postfach auf Ihren Code und geben Sie ihn hier ein.',
    login_disclaimer:
      'Benutzergenerierte Inhalte für {game} werden von mod.io verwaltet.',
    terms_disclaimer: `Indem Sie auf „Anmelden“ klicken, stimmen Sie den {studioterms}{terms} und der {privacy} von mod.io zu und bestätigen, dass Sie mindestens {min_age} Jahre alt sind.`,
    terms_disclaimer_studio: '{terms} von {studio}, den ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' und ',
    code_error: 'Code kann nicht angefordert werden.',
    or: 'oder',
    login_prompt:
      'Die Seite, auf die Sie zugreifen möchten, erfordert eine Authentifizierung. Bitte melden Sie sich an, um fortzufahren.',

    // Terms
    terms: 'Nutzungsbedingungen',
    privacy: 'Datenschutzerklärung',
    aup: 'Richtlinien zur akzeptablen Nutzung',
    monetization: 'Monetarisierungsbedingungen',
    game: 'Spielbedingungen',
    api: 'API-Zugriffsbedingungen',
    effective: 'Gültig ab',

    // Report
    report_heading: 'Diese Mod melden',
    report_intro:
      'Sie können Probleme mit Inhalten oder Verstöße gegen die {Bedingungen} von mod.io melden oder einen {dmca} über das unten stehende Formular einreichen.',
    report_details:
      'Stellen Sie sicher, dass Sie alle relevanten Informationen angeben, um die Lösung Ihres Berichts zu erleichtern. Bitte beachten Sie, dass diese Informationen an Spielmoderatoren weitergegeben werden und möglicherweise an den Benutzer weitergegeben werden, der den mutmaßlich rechtsverletzenden Inhalt gepostet hat.',
    report_not_working_prompt:
      '„Funktioniert nicht“-Meldungen werden direkt mit dem Ersteller des Inhalts geteilt.',
    dmca: 'DMCA-Beschwerde',
    reason: 'Grund für die Meldung',
    urls: 'Problematische URLs',
    urls_placeholder: 'URLs (eine pro Zeile)',
    company_or_name: 'Unternehmen oder Ihr Name',
    email: 'E-Mail',
    address: 'Adresse',
    country: 'Land',
    country_placeholder: 'Wählen Sie ein Land aus',
    details: 'Einzelheiten zum Verstoß',
    details_placeholder:
      'Um Ihnen bei der Lösung Ihrer Meldung behilflich zu sein, geben Sie bitte so viele Details und Kontexte wie möglich an.',
    dmca_1:
      '{mod_io} respektiert die geistigen Eigentumsrechte anderer und wir bitten darum, dass jeder, der unsere Internet-Websites und Dienste nutzt, dies ebenfalls tut. Wenn Sie der Meinung sind, dass Ihr Werk auf einer unserer Internet-Websites oder im Rahmen eines unserer Dienste in einer Weise reproduziert wurde, die eine Urheberrechtsverletzung darstellt, können Sie den Vertreter für Urheberrechte bei mod.io über das unten stehende Formular informieren.',
    dmca_2:
      'Das Einreichen einer Beschwerde wegen Urheberrechtsverletzung ist eine ernste rechtliche Angelegenheit. Bevor Sie fortfahren, sollten Sie erwägen, die Person direkt zu kontaktieren, um das Problem anzusprechen. Möglicherweise handelt es sich um ein einfaches Missverständnis, das sich abseits des Rechtswegs klären lässt.',
    dmca_3:
      'Bitte beachten Sie, dass gemäß Abschnitt 512 (f) des Digital Millennium Copyright Act jede Person, die wissentlich falsche Angaben über die Rechtsverletzung von Material oder Aktivitäten macht, schadensersatzpflichtig sein kann. Bitte beachten Sie weiterhin, dass die in diesem rechtlichen Hinweis enthaltenen Informationen an Dritte weitergegeben oder veröffentlicht werden können.',
    claim_check:
      'Indem ich die folgenden Kästchen ankreuze und diese Beschwerde einreiche, erkläre ich UNTER ANDROHUNG VON STRAFE FÜR SCHULDHAFTES VERHALTEN, dass',
    copyright_owner: 'ich der Urheberrechtsinhaber bin',
    copyright_auth_owner:
      'ich berechtigt bin, in dieser Situation im Namen des Urheberrechtsinhabers zu handeln.',
    authorization: 'Genehmigung',
    authorization_check:
      'Ich bin in gutem Glauben davon überzeugt, dass die Verwendung des in dieser Beschwerde genannten Materials nicht durch den Urheberrechtsinhaber, dessen Vertreter oder das Gesetz genehmigt ist.',
    acknowledgement: 'Bestätigung',
    acknowledgement_check:
      'Ich erkenne an, dass gemäß Abschnitt 512 (f) des DMCA jede Person, die wissentlich falsche Angaben über die Rechtsverletzung von Material oder Aktivitäten macht, schadensersatzpflichtig sein kann.',
    required: 'erforderlich',
    signature: 'Unterschrift',
    signature_placeholder:
      'Geben Sie Ihren vollständigen Namen in dieses Feld ein, was als Ihre digitale Unterschrift dient.',
    report_success_title: 'Ihre Meldung ist eingegangen',
    report_success_text:
      'Falls Ihre Nachricht eine Antwort erfordert, haben Sie bitte etwas Geduld. Wir bemühen uns, alle Meldungen innerhalb von 48 Stunden nach Erhalt zu beantworten.',
    report_error_title: 'Fehler beim Senden der Meldung',

    // Table
    name: 'Name',
    added: 'Hinzugefügt',
    updated: 'Aktualisiert',
    file: 'Datei',
    version: 'Version',
    scan: 'Virenscan',
    actions: 'Aktionen',
    locally_installed: 'Lokal installiert',

    // Releases
    download_from_game_client: '{mod} im Spielclient herunterladen',
    release_notes: 'Versionshinweise',
    no_release_notes: 'Keine Versionshinweise verfügbar',
    previous_releases: 'Vorherige Versionen',
    view_previous_releases: 'Frühere Veröffentlichungen anzeigen',
    no_previous_releases: 'Es gibt keine vorherigen Versionen',
    no_files_published: 'Keine Dateien veröffentlicht',
    download_files_manually: 'Laden Sie Dateien manuell herunter',
    platforms: 'Plattform | Plattformen',

    // Ratings
    overwhelmingly_positive: 'Äußerst positiv',
    overwhelmingly_negative: 'Äußerst negativ',
    very_positive: 'Sehr positiv',
    very_negative: 'Sehr negativ',
    mostly_positive: 'Größtenteils positiv',
    mostly_negative: 'Größtenteils negativ',
    negative: 'Negativ',
    positive: 'Positiv',
    mixed: 'Gemischt',
    unrated: 'Keine Bewertung',

    // Sort
    sort_comments: 'Kommentare',
    sort_total_mods: 'Gesamtzahl der Mods',
    sort_trending: 'Im Trend',
    sort_highest_rated: 'Am besten bewertet',
    sort_most_popular: 'Am beliebtesten',
    sort_most_subscribers: 'Die meisten Abonnenten',
    sort_recently_added: 'Kürzlich hinzugefügt',
    sort_last_updated: 'Letzte Aktualisierung',
    sort_alphabetical: 'Alphabetisch',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Funktioniert nicht',
    report_rude_content: 'Unhöflicher Inhalt',
    report_illegal_content: 'Illegaler Inhalt',
    report_stolen_content: 'Gestohlener Inhalt',
    report_false_information: 'Falsche Informationen',
    report_other: 'Andere',

    // Report not working reasons
    report_crash: 'Spiel stürzt ab',
    report_no_load: 'Lädt nicht',
    report_conflicts: 'Konflikte mit anderen {ugc}',
    report_missing_deps: 'Fehlende Abhängigkeiten',
    report_install_issues: 'Installationsprobleme',
    report_bugs: 'Buggy-Verhalten',
    report_incompatability: 'Inkompatibel mit der Spielversion',
    report_corrupt: 'Beschädigte Dateien',

    // Errors
    error_ref: 'Fehlercode: ',
    login_fail: 'Einloggen fehlgeschlagen',
    login_fail_message:
      'Einloggen fehlgeschlagen. Bitte warten Sie eine Minute und versuchen Sie es erneut.',
    subscribe_fail: 'Nicht abonnieren',
    unsubscribe_fail: 'Versäumnis, sich abzumelden',
    error: 'Fehler',
    default_error_message:
      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es bald erneut.',
    follow_error: 'Konnte nicht folgen',
    unfollow_error: 'Das Entfolgen ist fehlgeschlagen',

    agreement_not_found: 'Vereinbarung nicht gefunden',
    form_chars_remaining: '{count} Zeichen verbleibend.',
    form_not_enough_chars: '{count} Weitere Zeichen erforderlich.',
    filter: 'Filtern',
    loading: 'Wird geladen',
    next_version: 'Nächste Version',
    no_results_found: 'keine Ergebnisse gefunden',
    page_not_found: 'Seite nicht gefunden!',
    previous_version: 'Vorherige Version',
    take_me_home: 'Bring mich nach Hause',
    validation_alpha_num: 'Feld kann nur Zahlen oder Buchstaben enthalten',
    validation_size: 'Das Feld muss {size} Zeichen sein',
    validation_url: 'Feld muss eine URL sein',
    validation_min: 'Mindestens {min} Zeichen',
    validation_max: 'Maximum {max} Zeichen',
    validation_email: 'Das Feld muss eine E-Mail sein',
    validation_checked: 'Das Feld muss überprüft werden',
    validation_required: 'Feld ist erforderlich',
    validation_name:
      'Das Feld darf nur Buchstaben und namensbezogene Symbole enthalten',
    validation_image: 'Die Datei muss ein gültiges JPEG- oder PNG-Bild sein',
    validation_youtube: 'Das Feld muss eine gültige Youtube-URL sein',
    validation_sketchfab: 'Das Feld muss eine gültige Sketchfab-URL sein',
    validation_zip: 'Die Datei muss eine ZIP-Datei sein',
    validation_filesize: 'Die Dateigröße darf {size} nicht überschreiten',

    comment: 'Kommentar | Kommentare',
    team_member: 'Teammitglied',
    author: 'Autor',
    good_karma: 'Gutes Karma',
    bad_karma: 'Schlechtes Karma',
    reply: 'Antwort',
    replies: 'antworten | Antworten',
    save: 'Speichern',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    post: 'Post',
    pinned: 'Angepinnt',
    pin: 'Stift',
    unpin: 'Entpinnen',
    locked: 'Gesperrt',
    lock: 'Sperren',
    unlock: 'Entsperren',
    add_comment: 'Einen Kommentar hinzufügen...',
    comment_cant_reply_locked:
      'Kann nicht antworten, da der Kommentarthread gesperrt ist',
    comment_cant_edit_locked:
      'Kann nicht bearbeitet werden, da der Kommentarthread gesperrt ist',
    comment_max_pin_limit:
      'Maximales Limit für angeheftete Kommentare erreicht',
    comment_below_threshold:
      'Kommentar unter dem Schwellenwert. Zum Ansehen hier klicken.',
    comment_login_prompt: 'Melden Sie sich an, um der Diskussion beizutreten!',
    comment_error_add: 'Fehlte keinen Kommentar hinzufügen',
    comment_error_edit: 'Kommentar nicht bearbeiten',
    comment_error_delete: 'Kommentar nicht gelöscht',
    comment_error_rate: 'Kommentar konnte nicht bewertet werden',
    comment_error_update: 'Kommentar konnte nicht aktualisiert werden',
    comments_error_fetch: 'Weitere Kommentare nicht laden',
    discussion: 'Diskussion',
    load_more_comments: 'Laden Sie weitere Kommentare',
    comment_confirm_delete:
      'Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?',
    comment_deleted: 'Der Kommentar wurde erfolgreich gelöscht',

    // Mod admin
    mod_profile: '{ugc}-Profil',
    media: 'Medien',
    file_manager: 'Dateimanager',
    step: 'SCHRITT {step}',
    save_next: 'Speichern und Weiter',
    auth_required: 'Authentifizierung erforderlich',
    tools_only_1:
      'Du kannst hier keine {ugc} für {game} hochladen. Sie müssen mit Tools hochgeladen werden, die der Spieleentwickler erstellt hat, damit die Dateien verifiziert werden können. Weitere Informationen findest du in den {guides}, die für dieses Spiel geschrieben wurden. ',
    tools_only_2: 'Hier sind die Anweisungen des Spieleentwicklers:',
    tools_only_3:
      'Wir empfehlen Ihnen, zur {ugc} -Liste zurückzukehren und von dort aus die Links zu durchsuchen. Wenn das Problem weiterhin besteht, besuchen Sie unser {help_center} für weitere Informationen.',
    unsaved_heading: 'Sie haben ungespeicherte Änderungen',
    unsaved_confirm:
      'Es gibt Änderungen, die nicht gespeichert wurden. Möchten Sie trotzdem gehen?',

    headline_mod_profile: 'Erstellen Sie Ihr {ugc}-Profil',
    headline_media: 'Fügen Sie Ihrem {ugc}-Profil Medien hinzu',
    headline_files: 'Laden Sie Ihre {ugc}-Datei hoch',
    headline_permissions:
      'Legen Sie Wiederverwendungsberechtigungen für Ihr {ugc} fest',
    headline_dependencies: 'Fügen Sie alle {ugc}-Abhängigkeiten hinzu',

    // Basics
    basics: 'Grundlagen',
    logo: 'Logo',
    logo_prompt_1: 'Bilddatei, die das Logo Ihres {ugc} darstellt.',
    logo_prompt_2: '512x288 oder größer',
    logo_prompt_3: 'Seitenverhältnis 16:9 empfohlen',
    logo_prompt_4: 'Maximal 8 MB',
    summary_placeholder:
      'Erzähle uns in einem Absatz von den Änderungen, die dein {ugc} am Spiel vornimmt.',
    homepage: 'Startseite',
    profile_url: 'Profil-URL',
    visibility: 'Sichtbarkeit',
    visibility_tip:
      'Sobald sie live sind, sind öffentliche {ugc} für jeden sichtbar. Hidden {ugc} sind nur für Teammitglieder und Abonnenten sichtbar.',
    upload_images: 'Bilder hochladen',
    mature_content: 'Enthält Inhalte für Erwachsene',
    mature_content_alcohol: 'Alkohol',
    mature_content_drugs: 'Drogen',
    mature_content_violence: 'Gewalt',
    mature_content_explicit: 'Explizit',
    community: 'Gemeinschaft',
    allow_comments: 'Kommentare zulassen',
    allow_dependants: 'Angehörige zulassen',
    create_mod: '{ugc} erstellen',
    error_mod_create: '{ugc} konnte nicht erstellt werden',
    error_mod_update: '{ugc} konnte nicht aktualisiert werden',
    error_tags_update: 'Die Tags konnten nicht aktualisiert werden',
    success_create_mod: '{modname} wurde erfolgreich erstellt',
    success_update_mod: '{modname} wurde erfolgreich aktualisiert',
    description_tip:
      'Optionale Beschreibung für Ihr {ugc}, die Details wie „Über uns“, „Funktionen“, „Nutzung“, „Häufig gestellte Fragen“ usw. enthalten kann. HTML wird unterstützt und empfohlen',

    // Media
    models: 'Modelle',
    media_zip_hint_1:
      'Laden Sie bis zu {count} Bilder oder ein einzelnes ZIP-Archiv mit Bildern hoch, um sie der Galerie hinzuzufügen.',
    media_zip_hint_2:
      'Nur gültige JPG- und PNG-Bilder in der Zip-Datei werden verarbeitet.',
    error_media_update: 'Medien konnten nicht aktualisiert werden',
    error_media_add_links: 'Medienlinks konnten nicht hinzugefügt werden',
    error_media_remove_links: 'Medienlinks konnten nicht entfernt werden',
    success_media_updated: 'Medien wurden erfolgreich aktualisiert',

    // File manager
    add_file: 'Eine Datei hinzufügen',
    edit_file: 'Datei bearbeiten',
    select_zip: 'Wählen Sie eine Zip-Datei',
    upload: 'Upload',
    file_id: 'Datei-ID:',
    virus_none: 'Kein Virus erkannt',
    virus_waiting: 'Warte auf den Scan',
    virus_scanning: 'Scan läuft',
    virus_too_large: 'Datei ist zu groß zum Scannen',
    virus_not_found: 'Die Datei wurde nicht gefunden',
    virus_error: 'Fehler beim Scannen der Datei',
    virus_found: 'Virus entdeckt',
    virus_risk:
      'Diese Datei enthält eine ausführbare Datei und beim Öffnen ist Vorsicht geboten',
    version_placeholder: 'Versionsnummer, z. B. 1.0.0',
    release_notes_placeholder:
      'Geben Sie jede Änderung in einer separaten Zeile ein',
    game_moderator: 'Spielmoderator',
    metadata_blob: 'Metadaten-Blob',
    metadata_blob_placeholder:
      'Geben Sie spezifische Metadaten ein, z. B. die Version des Spiels, mit der diese Version kompatibel ist, wie sie installiert wird usw. Gültige UTF-8-Daten werden unterstützt.',
    select_platforms:
      'Plattformen (wählen Sie alle zutreffenden Antworten aus)',
    file_pending: 'Ausstehende Genehmigung',
    file_approved: 'Genehmigt',
    file_approved_live: 'Genehmigt und live',
    file_approved_not_live: 'Genehmigt, aber nicht live',
    file_denied: 'Abgelehnt',
    file_targeted: 'Gezielt',
    live_file: 'Diese Datei ist derzeit live.',
    file_is_live: 'Datei ist live',
    make_file_live: 'Machen Sie diese Veröffentlichung live',
    make_file_live_platforms:
      'Machen Sie diese Version für zugelassene Plattformen live',
    file_processing:
      'Ihre Datei wird bearbeitet. Sie können die Website weiter durchsuchen, aber bitte schließen Sie den Tab oder das Browserfenster erst, wenn der Vorgang abgeschlossen ist.',
    file_instructions_1:
      'ZIPPEN Sie den Basisordner Ihres {ugc} und laden Sie ihn hoch. Wenn es sich um eine Sammlung von Dateien handelt, die sich in einem bereits vorhandenen Spielordner befinden, sollten Sie diese Dateien komprimieren:',
    file_instructions_2: 'Muss eine ZIP-Datei sein',
    file_instructions_3: 'Die Dateigröße darf 20 GB nicht überschreiten',
    file_instructions_4:
      'Dateinamen dürfen keines der folgenden Zeichen enthalten: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc} welche das Überschreiben von Dateien nicht unterstützt werden, es sei denn, das Spiel schafft das',
    file_instructions_6:
      '{ugc}, die sich über mehrere Spielverzeichnisse erstrecken, werden nicht unterstützt, es sei denn, das Spiel schafft dies',
    file_terms: 'Ich stimme den {terms} und {privacy} zu.',
    error_get_files: 'Dateien konnten nicht abgerufen werden',
    error_upload_file: 'Datei konnte nicht hochgeladen werden',
    error_update_file: 'Datei konnte nicht aktualisiert werden',
    error_file_required: 'Datei ist erforderlich',
    error_platform_required: 'Sie müssen mindestens eine Plattform auswählen',
    success_file_uploaded: 'Datei wurde erfolgreich hochgeladen',
    success_file_moderation:
      'Ein Moderator überprüft Plattformen, die moderiert werden müssen',
    success_file_updated: 'Datei wurde erfolgreich aktualisiert',
    error_no_approved_platforms:
      'Keine Plattformen, die live gestellt werden können',
    error_no_approved_platforms_text:
      'Diese Datei hat keine zugelassenen Plattformen, die derzeit nicht live sind',

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Berechtigungen wiederverwenden',
      allow_redistribution:
        'Erlaube anderen, meine {ugc} -Assets auf anderen Websites hochzuladen, aber ich muss als ursprünglicher Ersteller genannt werden',
      allow_porting:
        'Erlaube anderen, meine {ugc} -Assets so zu konvertieren, dass sie in anderen Spielen funktionieren, aber ich muss als ursprünglicher Schöpfer genannt werden',
      allow_patching_no_credit:
        'Erlaube anderen, meine {ugc} -Assets zu ändern (einschließlich der Veröffentlichung von Bugfixes oder Verbesserungen) und sie als neues {ugc} zu veröffentlichen',
      require_credit: 'Ich muss als der ursprüngliche Schöpfer genannt werden',
      require_permission: 'Erfordern Sie zuerst die Erlaubnis von mir',
      require_repackage_no_credit:
        'Erlaube anderen, meine {0} Assets in ihren eigenen {1} zu verwenden',
      allow_resell:
        'Erlaube anderen, meine {ugc} -Assets in beliebigen {ugc} /Dateien in kommerziellen Werken zu verwenden',
      original_assets:
        'Mein {ugc} enthält zu 100% Originalarbeiten oder Ressourcen, die weiterverteilt werden können',
      error_update:
        'Die Wiederverwendungsberechtigungen konnten nicht aktualisiert werden',
      success_update:
        'Die Wiederverwendungsberechtigungen wurden erfolgreich aktualisiert',
      prompt:
        'Lege Berechtigungen fest, wie deine {ugc} -Assets für die Erstellung von Inhalten anderer verwendet werden können. Indem Sie anderen die Erlaubnis erteilen, Ihre Dateien zu verwenden oder zu ändern, erkennen Sie an, dass eine solche Genehmigung in Bezug auf bereits erstellte Werke nicht rückwirkend widerrufen werden kann.',
    },

    // Dependencies form
    dependencies_prompt:
      'Wählen Sie {0} aus, damit {1} korrekt funktioniert (wenn {1} nicht angezeigt wird, bedeutet dies, dass keine Abhängigkeiten zulässig sind, und Sie sollten den Ersteller kontaktieren, um ihn einzuschalten):',
    remove_dependency: 'Abhängigkeit entfernen',
    dependants: 'Familienangehörige',
    error_update_dependencies:
      'Abhängigkeiten konnten nicht aktualisiert werden',
    success_update_dependencies:
      'Die Abhängigkeitseinstellungen wurden erfolgreich aktualisiert',
    dependants_warning_1:
      'Sobald dies erlaubt ist, beachten Sie bitte, dass {ugc} mit verbundenen Angehörigen:',
    dependants_warning_2: 'Angehörige können nicht deaktiviert werden',
    dependants_warning_3: 'Kann nicht gelöscht werden',
    dependants_warning_4: 'Kann nicht monetarisiert werden',
    dependants_confirm_enable:
      'Dies dient dazu, die fortlaufende Kompatibilität von connected {ugc} sicherzustellen. Bitte bestätigen Sie, um es zu aktivieren.',
    dependants_confirm_disable:
      'Möchten Sie Angehörige wirklich verbieten? Das bedeutet, dass andere {0} Ihre {1} nicht als Abhängigkeit hinzufügen können.',
  },
  it: {
    language: 'Lingua',

    // Nav
    browse: 'Esplora',
    library: 'Biblioteca',
    account: 'Il mio conto',
    back: 'Torna indietro',
    more: 'Di più',
    add_mod: 'Aggiungi {ugc}',
    edit_mod: 'Modifica su mod.io',
    help_center: 'centro assistenza',

    // Guides
    guide: 'Guida | Guide',
    views: 'Visualizzazioni',
    table_of_contents: 'Sommario',
    reading_time: 'Momento della lettura',
    date_added: 'Data aggiunta',
    date_updated: 'Data aggiornata',
    minutes_read: 'Minuto letto',
    published: 'Pubblicato',

    // Users
    joined: 'Partecipato',
    last_online: 'Ultimo Online',
    online: 'in linea',
    followers: 'Seguaci',
    partner: 'Compagno',
    profile_hidden: 'Il profilo di questo utente è nascosto',
    follow: 'Seguire',
    unfollow: 'Smetti di seguire',

    // Mods
    mod: 'Mod | Mods',
    level: 'Livello | Livelli',
    content: 'Contenuto',
    subscribed: 'Iscritto',
    tags: 'Tag',
    summary: 'Riepilogo',
    contributors: 'Collaboratori',
    description: 'Descrizione',
    dependencies: 'Dipendenze',
    of: 'di',
    ago: 'fa',
    and: 'E',
    by: 'Di',
    reset_filters: 'Reimposta filtri',
    show_more: 'Mostra di più',
    show_less: 'Mostra meno',
    no_ugc_found: 'Nessun {ugc_name} trovato',
    no_subscriptions: 'Non sei abbonato a nessun {ugc_name}',
    mod_disclaimer_heading: 'Dichiarazione di non responsabilità',
    mod_disclaimer_privacy: 'Informativa sulla privacy',
    thumbs_up_received: 'Pollice in su ricevuto',
    thumbs_up: 'Pollice su',
    total_downloads: 'Download totali',
    releases: 'Rilasci',
    bytes: 'byte',

    // Actions
    login: 'Accedi',
    logout: 'Esci',
    search: 'Cerca',
    clear_all: 'Cancella tutto',
    download: 'Scarica',
    subscribe: 'Iscriviti',
    unsubscribe: "Annulla l'iscrizione",
    resubscribe: "Ripristina l'iscrizione",
    activate: 'Attiva',
    deactivate: 'Disattiva',
    like: 'Mi piace',
    dislike: 'Non mi piace',
    report: 'Segnala un problema',
    submit: 'Invia',
    cancel: 'Annulla',
    select: 'Selezionare',
    previous: 'Precedente',
    next: 'Prossimo',
    confirm: 'Confermare',
    allow: 'Consenti',

    // Filters
    all: 'Tutto',
    available: 'disponibili',
    filesize: 'Dimensione file',
    downloads: 'Download',
    todays_downloads: 'I download di oggi',
    subscribers: 'Iscritti',
    rating: 'Valutazione',
    sort_by: 'Ordina per',
    creator: 'Creatore',
    apply: 'Fare domanda a',
    reset: 'Ripristina',
    featured: 'In primo piano',
    toggle_filters: 'Attiva i filtri',
    hidden: 'Nascosto',
    public: 'Pubblico',
    pending: 'In attesa di',
    deleted: 'Eliminato',
    last_day: 'Ultimo giorno',
    last_week: 'La settimana scorsa',
    last_month: 'Il mese scorso',
    last_3_months: 'Ultimi 3 mesi',
    last_year: `L'anno scorso`,

    // Login
    signup: 'Accedi o registrati con email',
    login_with: 'Accedi con {service}',
    email_placeholder: 'Indirizzo email',
    code_placeholder: 'Codice a 5 cifre',
    have_code: 'Ho un codice',
    email_code: 'Codice email',
    send_new_code: 'Invia nuovo codice',
    check_email: 'Recuperare il codice nella casella di posta e inserirlo qui.',
    login_disclaimer:
      'I contenuti generati dagli utenti per {game} sono gestiti da mod.io.',
    terms_disclaimer: `Premendo "Accedi", accetti i {studioterms}{terms} e la {privacy} di mod.io e confermi di avere {min_age} anni o più.`,
    terms_disclaimer_studio: '{terms} di {studio}, i ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' e ',
    code_error: 'Impossibile richiedere il codice.',
    or: 'O',
    login_prompt:
      "La pagina a cui stai tentando di accedere richiede l'autenticazione. Effettua il login per continuare.",

    // Terms
    terms: "Termini d'uso",
    privacy: 'Informativa sulla privacy',
    aup: 'Politica di utilizzo accettabile',
    monetization: 'Termini di monetizzazione',
    game: 'Termini di gioco',
    api: "Termini di accesso all'API",
    effective: 'Efficace da',

    // Report
    report_heading: 'Segnala questa mod',
    report_intro:
      'Puoi segnalare problemi con i contenuti, violazioni di mod.io {terms} o inviare un {dmca} utilizzando il modulo sottostante.',
    report_details:
      "Assicurati di includere tutte le informazioni rilevanti per facilitare la risoluzione della tua segnalazione. Tieni presente che queste informazioni verranno condivise con i moderatori del gioco e potrebbero essere condivise con l'utente che ha pubblicato il contenuto in presunta violazione.",
    report_not_working_prompt:
      'Le segnalazioni "Non funzionanti" verranno condivise direttamente con il creatore del contenuto.',
    dmca: 'Reclamo DMCA',
    reason: 'Motivo della segnalazione',
    urls: 'URL della violazione',
    urls_placeholder: 'URL (1 per riga)',
    company_or_name: "Nome proprio o dell'azienda",
    email: 'Email',
    address: 'Indirizzo',
    country: 'Paese',
    country_placeholder: 'Selezionare un Paese',
    details: 'Informazioni sulla violazione',
    details_placeholder:
      'Per aiutarti a risolvere la tua segnalazione, fornisci quanti più dettagli e contesto possibile.',
    dmca_1:
      "{mod_io} rispetta i diritti di proprietà intellettuale altrui e richiede altrettanto a tutti coloro che utilizzano i suoi siti internet e servizi. Chiunque ritenga che la propria opera sia stata riprodotta in uno dei nostri siti internet o servizi in modo tale da costituire una violazione del copyright, può segnalarlo all'agente designato al DMCA di mod.io attraverso il modulo sottostante.",
    dmca_2:
      'Inoltrare un reclamo per violazione del copyright presenta serie implicazioni giuridiche. Prima di procedere, valutare la possibilità di sporgere reclamo al diretto interessato contattandolo personalmente. Potrebbe trattarsi di un semplice malinteso risolvibile senza il ricorso a provvedimenti legali.',
    dmca_3:
      "Nota bene: secondo il Digital Millennium Copyright Act, Sezione 512 (f), chiunque consapevolmente e sostanzialmente dichiari il falso sulla violazione di un materiale o un'attività, può essere chiamato a rispondere di eventuali danni. Inoltre, le informazioni fornite sotto la presente nota legale potranno essere condivise con terze parti o rese pubbliche.",
    claim_check:
      'Selezionando le seguenti caselle e inviando il presente reclamo, dichiaro SOTTO PENA DI SPERGIURO che',
    copyright_owner: 'Sono il titolare del copyright',
    copyright_auth_owner:
      "Ho l'autorizzazione ad agire per conto del titolare del copyright nelle presenti circostanze.",
    authorization: 'Autorizzazione',
    authorization_check:
      "Ritengo in buona fede che l'utilizzo del materiale segnalato in questo reclamo non sia autorizzato dal titolare del copyright, dal suo agente o dalla legge.",
    acknowledgement: 'Riconoscimento',
    acknowledgement_check:
      "Riconosco che secondo il DMCA, Sezione 512 (f), chiunque consapevolmente e sostanzialmente dichiari il falso sulla violazione di un materiale o un'attività, può essere chiamato a rispondere di eventuali danni.",
    required: 'campo obbligatorio',
    signature: 'Firma',
    signature_placeholder:
      'Digitare nome e cognome nel campo sottostante. Fungerà da firma digitale.',
    report_success_title: 'La segnalazione è stata ricevuta',
    report_success_text:
      'Se il messaggio inviato richiede risposta, si prega di attendere. Ci adoperiamo per rispondere a tutte le segnalazioni entro 48 ore dalla ricezione.',
    report_error_title: 'Impossibile inviare la segnalazione',

    // Table
    name: 'Nome',
    added: 'Periodo pubblicazione',
    updated: 'Aggiornamento',
    file: 'File',
    version: 'Versione',
    scan: 'Scansione antivirus',
    actions: 'Azioni',
    locally_installed: 'Installazione locale',

    // Releases
    download_from_game_client: 'Scarica {mod} dal client di gioco',
    release_notes: 'Note di versione',
    no_release_notes: 'Nessuna nota di versione',
    previous_releases: 'Versioni precedenti',
    view_previous_releases: 'Visualizza le versioni precedenti',
    no_previous_releases: 'Nessuna versione precedente',
    no_files_published: 'Nessun file pubblicato',
    download_files_manually: 'Scaricare i file manualmente',
    platforms: 'Piattaforma | Piattaforme',

    // Ratings
    overwhelmingly_positive: 'Estremamente positivo',
    overwhelmingly_negative: 'Estremamente negativo',
    very_positive: 'Molto positivo',
    very_negative: 'Molto negativo',
    mostly_positive: 'Prevalentemente positivo',
    mostly_negative: 'Prevalentemente negativo',
    negative: 'Negativo',
    positive: 'Positivo',
    mixed: 'Ambivalente',
    unrated: 'Non classificato',

    // Sort
    sort_comments: 'Commenti',
    sort_total_mods: 'Mod totali',
    sort_trending: 'Di tendenza',
    sort_highest_rated: 'Voto più alto',
    sort_most_popular: 'Più popolare',
    sort_most_subscribers: 'La maggior parte degli abbonati',
    sort_recently_added: 'Aggiunto recentemente',
    sort_last_updated: 'Ultimo aggiornamento',
    sort_alphabetical: 'Alfabetico',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Non funzionante',
    report_rude_content: 'Contenuti maleducati',
    report_illegal_content: 'Contenuto illegale',
    report_stolen_content: 'Contenuto rubato',
    report_false_information: 'Informazioni false',
    report_other: 'Altro',

    // Report not working reasons
    report_crash: 'Gioco in crash',
    report_no_load: 'Non si carica',
    report_conflicts: 'Conflitti con altri {ugc}',
    report_missing_deps: 'Dipendenze mancanti',
    report_install_issues: 'Problemi di installazione',
    report_bugs: 'Comportamento buggato',
    report_incompatability: 'Incompatibile con la versione del gioco',
    report_corrupt: 'File danneggiati',

    // Errors
    error_ref: 'Codice di errore: ',
    login_fail: 'Impossibile accedere',
    login_fail_message:
      'Impossibile accedere. Per favore aspetta un minuto e riprova.',
    subscribe_fail: 'Impossibile iscriversi',
    unsubscribe_fail: "Impossibile annullare l'iscrizione",
    error: 'Errore',
    default_error_message: 'Si è verificato un errore, riprova presto.',
    follow_error: 'Impossibile seguire',
    unfollow_error: 'Impossibile smettere di seguire',

    agreement_not_found: 'Accordo non trovato',
    form_chars_remaining: '{count} personaggi rimanenti.',
    form_not_enough_chars: '{count} più caratteri richiesti.',
    filter: 'Filtro',
    loading: 'Caricamento',
    next_version: 'Versione successiva',
    no_results_found: 'nessun risultato trovato',
    page_not_found: 'Pagina non trovata!',
    previous_version: 'Versione precedente',
    take_me_home: 'Portami a casa',
    validation_alpha_num: 'Il campo può contenere solo numeri o lettere',
    validation_size: 'Il campo deve essere {size} caratteri',
    validation_url: 'Il campo deve essere un URL',
    validation_min: 'Minimo {min} caratteri',
    validation_max: 'Massimi caratteri {max}',
    validation_email: "Il campo deve essere un'e-mail",
    validation_checked: 'Il campo deve essere controllato',
    validation_required: 'Il campo è obbligatiorio',
    validation_name:
      'Il campo può contenere solo lettere e simboli relativi al nome',
    validation_image: "Il file deve essere un'immagine jpeg o png valida",
    validation_youtube: 'Il campo deve essere un URL Youtube valido',
    validation_sketchfab: 'Il campo deve essere un URL Sketchfab valido',
    validation_zip: 'Il file deve essere un file ZIP',
    validation_filesize: 'La dimensione del file non deve superare {size}',

    comment: 'commento | commenti',
    team_member: 'Membro della squadra',
    author: 'Autore',
    good_karma: 'Buon karma',
    bad_karma: 'Cattivo karma',
    reply: 'Rispondere',
    replies: 'risposta | risposte',
    save: 'Salva',
    delete: 'Eliminare',
    edit: 'Modificare',
    post: 'Inviare',
    pinned: 'Appuntato',
    pin: 'Perno',
    unpin: 'Sblocca',
    locked: 'Bloccato',
    lock: 'Serratura',
    unlock: 'Sblocca',
    add_comment: 'Aggiungi un commento...',
    comment_cant_reply_locked:
      'Impossibile rispondere perché il thread dei commenti è bloccato',
    comment_cant_edit_locked:
      'Impossibile modificare poiché il thread dei commenti è bloccato',
    comment_max_pin_limit: 'Raggiunto il limite massimo di commenti bloccati',
    comment_below_threshold:
      'Commento al di sotto della soglia. Fai clic per visualizzare.',
    comment_login_prompt: 'Accedi per unirti alla discussione!',
    comment_error_add: 'Impossibile aggiungere un commento',
    comment_error_edit: 'Impossibile modificare il commento',
    comment_error_delete: 'Impossibile eliminare il commento',
    comment_error_rate: 'Impossibile valutare il commento',
    comment_error_update: 'Aggiornamento del commento non riuscito',
    comments_error_fetch: 'Impossibile caricare più commenti',
    discussion: 'Discussione',
    load_more_comments: 'Carica più commenti',
    comment_confirm_delete: 'Sei sicuro di voler eliminare questo commento?',
    comment_deleted: 'Il commento è stato eliminato con successo',

    // Mod admin
    mod_profile: 'profilo {ugc}',
    media: 'Media',
    file_manager: 'Gestore dei file',
    step: 'PASSO {step}',
    save_next: 'Salva e Avanti',
    auth_required: 'Autenticazione richiesta',
    tools_only_1:
      'Non puoi caricare {ugc} per {game} qui, devono essere caricati utilizzando gli strumenti creati dallo sviluppatore del gioco, in modo che i file possano essere verificati. Per maggiori informazioni, consulta le {guides} scritte per questo gioco. ',
    tools_only_2: 'Ecco le istruzioni fornite dallo sviluppatore del gioco:',
    tools_only_3:
      "Ti consigliamo di tornare all'elenco {ugc} e di sfogliare i link da lì. Se il problema persiste, visita il nostro {help_center} per maggiori informazioni.",
    unsaved_heading: 'Hai modifiche non salvate',
    unsaved_confirm:
      'Ci sono modifiche che non sono state salvate, vuoi ancora andartene?',

    headline_mod_profile: 'Crea il tuo profilo {ugc}',
    headline_media: 'Aggiungi contenuti multimediali al tuo profilo {ugc}',
    headline_files: 'Carica il tuo file {ugc}',
    headline_permissions:
      'Imposta le autorizzazioni di riutilizzo per il tuo {ugc}',
    headline_dependencies: 'Aggiungi eventuali dipendenze {ugc}',

    // Basics
    basics: 'Nozioni di base',
    logo: 'Logo',
    logo_prompt_1: 'File di immagine che rappresenterà il logo del tuo {ugc}.',
    logo_prompt_2: '512x288 o superiore',
    logo_prompt_3: "Rapporto d'aspetto 16:9 consigliato",
    logo_prompt_4: 'massimo 8 MB',
    summary_placeholder:
      'Parlaci delle modifiche che il tuo {ugc} apporta al gioco in 1 paragrafo.',
    homepage: 'Homepage',
    profile_url: 'URL del profilo',
    visibility: 'Visibilità',
    visibility_tip:
      'Una volta pubblicati, i {ugc} pubblici sono visibili a tutti. I {ugc} nascosti sono visibili solo ai membri del team e agli iscritti.',
    upload_images: 'Carica immagini',
    mature_content: 'Contiene contenuti per adulti',
    mature_content_alcohol: 'Alcol',
    mature_content_drugs: 'Farmaci',
    mature_content_violence: 'Violenza',
    mature_content_explicit: 'Esplicito',
    community: 'Comunità',
    allow_comments: 'Consenti commenti',
    allow_dependants: 'Consenti persone a carico',
    create_mod: 'Crea {ugc}',
    error_mod_create: 'Creazione di {ugc} non riuscita',
    error_mod_update: 'Aggiornamento {ugc} non riuscito',
    error_tags_update: 'Aggiornamento dei tag non riuscito',
    success_create_mod: '{modname} creato con successo',
    success_update_mod: '{modname} aggiornato correttamente',
    description_tip:
      'Descrizione opzionale per il tuo {ugc}, che può includere dettagli come «Informazioni», «Caratteristiche», «Utilizzo», «Domande frequenti», ecc. HTML supportato e incoraggiato',

    // Media
    models: 'modelli',
    media_zip_hint_1:
      'Carica fino a {count} immagini o un singolo archivio zip di immagini da aggiungere alla galleria.',
    media_zip_hint_2:
      'Verranno elaborate solo le immagini jpg e png valide nel file zip.',
    error_media_update: 'Aggiornamento dei file multimediali non riuscito',
    error_media_add_links: 'Impossibile aggiungere collegamenti multimediali',
    error_media_remove_links:
      'Rimozione dei collegamenti multimediali non riuscita',
    success_media_updated: 'File multimediali aggiornati con successo',

    // File manager
    add_file: 'Aggiungere un file',
    edit_file: 'Modifica file',
    select_zip: 'Seleziona il file zip',
    upload: 'Carica',
    file_id: 'ID del file:',
    virus_none: 'Nessun virus rilevato',
    virus_waiting: 'In attesa della scansione',
    virus_scanning: 'Scansione in corso',
    virus_too_large: 'File troppo grande per la scansione',
    virus_not_found: 'File non trovato',
    virus_error: 'Errore durante la scansione del file',
    virus_found: 'Virus rilevato',
    virus_risk:
      "Questo file contiene un file eseguibile e occorre fare attenzione all'apertura",
    version_placeholder: 'Numero di versione, ad esempio 1.0.0',
    release_notes_placeholder: 'Inserisci ogni modifica su una riga separata',
    game_moderator: 'Moderatore del gioco',
    metadata_blob: 'Blob di metadati',
    metadata_blob_placeholder:
      'Inserisci metadati specifici come la versione del gioco con cui questa versione è compatibile, come installarla, ecc. Sono supportati dati validi UTF-8.',
    select_platforms: 'Piattaforme (seleziona tutte le risposte pertinenti)',
    file_pending: 'In attesa di approvazione',
    file_approved: 'Approvato',
    file_approved_live: 'Approvato e in diretta',
    file_approved_not_live: 'Approvato ma non attivo',
    file_denied: 'Negata',
    file_targeted: 'Mirato',
    live_file: 'Questo file è attualmente attivo.',
    file_is_live: 'Il file è attivo',
    make_file_live: 'Rendi attiva questa versione',
    make_file_live_platforms:
      'Rendi attiva questa versione per le piattaforme approvate',
    file_processing:
      'Il tuo file è in fase di elaborazione. Puoi continuare a navigare nel sito ma non chiudere la scheda o la finestra del browser finché non è terminata.',
    file_instructions_1:
      'Comprimi e carica la cartella base del tuo {ugc}, o se si tratta di una raccolta di file che si trovano in una cartella di gioco preesistente, dovresti comprimere quei file:',
    file_instructions_2: 'Deve essere un file ZIP',
    file_instructions_3: 'La dimensione del file non può superare i 20 GB',
    file_instructions_4:
      'I nomi di file non possono contenere nessuno dei seguenti caratteri: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc} che sovrascrivono i file non sono supportati a meno che il gioco non lo gestisca',
    file_instructions_6:
      'I {ugc} che si estendono su più cartelle di gioco non sono supportati a meno che il gioco non gestisca questa funzionalità',
    file_terms: 'Accetto i {terms} e la {privacy}.',
    error_get_files: 'Impossibile recuperare i file',
    error_upload_file: 'Caricamento del file non riuscito',
    error_update_file: 'Aggiornamento del file non riuscito',
    error_file_required: 'Il file è obbligatorio',
    error_platform_required: 'È necessario selezionare almeno una piattaforma',
    success_file_uploaded: 'File caricato con successo',
    success_file_moderation:
      'Un moderatore esaminerà le piattaforme che richiedono moderazione',
    success_file_updated: 'File aggiornato con successo',
    error_no_approved_platforms: 'Nessuna piattaforma da mettere in diretta',
    error_no_approved_platforms_text:
      'Questo file non ha piattaforme approvate che non sono attualmente attive',

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Riutilizzare le autorizzazioni',
      allow_redistribution:
        'Consenti ad altri di caricare le mie risorse {ugc} su altri siti, ma devo essere accreditato come creatore originale',
      allow_porting:
        'Consenti ad altri di convertire le mie risorse {ugc} in modo che funzionino in altri giochi, ma devo essere accreditato come creatore originale',
      allow_patching_no_credit:
        'Consenti ad altri di modificare le mie risorse {ugc} (incluso il rilascio di correzioni di bug o miglioramenti) e rilasciarle come nuove {ugc}',
      require_credit: 'Devo essere accreditato come creatore originale',
      require_permission: 'Richiedi prima il mio permesso',
      require_repackage_no_credit:
        'Consenti ad altri di utilizzare i miei {0} asset nei propri {1}',
      allow_resell:
        'Consenti ad altri di utilizzare le mie risorse {ugc} in qualsiasi file {ugc} in qualsiasi opera commerciale',
      original_assets:
        'Il mio {ugc} contiene opere originali al 100% o risorse che possono essere ridistribuite',
      error_update:
        'Aggiornamento delle autorizzazioni di riutilizzo non riuscito',
      success_update: 'Autorizzazioni di riutilizzo aggiornate correttamente',
      prompt:
        'Imposta le autorizzazioni su come le tue risorse {ugc} possono essere utilizzate per la creazione di contenuti altrui. Concedendo il permesso ad altri di utilizzare o modificare i tuoi file, riconosci che tale autorizzazione non può essere revocata retroattivamente rispetto alle opere già create.',
    },

    // Dependencies form
    dependencies_prompt:
      'Seleziona {0} richiesto affinché questo {1} funzioni correttamente (se non viene visualizzato un {1}, significa che non ha consentito dipendenze e devi contattare il creatore per attivarlo):',
    remove_dependency: 'Rimuovi la dipendenza',
    dependants: 'Persone a carico',
    error_update_dependencies: 'Aggiornamento delle dipendenze non riuscito',
    success_update_dependencies:
      'Impostazioni delle dipendenze aggiornate correttamente',
    dependants_warning_1:
      'Una volta consentito, tieni presente che {ugc} con persone a carico collegate:',
    dependants_warning_2: 'Impossibile disattivare le persone a carico',
    dependants_warning_3: 'Non può essere eliminato',
    dependants_warning_4: 'Non può essere monetizzato',
    dependants_confirm_enable:
      'Questo serve a garantire la compatibilità continua di connected {ugc}. Conferma per abilitare.',
    dependants_confirm_disable:
      "Sei sicuro di voler negare l'autorizzazione alle persone a carico? Ciò significa che altri {0} non saranno in grado di aggiungere la tua {1} come dipendenza.",
  },
  es: {
    language: 'Idioma',

    // Nav
    browse: 'Explorar',
    library: 'Biblioteca',
    account: 'Mi cuenta',
    back: 'Regresa',
    more: 'más',
    add_mod: 'Añadir {ugc}',
    edit_mod: 'Editar en mod.io',
    help_center: 'centro de ayuda',

    // Guides
    guide: 'Guía | Guías',
    views: 'Puntos de vista',
    table_of_contents: 'Tabla de contenido',
    reading_time: 'Tiempo de leer',
    date_added: 'Fecha Agregada',
    date_updated: 'Fecha actualizada',
    minutes_read: 'Lectura de minutos',
    published: 'Publicado',

    // Users
    joined: 'Unido',
    last_online: 'Último en línea',
    online: 'En línea',
    followers: 'Seguidores',
    partner: 'Pareja',
    profile_hidden: 'El perfil de este usuario está oculto',
    follow: 'Seguir',
    unfollow: 'Dejar de seguir',

    // Mods
    mod: 'Mod | Mods',
    level: 'Nivel | Niveles',
    content: 'Contenido',
    subscribed: 'Suscrito',
    tags: 'Etiquetas',
    summary: 'Resumen',
    contributors: 'Contribuciones',
    description: 'Descripción',
    dependencies: 'Dependencias',
    of: 'de',
    ago: 'atrás',
    and: 'y',
    by: 'Por',
    reset_filters: 'Restablecer filtros',
    show_more: 'Mostrar más',
    show_less: 'Mostrar menos',
    no_ugc_found: 'No se encontró {ugc_name}',
    no_subscriptions: 'No te has suscrito a ningún {ugc_name}',
    mod_disclaimer_heading: 'Aviso legal',
    mod_disclaimer_privacy: 'Política de privacidad',
    thumbs_up_received: 'Pulgar arriba recibido',
    thumbs_up: 'Pulgares hacia arriba',
    total_downloads: 'Descargas totales',
    releases: 'Lanzamientos',
    bytes: 'bytes',

    // Actions
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    search: 'Buscar',
    clear_all: 'Borrar todo',
    download: 'Descargar',
    subscribe: 'Suscribirse',
    unsubscribe: 'Anular suscripción',
    resubscribe: 'Volver a suscribirse',
    activate: 'Activar',
    deactivate: 'Desactivar',
    like: 'Me gusta',
    dislike: 'No me gusta',
    report: 'Informar de un problema',
    submit: 'Enviar',
    cancel: 'Cancelar',
    select: 'Seleccionar',
    previous: 'Anterior',
    next: 'Próxima',
    confirm: 'Confirmar',
    allow: 'Permitir',

    // Filters
    all: 'Todo',
    available: 'disponible(s)',
    filesize: 'Tamaño de archivo',
    downloads: 'Descargas',
    todays_downloads: 'Descargados hoy',
    subscribers: 'Suscriptores',
    rating: 'Valuación',
    sort_by: 'Ordenar por',
    creator: 'Creador',
    apply: 'Aplicar',
    reset: 'Reiniciar',
    featured: 'Presentado',
    toggle_filters: 'Filtros de al revés',
    hidden: 'Oculto',
    public: 'Público',
    pending: 'Pendiente',
    deleted: 'Eliminado',
    last_day: 'Día último',
    last_week: 'La semana pasada',
    last_month: 'Mes pasado',
    last_3_months: 'últimos 3 meses',
    last_year: 'El año pasado',

    // Login
    signup: 'Inicie sesión o regístrese con correo electrónico',
    login_with: 'Iniciar sesión con {service}',
    email_placeholder: 'Dirección de correo electrónico',
    code_placeholder: 'Código de 5 dígitos',
    have_code: 'Tengo un código',
    email_code: 'Enviar código al correo',
    send_new_code: 'Enviar otro código',
    check_email:
      'Busque el correo electrónico con el código e introdúzcalo aquí.',
    login_disclaimer:
      'El contenido generado por el usuario para {game} lo administra mod.io.',
    terms_disclaimer: `Al presionar 'Iniciar sesión', aceptas los {studioterms}{terms} y la {privacy} de mod.io y confirmas que tienes {min_age} años de edad o más.`,
    terms_disclaimer_studio: '{terms} de {studio}, los ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' y ',
    code_error: 'No se ha podido solicitar el código.',
    or: 'o',
    login_prompt:
      'La página a la que intenta acceder requiere autenticación. Por favor inicia sesión para continuar.',

    // Terms
    terms: 'Términos de uso',
    privacy: 'Política de privacidad',
    aup: 'Política de uso aceptable',
    monetization: 'Términos de monetización',
    game: 'Términos del juego',
    api: 'Términos de acceso a la API',
    effective: 'Efectivo desde',

    // Report
    report_heading: 'Denunciar este mod',
    report_intro:
      'Puede informar problemas con el contenido, violaciones de los {términos} de mod.io o enviar un {dmca} mediante el siguiente formulario.',
    report_details:
      'Asegúrese de incluir toda la información relevante para facilitar la resolución de su informe. Tenga en cuenta que esta información se compartirá con los moderadores del juego y puede compartirse con el usuario que publicó el contenido supuestamente infractor.',
    report_not_working_prompt:
      'Los informes "no funcionan" se compartirán directamente con el creador del contenido.',
    dmca: 'queja DMCA',
    reason: 'Motivo de la denuncia',
    urls: 'URL ilícita',
    urls_placeholder: 'URL (1 por línea)',
    company_or_name: 'Empresa o nombre',
    email: 'Correo electrónico',
    address: 'Dirección',
    country: 'País',
    country_placeholder: 'Selecciona un país',
    details: 'Detalles de la infracción',
    details_placeholder:
      'Para ayudar a resolver su informe, proporcione tantos detalles y contexto como sea posible.',
    dmca_1:
      '{mod_io} respeta los derechos de propiedad intelectual de todas las personas, por lo que pedimos a quienes hacen uso de nuestros sitios web que también respeten dichos derechos. Cualquier persona que considere que su trabajo se ha replicado en alguno de nuestros sitios web o servicios de manera que constituya un incumplimiento de los derechos de copyright debe notificar al intermediario de derechos copyright de mod.io a través del siguiente formulario.',
    dmca_2:
      'Presentar una reclamación por infracción de los derechos de copyright es un asunto legal grave. Antes de continuar, intente contactar directamente con la persona para remitir directamente su queja. Es posible que se trate de un malentendido y se pueda resolver sin recurrir a un proceso judicial.',
    dmca_3:
      'Tenga en cuenta que, según la sección 512(f) de la Digital Millennium Copyright Act (La Ley de Derechos de Autor de la Era Digital), cualquier persona que informe intencionalmente y sin fundamento acerca de material o actividad ilícita, podrá declararse responsable de los daños. Tenga en cuenta también que la información que proporcione en este aviso legal podrá hacerse pública o compartirse con terceros.',
    claim_check:
      'Al seleccionar las siguientes casillas y enviar esta reclamación declaro BAJO PENA DE PERJURIO que',
    copyright_owner: 'Soy titular de los derechos de Copyright',
    copyright_auth_owner:
      'Tengo autoridad para actuar en nombre del titular de los derechos de copyright en este caso.',
    authorization: 'Autorización',
    authorization_check:
      'Creo de buena fe que el uso del material mencionado en esta reclamación no cuenta con autorización del titular de los derechos de copyright, su representante o la ley.',
    acknowledgement: 'Confirmación',
    acknowledgement_check:
      'Reconozco que, según la sección 512(f) del DMCA, cualquier persona que informe intencionalmente y sin fundamentos de material o actividad ilícito, podrá declararse responsable de los daños.',
    required: 'obligatorio',
    signature: 'Firma',
    signature_placeholder:
      'Escriba su nombre completo en este cuadro como firma digital.',
    report_success_title: 'Hemos recibido su denuncia.',
    report_success_text:
      'Si su mensaje requiere una respuesta, le pedimos que tenga paciencia. Tratamos de responder todas las denuncias en un plazo de 48 horas desde que las recibimos.',
    report_error_title: 'Error al enviar la denuncia',

    // Table
    name: 'Nombre',
    added: 'Añadida',
    updated: 'Actualizada',
    file: 'Archivo',
    version: 'Versión',
    scan: 'Análisis de virus',
    actions: 'Medidas',
    locally_installed: 'Instalado en local',

    // Releases
    download_from_game_client: 'Descargar {mod} desde el cliente del juego',
    release_notes: 'Notas de la versión',
    no_release_notes: 'No se han proporcionado notas de la versión',
    previous_releases: 'Versiones anteriores',
    view_previous_releases: 'Ver lanzamientos anteriores',
    no_previous_releases: 'No hay versiones anteriores',
    no_files_published: 'No se han publicado archivos',
    download_files_manually: 'Descargar archivos manualmente',
    platforms: 'Plataforma | Plataformas',

    // Ratings
    overwhelmingly_positive: 'Extremadamente positivo',
    overwhelmingly_negative: 'Extremadamente negativo',
    very_positive: 'Muy positivo',
    very_negative: 'Muy negativo',
    mostly_positive: 'Generalmente positivo',
    mostly_negative: 'Generalmente negativo',
    negative: 'Negativo',
    positive: 'Positivo',
    mixed: 'Variado',
    unrated: 'Sin clasificar',

    // Sort
    sort_comments: 'Comentarios',
    sort_total_mods: 'Modos totales',
    sort_trending: 'Tendencias',
    sort_highest_rated: 'El mejor valorado',
    sort_most_popular: 'Más popular',
    sort_most_subscribers: 'La mayoría de los suscriptores',
    sort_recently_added: 'Recientemente añadido',
    sort_last_updated: 'Última actualización',
    sort_alphabetical: 'Alfabético',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'No funciona',
    report_rude_content: 'Contenido grosero',
    report_illegal_content: 'Contenido ilegal',
    report_stolen_content: 'Contenido Robado',
    report_false_information: 'Información Falsa',
    report_other: 'Otro',

    // Report not working reasons
    report_crash: 'Se bloquea el juego',
    report_no_load: 'no carga',
    report_conflicts: 'Conflictos con otros {ugc}',
    report_missing_deps: 'Dependencias faltantes',
    report_install_issues: 'Problemas de instalación',
    report_bugs: 'Comportamiento con errores',
    report_incompatability: 'Incompatible con la versión del juego',
    report_corrupt: 'Archivos corruptos',

    // Errors
    error_ref: 'Código de error: ',
    login_fail: 'Error al iniciar sesión',
    login_fail_message:
      'Error al iniciar sesión. Espere un minuto y vuelva a intentarlo.',
    subscribe_fail: 'No se suscribió',
    unsubscribe_fail: 'No se puso de baja',
    error: 'Error',
    default_error_message:
      'Se ha producido un error, inténtelo de nuevo pronto.',
    follow_error: 'No se pudo seguir',
    unfollow_error: 'No se pudo dejar de seguir',

    agreement_not_found: 'Acuerdo no encontrado',
    form_chars_remaining: '{count} caracteres restantes.',
    form_not_enough_chars: '{count} se requieren más caracteres.',
    filter: 'Filtrar',
    loading: 'Cargando',
    next_version: 'Siguiente versión',
    no_results_found: 'No se han encontrado resultados',
    page_not_found: '¡Página no encontrada!',
    previous_version: 'Versión previa',
    take_me_home: 'Llévame a casa',
    validation_alpha_num: 'El campo solo puede contener números o letras',
    validation_size: 'El campo debe ser {size} caracteres',
    validation_url: 'El campo debe ser una URL',
    validation_min: 'Mínimo {min} caracteres',
    validation_max: 'Máximo caracteres {max}',
    validation_email: 'El campo debe ser un correo electrónico',
    validation_checked: 'El campo debe ser marcado',
    validation_required: 'Se requiere campo',
    validation_name:
      'El campo solo puede contener letras y símbolos relacionados con el nombre.',
    validation_image: 'El archivo debe ser una imagen jpeg o png válida',
    validation_youtube: 'El campo debe ser una URL de Youtube válida',
    validation_sketchfab: 'El campo debe ser una URL de Sketchfab válida',
    validation_zip: 'El archivo debe ser un archivo ZIP',
    validation_filesize: 'El tamaño del archivo no debe superar {size}',

    comment: 'comentario | comentarios',
    team_member: 'Miembro del equipo',
    author: 'Autor',
    good_karma: 'Buen karma',
    bad_karma: 'Karma negativo',
    reply: 'Responder',
    replies: 'responder | respuestas',
    save: 'Ahorrar',
    delete: 'Borrar',
    edit: 'Editar',
    post: 'Correo',
    pinned: 'Fijado',
    pin: 'Alfiler',
    unpin: 'Desanclar',
    locked: 'bloqueado',
    lock: 'Bloquear',
    unlock: 'Desbloquear',
    add_comment: 'Añadir un comentario...',
    comment_cant_reply_locked:
      'No se puede responder porque el hilo de comentarios está bloqueado',
    comment_cant_edit_locked:
      'No se puede editar porque el hilo de comentarios está bloqueado',
    comment_max_pin_limit:
      'Se ha alcanzado el límite máximo de comentarios anclados',
    comment_below_threshold:
      'Comenta por debajo del umbral. Haga clic para ver.',
    comment_login_prompt: '¡Inicie sesión para unirse a la discusión!',
    comment_error_add: 'No se pudo agregar comentarios',
    comment_error_edit: 'No se pudo editar comentarios',
    comment_error_delete: 'No se pudo eliminar el comentario',
    comment_error_rate: 'No se pudo calificar el comentario',
    comment_error_update: 'No se pudo actualizar el comentario',
    comments_error_fetch: 'No se pudo cargar más comentarios',
    discussion: 'Discusión',
    load_more_comments: 'Cargar más comentarios',
    comment_confirm_delete:
      '¿Estás seguro de que quieres eliminar este comentario?',
    comment_deleted: 'El comentario fue eliminado con éxito',

    // Mod admin
    mod_profile: 'perfil de {ugc}',
    media: 'Medios de comunicación',
    file_manager: 'Gestor de archivos',
    step: 'PASO {step}',
    save_next: 'Guardar y seguir',
    auth_required: 'Se requiere autenticación',
    tools_only_1:
      'No puedes subir {ugc} para {game} aquí. Debes subirlos con las herramientas que el desarrollador del juego haya creado para poder verificar los archivos. Para obtener más información, consulta las {guides} escritas para este juego. ',
    tools_only_2:
      'Estas son las instrucciones proporcionadas por el desarrollador del juego:',
    tools_only_3:
      'Le recomendamos que vuelva a la lista {ugc} y explore los enlaces desde allí. Si el problema persiste, visita nuestro {help_center} para obtener más información.',
    unsaved_heading: 'Tienes cambios sin guardar',
    unsaved_confirm:
      'Hay cambios que no se han guardado, ¿quieres marcharte de todos modos?',

    headline_mod_profile: 'Crea tu perfil {ugc}',
    headline_media: 'Añade medios a tu perfil de {ugc}',
    headline_files: 'Sube tu archivo {ugc}',
    headline_permissions: 'Establece permisos de reutilización para tu {ugc}',
    headline_dependencies: 'Agregue cualquier dependencia de {ugc}',

    // Basics
    basics: 'Conceptos básicos',
    logo: 'Logotipo',
    logo_prompt_1:
      'Archivo de imagen que representará el logotipo de tu {ugc}.',
    logo_prompt_2: '512x288 o más',
    logo_prompt_3: 'Se recomienda una relación de aspecto de 16:9',
    logo_prompt_4: '8 MB como máximo',
    summary_placeholder:
      'Cuéntanos los cambios que tu {ugc} realiza en el juego en 1 párrafo.',
    homepage: 'Página principal',
    profile_url: 'URL del perfil',
    visibility: 'Visibilidad',
    visibility_tip:
      'Una vez en vivo, los {ugc} públicos son visibles para todos. Los {ugc} ocultos solo son visibles para los miembros del equipo y los suscriptores.',
    upload_images: 'Subir imágenes',
    mature_content: 'Contiene contenido para adultos',
    mature_content_alcohol: 'Alcohol',
    mature_content_drugs: 'Fármacos',
    mature_content_violence: 'Violencia',
    mature_content_explicit: 'Explícito',
    community: 'Comunidad',
    allow_comments: 'Permitir comentarios',
    allow_dependants: 'Permitir dependientes',
    create_mod: 'Crea {ugc}',
    error_mod_create: 'No se pudo crear {ugc}',
    error_mod_update: 'No se pudo actualizar {ugc}',
    error_tags_update: 'No se pudieron actualizar las etiquetas',
    success_create_mod: '{modname} se creó correctamente',
    success_update_mod: '{modname} se actualizó correctamente',
    description_tip:
      'Descripción opcional para tu {ugc}, que puede incluir detalles como «Acerca de», «Características», «Uso», «Preguntas frecuentes», etc. Se admite y recomienda usar HTML',

    // Media
    models: 'modelos',
    media_zip_hint_1:
      'Sube hasta {count} imágenes o un único archivo zip de imágenes para añadirlas a la galería.',
    media_zip_hint_2:
      'Solo se procesarán las imágenes jpg y png válidas del archivo zip.',
    error_media_update: 'No se pudo actualizar el contenido multimedia',
    error_media_add_links: 'No se pudieron agregar enlaces multimedia',
    error_media_remove_links: 'No se pudieron eliminar los enlaces multimedia',
    success_media_updated: 'El contenido multimedia se actualizó correctamente',

    // File manager
    add_file: 'Añadir un archivo',
    edit_file: 'Editar archivo',
    select_zip: 'Seleccione un archivo zip',
    upload: 'Cargar',
    file_id: 'ID del archivo:',
    virus_none: 'No se detectó ningún virus',
    virus_waiting: 'Esperando para escanear',
    virus_scanning: 'Escaneo en curso',
    virus_too_large: 'El archivo es demasiado grande para escanearlo',
    virus_not_found: 'No se encontró el archivo',
    virus_error: 'Error al escanear el archivo',
    virus_found: 'Virus detectado',
    virus_risk:
      'Este archivo contiene un ejecutable y se debe tener cuidado al abrirlo.',
    version_placeholder: 'Número de versión, es decir, 1.0.0',
    release_notes_placeholder: 'Ingresa cada cambio en una línea separada',
    game_moderator: 'Moderador del juego',
    metadata_blob: 'Blob de metadatos',
    metadata_blob_placeholder:
      'Introduce metadatos específicos, como la versión del juego con la que es compatible esta versión, cómo instalarlo, etc. Se admiten datos válidos en UTF-8.',
    select_platforms: 'Plataformas (seleccione todas las que correspondan)',
    file_pending: 'Pendiente de aprobación',
    file_approved: 'Aprobado',
    file_approved_live: 'Aprobado y en vivo',
    file_approved_not_live: 'Aprobado pero no en vivo',
    file_denied: 'Denegado',
    file_targeted: 'Dirigido',
    live_file: 'Este archivo está activo actualmente.',
    file_is_live: 'El archivo está activo',
    make_file_live: 'Publica este lanzamiento',
    make_file_live_platforms:
      'Haga que esta versión esté disponible para las plataformas aprobadas',
    file_processing:
      'Se está procesando tu archivo. Puede continuar navegando por el sitio, pero no cierre la pestaña o la ventana del navegador hasta que haya terminado.',
    file_instructions_1:
      'Comprime y sube la carpeta base de tu {ugc} o, si se trata de una colección de archivos que se encuentran en una carpeta de juego preexistente, debes comprimirlos en ZIP:',
    file_instructions_2: 'Debe ser un archivo ZIP',
    file_instructions_3: 'El tamaño del archivo no puede superar los 20 GB',
    file_instructions_4:
      'Los nombres de archivo no pueden contener ninguno de los siguientes caracteres: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc} que sobrescriben archivos no son compatibles a menos que el juego lo consiga',
    file_instructions_6:
      'No se admiten {ugc}, que abarcan varios directorios del juego, a menos que el juego lo gestione',
    file_terms: 'Acepto las {terms} y la {privacy}.',
    error_get_files: 'No se pudieron obtener los archivos',
    error_upload_file: 'No se pudo cargar el archivo',
    error_update_file: 'No se pudo actualizar el archivo',
    error_file_required: 'El archivo es obligatorio',
    error_platform_required: 'Debes seleccionar al menos una plataforma',
    success_file_uploaded: 'El archivo se ha subido correctamente',
    success_file_moderation:
      'Un moderador revisará las plataformas que requieren moderación',
    success_file_updated: 'El archivo se actualizó correctamente',
    error_no_approved_platforms: 'No hay plataformas para poner en marcha',
    error_no_approved_platforms_text:
      'Este archivo no tiene plataformas aprobadas que no estén activas actualmente',

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Reutilizar permisos',
      allow_redistribution:
        'Permitir que otros suban mis recursos de {ugc} a otros sitios, pero se me debe acreditar como el creador original',
      allow_porting:
        'Permitir que otros conviertan mis recursos de {ugc} para que funcionen en otros juegos, pero se me debe acreditar como creador original',
      allow_patching_no_credit:
        'Permitir que otros modifiquen mis recursos de {ugc} (incluida la publicación de correcciones de errores o mejoras) y publicarlos como un nuevo {ugc}',
      require_credit: 'Debo ser acreditado como el creador original',
      require_permission: 'Exige mi permiso primero',
      require_repackage_no_credit:
        'Permitir que otros usen mis {0} activos en sus propios {1}',
      allow_resell:
        'Permitir que otros usen mis recursos {ugc} en cualquier {ugc} /archivo de cualquier obra comercial',
      original_assets:
        'Mi {ugc} contiene obras o activos 100% originales que se pueden redistribuir',
      error_update: 'No se pudieron actualizar los permisos de reutilización',
      success_update:
        'Los permisos de reutilización se actualizaron correctamente',
      prompt:
        'Establece permisos sobre cómo se pueden usar tus recursos de {ugc} para la creación de contenido de otras personas. Al conceder permiso a otras personas para que usen o modifiquen tus archivos, reconoces que dicha autorización no se puede revocar retroactivamente con respecto a las obras ya creadas.',
    },

    // Dependencies form
    dependencies_prompt:
      'Selecciona {0} necesario para que este {1} funcione correctamente (si no se muestra un {1}, significa que no ha permitido dependencias y debes ponerte en contacto con el creador para activarlo):',
    remove_dependency: 'Eliminar la dependencia',
    dependants: 'Dependientes',
    error_update_dependencies: 'No se pudieron actualizar las dependencias',
    success_update_dependencies:
      'La configuración de dependencias se actualizó correctamente',
    dependants_warning_1:
      'Una vez permitido, ten en cuenta que {ugc} con dependientes conectados:',
    dependants_warning_2: 'No se pueden deshabilitar los dependientes',
    dependants_warning_3: 'No se puede eliminar',
    dependants_warning_4: 'No se puede monetizar',
    dependants_confirm_enable:
      'Esto es para garantizar la compatibilidad continua de los {ugc} conectados. Confirma para habilitarlo.',
    dependants_confirm_disable:
      '¿Estás seguro de que quieres no permitir a los dependientes? Esto significa que otros {0} no podrán agregar tu {1} como dependencia.',
  },
  pl: {
    language: 'Język',

    // Nav
    browse: 'Przeglądaj',
    library: 'Biblioteka',
    account: 'Moje konto',
    back: 'Wróć',
    more: 'więcej',
    add_mod: 'Dodaj {ugc}',
    edit_mod: 'Edytuj na mod.io',
    help_center: 'centrum pomocy',

    // Guides
    guide: 'Przewodnik | Przewodniki',
    views: 'Wyświetlenia',
    table_of_contents: 'Spis treści',
    reading_time: 'Czas czytania',
    date_added: 'Data dodania',
    date_updated: 'Data zaktualizowana',
    minutes_read: 'Minuta czytania',
    published: 'Opublikowany',

    // Users
    joined: 'Dołączył',
    last_online: 'Ostatnio online',
    online: 'online',
    followers: 'Obserwujący',
    partner: 'Partner',
    profile_hidden: 'Profil tego użytkownika jest ukryty',
    follow: 'Podążać',
    unfollow: 'Przestań obserwować',

    // Mods
    mod: 'Mod | Mods',
    level: 'Poziom | Poziomy',
    content: 'Zawartość',
    subscribed: 'Subskrybujesz',
    tags: 'Tagi',
    summary: 'Podsumowanie',
    contributors: 'Współtwórcy',
    description: 'Opis',
    dependencies: 'Zależności',
    of: 'z',
    ago: 'temu',
    and: 'I',
    by: 'Przez',
    reset_filters: 'Zresetuj filtry',
    show_more: 'Pokaż więcej',
    show_less: 'Pokaż mniej',
    no_ugc_found: 'Nie znaleziono {ugc_name}',
    no_subscriptions: 'Nie zasubskrybowałeś żadnego {ugc_name}',
    mod_disclaimer_heading: 'Wyłączenie odpowiedzialności',
    mod_disclaimer_privacy: 'Polityce prywatności',
    thumbs_up_received: 'Kciuki w górę odebrane',
    thumbs_up: 'Kciuki w górę',
    total_downloads: 'Całkowita liczba pobrań',
    releases: 'Wydania',
    bytes: 'bajty',

    // Actions
    login: 'Zaloguj',
    logout: 'Wyloguj',
    search: 'Szukaj',
    clear_all: 'Wyczyść wszystko',
    download: 'Pobierz',
    subscribe: 'Subskrybuj',
    unsubscribe: 'Anuluj subskrypcję',
    resubscribe: 'Subskrybuj ponownie',
    activate: 'Aktywuj',
    deactivate: 'Dezaktywuj',
    like: 'Lubię',
    dislike: 'Nie lubię',
    report: 'Zgłoś problem',
    submit: 'Prześlij',
    cancel: 'Anuluj',
    select: 'Wybierać',
    previous: 'Poprzednie',
    next: 'Kolejny',
    confirm: 'Potwierdź',
    allow: 'Zezwalaj',

    // Filters
    all: 'Wszystko',
    available: 'dostępne(-ych)',
    filesize: 'Rozmiar pliku',
    downloads: 'Liczba pobrań',
    todays_downloads: 'Pobrania dziś',
    subscribers: 'Subskrybenci',
    rating: 'Ocena',
    sort_by: 'Sortuj według',
    creator: 'Twórca',
    apply: 'Stosować',
    reset: 'Resetowanie',
    featured: 'Wyróżniony',
    toggle_filters: 'Przełącz filtry',
    hidden: 'Ukryty',
    public: 'Publiczne',
    pending: 'Aż do',
    deleted: 'Usunięto',
    last_day: 'Ostatni dzień',
    last_week: 'Ostatni tydzień',
    last_month: 'Ostatni miesiąc',
    last_3_months: 'Ostatnie 3 miesiące',
    last_year: 'W ubiegłym roku',

    // Login
    signup: 'Zaloguj się lub zarejestruj za pomocą adresu e-mail',
    login_with: 'Zaloguj się za pomocą {service}',
    email_placeholder: 'Adres e-mail',
    code_placeholder: '5-cyfrowy kod',
    have_code: 'Mam kod.',
    email_code: 'Wyślij kod',
    send_new_code: 'Wyślij nowy kod',
    check_email: 'Sprawdź pocztę i wprowadź otrzymany kod tutaj.',
    login_disclaimer:
      'Treściami gry {game} generowanymi przez użytkowników zarządza mod.io.',
    terms_disclaimer: `Naciskając „Zaloguj się”, wyrażasz zgodę na {studioterms}{terms} i {privacy} mod.io i potwierdzasz, że masz ukończone {min_age} lat.`,
    terms_disclaimer_studio: '{terms} {studio}, ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' i ',
    code_error: 'Nie można poprosić o kod.',
    or: 'Lub',
    login_prompt:
      'Strona, do której próbujesz uzyskać dostęp, wymaga uwierzytelnienia. Zaloguj się, aby kontynuować.',

    // Terms
    terms: 'Warunki korzystania',
    privacy: 'Polityka prywatności',
    aup: 'Zasady dopuszczalnego użytkowania',
    monetization: 'Warunki monetyzacji',
    game: 'Warunki gry',
    api: 'Warunki dostępu do API',
    effective: 'Obowiązuje od',

    // Report
    report_heading: 'Zgłoś tego moda',
    report_intro:
      'Możesz zgłosić problemy z treścią, naruszenia mod.io {terms} lub przesłać {dmca} za pomocą poniższego formularza.',
    report_details:
      'Upewnij się, że zamieściłeś wszystkie istotne informacje, które ułatwią rozpatrzenie Twojego zgłoszenia. Należy pamiętać, że informacje te zostaną udostępnione moderatorom gier i mogą zostać udostępnione użytkownikowi, który opublikował treści rzekomo naruszające prawa.',
    report_not_working_prompt:
      'Raporty „Nie działa” zostaną udostępnione bezpośrednio twórcy treści.',
    reason: 'Powód zgłoszenia',
    urls: 'Obraźliwe adresy URL',
    urls_placeholder: 'Adresy URL (po jednym na wiersz)',
    company_or_name: 'Nazwa firmy lub Twoje imię i nazwisko',
    email: 'Adres e-mail',
    address: 'Adres',
    country: 'Kraj',
    country_placeholder: 'Wybierz kraj',
    details: 'Szczegóły naruszenia',
    details_placeholder:
      'Aby pomóc w rozwiązaniu Twojego zgłoszenia, podaj jak najwięcej szczegółów i kontekstu.',
    dmca_1:
      'Zespół {mod_io} szanuje prawa własności intelektualnej innych osób i prosimy wszystkich korzystających z naszych stron internetowych i usług o to samo. Każdy, kto uważa, że jego praca została powielona na jednej z naszych stron internetowych lub w jednej z usług w sposób stanowiący naruszenie praw autorskich, może powiadomić przedstawiciela ds. praw autorskich mod.io, korzystając z poniższego formularza.',
    dmca_2:
      'Zgłoszenie roszczenia dotyczącego naruszenia praw autorskich to poważna kwestia prawna. Zanim przejdziesz dalej, możesz rozważyć bezpośredni kontakt z daną osobą w celu złożenia skargi. To może być zwykłe nieporozumienie, które można rozwiązać bez angażowania się w faktyczny proces prawny.',
    dmca_3:
      'Pamiętaj, że zgodnie z sekcją 512(f) amerykańskiej ustawy Digital Millennium Copyright Act każda osoba, która świadomie wprowadza innych w błąd, twierdząc, że materiał lub działanie narusza prawa autorskie, może zostać pociągnięta do odpowiedzialności za wynikłe szkody. Pamiętaj też, że informacje zawarte w niniejszej nocie prawnej mogą być udostępniane stronom trzecim lub upubliczniane.',
    claim_check:
      'Zaznaczając poniższe pola i przesyłając niniejsze roszczenie, oświadczam POD KARĄ ZA SKŁADANIE FAŁSZYWYCH ZEZNIAŃ, co następuje:',
    copyright_owner: 'Jestem właścicielem(-ką) praw autorskich.',
    copyright_auth_owner:
      'Mam upoważnienie do działania w imieniu właściciela(-ki) praw autorskich w takiej sytuacji.',
    authorization: 'Upoważnienie',
    authorization_check:
      'W dobrej wierze wyrażam przekonanie, że wykorzystanie materiału wymienionego w tym roszczeniu nie zostało autoryzowane przez właściciela(-kę) praw autorskich, jego/jej przedstawiciela(-kę) lub nie jest zgodne z prawem.',
    acknowledgement: 'Potwierdzenie',
    acknowledgement_check:
      'Przyjmuję do wiadomości, że zgodnie z sekcją 512(f) amerykańskiej ustawy Digital Millennium Copyright Act każda osoba, która świadomie wprowadza innych w błąd, twierdząc, że materiał lub działanie narusza prawa autorskie, może zostać pociągnięta do odpowiedzialności za wynikłe szkody.',
    required: 'wymagane',
    signature: 'Podpis',
    signature_placeholder:
      'Wpisz imię i nazwisko w tym polu – będzie równoznaczne z Twoim podpisem cyfrowym.',
    report_success_title: 'Otrzymaliśmy Twoje zgłoszenie',
    report_success_text:
      'Jeśli Twoja wiadomość wymaga odpowiedzi, prosimy o cierpliwość, ponieważ staramy się odpowiadać na wszystkie zgłoszenia w ciągu 48 godzin od ich otrzymania.',
    report_error_title: 'Nie udało się przesłać zgłoszenia',

    // Table
    name: 'Nazwa',
    added: 'Dodano',
    updated: 'Zaktualizowano',
    file: 'Plik',
    version: 'Wersja',
    scan: 'Skanowanie wirusowe',
    actions: 'Działania',
    locally_installed: 'Zainstalowane lokalnie',

    // Releases
    download_from_game_client: 'Pobierz moda {mod} z klienta gry',
    release_notes: 'Informacje o wydaniu',
    no_release_notes: 'Brak informacji o wydaniu',
    previous_releases: 'Poprzednie wydania',
    view_previous_releases: 'Zobacz poprzednie wydania',
    no_previous_releases: 'Nie ma poprzednich wydań.',
    no_files_published: 'Brak opublikowanych plików',
    download_files_manually: 'Pobierz pliki ręcznie',
    platforms: 'Platforma | Platformy',

    // Ratings
    overwhelmingly_positive: 'Przytłaczająco pozytywne',
    overwhelmingly_negative: 'Przytłaczająco negatywne',
    very_positive: 'Bardzo pozytywne',
    very_negative: 'Bardzo negatywne',
    mostly_positive: 'Głównie pozytywne',
    mostly_negative: 'Głównie negatywne',
    negative: 'Negatywne',
    positive: 'Pozytywne',
    mixed: 'Mieszane',
    unrated: 'Brak ocen',

    // Sort
    sort_comments: 'Uwagi',
    sort_total_mods: 'Wszystkie mody',
    sort_trending: 'Popularne',
    sort_highest_rated: 'Najwyżej oceniany',
    sort_most_popular: 'Najbardziej popularny',
    sort_most_subscribers: 'Większość abonentów',
    sort_recently_added: 'Niedawno dodane',
    sort_last_updated: 'Ostatnio zaktualizowany',
    sort_alphabetical: 'Alfabetyczny',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Nie działa',
    report_rude_content: 'Niegrzeczna treść',
    report_illegal_content: 'Nielegalna treść',
    report_stolen_content: 'Skradziona zawartość',
    report_false_information: 'Fałszywe informacje',
    report_other: 'Inne',

    // Report not working reasons
    report_crash: 'Zawiesza grę',
    report_no_load: 'Nie ładuje się',
    report_conflicts: 'Konflikty z innymi {ugc}',
    report_missing_deps: 'Brakujące zależności',
    report_install_issues: 'Problemy z instalacją',
    report_bugs: 'Bugiczne zachowanie',
    report_incompatability: 'Niekompatybilny z wersją gry',
    report_corrupt: 'Uszkodzone pliki',

    // Errors
    error_ref: 'Kod błędu: ',
    login_fail: 'Nie udało się zalogować',
    login_fail_message:
      'Nie udało się zalogować. Poczekaj chwilę i spróbuj ponownie.',
    subscribe_fail: 'Nie udało się zasubskrybować',
    unsubscribe_fail: 'Nie udało się zrezygnować z subskrypcji',
    error: 'Błąd',
    default_error_message: 'Wystąpił błąd. Spróbuj ponownie wkrótce.',
    follow_error: 'Nie udało się śledzić',
    unfollow_error: 'Nie udało się przestać obserwować',

    agreement_not_found: 'Umowa nie została znaleziona',
    form_chars_remaining: '{count} pozostałe znaki.',
    form_not_enough_chars: '{count} Wymagane więcej znaków.',
    filter: 'Filtr',
    loading: 'Ładowanie',
    next_version: 'Następna wersja',
    no_results_found: 'Nie znaleziono wyników',
    page_not_found: 'Strona nie znaleziona!',
    previous_version: 'Poprzednia wersja',
    take_me_home: 'Zabierz mnie do domu',
    validation_alpha_num: 'Pole może zawierać tylko liczby lub litery',
    validation_size: 'Pole musi być znakami {size}',
    validation_url: 'Pole musi być adresem URL',
    validation_min: 'Minimum {min} znaków',
    validation_max: 'Maksymalne znaki {max}',
    validation_email: 'Pole musi być e -mailem',
    validation_checked: 'Pole musi być sprawdzone',
    validation_required: 'To pole jest wymagane',
    validation_name:
      'Pole może zawierać wyłącznie litery i symbole związane z nazwą',
    validation_image: 'Plik musi być prawidłowym obrazem jpeg lub png',
    validation_youtube: 'Pole musi być prawidłowym adresem URL Youtube',
    validation_sketchfab: 'Pole musi być prawidłowym adresem URL Sketchfab',
    validation_zip: 'Plik musi być ZIP',
    validation_filesize: 'Rozmiar pliku nie może przekraczać {size}',

    comment: 'komentarz | komentarze',
    team_member: 'Członek zespołu',
    author: 'Autor',
    good_karma: 'Dobra Karma',
    bad_karma: 'Zła karma',
    reply: 'Odpowiedź',
    replies: 'odpowiedź | odpowiedzi',
    save: 'Ratować',
    delete: 'Usuwać',
    edit: 'Edytować',
    post: 'Post',
    pinned: 'Przypięty',
    pin: 'Przypinka',
    unpin: 'Rozpiąć',
    locked: 'Zablokowany',
    lock: 'Zamek',
    unlock: 'Odblokuj',
    add_comment: 'Dodaj komentarz...',
    comment_cant_reply_locked:
      'Nie można odpowiedzieć, ponieważ wątek komentarza jest zablokowany',
    comment_cant_edit_locked:
      'Nie można edytować, ponieważ wątek komentarza jest zablokowany',
    comment_max_pin_limit: 'Osiągnięto maksymalny limit komentarzy',
    comment_below_threshold:
      'Komentarz poniżej progu. Kliknij, aby wyświetlić.',
    comment_login_prompt: 'Zaloguj się, aby dołączyć do dyskusji!',
    comment_error_add: 'Nie udało się dodać komentarza',
    comment_error_edit: 'Nie udało się edytować komentarza',
    comment_error_delete: 'Nie udało się usunąć komentarza',
    comment_error_rate: 'Nie udało się ocenić komentarza',
    comment_error_update: 'Nie udało się zaktualizować komentarza',
    comments_error_fetch: 'Nie udało się załadować więcej komentarzy',
    discussion: 'Dyskusja',
    load_more_comments: 'Załaduj więcej komentarzy',
    comment_confirm_delete: 'Czy na pewno chcesz usunąć ten komentarz?',
    comment_deleted: 'Komentarz został pomyślnie usunięty',

    // Mod admin
    mod_profile: 'profil {ugc}',
    media: 'Głoska bezdźwięczna',
    file_manager: 'Menedżer plików',
    step: 'KROK {step}',
    save_next: 'Zapisz i Dalej',
    auth_required: 'Uwierzytelnianie wymagane',
    tools_only_1:
      'Nie możesz przesłać tutaj {ugc} dla {game}, muszą one zostać przesłane za pomocą narzędzi stworzonych przez twórcę gry, aby pliki mogły zostać zweryfikowane. Aby uzyskać więcej informacji, zobacz {guides} napisane dla tej gry. ',
    tools_only_2: 'Oto instrukcje dostarczone przez twórcę gry:',
    tools_only_3:
      'Zalecamy powrót do listy {ugc} i przeglądanie linków stamtąd. Jeśli problem będzie się powtarzał, przejdź do naszego {help_center}, aby uzyskać więcej informacji.',
    unsaved_heading: 'Masz niezapisane zmiany',
    unsaved_confirm:
      'Są zmiany, które nie zostały zapisane, czy nadal chcesz odejść?',

    headline_mod_profile: 'Utwórz swój profil {ugc}',
    headline_media: 'Dodaj multimedia do swojego profilu {ugc}',
    headline_files: 'Prześlij swój plik {ugc}',
    headline_permissions:
      'Ustaw uprawnienia do ponownego wykorzystania dla swojego {ugc}',
    headline_dependencies: 'Dodaj wszelkie zależności {ugc}',

    // Basics
    basics: 'Podstawy',
    logo: 'Logo',
    logo_prompt_1:
      'Plik obrazu, który będzie reprezentował logo Twojego {ugc}.',
    logo_prompt_2: '512x288 lub więcej',
    logo_prompt_3: 'Zalecany współczynnik proporcji 16:9',
    logo_prompt_4: 'Maksymalnie 8 MB',
    summary_placeholder:
      'Opowiedz nam o zmianach wprowadzonych przez użytkownika {ugc} w grze w 1 akapicie.',
    homepage: 'Strona domowa',
    profile_url: 'Adres URL profilu',
    visibility: 'Widoczność',
    visibility_tip:
      'Raz na żywo, publiczne {ugc} są widoczne dla wszystkich. Ukryte {ugc} są widoczne tylko dla członków zespołu i subskrybentów.',
    upload_images: 'Przesyłanie obrazów',
    mature_content: 'Zawiera dojrzałe treści',
    mature_content_alcohol: 'Alkohol',
    mature_content_drugs: 'Narkotyki',
    mature_content_violence: 'Przemoc',
    mature_content_explicit: 'Wyraźny',
    community: 'Społeczność',
    allow_comments: 'Zezwalaj na komentarze',
    allow_dependants: 'Zezwalaj osobom na utrzymaniu',
    create_mod: 'Utwórz {ugc}',
    error_mod_create: 'Nie udało się utworzyć {ugc}',
    error_mod_update: 'Nie udało się zaktualizować {ugc}',
    error_tags_update: 'Nie udało się zaktualizować tagów',
    success_create_mod: 'Pomyślnie utworzono {modname}',
    success_update_mod: 'Pomyślnie zaktualizowano {modname}',
    description_tip:
      'Opcjonalny opis użytkownika {ugc}, który może zawierać szczegóły, takie jak „Informacje”, „Funkcje”, „Użycie”, „FAQ” itp. Obsługiwane i zalecane HTML',

    // Media
    models: 'modele',
    media_zip_hint_1:
      'Prześlij do {count} obrazów lub pojedyncze archiwum zip obrazów, aby dodać do galerii.',
    media_zip_hint_2:
      'Przetwarzane będą tylko prawidłowe obrazy jpg i png w pliku zip.',
    error_media_update: 'Aktualizacja nośnika nie powiodła się',
    error_media_add_links: 'Dodawanie linków multimedialnych nie powiodło się',
    error_media_remove_links: 'Nie udało się usunąć linków multimedialnych',
    success_media_updated: 'Pomyślnie zaktualizowano media',

    // File manager
    add_file: 'Dodawanie pliku',
    edit_file: 'Edytuj plik',
    select_zip: 'Wybierz plik zip',
    upload: 'Przesyłanie',
    file_id: 'Identyfikator pliku:',
    virus_none: 'Nie wykryto wirusa',
    virus_waiting: 'Czekam na skanowanie',
    virus_scanning: 'Skanowanie w toku',
    virus_too_large: 'Plik zbyt duży do skanowania',
    virus_not_found: 'Plik nie został znaleziony',
    virus_error: 'Błąd skanowania pliku',
    virus_found: 'Wykryto wirus',
    virus_risk:
      'Ten plik zawiera plik wykonywalny i należy zachować ostrożność przy otwarciu',
    version_placeholder: 'Numer wersji tj. 1.0.0',
    release_notes_placeholder: 'Wprowadź każdą zmianę w osobnym wierszu',
    game_moderator: 'Moderator gry',
    metadata_blob: 'Blob metadanych',
    metadata_blob_placeholder:
      'Wprowadź określone metadane, takie jak wersja gry, z którą jest kompatybilna ta wersja, jak ją zainstalować itp. Obsługiwane są prawidłowe dane UTF-8.',
    select_platforms: 'Platformy (wybierz wszystkie, które mają zastosowanie)',
    file_pending: 'Oczekiwanie na zatwierdzenie',
    file_approved: 'Zatwierdzony',
    file_approved_live: 'Zatwierdzony i aktywny',
    file_approved_not_live: 'Zatwierdzone, ale nie na żywo',
    file_denied: 'Odmowa',
    file_targeted: 'Ukierunkowany',
    live_file: 'Ten plik jest aktualnie dostępny.',
    file_is_live: 'Plik jest aktywny',
    make_file_live: 'Uruchom to wydanie',
    make_file_live_platforms:
      'Uruchom to wydanie na zatwierdzonych platformach',
    file_processing:
      'Twój plik jest przetwarzany. Możesz kontynuować przeglądanie witryny, ale nie zamykaj karty lub okna przeglądarki, dopóki nie zostanie zakończona.',
    file_instructions_1:
      'ZIP i prześlij folder bazowy swojego {ugc}, lub jeśli jest to zbiór plików, które znajdują się w wcześniej istniejącym folderze gry, należy je ZIP:',
    file_instructions_2: 'Musi być plikiem ZIP',
    file_instructions_3: 'Rozmiar pliku nie może przekraczać 20 GB',
    file_instructions_4:
      'Nazwy plików nie mogą zawierać żadnego z następujących znaków: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc}, które nadpisują pliki nie są obsługiwane, chyba że gra to zarządza',
    file_instructions_6:
      '{ugc}, które obejmują wiele katalogów gier, nie są obsługiwane, chyba że gra to zarządza',
    file_terms: 'Zgadzam się na {terms} i {privacy}.',
    error_get_files: 'Nie udało się pobrać plików',
    error_upload_file: 'Nie udało się przesłać pliku',
    error_update_file: 'Nie udało się zaktualizować pliku',
    error_file_required: 'Plik jest wymagany',
    error_platform_required: 'Musisz wybrać co najmniej jedną platformę',
    success_file_uploaded: 'Plik przesłany pomyślnie',
    success_file_moderation:
      'Moderator dokona przeglądu platform wymagających moderacji',
    success_file_updated: 'Plik został pomyślnie zaktualizowany',
    error_no_approved_platforms: 'Brak platform do uruchomienia',
    error_no_approved_platforms_text:
      'Ten plik nie ma zatwierdzonych platform, które nie są obecnie dostępne',

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Ponowne wykorzystanie uprawnień',
      allow_redistribution:
        'Pozwól innym na przesyłanie moich zasobów {ugc} na inne strony, ale muszę zostać uznany za oryginalnego twórcę',
      allow_porting:
        'Pozwól innym na konwersję moich zasobów {ugc} do pracy w innych grach, ale muszę zostać uznany za oryginalnego twórcę',
      allow_patching_no_credit:
        'Zezwalaj innym na modyfikację moich zasobów {ugc} (w tym uwalnianie poprawek błędów lub ulepszeń) i udostępnij je jako nowy {ugc}',
      require_credit: 'Muszę zostać uznany za pierwotnego twórcę',
      require_permission: 'Najpierw zażądaj ode mnie zgody',
      require_repackage_no_credit:
        'Pozwól innym korzystać z moich {0} zasobów we własnym {1}',
      allow_resell:
        'Pozwól innym na używanie moich zasobów {ugc} w dowolnych plikach {ugc} /w dowolnych dziełach komercyjnych',
      original_assets:
        'Mój {ugc} zawiera w 100% oryginalne dzieło lub zasoby, które można redystrybuować',
      error_update: 'Nie udało się zaktualizować uprawnień ponownego użycia',
      success_update: 'Pomyślnie zaktualizowane uprawnienia ponownego',
      prompt:
        'Ustaw uprawnienia do wykorzystywania zasobów {ugc} do tworzenia treści innych osób. Udzielając zezwolenia innym osobom na używanie lub modyfikowanie Twoich plików, użytkownik przyjmuje do wiadomości, że takie zezwolenie nie może zostać cofnięte z mocą wsteczną w odniesieniu do utworów już utworzonych.',
    },

    // Dependencies form
    dependencies_prompt:
      'Wybierz {0} wymagane, aby ten {1} działał poprawnie (jeśli {1} nie jest wyświetlany, oznacza to, że nie zezwala na zależności i powinieneś skontaktować się z twórcą, aby go włączyć):',
    remove_dependency: 'Usuń zależność',
    dependants: 'Osoby na utrzymaniu',
    error_update_dependencies: 'Aktualizacja zależności nie powiodła się',
    success_update_dependencies:
      'Pomyślnie zaktualizowano ustawienia zależności',
    dependants_warning_1:
      'Po zezwoleniu należy pamiętać, że {ugc} z podłączonymi osobami na utrzymaniu:',
    dependants_warning_2: 'Nie można wyłączyć osób zależnych',
    dependants_warning_3: 'Nie można usunąć',
    dependants_warning_4: 'Nie można zarabiać',
    dependants_confirm_enable:
      'Ma to na celu zapewnienie stałej kompatybilności połączonego {ugc}. Potwierdź, aby włączyć.',
    dependants_confirm_disable:
      'Jesteś pewien, że chcesz zakazać osobom pozostającym na utrzymaniu? Oznacza to, że inne {0} nie będą mogły dodać twojego {1} jako zależności.',
  },
  ja: {
    language: '言語',

    // Nav
    browse: '閲覧',
    library: '図書館',
    account: '私のアカウント',
    back: '戻る',
    more: 'もっと',
    add_mod: '{ugc} を追加',
    edit_mod: 'mod.io で編集',
    help_center: 'ヘルプセンター',

    // Guides
    guide: 'ガイド |ガイド',
    views: 'ビュー',
    table_of_contents: '目次',
    reading_time: '読書の時間',
    date_added: '追加日',
    date_updated: '更新日',
    minutes_read: '分読み',
    published: '発行済み',

    // Users
    joined: '参加しました',
    last_online: 'ラストオンライン',
    online: 'オンライン',
    followers: 'フォロワー',
    partner: '相棒',
    profile_hidden: 'このユーザーのプロフィールは非表示です',
    follow: 'フォローする',
    unfollow: 'フォローを解除する',

    // Mods
    mod: 'Mod | Mods',
    level: 'レベル |レベル',
    content: 'コンテンツ',
    subscribed: '購読中',
    tags: 'タグ',
    summary: '概要',
    contributors: '貢献者',
    description: '説明',
    dependencies: '依存関係',
    of: '/',
    ago: '前',
    and: 'そして',
    by: 'による',
    reset_filters: 'フィルターをリセット',
    show_more: 'もっと見せる',
    show_less: '表示を減らす',
    no_ugc_found: '{ugc_name} は見つかりませんでした',
    no_subscriptions: 'どの {ugc_name} も購読していません',
    mod_disclaimer_heading: '法的免責事項',
    mod_disclaimer_privacy: 'プライバシーポリシー',
    thumbs_up_received: '親指を受け取りました',
    thumbs_up: 'いいぞ',
    total_downloads: '総ダウンロード数',
    releases: 'リリース',
    bytes: 'バイト',

    // Actions
    login: 'ログイン',
    logout: 'ログアウト',
    search: '検索',
    clear_all: 'すべてクリア',
    download: 'ダウンロード',
    subscribe: '購読',
    unsubscribe: '購読解除',
    resubscribe: '再講読',
    activate: '有効化',
    deactivate: '無効化',
    like: 'いいね',
    dislike: '気に入らない',
    report: '問題を報告します',
    submit: '送信',
    cancel: 'キャンセル',
    select: '選択',
    previous: '前へ',
    next: '[次へ]',
    confirm: '確認',
    allow: '許可',

    // Filters
    all: 'すべて',
    available: '利用可能',
    filesize: 'ファイルサイズ',
    downloads: 'ダウンロード数',
    todays_downloads: '今日のダウンロード数',
    subscribers: '購読ユーザー',
    rating: '評価',
    sort_by: '並べ替え',
    creator: 'クリエイター',
    apply: '適用する',
    reset: 'リセット',
    featured: '特徴',
    toggle_filters: 'フィルターを切り替えます',
    hidden: '隠れた',
    public: 'パブリック',
    pending: '保留中',
    deleted: '削除されました',
    last_day: '最終日',
    last_week: '先週',
    last_month: '先月',
    last_3_months: '過去 3 か月',
    last_year: '去年',

    // Login
    signup: 'ログインまたはメールアドレスで登録',
    login_with: '{service}でログインします',
    email_placeholder: 'メールアドレス',
    code_placeholder: '5 桁のコード',
    have_code: 'コードを持っています',
    email_code: 'コードをメール送信',
    send_new_code: '新しいコードを送信',
    check_email: 'メールに記載されたコードを確認し、ここに入力してください。',
    login_disclaimer:
      '{game} のユーザー生成コンテンツは mod.io によって管理されています。',
    terms_disclaimer: `「ログイン」を押すと{studioterms}、mod.io の {terms} と {privacy} に同意し、{min_age} 歳以上であることを確認したことになります。`,
    terms_disclaimer_studio: '、{studio} の {terms}',
    terms_disclaimer_studio_separator: '、',
    terms_disclaimer_studio_and: ' と ',
    code_error: 'コードをリクエストできません。',
    or: 'または',
    login_prompt:
      'アクセスしようとしているページには認証が必要です。続行するにはログインしてください。',

    // Terms
    terms: '利用規約',
    privacy: 'プライバシーポリシー',
    aup: '利用規約',
    monetization: '収益化条件',
    game: 'ゲーム規約',
    api: 'APIアクセス規約',
    effective: 'から有効',

    // Report
    report_heading: 'この mod を報告する',
    report_intro:
      '以下のフォームを使用して、コンテンツに関する問題、mod.io {terms} の違反を報告したり、{dmca} を送信したりできます。',
    report_details:
      'レポートの解決を容易にするために、すべての関連情報を必ず含めてください。この情報はゲームのモデレーターと共有され、また、侵害の疑いのあるコンテンツを投稿したユーザーと共有される可能性があることに注意してください。',
    report_not_working_prompt:
      '「動作しない」レポートはコンテンツ作成者と直接共有されます。',
    dmca: 'DMCA に関わる苦情',
    reason: '報告理由',
    urls: '違反のある URL',
    urls_placeholder: 'URL（1 行ずつ）',
    company_or_name: '会社またはあなたの名前',
    email: 'メール',
    address: 'アドレス',
    country: '国',
    country_placeholder: '国を選択してください',
    details: '侵害・違反の詳細',
    details_placeholder:
      'レポートの解決に役立てるため、できるだけ詳細とコンテキストを提供してください。',
    dmca_1:
      '{mod_io} は他者の知的財産権を尊重しており、当社のネットサイトとサービスをご利用のすべての方にも同様のことを求めています。当社のいずれかのネットサイトまたはサービスにおいて、著作権侵害にあたる何らかの方法で自分の作品が複製されていると思われる場合は、以下のフォームを使用して mod.io の著作権代理人に通知することができます。',
    dmca_2:
      '著作権侵害の申し立てを提出することは、重大な法的事項です。続行する前に、直接個人に連絡し、申し立ての内容に対処することを検討してください。適切な法的手続きをとらずとも対処できる単純な誤解である可能性もあります。',
    dmca_3:
      'デジタルミレニアム著作権法第 512 条(f) に基づき、作品または活動が侵害しているとして、故意に重大な虚偽を訴える者は、損害賠償責任を負う可能可能性があることに注意してください。また、この法的通知で提供される情報は第三者と共有されたり、公開されたりする可能性があることにも注意してください。',
    claim_check:
      '以下のチェックボックスをオンにしてこの申し立てを提出することにより、私は、偽証罪の罰則付きにより、以下のことを表明します。',
    copyright_owner: '私は著作権所有者です。',
    copyright_auth_owner:
      '私は、この状況において、著作権所有者に代わって行動する権限を有しています。',
    authorization: '許可',
    authorization_check:
      '私は、この申し立てに記載されている作品の使用が著作権所有者、その代理人、または法によって許可されていないと確信しています。',
    acknowledgement: '同意',
    acknowledgement_check:
      '私は、DMCA の第 512 条(f) に基づき、作品または活動が侵害しているとして故意に重大な虚偽を訴えた者は、損害賠償責任を負う可能性があることを認めます。',
    required: '必須',
    signature: '署名',
    signature_placeholder:
      'このボックスにデジタル署名として機能する氏名を入力してください。',
    report_success_title: 'あなたの報告を受領いたしました。',
    report_success_text:
      'あなたのメッセージに返信が必要な場合は、報告を受けてから 48 時間以内にすべての報告者に返信するように努めておりますので、しばらくお待ちください。',
    report_error_title: '報告を提出できませんでした',

    // Table
    name: '名前',
    added: '追加',
    updated: '更新',
    file: 'ファイル',
    version: 'バージョン',
    scan: 'ウイルススキャン',
    actions: 'アクション',
    locally_installed: 'ローカルにインストール済み',

    // Releases
    download_from_game_client: 'ゲームクライアントから {mod} をダウンロード',
    release_notes: 'リリースノート',
    no_release_notes: 'リリースノートはありません',
    previous_releases: '以前のリリース',
    view_previous_releases: '以前のリリースを表示する',
    no_previous_releases: '以前のリリースはありません',
    no_files_published: 'ファイルは公開されていません',
    download_files_manually: 'ファイルを手動でダウンロードする',
    platforms: 'プラットフォーム | プラットフォーム',

    // Ratings
    overwhelmingly_positive: '圧倒的にポジティブ',
    overwhelmingly_negative: '圧倒的にネガティブ',
    very_positive: '非常にポジティブ',
    very_negative: '非常にネガティブ',
    mostly_positive: 'ほとんどがポジティブ',
    mostly_negative: 'ほとんどがネガティブ',
    negative: 'ネガティブ',
    positive: 'ポジティブ',
    mixed: 'どちらでもない',
    unrated: '未評価',

    // Sort
    sort_comments: 'コメント',
    sort_total_mods: '総改造数',
    sort_trending: 'トレンド',
    sort_highest_rated: '最高評価',
    sort_most_popular: '最も人気のある',
    sort_most_subscribers: 'ほとんどの購読者',
    sort_recently_added: '最近追加された',
    sort_last_updated: '最終更新',
    sort_alphabetical: 'アルファベット順',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '動作していません',
    report_rude_content: '失礼なコンテンツ',
    report_illegal_content: '違法なコンテンツ',
    report_stolen_content: '盗まれたコンテンツ',
    report_false_information: '虚偽の情報',
    report_other: 'その他',

    // Report not working reasons
    report_crash: 'クラッシュゲーム',
    report_no_load: '読み込まない',
    report_conflicts: '他の {ugc} との競合',
    report_missing_deps: '依存関係が欠落している',
    report_install_issues: 'インストールの問題',
    report_bugs: 'バグっぽい動作',
    report_incompatability: 'ゲームのバージョンと互換性がありません',
    report_corrupt: '破損したファイル',

    // Errors
    error_ref: 'エラーコード: ',
    login_fail: 'ログインに失敗しました',
    login_fail_message:
      'ログインに失敗しました。ちょっと待って、もう一度やり直してください。',
    subscribe_fail: '購読に失敗しました',
    unsubscribe_fail: '登録解除に失敗しました',
    error: 'エラー',
    default_error_message:
      'エラーが発生しました。すぐにもう一度お試しください。',
    follow_error: 'フォローに失敗しました',
    unfollow_error: 'フォローを解除できませんでした',

    agreement_not_found: '合意が見つかりません',
    form_chars_remaining: '{count}文字が残っています。',
    form_not_enough_chars: '{count}より多くの文字が必要です。',
    filter: 'フィルター',
    loading: '読み込み',
    next_version: '次のバージョン',
    no_results_found: '結果が見つかりません',
    page_not_found: 'ページが見つかりません！',
    previous_version: '前のバージョン',
    take_me_home: '家まで連れて帰ってください',
    validation_alpha_num:
      'フィールドには数字または文字のみを含めることができます',
    validation_size: 'フィールドは{size}文字でなければなりません',
    validation_url: 'フィールドはURLでなければなりません',
    validation_min: '最小 {min} 文字数',
    validation_max: '最大{max}文字 {max}',
    validation_email: 'フィールドは電子メールでなければなりません',
    validation_checked: 'フィールドを確認する必要があります',
    validation_required: 'フィールドは必須項目です',
    validation_name:
      'フィールドには文字と名前関連の記号のみを含めることができます',
    validation_image:
      'ファイルは有効な JPEG または PNG 画像でなければなりません',
    validation_youtube: 'フィールドは有効な YouTube URL でなければなりません',
    validation_sketchfab:
      'フィールドは有効な Sketchfab URL でなければなりません',
    validation_zip: 'ファイルは ZIP である必要があります',
    validation_filesize: 'ファイルサイズは {size} を超えてはいけません',

    comment: 'コメント',
    team_member: 'チームメンバー',
    author: '著者',
    good_karma: '良いカルマ',
    bad_karma: '悪いカルマ',
    reply: '返事',
    replies: '返信 |返信する',
    save: '保存',
    delete: '消去',
    edit: '編集',
    post: '役職',
    pinned: 'ピン留め',
    pin: 'ピン',
    unpin: 'ピン留め解除',
    locked: 'ロックされています',
    lock: '[ロック]',
    unlock: 'ロック解除',
    add_comment: 'コメントを追加...',
    comment_cant_reply_locked:
      'コメントスレッドがロックされているため返信できません',
    comment_cant_edit_locked:
      'コメントスレッドがロックされているため編集できません',
    comment_max_pin_limit: 'ピン留めされたコメントの最大制限に達しました',
    comment_below_threshold:
      'コメントがしきい値を下回っています。クリックすると表示されます。',
    comment_login_prompt: 'ログインしてディスカッションに参加してください！',
    comment_error_add: 'コメントを追加できませんでした',
    comment_error_edit: 'コメントの編集に失敗しました',
    comment_error_delete: 'コメントを削除できませんでした',
    comment_error_rate: 'コメントの評価に失敗しました',
    comment_error_update: 'コメントを更新できませんでした',
    comments_error_fetch: 'より多くのコメントをロードできませんでした',
    discussion: '議論',
    load_more_comments: 'より多くのコメントを読み込みます',
    comment_confirm_delete: 'このコメントを削除したいですか？',
    comment_deleted: 'コメントは正常に削除されました',

    // Mod admin
    mod_profile: '{ugc} プロフィール',
    media: 'メディア',
    file_manager: 'ファイルマネージャー',
    step: 'ステップ{step}',
    save_next: '保存して次へ',
    auth_required: '認証が必要です',
    tools_only_1:
      '{game} の {ugc} をここにアップロードすることはできません。ファイルを検証するには、ゲーム開発者が作成したツールを使用してアップロードする必要があります。詳細については、このゲーム用に書かれた {guides} を参照してください。',
    tools_only_2: 'ゲーム開発者が提供する手順は次のとおりです。',
    tools_only_3:
      '{ugc} リストに戻り、そこからリンクを参照することをお勧めします。問題が解決しない場合は、{help_center} にアクセスして詳細を確認してください。',
    unsaved_heading: '変更が保存されていません',
    unsaved_confirm:
      '保存されていない変更がありますが、そのまま保存してもよろしいですか?',

    headline_mod_profile: '{ugc} プロフィールを作成する',
    headline_media: '{ugc} プロフィールにメディアを追加する',
    headline_files: '{ugc} ファイルをアップロードします',
    headline_permissions: '{ugc} の再利用権限を設定する',
    headline_dependencies: '{ugc} 依存関係を追加します',

    // Basics
    basics: '基本',
    logo: '[ロゴ]',
    logo_prompt_1: '{ugc} のロゴを表す画像ファイル。',
    logo_prompt_2: '512 x 288 またはそれ以上',
    logo_prompt_3: '16:9 のアスペクト比を推奨',
    logo_prompt_4: '最大 8 メガバイト',
    summary_placeholder:
      '{ugc} がゲームに加えた変更について、1段落で教えてください。',
    homepage: 'ホームページ',
    profile_url: 'プロフィール URL',
    visibility: '可視性',
    visibility_tip:
      '公開後、公開 {ugc} はすべてのユーザーに表示されます。非表示の {ugc} はチームメンバーと購読者のみに表示されます。',
    upload_images: '画像をアップロード',
    mature_content: '成人向けコンテンツを含む',
    mature_content_alcohol: 'アルコール',
    mature_content_drugs: '医薬品',
    mature_content_violence: '暴力',
    mature_content_explicit: 'エクスプリシット',
    community: 'コミュニティ',
    allow_comments: 'コメントを許可',
    allow_dependants: '扶養家族を許可',
    create_mod: '{ugc} を作成',
    error_mod_create: '{ugc} を作成できませんでした',
    error_mod_update: '{ugc} を更新できませんでした',
    error_tags_update: 'タグを更新できませんでした',
    success_create_mod: '{modname} が正常に作成されました',
    success_update_mod: '{modname} は正常に更新されました',
    description_tip:
      '{ugc} の説明(オプション)には、「概要」、「機能」、「使用方法」、「よくある質問」などの詳細を含めることができます。HTMLがサポートおよび推奨されています',

    // Media
    models: 'モデル',
    media_zip_hint_1:
      '最大 {count} 枚の画像、または画像の zip アーカイブを 1 つアップロードしてギャラリーに追加します。',
    media_zip_hint_2:
      'zip ファイル内の有効な jpg および png 画像のみが処理されます。',
    error_media_update: 'メディアを更新できませんでした',
    error_media_add_links: 'メディアリンクを追加できませんでした',
    error_media_remove_links: 'メディアリンクを削除できませんでした',
    success_media_updated: 'メディアが正常に更新されました',

    // File manager
    add_file: 'ファイルを追加',
    edit_file: '[ファイルを編集]',
    select_zip: 'ZIP ファイルを選択',
    upload: 'アップロード',
    file_id: 'ファイル ID:',
    virus_none: 'ウイルスは検出されませんでした',
    virus_waiting: 'スキャン待ち',
    virus_scanning: 'スキャン中',
    virus_too_large: 'ファイルが大きすぎてスキャンできない',
    virus_not_found: 'ファイルが見つかりません',
    virus_error: 'ファイルのスキャン中にエラーが発生しました',
    virus_found: 'ウイルスが検出されました',
    virus_risk:
      'このファイルには実行ファイルが含まれているため、開く際には注意が必要です。',
    version_placeholder: 'バージョン番号、すなわち 1.0.0',
    release_notes_placeholder: 'それぞれの変更を 1 行ずつ入力してください。',
    game_moderator: 'ゲームモデレーター',
    metadata_blob: 'メタデータブロブ',
    metadata_blob_placeholder:
      'このリリースに対応するゲームのバージョン、インストール方法など、特定のメタデータを入力します。UTF-8の有効なデータがサポートされます。',
    select_platforms: 'プラットフォーム (該当するものをすべて選択)',
    file_pending: '承認待ち',
    file_approved: '承認済み',
    file_approved_live: '承認済みで稼働中',
    file_approved_not_live: '承認済みだが公開されていない',
    file_denied: '拒否',
    file_targeted: 'ターゲット',
    live_file: 'このファイルは現在公開中です。',
    file_is_live: 'ファイルはライブです',
    make_file_live: 'このリリースを公開する',
    make_file_live_platforms:
      'このリリースを承認済みのプラットフォーム向けに公開する',
    file_processing:
      'ファイルは処理中です。引き続きサイトを閲覧することはできますが、終了するまでタブやブラウザウィンドウを閉じないでください。',
    file_instructions_1:
      '{ugc} のベースフォルダをZIPしてアップロードします。既存のゲームフォルダにあるファイルの集まりである場合は、それらのファイルをZIPしてください。',
    file_instructions_2: 'ZIP ファイルである必要があります',
    file_instructions_3: 'ファイルサイズは 20 GB を超えることはできません',
    file_instructions_4:
      'ファイル名には次の文字を含めることはできません: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      'ファイルを上書きする {ugc} は、ゲームがこれを管理しない限りサポートされません',
    file_instructions_6:
      '複数のゲームディレクトリにまたがる {ugc} は、ゲームがこれを管理しない限りサポートされません',
    file_terms: '{terms} と {privacy} に同意します。',
    error_get_files: 'ファイルを取得できませんでした',
    error_upload_file: 'ファイルをアップロードできませんでした',
    error_update_file: 'ファイルの更新に失敗しました',
    error_file_required: 'ファイルが必要です',
    error_platform_required:
      '少なくとも 1 つのプラットフォームを選択する必要があります',
    success_file_uploaded: 'ファイルは正常にアップロードされました',
    success_file_moderation:
      'モデレーターは、モデレーションが必要なプラットフォームをレビューします',
    success_file_updated: 'ファイルは正常に更新されました',
    error_no_approved_platforms: 'ライブに設定するプラットフォームはありません',
    error_no_approved_platforms_text:
      'このファイルには、現在稼働していない承認済みのプラットフォームはありません',

    // Reuse permissions form
    reuse_permissions: {
      heading: '権限を再利用する',
      allow_redistribution:
        '他の人が私の {ugc} アセットを他のサイトにアップロードすることを許可するが、私は元の作成者としてクレジットされなければならない',
      allow_porting:
        '他の人が私の {ugc} アセットを他のゲームで使用できるように変換することを許可するが、私は元の作成者としてクレジットされなければならない',
      allow_patching_no_credit:
        '他のユーザーが私の {ugc} アセットを変更し（バグ修正や改善のリリースを含む）、新しい {ugc} としてリリースできるようにする',
      require_credit:
        '私はオリジナルクリエイターとしてクレジットされなければなりません',
      require_permission: '最初に私の許可を要求',
      require_repackage_no_credit:
        '自分の {0} アセットを自分の {1} で使用することを他のユーザーに許可する',
      allow_resell:
        '他の人が私の {ugc} アセットを任意の商用作品の任意の {ugc} /ファイルで使用できるようにする',
      original_assets:
        '私の {ugc} には 100% オリジナルの作品、または再配布可能なアセットが含まれています',
      error_update: '再利用権限を更新できませんでした',
      success_update: '再利用権限が正常に更新されました',
      prompt:
        '自分の {ugc} アセットを他のユーザーのコンテンツ作成にどのように使用できるかについての権限を設定します。他の人にあなたのファイルを使用または変更する許可を与えることで、既に作成された作品に関してそのような許可をさかのぼって取り消すことはできないことを認めたものとみなされます。',
    },

    // Dependencies form
    dependencies_prompt:
      'この {1} が正しく機能するには、必要な {0} を選択してください ({1} が表示されない場合は依存関係が許可されていないため、作成者に連絡して有効にしてください)。',
    remove_dependency: '依存関係を削除',
    dependants: '扶養家族',
    error_update_dependencies: '依存関係の更新に失敗しました',
    success_update_dependencies: '依存関係の設定が正常に更新されました',
    dependants_warning_1:
      '許可されると、扶養家族が接続されている {ugc} は次の点に注意してください。',
    dependants_warning_2: '依存関係を無効にできない',
    dependants_warning_3: '削除できません',
    dependants_warning_4: '収益化できない',
    dependants_confirm_enable:
      'これは、接続された {ugc} の継続的な互換性を確保するためです。有効にすることを確認してください。',
    dependants_confirm_disable:
      '本当に扶養家族を拒否してもよろしいですか？つまり、他の {0} はあなたの {1} を依存関係として追加できなくなります。',
  },
  ko: {
    language: '언어',

    // Nav
    browse: '탐색',
    library: '도서관',
    account: '내 계정',
    back: '돌아 가라',
    more: '더',
    add_mod: '{ugc} 추가',
    edit_mod: 'mod.io 에서 편집',
    help_center: '헬프 센터',

    // Guides
    guide: '가이드 | 가이드',
    views: '견해',
    table_of_contents: '목차',
    reading_time: '독서 시간',
    date_added: '추가된 날짜',
    date_updated: '업데이트된 날짜',
    minutes_read: '분 읽기',
    published: '게시됨',

    // Users
    joined: '가입됨',
    last_online: '마지막 온라인',
    online: '온라인',
    followers: '추종자',
    partner: '파트너',
    profile_hidden: '이 사용자의 프로필은 숨겨져 있습니다',
    follow: '따르다',
    unfollow: '언팔로우',

    // Mods
    mod: 'Mod | Mods',
    level: '레벨 | 레벨',
    content: '콘텐츠',
    subscribed: '구독',
    tags: '태그',
    summary: '요약',
    contributors: '기여자',
    description: '설명',
    dependencies: '종속성',
    of: '/',
    ago: '~ 전에',
    and: '그리고',
    by: '에 의해',
    reset_filters: '필터 재설정',
    show_more: '자세히보기',
    show_less: '간략히 표시',
    no_ugc_found: '{ugc_name}을(를) 찾을 수 없음',
    no_subscriptions: '{ugc_name}을(를) 구독하지 않았습니다.',
    mod_disclaimer_heading: '법적 면책 조항',
    mod_disclaimer_privacy: '개인정보보호방침을 참조해 주세요',
    thumbs_up_received: '좋아요를 받았습니다.',
    thumbs_up: '엄지손가락',
    total_downloads: '총 다운로드',
    releases: '릴리스',
    bytes: '바이트',

    // Actions
    login: '로그인',
    logout: '로그 아웃',
    search: '검색',
    clear_all: '모두 지우기',
    download: '다운로드',
    subscribe: '구독',
    unsubscribe: '구독 취소',
    resubscribe: '재구독',
    activate: '활성화',
    deactivate: '비활성화',
    like: '좋아요',
    dislike: '싫어요',
    report: '문제 신고',
    submit: '제출',
    cancel: '취소',
    select: '선택하다',
    previous: '이전',
    next: '다음',
    confirm: '확인',
    allow: '허용',

    // Filters
    all: '모두',
    available: '사용 가능',
    filesize: '파일 크기',
    downloads: '다운로드',
    todays_downloads: '오늘의 다운로드',
    subscribers: '가입자',
    rating: '평가',
    sort_by: '정렬 기준',
    creator: '생성자',
    apply: '적용하다',
    reset: '초기화',
    featured: '추천',
    toggle_filters: '전환 필터',
    hidden: '숨겨진',
    public: '공개',
    pending: '보류 중',
    deleted: '삭제됨',
    last_day: '마지막 날',
    last_week: '지난주',
    last_month: '전달',
    last_3_months: '지난 3개월',
    last_year: '작년',

    // Login
    signup: '이메일로 로그인 또는 가입',
    login_with: '{service}로 로그인',
    email_placeholder: '이메일 주소',
    code_placeholder: '5자리 코드',
    have_code: '코드가 있습니다',
    email_code: '이메일 코드',
    send_new_code: '새 코드 보내기',
    check_email: '이메일에서 코드를 확인하고 여기에 입력하세요.',
    login_disclaimer: '{game}의 사용자 생성 콘텐츠는 mod.io에서 관리합니다.',
    terms_disclaimer: `"로그인"을 누르면 {studioterms}mod.io의 {terms} 및 {privacy}에 동의하고 {min_age}세 이상임을 확인하게 됩니다.`,
    terms_disclaimer_studio: '{studio}의 {terms}, ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' 및 ',
    code_error: '코드를 요청할 수 없습니다.',
    or: '또는',
    login_prompt:
      '액세스하려는 페이지에는 인증이 필요합니다. 계속하려면 로그인하세요.',

    // Terms
    terms: '이용 약관',
    privacy: '개인정보 보호 정책',
    aup: '허용되는 사용 정책',
    monetization: '수익 창출 조건',
    game: '게임 용어',
    api: 'API 액세스 약관',
    effective: '부터 발효',

    // Report
    report_heading: '이 Mod 신고',
    report_intro:
      '아래 양식을 사용하여 콘텐츠 문제, mod.io {terms} 위반 사항을 보고하거나 {dmca}를 제출할 수 있습니다.',
    report_details:
      '신고 내용을 쉽게 해결할 수 있도록 관련 정보를 모두 포함했는지 확인하세요. 이 정보는 게임 중재자와 공유되며, 침해가 의심되는 콘텐츠를 게시한 사용자와도 공유될 수 있습니다.',
    report_not_working_prompt:
      "'작동하지 않음' 보고서는 콘텐츠 작성자와 직접 공유됩니다.",
    dmca: 'DMCA 불만 제기',
    reason: '신고 이유',
    urls: '잘못된 URL',
    urls_placeholder: 'URL(한 줄에 1개)',
    company_or_name: '회사 또는 귀하의 이름',
    email: '이메일',
    address: '주소',
    country: '국가',
    country_placeholder: '국가를 선택하세요',
    details: '위반 사항 세부 정보',
    details_placeholder:
      '신고하신 내용을 해결하는 데 도움이 되도록 최대한 많은 세부정보와 맥락을 제공해 주시기 바랍니다.',
    dmca_1:
      '{mod_io}는 타인의 지적 재산권을 존중하며 당사 인터넷 사이트 및 서비스를 사용하는 모든 사람이 동일하게 행동할 것을 요청합니다. 자신의 작품이 저작권을 침해하는 방식으로 당사의 인터넷 사이트 또는 서비스 중 하나에 복제되었다고 생각하는 사람은 아래 양식을 사용하여 mod.io의 저작권 대리인에게 알릴 수 있습니다.',
    dmca_2:
      '저작권 침해 주장을 제출하는 것은 심각한 법적 문제입니다. 진행하기 전에 개인에게 직접 연락하여 불만 사항을 처리하는 것을 고려할 수 있습니다. 단순한 오해일 수 있으며 적절한 법적 절차를 거치지 않고 해결할 수 있습니다.',
    dmca_3:
      '디지털 밀레니엄 저작권법(Digital Millennium Copyright Act) 512(f)절에 따라 자료나 활동이 저작권을 침해한다고 고의적으로 실질적으로 허위 진술하는 사람은 손해 배상 책임을 질 수 있습니다. 또한 이 법적 고지에 제공된 정보는 제3자와 공유되거나 공개될 수 있습니다.',
    claim_check:
      '다음 확인란을 선택하고 이러한 불만 사항을 제출함으로써 본인은 위증 시 처벌을 받는다는 조건 하에 다음과 같이 진술합니다.',
    copyright_owner: '본인은 저작권 소유자입니다',
    copyright_auth_owner:
      '본인은 이 상황에서 저작권 소유자를 대신하여 행동할 권한이 있습니다.',
    authorization: '권한 부여',
    authorization_check:
      '본인은 이 불만 사항에 언급된 자료의 사용이 저작권 소유자, 그 대리인 또는 법률에 의해 승인되지 않았음을 선의로 믿습니다.',
    acknowledgement: '인정',
    acknowledgement_check:
      '본인은 DMCA 512(f)절에 따라 자료나 활동이 저작권을 침해한다고 고의적으로 실질적으로 허위 진술하는 사람은 손해 배상 책임을 질 수 있음을 인정합니다.',
    required: '필수',
    signature: '서명',
    signature_placeholder:
      '디지털 서명 역할을 하는 성명을 이 확인란에 입력하십시오.',
    report_success_title: '귀하의 신고가 접수되었습니다',
    report_success_text:
      '귀하의 메시지에 대한 답변이 필요한 경우 모든 보고서를 받은 후 48시간 이내에 답변하기 위해 최선을 다하고 있으니 조금만 기다려 주십시오.',
    report_error_title: '보고서를 제출하지 못했습니다',

    // Table
    name: '이름',
    added: '추가됨',
    updated: '업데이트됨',
    file: '파일',
    version: '버전',
    scan: '바이러스 스캔',
    actions: '행동',
    locally_installed: '로컬로 설치됨',

    // Releases
    download_from_game_client: '게임 클라이언트에서 {mod} 다운로드',
    release_notes: '릴리즈 노트',
    no_release_notes: '릴리스 노트가 제공되지 않음',
    previous_releases: '이전 릴리스',
    view_previous_releases: '이전 릴리스 보기',
    no_previous_releases: '이전 릴리스가 없습니다',
    no_files_published: '게시된 파일 없음',
    download_files_manually: '수동으로 파일 다운로드',
    platforms: '플랫폼 | 플랫폼',

    // Ratings
    overwhelmingly_positive: '압도적으로 긍정적',
    overwhelmingly_negative: '압도적으로 부정적',
    very_positive: '매우 긍정적',
    very_negative: '매우 부정적',
    mostly_positive: '대부분 긍정적',
    mostly_negative: '대부분 부정적',
    negative: '부정적',
    positive: '긍정적',
    mixed: '혼합',
    unrated: '등급 평가 없음',

    // Sort
    sort_comments: '코멘트',
    sort_total_mods: '총 모드',
    sort_trending: '트렌드',
    sort_highest_rated: '최고 평점',
    sort_most_popular: '가장 인기 많은',
    sort_most_subscribers: '대부분의 가입자',
    sort_recently_added: '최근에 추가',
    sort_last_updated: '마지막 업데이트',
    sort_alphabetical: '알파벳순',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '작동하지 않음',
    report_rude_content: '무례한 콘텐츠',
    report_illegal_content: '불법 콘텐츠',
    report_stolen_content: '훔친 콘텐츠',
    report_false_information: '거짓 정보',
    report_other: '기타',

    // Report not working reasons
    report_crash: '충돌 게임',
    report_no_load: '로드되지 않음',
    report_conflicts: '다른 {ugc}와의 충돌',
    report_missing_deps: '종속성 누락',
    report_install_issues: '설치 문제',
    report_bugs: '버기 행동',
    report_incompatability: '게임 버전과 호환되지 않음',
    report_corrupt: '손상된 파일',

    // Errors
    error_ref: '오류 코드: ',
    login_fail: '로그인 실패',
    login_fail_message: '로그인 실패. 잠시만 기다려 다시 시도하십시오.',
    subscribe_fail: '구독하지 못했습니다',
    unsubscribe_fail: '구독 취소 실패',
    error: '오류',
    default_error_message: '오류가 발생했습니다. 잠시 후 다시 시도해 주세요.',
    follow_error: '팔로우 실패',
    unfollow_error: '팔로우를 취소하지 못했습니다.',

    agreement_not_found: '계약을 찾을 수 없습니다',
    form_chars_remaining: '{count} 남은 문자.',
    form_not_enough_chars: '{count} 더 많은 문자가 필요합니다.',
    filter: '필터',
    loading: '로딩',
    next_version: '다음 버전',
    no_results_found: '검색 결과가 없습니다',
    page_not_found: '페이지를 찾을 수 없습니다!',
    previous_version: '이전 버전',
    take_me_home: '나를 집에 데려가',
    validation_alpha_num: '필드에는 숫자 나 글자 만 포함 할 수 있습니다',
    validation_size: '필드는 {size} 문자 여야합니다',
    validation_url: '필드는 URL이어야합니다',
    validation_min: '최소 {min} 자',
    validation_max: '최대 {max} 문자',
    validation_email: '필드는 이메일이어야합니다',
    validation_checked: '필드를 점검해야합니다',
    validation_required: '필드가 필요합니다',
    validation_name: '필드에는 문자 및 이름 관련 기호만 포함될 수 있습니다.',
    validation_image: '파일은 유효한 jpeg 또는 png 이미지여야 합니다.',
    validation_youtube: '필드는 유효한 유튜브 URL이어야 합니다',
    validation_sketchfab: '필드는 유효한 스케치팹 URL이어야 합니다.',
    validation_zip: '파일은 ZIP이어야 합니다.',
    validation_filesize: '파일 크기는 {size} 를 초과할 수 없습니다.',

    comment: '논평',
    team_member: '팀 구성원',
    author: '작가',
    good_karma: '좋은 카르마',
    bad_karma: '나쁜 카르마',
    reply: '회신하다',
    replies: '답장 | 답글',
    save: '구하다',
    delete: '삭제',
    edit: '편집하다',
    post: '우편',
    pinned: '고정됨',
    pin: '핀',
    unpin: '고정 해제',
    locked: '잠김',
    lock: '잠금',
    unlock: '잠금 해제',
    add_comment: '의견을 추가하다...',
    comment_cant_reply_locked:
      '댓글 스레드가 잠겼기 때문에 응답할 수 없습니다.',
    comment_cant_edit_locked: '댓글 스레드가 잠겼기 때문에 편집할 수 없습니다.',
    comment_max_pin_limit: '고정 댓글의 최대 한도에 도달했습니다.',
    comment_below_threshold: '댓글이 임계값 미만입니다.보려면 클릭하세요.',
    comment_login_prompt: '토론에 참여하려면 로그인하십시오!',
    comment_error_add: '댓글을 추가하지 못했습니다',
    comment_error_edit: '주석을 편집하지 못했습니다',
    comment_error_delete: '주석을 삭제하지 못했습니다',
    comment_error_rate: '댓글을 평가하지 못했습니다.',
    comment_error_update: '댓글 업데이트 실패',
    comments_error_fetch: '더 많은 댓글을로드하지 못했습니다',
    discussion: '논의',
    load_more_comments: '더 많은 의견을로드하십시오',
    comment_confirm_delete: '이 의견을 삭제 하시겠습니까?',
    comment_deleted: '의견이 성공적으로 삭제되었습니다',

    // Mod admin
    mod_profile: '{ugc} 프로필',
    media: '메디아',
    file_manager: '파일 관리자',
    step: '{step}단계',
    save_next: '저장 및 다음',
    auth_required: '인증이 필요합니다.',
    tools_only_1:
      '여기서 {game} 용 {ugc} 를 업로드할 수 없습니다. 파일을 확인하려면 게임 개발자가 만든 도구를 사용하여 업로드해야 합니다.자세한 내용은 이 게임을 위해 작성된 {guides} 를 참조하십시오. ',
    tools_only_2: '게임 개발자가 제공하는 지침은 다음과 같습니다.',
    tools_only_3:
      '{ugc} 목록으로 돌아가서 링크를 찾아보는 것이 좋습니다.문제가 지속되면 {help_center} 로 이동하여 자세한 내용을 확인하세요.',
    unsaved_heading: '변경 내용을 저장하지 않았습니다.',
    unsaved_confirm:
      '아직 저장되지 않은 변경 사항이 있습니다. 그래도 종료하시겠습니까?',

    headline_mod_profile: '{ugc} 프로필을 만드세요',
    headline_media: '{ugc} 프로필에 미디어를 추가하세요',
    headline_files: '{ugc} 파일 업로드',
    headline_permissions: '{ugc}에 대한 재사용 권한 설정',
    headline_dependencies: '{ugc} 종속성을 추가하세요.',

    // Basics
    basics: '기초',
    logo: '로고',
    logo_prompt_1: '{ugc} 로고를 나타내는 이미지 파일입니다.',
    logo_prompt_2: '512x288 이상',
    logo_prompt_3: '16:9 화면 비율 권장',
    logo_prompt_4: '최대 8MB',
    summary_placeholder:
      '{ugc} 님이 게임에 적용한 변경 사항을 한 단락으로 알려주세요.',
    homepage: '홈페이지',
    profile_url: '프로필 URL',
    visibility: '가시성',
    visibility_tip:
      '일단 게시되면 모든 사람이 공개 {ugc} 를 볼 수 있습니다.Hidden {ugc} 은 팀원과 구독자만 볼 수 있습니다.',
    upload_images: '이미지 업로드',
    mature_content: '성인용 콘텐츠 포함',
    mature_content_alcohol: '알코올',
    mature_content_drugs: '약물',
    mature_content_violence: '폭력',
    mature_content_explicit: '명시적',
    community: '커뮤니티',
    allow_comments: '댓글 허용',
    allow_dependants: '부양가족 허용',
    create_mod: '{ugc} 만들기',
    error_mod_create: '{ugc} 을 (를) 생성하지 못했습니다.',
    error_mod_update: '{ugc} 을 (를) 업데이트하지 못했습니다.',
    error_tags_update: '태그 업데이트 실패',
    success_create_mod: '{modname} 을 (를) 성공적으로 만들었습니다.',
    success_update_mod: '{modname} 을 (를) 성공적으로 업데이트했습니다.',
    description_tip:
      "{ugc} 에 대한 선택적 설명에는 '정보', '기능', '사용법', 'FAQ' 등과 같은 세부 정보가 포함될 수 있습니다. HTML이 지원되고 권장됩니다.",

    // Media
    models: '모델',
    media_zip_hint_1:
      '최대 {count} 개의 이미지 또는 단일 zip 이미지 아카이브를 업로드하여 갤러리에 추가할 수 있습니다.',
    media_zip_hint_2: 'zip 파일에 있는 유효한 jpg 및 png 이미지만 처리됩니다.',
    error_media_update: '미디어를 업데이트하지 못했습니다.',
    error_media_add_links: '미디어 링크를 추가하지 못했습니다.',
    error_media_remove_links: '미디어 링크를 제거하지 못했습니다.',
    success_media_updated: '미디어를 성공적으로 업데이트했습니다.',

    // File manager
    add_file: '파일 추가',
    edit_file: '파일 편집',
    select_zip: 'zip 파일 선택',
    upload: '업로드',
    file_id: '파일 ID:',
    virus_none: '바이러스가 발견되지 않았습니다.',
    virus_waiting: '스캔 대기 중',
    virus_scanning: '스캔 진행 중',
    virus_too_large: '파일이 너무 커서 스캔할 수 없습니다',
    virus_not_found: '파일을 찾을 수 없음',
    virus_error: '파일 검사 중 오류 발생',
    virus_found: '바이러스가 감지됨',
    virus_risk:
      '이 파일은 실행 파일을 포함하고 있으므로 열 때 주의해야 합니다.',
    version_placeholder: '버전 번호, 즉 1.0.0',
    release_notes_placeholder: '각 변경 사항을 별도의 줄에 입력합니다.',
    game_moderator: '게임 모더레이터',
    metadata_blob: '메타데이터 블럽',
    metadata_blob_placeholder:
      '이 릴리스와 호환되는 게임 버전, 설치 방법 등과 같은 특정 메타데이터를 입력합니다. UTF-8 유효 데이터가 지원됩니다.',
    select_platforms: '플랫폼 (해당하는 항목 모두 선택)',
    file_pending: '승인 보류 중',
    file_approved: '승인됨',
    file_approved_live: '승인 및 라이브',
    file_approved_not_live: '승인되었지만 실제는 아님',
    file_denied: '거부됨',
    file_targeted: '타겟팅',
    live_file: '이 파일은 현재 라이브 상태입니다.',
    file_is_live: '파일이 라이브 상태임',
    make_file_live: '이 릴리스를 출시하세요',
    make_file_live_platforms: '승인된 플랫폼을 대상으로 이 릴리스를 출시하세요',
    file_processing:
      '파일이 처리 중입니다.사이트를 계속 탐색할 수 있지만 완료될 때까지 탭이나 브라우저 창을 닫지 마십시오.',
    file_instructions_1:
      '{ugc} 의 기본 폴더를 압축하여 업로드하거나 기존 게임 폴더에 있는 파일 모음인 경우 해당 파일을 압축해야 합니다.',
    file_instructions_2: 'ZIP 파일이어야 함',
    file_instructions_3: '파일 크기가 20GB를 초과할 수 없음',
    file_instructions_4:
      '파일 이름에는 다음 문자를 포함할 수 없습니다. \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc} 파일 덮어쓰기는 게임에서 관리하지 않는 한 지원되지 않습니다.',
    file_instructions_6:
      '여러 게임 디렉터리에 걸쳐 있는 {ugc} 는 게임에서 관리하지 않는 한 지원되지 않습니다.',
    file_terms: '{terms} 및 {privacy} 에 동의합니다.',
    error_get_files: '파일을 가져오지 못했습니다.',
    error_upload_file: '파일 업로드 실패',
    error_update_file: '파일 업데이트 실패',
    error_file_required: '파일이 필요합니다.',
    error_platform_required: '플랫폼을 하나 이상 선택해야 합니다.',
    success_file_uploaded: '파일이 성공적으로 업로드되었습니다',
    success_file_moderation: '중재자가 조정이 필요한 플랫폼을 검토합니다.',
    success_file_updated: '파일이 성공적으로 업데이트되었습니다.',
    error_no_approved_platforms: '라이브로 설정할 플랫폼 없음',
    error_no_approved_platforms_text:
      '이 파일에는 현재 활성화되지 않은 승인된 플랫폼이 없습니다.',

    // Reuse permissions form
    reuse_permissions: {
      heading: '재사용 권한',
      allow_redistribution:
        '다른 사람들이 내 {ugc} 자산을 다른 사이트에 업로드할 수 있도록 허용하되, 제가 원본 제작자로 인정되어야 합니다.',
      allow_porting:
        '다른 사람이 내 {ugc} 애셋을 다른 게임에서 사용할 수 있도록 허용하세요. 단, 제가 오리지널 크리에이터로 인정되어야 합니다.',
      allow_patching_no_credit:
        '다른 사람들이 내 {ugc} 자산을 수정 (버그 수정 또는 개선 사항 릴리스 포함) 하고 새 {ugc} 로 릴리스하도록 허용',
      require_credit: '제가 오리지널 크리에이터로 인정되어야 합니다',
      require_permission: '먼저 저에게 허락을 구하세요',
      require_repackage_no_credit:
        '다른 사람들이 내 {0} 자산을 자신의 {1} 에서 사용할 수 있도록 허용',
      allow_resell:
        '다른 사람들이 상업 저작물의 {ugc} /파일에서 내 {ugc} 자산을 사용할 수 있도록 허용',
      original_assets:
        'My {ugc} 에는 100% 오리지널 작품 또는 재배포할 수 있는 에셋이 포함되어 있습니다.',
      error_update: '재사용 권한을 업데이트하지 못했습니다.',
      success_update: '재사용 권한을 성공적으로 업데이트했습니다.',
      prompt:
        '{ugc} 자산을 다른 사람의 콘텐츠 제작에 사용할 수 있는 방법에 대한 권한을 설정합니다.다른 사람에게 파일을 사용하거나 수정할 수 있는 권한을 부여하면 이미 만든 저작물에 대해 해당 권한을 소급 취소할 수 없다는 점을 인정하는 것으로 간주됩니다.',
    },

    // Dependencies form
    dependencies_prompt:
      '이 {1} 이 제대로 작동하려면 {0} required를 선택하십시오 ({1} 가 표시되지 않으면 종속성을 허용하지 않았음을 의미하므로 작성자에게 문의하여 켜야 합니다).',
    remove_dependency: '종속성 제거',
    dependants: '부양가족',
    error_update_dependencies: '종속성을 업데이트하지 못했습니다.',
    success_update_dependencies: '종속성 설정을 성공적으로 업데이트했습니다.',
    dependants_warning_1: '허용되면 종속 항목이 연결된 {ugc} 에 유의하세요.',
    dependants_warning_2: '종속 항목을 비활성화할 수 없습니다.',
    dependants_warning_3: '삭제할 수 없음',
    dependants_warning_4: '수익 창출 불가',
    dependants_confirm_enable:
      '이는 연결된 {ugc} 의 지속적인 호환성을 보장하기 위한 것입니다.활성화할지 확인해 주세요.',
    dependants_confirm_disable:
      '정말로 부양가족을 허용하지 않으시겠습니까?즉, 다른 {0} 에서는 {1} 을 (를) 종속성으로 추가할 수 없습니다.',
  },
  ru: {
    language: 'Язык',

    // Nav
    browse: 'Просмотр',
    library: 'Библиотека',
    account: 'Мой счет',
    back: 'Возвращаться',
    more: 'более',
    add_mod: 'Добавить {ugc}',
    edit_mod: 'Редактировать на mod.io',
    help_center: 'справочный центр',

    // Guides
    guide: 'Путеводитель | Путеводители',
    views: 'Взгляды',
    table_of_contents: 'Оглавление',
    reading_time: 'Время чтения',
    date_added: 'Дата Добавлена',
    date_updated: 'Дата обновления',
    minutes_read: 'Минута чтения',
    published: 'Опубликовано',

    // Users
    joined: 'Присоединился',
    last_online: 'Последний раз в сети',
    online: 'В сети',
    followers: 'Последователи',
    partner: 'Партнер',
    profile_hidden: 'Профиль этого пользователя скрыт',
    follow: 'Следовать',
    unfollow: 'Отписаться',

    // Mods
    mod: 'Mod | Mods',
    level: 'Уровень | Уровни',
    content: 'Содержимое',
    subscribed: 'В подписках',
    tags: 'Теги',
    summary: 'Сведения',
    contributors: 'Участники',
    description: 'Описание',
    dependencies: 'Зависимости',
    of: 'из',
    ago: 'назад',
    and: 'и',
    by: 'К',
    reset_filters: 'Сбросить фильтры',
    show_more: 'Показать больше',
    show_less: 'Показать меньше',
    no_ugc_found: '{ugc_name} не найдено',
    no_subscriptions: 'Вы не подписаны ни на один {ugc_name}',
    mod_disclaimer_heading: 'Заявление об отказе от ответственности',
    mod_disclaimer_privacy: 'Положением об использовании персональных данных',
    thumbs_up_received: 'Оценка "Нравится" получена',
    thumbs_up: 'Недурно',
    total_downloads: 'Всего загрузок',
    releases: 'Релизы',
    bytes: 'байты',

    // Actions
    login: 'Вход',
    logout: 'Выйти',
    search: 'Поиск',
    clear_all: 'Очистить все',
    download: 'Загрузить',
    subscribe: 'Подписаться',
    unsubscribe: 'Отписаться',
    resubscribe: 'Подписаться снова',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    like: 'Нравится',
    dislike: 'Не нравится',
    report: 'Сообщить о проблеме',
    submit: 'Отправить',
    cancel: 'Отмена',
    select: 'Выбирать',
    previous: 'Предыдущее',
    next: 'Следующий',
    confirm: 'Подтвердить',
    allow: 'Разрешить',

    // Filters
    all: 'Все',
    available: 'для загрузки',
    filesize: 'Размер файла',
    downloads: 'Загрузок',
    todays_downloads: 'Загрузок сегодня',
    subscribers: 'Подписчиков',
    rating: 'Рейтинг',
    sort_by: 'Сортировать по',
    creator: 'Создатель',
    apply: 'Применять',
    reset: 'Перезагрузить',
    featured: 'Показан',
    toggle_filters: 'Переверните фильтры',
    hidden: 'Скрытый',
    public: 'Общественность',
    pending: 'В ожидании',
    deleted: 'Удалено',
    last_day: 'Последний день',
    last_week: 'На прошлой неделе',
    last_month: 'В прошлом месяце',
    last_3_months: 'Последние 3 месяца',
    last_year: 'В прошлом году',

    // Login
    signup: 'Войдите или зарегистрируйтесь с помощью эл. почты',
    login_with: 'Войдите с помощью {service}',
    email_placeholder: 'Адрес эл. почты',
    code_placeholder: '5-значный код',
    have_code: 'У меня есть код',
    email_code: 'Отправить код письмом',
    send_new_code: 'Отправить новый код',
    check_email:
      'Проверьте электронную почту на наличие письма с кодом и введите код здесь.',
    login_disclaimer:
      'Контент, созданный пользователями для {game}, обрабатывает mod.io.',
    terms_disclaimer: `Нажимая «Войдите», вы соглашаетесь с {studioterms}{terms} и {privacy} mod.io и подтверждаете, что вам исполнилось {min_age} лет или больше.`,
    terms_disclaimer_studio: '{terms} {studio}, c ',
    terms_disclaimer_studio_separator: ', ',
    terms_disclaimer_studio_and: ' и ',
    code_error: 'Не удается запросить код.',
    or: 'или',
    login_prompt:
      'Страница, к которой вы пытаетесь получить доступ, требует аутентификации. Пожалуйста, войдите, чтобы продолжить.',

    // Terms
    terms: 'Условия использования',
    privacy: 'Политика конфиденциальности',
    aup: 'Политика приемлемого использования',
    monetization: 'Условия монетизации',
    game: 'Условия игры',
    api: 'Условия доступа к API',
    effective: 'Действует с',

    // Report
    report_heading: 'Пожаловаться на этот мод',
    report_intro:
      'Вы можете сообщить о проблемах с контентом, нарушениях {terms} mod.io или отправить {dmca}, используя форму ниже.',
    report_details:
      'Убедитесь, что вы включили всю необходимую информацию, чтобы облегчить разрешение вашего отчета. Обратите внимание, что эта информация будет передана модераторам игры и может быть передана пользователю, разместившему контент, предположительно нарушающий авторские права.',
    report_not_working_prompt:
      'Отчеты о «неработоспособности» будут переданы непосредственно создателю контента.',
    dmca: 'Жалоба на нарушение авторского права',
    reason: 'Основания для жалобы',
    urls: 'URL-адреса материалов, нарушающих авторское право',
    urls_placeholder: 'URL-адреса (по 1 в строке)',
    company_or_name: 'Название компании или ваше имя',
    email: 'Адрес эл. почты',
    address: 'Адрес',
    country: 'Страна',
    country_placeholder: 'Выберите страну',
    details: 'Подробности о нарушении авторского права',
    details_placeholder:
      'Чтобы помочь в разрешении вашего отчета, предоставьте как можно больше подробностей и контекста.',
    dmca_1:
      '{mod_io} уважает владельцев прав на интеллектуальную собственность и призывает к этому всех, кто пользуется нашими сайтами и службами. Если вы считаете, что ваша работа была использована на наших сайтах или в службах с нарушением авторского права, вы можете уведомить об этом агента по авторским правам в mod.io, используя форму ниже.',
    dmca_2:
      'Подача иска о нарушении авторского права — серьезный правовой вопрос. Перед тем, как продолжить, вы могли бы связаться с нарушителем и попытаться разрешить проблему напрямую с ним. Возможно, возникло простое недоразумение, которое можно урегулировать без привлечения надлежащих правовых процессов.',
    dmca_3:
      'Помните: в соответствии с разделом 512(f) Закона об авторском праве в цифровую эпоху, лицо, сообщившее заведомо ложные сведения о нарушении авторского права, может быть привлечено к ответственности за причиненный ущерб. Также обратите внимание, что информация, указанная в этом юридическом уведомлении, может быть передана третьим сторонам или стать общедоступной.',
    claim_check:
      'Проставляя отметки и отправляя эту форму, я заявляю, НЕСЯ ОТВЕТСТВЕННОСТЬ ЗА ЛЖЕСВИДЕТЕЛЬСТВО, что:',
    copyright_owner: 'я являюсь правообладателем;',
    copyright_auth_owner:
      'я имею полномочия действовать от имени владельца авторских прав в этой ситуации.',
    authorization: 'Наличие разрешения',
    authorization_check:
      'Я добросовестно полагаю, что использование материалов, указанных в этом заявлении, не разрешено владельцем авторских прав, его представителем или законом.',
    acknowledgement: 'Подтверждение',
    acknowledgement_check:
      'Я понимаю, что в соответствии с разделом 512(f) Закона об авторском праве в цифровую эпоху (DMCA), лицо, сообщившее заведомо ложные сведения о нарушении авторского права, несет ответственность за причиненный ущерб.',
    required: 'обязательное поле',
    signature: 'Цифровая подпись',
    signature_placeholder:
      'Введите в это поле свое полное имя, которое будет использоваться в качестве цифровой подписи.',
    report_success_title: 'Ваша жалоба была получена',
    report_success_text:
      'Если ваше сообщение требует ответа, проявите немного терпения. Мы стараемся отвечать на все жалобы в течение 48 часов с момента их получения.',
    report_error_title: 'Не удалось отправить жалобу',

    // Table
    name: 'Название',
    added: 'Добавлен',
    updated: 'Обновлен',
    file: 'Файл',
    version: 'Версия',
    scan: 'Сканирование на вирусы',
    actions: 'Действия',
    locally_installed: 'На устройстве',

    // Releases
    download_from_game_client: 'Загрузить мод {mod} из клиента игры',
    release_notes: 'Сведения о версии',
    no_release_notes: 'Сведения о версии не указаны',
    previous_releases: 'Предыдущие версии',
    view_previous_releases: 'Посмотреть предыдущие выпуски',
    no_previous_releases: 'Предыдущие версии отсутствуют',
    no_files_published: 'Нет опубликованных файлов',
    download_files_manually: 'Загрузка файлов вручную',
    platforms: 'Платформа | Платформы',

    // Ratings
    overwhelmingly_positive: 'Крайне положительный',
    overwhelmingly_negative: 'Крайне отрицательный',
    very_positive: 'Очень положительный',
    very_negative: 'Очень отрицательный',
    mostly_positive: 'В основном положительный',
    mostly_negative: 'В основном отрицательный',
    negative: 'Отрицательный',
    positive: 'Положительный',
    mixed: 'Смешанный',
    unrated: 'Без рейтинга',

    // Sort
    sort_comments: 'Комментарии',
    sort_total_mods: 'Всего модов',
    sort_trending: 'В тренде',
    sort_highest_rated: 'Наивысший рейтинг',
    sort_most_popular: 'Самый популярный',
    sort_most_subscribers: 'Большинство подписчиков',
    sort_recently_added: 'Недавно добавленный',
    sort_last_updated: 'Последнее обновление',
    sort_alphabetical: 'Алфавитный',

    // Report Reason
    report_dmca: 'Закон о защите авторских прав в цифровую эпоху',
    report_not_working: 'Не работает',
    report_rude_content: 'Грубый контент',
    report_illegal_content: 'Незаконный контент',
    report_stolen_content: 'Украденный контент',
    report_false_information: 'Ложная информация',
    report_other: 'Другое',

    // Report not working reasons
    report_crash: 'Вылетает игра',
    report_no_load: 'Не загружается',
    report_conflicts: 'Конфликты с другими {ugc}',
    report_missing_deps: 'Отсутствующие зависимости',
    report_install_issues: 'Проблемы с установкой',
    report_bugs: 'Некорректное поведение',
    report_incompatability: 'Несовместимо с версией игры.',
    report_corrupt: 'Поврежденные файлы',

    // Errors
    error_ref: 'Код ошибки: ',
    login_fail: 'Не удалось войти',
    login_fail_message:
      'Не удалось войти. Пожалуйста, подождите минутку и попробуйте еще раз.',
    subscribe_fail: 'Не удалось подписаться',
    unsubscribe_fail: 'Не удалось отписаться',
    error: 'Ошибка',
    default_error_message: 'Произошла ошибка. Повторите попытку позже.',
    follow_error: 'Не удалось подписаться',
    unfollow_error: 'Не удалось отписаться',

    agreement_not_found: 'Соглашение не найдено',
    form_chars_remaining: '{count} оставшиеся символы.',
    form_not_enough_chars: '{count} больше символов требуется.',
    filter: 'Фильтр',
    loading: 'Загрузка',
    next_version: 'Следующая версия',
    no_results_found: 'результатов не найдено',
    page_not_found: 'Страница не найдена!',
    previous_version: 'Предыдущая версия',
    take_me_home: 'Отведи меня домой',
    validation_alpha_num: 'Поле может содержать только цифры или буквы',
    validation_size: 'Поле должно быть {size} символы',
    validation_url: 'Поле должно быть URL',
    validation_min: 'Минимум {min} символов',
    validation_max: 'Максимум {max} символы',
    validation_email: 'Поле должно быть электронным письмом',
    validation_checked: 'Поле должно быть проверено',
    validation_required: 'Поле, обязательное для заполнения',
    validation_name:
      'Поле может содержать только буквы и символы, относящиеся к имени.',
    validation_image:
      'Файл должен быть действительным изображением в формате jpeg или png',
    validation_youtube: 'Поле должно быть действительным URL-адресом Youtube',
    validation_sketchfab:
      'Поле должно содержать действительный URL-адрес Sketchfab',
    validation_zip: 'Файл должен быть в формате ZIP',
    validation_filesize: 'Размер файла не должен превышать {size}',

    comment: 'комментарий | комментарии',
    team_member: 'Участник команды',
    author: 'Автор',
    good_karma: 'Хорошая карма',
    bad_karma: 'Плохая карма',
    reply: 'Отвечать',
    replies: 'ответить | ответы',
    save: 'Сохранять',
    delete: 'Удалить',
    edit: 'Редактировать',
    post: 'Почта',
    pinned: 'Закреплено',
    pin: 'Штифт',
    unpin: 'Открепить',
    locked: 'Заблокировано',
    lock: 'Замок',
    unlock: 'Разблокируйте',
    add_comment: 'Добавить комментарий...',
    comment_cant_reply_locked:
      'Невозможно ответить, так как ветка комментариев заблокирована',
    comment_cant_edit_locked:
      'Невозможно редактировать, так как ветка комментариев заблокирована',
    comment_max_pin_limit:
      'Достигнуто максимальное количество прикрепленных комментариев',
    comment_below_threshold:
      'Комментарий ниже порогового значения. Нажмите для просмотра.',
    comment_login_prompt: 'Войдите, чтобы присоединиться к обсуждению!',
    comment_error_add: 'Не удалось добавить комментарий',
    comment_error_edit: 'Не удалось редактировать комментарий',
    comment_error_delete: 'Не удалось удалить комментарий',
    comment_error_rate: 'Не удалось оценить комментарий',
    comment_error_update: 'Не удалось обновить комментарий',
    comments_error_fetch: 'Не удалось загрузить больше комментариев',
    discussion: 'Дискуссия',
    load_more_comments: 'Загрузите больше комментариев',
    comment_confirm_delete: 'Вы уверенны, что хотите удалить этот комментарий?',
    comment_deleted: 'Комментарий был успешно удален',

    // Mod admin
    mod_profile: '{ugc} профиль',
    media: 'СМИ',
    file_manager: 'Файловый менеджер',
    step: 'ШАГ {step}',
    save_next: 'Сохранить и продолжить',
    auth_required: 'Требуется аутентификация',
    tools_only_1:
      'Здесь вы не можете загрузить {ugc} для {game}, их необходимо загрузить с помощью инструментов, созданных разработчиком игры, чтобы файлы можно было проверить. Дополнительные сведения см. в разделе {guides}, написанные для этой игры. ',
    tools_only_2: 'Вот инструкции, предоставленные разработчиком игры:',
    tools_only_3:
      'Мы рекомендуем вам вернуться к списку {ugc} и просмотреть ссылки оттуда. Если проблема не исчезнет, посетите наш {help_center} для получения дополнительной информации.',
    unsaved_heading: 'У вас есть несохраненные изменения',
    unsaved_confirm:
      'Есть изменения, которые не были сохранены, вы все еще хотите уйти?',

    headline_mod_profile: 'Создайте свой профиль {ugc}',
    headline_media: 'Добавьте медиа в свой профиль {ugc}',
    headline_files: 'Загрузите файл {ugc}',
    headline_permissions:
      'Установите разрешения на повторное использование для вашего {ugc}',
    headline_dependencies: 'Добавьте любые зависимости {ugc}',

    // Basics
    basics: 'Основы',
    logo: 'Логотип',
    logo_prompt_1:
      'Файл изображения, который будет представлять логотип вашего {ugc}.',
    logo_prompt_2: '512x288 или больше',
    logo_prompt_3: 'Рекомендуемое соотношение сторон 16:9',
    logo_prompt_4: 'Максимум 8 МБ',
    summary_placeholder:
      'Расскажите нам об изменениях, которые ваш {ugc} вносит в игру, в 1 абзаце.',
    homepage: 'домашняя страница',
    profile_url: 'URL-адрес профиля',
    visibility: 'Видимость',
    visibility_tip:
      'После выхода в эфир публика {ugc} будет видна всем. Скрытые {ugc} видны только членам команды и подписчикам.',
    upload_images: 'Загрузка изображений',
    mature_content: 'Содержит контент для взрослых',
    mature_content_alcohol: 'Алкоголь',
    mature_content_drugs: 'Наркотики',
    mature_content_violence: 'Насилие',
    mature_content_explicit: 'Явный',
    community: 'Сообщество',
    allow_comments: 'Разрешить комментарии',
    allow_dependants: 'Разрешить иждивенцам',
    create_mod: 'Создайте {ugc}',
    error_mod_create: 'Не удалось создать {ugc}',
    error_mod_update: 'Не удалось обновить {ugc}',
    error_tags_update: 'Не удалось обновить теги',
    success_create_mod: 'Успешно создано {modname}',
    success_update_mod: 'Успешно обновлено {modname}',
    description_tip:
      'Дополнительное описание вашего {ugc}, которое может включать такие сведения, как «О нас», «Функции», «Использование», «Часто задаваемые вопросы» и т. д. Поддерживается и поощряется HTML',

    // Media
    models: 'моделей',
    media_zip_hint_1:
      'Загрузите до {count} изображений или один zip-архив изображений для добавления в галерею.',
    media_zip_hint_2:
      'Будут обработаны только действительные изображения jpg и png в zip-файле.',
    error_media_update: 'Не удалось обновить носитель',
    error_media_add_links: 'Не удалось добавить ссылки на медиафайлы',
    error_media_remove_links: 'Не удалось удалить ссылки на медиафайлы',
    success_media_updated: 'Медиа успешно обновлены',

    // File manager
    add_file: 'Добавить файл',
    edit_file: 'Редактирование файла',
    select_zip: 'Выберите zip-файл',
    upload: 'Загрузить',
    file_id: 'Идентификатор файла:',
    virus_none: 'Вирус не обнаружен',
    virus_waiting: 'В ожидании сканирования',
    virus_scanning: 'Выполняется сканирование',
    virus_too_large: 'Файл слишком велик для сканирования',
    virus_not_found: 'Файл не найден',
    virus_error: 'Ошибка при сканировании файла',
    virus_found: 'Обнаружен вирус',
    virus_risk:
      'Этот файл содержит исполняемый файл, и его следует соблюдать осторожность при открытии',
    version_placeholder: 'Номер версии, т.е. 1.0.0',
    release_notes_placeholder: 'Введите каждое изменение в отдельной строке',
    game_moderator: 'Игровой модератор',
    metadata_blob: 'Блок метаданных',
    metadata_blob_placeholder:
      'Введите конкретные метаданные, такие как версия игры, с которой совместима эта версия, способ ее установки и т. д. Поддерживаются действительные данные в кодировке UTF-8.',
    select_platforms: 'Платформы (выберите все подходящие)',
    file_pending: 'В ожидании одобрения',
    file_approved: 'Утверждено',
    file_approved_live: 'Утверждено и запущено',
    file_approved_not_live: 'Одобрено, но не запущено',
    file_denied: 'Отказано',
    file_targeted: 'Целенаправленный',
    live_file:
      'Этот файл в настоящее время находится в режиме реального времени.',
    file_is_live: 'Файл находится в режиме реального времени',
    make_file_live: 'Сделайте этот релиз в прямом эфире',
    make_file_live_platforms: 'Выпустите этот релиз для одобренных платформ',
    file_processing:
      'Ваш файл обрабатывается. Вы можете продолжать просматривать сайт, но не закрывайте вкладку или окно браузера, пока они не закончатся.',
    file_instructions_1:
      'Заархивируйте архив и загрузите базовую папку вашего {ugc} или, если это набор файлов, которые находятся в уже существующей папке с игрой, вы должны заархивировать эти файлы:',
    file_instructions_2: 'Должен быть ZIP-файл',
    file_instructions_3: 'Размер файла не может превышать 20 ГБ',
    file_instructions_4:
      'Имена файлов не могут содержать следующие символы: \\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5:
      '{ugc}, файлы перезаписи которых не поддерживаются, если игра не справится с этим',
    file_instructions_6:
      '{ugc}, которые занимают несколько игровых каталогов, не поддерживаются, если игра не справится с этим',
    file_terms: 'Я согласен с {terms} и {privacy}.',
    error_get_files: 'Не удалось получить файлы',
    error_upload_file: 'Не удалось загрузить файл',
    error_update_file: 'Не удалось обновить файл',
    error_file_required: 'Необходим файл',
    error_platform_required: 'Вы должны выбрать хотя бы одну платформу',
    success_file_uploaded: 'Файл успешно загружен',
    success_file_moderation:
      'Модератор рассмотрит платформы, требующие модерации',
    success_file_updated: 'Файл успешно обновлен',
    error_no_approved_platforms: 'Нет платформ для запуска',
    error_no_approved_platforms_text:
      'В этом файле нет утвержденных платформ, которые в настоящее время не работают',

    // Reuse permissions form
    reuse_permissions: {
      heading: 'Разрешения на повторное использование',
      allow_redistribution:
        'Разрешить другим загружать мои ресурсы {ugc} на другие сайты, но я должен быть указан как оригинальный создатель',
      allow_porting:
        'Разрешите другим использовать мои ресурсы {ugc} для работы в других играх, но я должен быть назван автором оригинала',
      allow_patching_no_credit:
        'Разрешить другим изменять мои ресурсы {ugc} (включая исправление ошибок или улучшений) и выпускать их как новый {ugc}',
      require_credit: 'Я должен быть назван оригинальным создателем',
      require_permission: 'Сначала требуйте от меня разрешения',
      require_repackage_no_credit:
        'Разрешить другим использовать мои {0} активы в своих собственных {1}',
      allow_resell:
        'Разрешить другим использовать мои ресурсы {ugc} в любых файлах {ugc} /в любых коммерческих работах',
      original_assets:
        'Мой {ugc} содержит 100% оригинальных работ или активов, которые можно перераспределить',
      error_update: 'Не удалось обновить разрешения на повторное использование',
      success_update: 'Разрешения на повторное использование успешно обновлены',
      prompt:
        'Задайте разрешения на использование ваших ресурсов {ugc} для создания чужого контента. Разрешая другим пользователям использовать или изменять ваши файлы, вы подтверждаете, что такое разрешение не может быть отозвано задним числом в отношении уже созданных произведений.',
    },

    // Dependencies form
    dependencies_prompt:
      'Выберите {0}, необходимый для правильной работы {1} (если {1} не отображается, значит, зависимости запрещены, и вам следует связаться с создателем, чтобы включить его):',
    remove_dependency: 'Удалить зависимость',
    dependants: 'Иждивенцы',
    error_update_dependencies: 'Не удалось обновить зависимости',
    success_update_dependencies: 'Настройки зависимостей успешно обновлены',
    dependants_warning_1:
      'После получения разрешения имейте в виду, что {ugc} с подключенными иждивенцами:',
    dependants_warning_2: 'Невозможно отключить иждивенцев',
    dependants_warning_3: 'Не может быть удалено',
    dependants_warning_4: 'Невозможно монетизировать',
    dependants_confirm_enable:
      'Это необходимо для обеспечения постоянной совместимости подключенных {ugc}. Пожалуйста, подтвердите включение.',
    dependants_confirm_disable:
      'Вы действительно хотите запретить иждивенцам? Это означает, что другие {0} не смогут добавить вашу {1} в качестве зависимости.',
  },
  'zh-CN': {
    language: '语言',

    // Nav
    browse: '浏览',
    library: '图书馆',
    account: '我的账户',
    back: '返回',
    more: '更多的',
    add_mod: '添加 {ugc}',
    edit_mod: '在 mod.io 上编辑',
    help_center: '帮助中心',

    // Guides
    guide: '指南|指南',
    views: '意见',
    table_of_contents: '目录',
    reading_time: '阅读时间',
    date_added: '添加日期',
    date_updated: '更新日期',
    minutes_read: '阅读分钟数',
    published: '已发表',

    // Users
    joined: '已加入',
    last_online: '最后在线时间',
    online: '在线的',
    followers: '追随者',
    partner: '伙伴',
    profile_hidden: '该用户的个人资料已隐藏',
    follow: '跟随',
    unfollow: '取消关注',

    // Mods
    mod: '模组',
    level: '水平|级别',
    content: '内容',
    subscribed: '已订阅',
    tags: '标签',
    summary: '概要',
    contributors: '作者',
    description: '描述',
    dependencies: '依赖项',
    of: '/',
    ago: '前',
    and: '和',
    by: '经过',
    reset_filters: '重置过滤器',
    show_more: '展示更多',
    show_less: '显示较少',
    no_ugc_found: '未找到 {ugc_name}',
    no_subscriptions: '你还没有订阅任何 {ugc_name}',
    mod_disclaimer_heading: '法律免责声明',
    mod_disclaimer_privacy: '请查看我们的隐私权政策',
    thumbs_up_received: '已收到竖起大拇指',
    thumbs_up: '竖起大拇指',
    total_downloads: '总下载量',
    releases: '发布',
    bytes: '字节',

    // Actions
    login: '登录',
    logout: '退出',
    search: '搜索',
    clear_all: '全部清除',
    download: '下载',
    subscribe: '订阅',
    unsubscribe: '取消订阅',
    resubscribe: '重新订阅',
    activate: '激活',
    deactivate: '停用',
    like: '赞',
    dislike: '踩',
    report: '报告一个问题',
    submit: '提交',
    cancel: '取消',
    select: '选择',
    previous: '以前',
    next: '下一步',
    confirm: '确认',
    allow: '允许',

    // Filters
    all: '全部',
    available: '可用',
    filesize: '文件大小',
    downloads: '下载量',
    todays_downloads: '今日下载量',
    subscribers: '订阅者',
    rating: '评分',
    sort_by: '排序方式',
    creator: '创建者',
    apply: '申请',
    reset: '重置',
    featured: '特色',
    toggle_filters: '切换过滤器',
    hidden: '隐',
    public: '公开',
    pending: '待办的',
    deleted: '已删除',
    last_day: '最后一天',
    last_week: '上星期',
    last_month: '上个月',
    last_3_months: '过去 3 个月',
    last_year: '去年',

    // Login
    signup: '使用电子邮件登录或注册',
    login_with: '使用 {service} 登录',
    email_placeholder: '电子邮件地址',
    code_placeholder: '5 位代码',
    have_code: '我有代码',
    email_code: '通过电子邮件发送代码',
    send_new_code: '发送新代码',
    check_email: '请查看你的电子邮件获取代码并在这里输入。',
    login_disclaimer: '{game} 的用户生成内容由 mod.io 管理。',
    terms_disclaimer: `按“登录”即表示您同意 {studioterms}mod.io 的{terms}和{privacy}，并确认您已年满 {min_age} 岁。`,
    terms_disclaimer_studio: '{studio} 的{terms}、',
    terms_disclaimer_studio_separator: '、',
    terms_disclaimer_studio_and: '和',
    code_error: '无法请求代码。',
    or: '或者',
    login_prompt: '您尝试访问的页面需要身份验证。请登录以继续。',

    // Terms
    terms: '使用条款',
    privacy: '隐私政策',
    aup: '可接受的使用政策',
    monetization: '获利条款',
    game: '游戏条款',
    api: 'API访问条款',
    effective: '生效日期：',

    // Report
    report_heading: '举报此 mod',
    report_intro:
      '您可以报告内容问题、违反 mod.io {terms} 的行为或使用下面的表单提交 {dmca}。',
    report_details:
      '确保包含所有相关信息，以促进报告的解决。请注意，此信息将与游戏管理员共享，并可能与发布涉嫌侵权内容的用户共享。',
    report_not_working_prompt: '“不起作用”报告将直接与内容创建者共享。',
    dmca: 'DMCA 投诉',
    reason: '举报原因',
    urls: '违规网址',
    urls_placeholder: '网址（每行 1 个）',
    company_or_name: '公司或你的名字',
    email: '电子邮件',
    address: '地址',
    country: '国家/地区',
    country_placeholder: '选择国家/地区',
    details: '侵权详情',
    details_placeholder:
      '为了帮助解决您的报告，请提供尽可能多的详细信息和背景。',
    dmca_1:
      '{mod_io} 尊重他人的知识产权，我们要求每个使用我们网站和服务的人都这样做。如果你认为你的作品在我们的网站或服务中被以侵权版权的方式重现，你可以使用以下表单通知 mod.io 的版权代理。',
    dmca_2:
      '提交版权侵犯申诉是一项严肃的法律事务。在继续之前，你可以考虑直接联系当事人以解决投诉。这可能只是一个误会，可以在不涉及适当法律程序的情况下解决。',
    dmca_3:
      '请注意，根据《数字千年版权法案》第 512(f) 条，任何人如果故意歪曲材料或活动侵权，则可能需要承担损害赔偿责任。另请注意，本法律声明中提供的信息可能会与第三方共享或公开。',
    claim_check: '勾选以下方框并提交此申诉，如有伪证则将受罚，我声明',
    copyright_owner: '我是版权所有者',
    copyright_auth_owner: '在这种情况下，我被授权代表版权所有者行事。',
    authorization: '授权',
    authorization_check:
      '我有正当理由相信，本声明中所述材料的使用未经版权所有者、其代理人或法律授权。',
    acknowledgement: '知悉书',
    acknowledgement_check:
      '我知悉，根据 DMCA 第 512(f) 条，任何人如果故意歪曲材料或活动侵权，则可能需要承担损害赔偿责任。',
    required: '必填',
    signature: '签名',
    signature_placeholder: '在此框中输入你的全名，作为你的数字签名。',
    report_success_title: '你的举报已送达',
    report_success_text:
      '如果你的消息需要回复，请耐心等待，我们会尽力在收到举报后的 48 小时内回复。',
    report_error_title: '提交举报失败',

    // Table
    name: '名称',
    added: '存在时长',
    updated: '更新日期',
    file: '文件',
    version: '版本',
    scan: '病毒扫描',
    actions: '操作',
    locally_installed: '本地安装',

    // Releases
    download_from_game_client: '从游戏客户端下载 {mod}',
    release_notes: '版本说明',
    no_release_notes: '未提供版本说明',
    previous_releases: '之前的版本',
    view_previous_releases: '查看以前的版本',
    no_previous_releases: '没有之前的版本',
    no_files_published: '未发布文件',
    download_files_manually: '手动下载文件',
    platforms: '平台 | 平台',

    // Ratings
    overwhelmingly_positive: '好评如潮',
    overwhelmingly_negative: '差评如潮',
    very_positive: '特别好评',
    very_negative: '特别差评',
    mostly_positive: '多半好评',
    mostly_negative: '多半差评',
    negative: '差评',
    positive: '好评',
    mixed: '褒贬不一',
    unrated: '未评分',

    // Sort
    sort_comments: '评论',
    sort_total_mods: '模组总数',
    sort_trending: '热门',
    sort_highest_rated: '收视率最高',
    sort_most_popular: '最受欢迎',
    sort_most_subscribers: '大多数订阅者',
    sort_recently_added: '最近添加的',
    sort_last_updated: '最近更新时间',
    sort_alphabetical: '按字母顺序',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '不工作',
    report_rude_content: '粗鲁的内容',
    report_illegal_content: '非法内容',
    report_stolen_content: '被盗内容',
    report_false_information: '虚假信息',
    report_other: '其他',

    // Report not working reasons
    report_crash: '游戏崩溃',
    report_no_load: '不加载',
    report_conflicts: '与其他 {ugc} 冲突',
    report_missing_deps: '缺少依赖项',
    report_install_issues: '安装问题',
    report_bugs: '越野车行为',
    report_incompatability: '与游戏版本不兼容',
    report_corrupt: '文件损坏',

    // Errors
    error_ref: '错误代码： ',
    login_fail: '登录失败',
    login_fail_message: '登录失败。请等待一分钟，然后重试。',
    subscribe_fail: '未能订阅',
    unsubscribe_fail: '未能取消订阅',
    error: '错误',
    default_error_message: '发生错误，请尽快重试。',
    follow_error: '未能关注',
    unfollow_error: '取消关注失败',

    agreement_not_found: '未找到协议',
    form_chars_remaining: '{count}剩下的字符。',
    form_not_enough_chars: '{count}需要更多字符。',
    filter: '筛选',
    loading: '加载',
    next_version: '下一个版本',
    no_results_found: '未找到结果',
    page_not_found: '找不到网页！',
    previous_version: '以前的版本',
    take_me_home: '带我回家',
    validation_alpha_num: '字段只能包含数字或字母',
    validation_size: '字段必须为{size}字符',
    validation_url: '字段必须是一个URL',
    validation_min: '最少 {min} 个字符',
    validation_max: '最大{max}字符',
    validation_email: '字段必须是一封电子邮件',
    validation_checked: '必须检查字段',
    validation_required: '需要字段',
    validation_name: '字段只能包含字母和与名称相关的符号',
    validation_image: '文件必须是有效的 jpeg 或 png 图片',
    validation_youtube: '字段必须是有效的 Youtube 网址',
    validation_sketchfab: '字段必须是有效的 Sketchfab 网址',
    validation_zip: '文件必须是 ZIP',
    validation_filesize: '文件大小不得超过 {size}',

    comment: '评论',
    team_member: '队员',
    author: '作者',
    good_karma: '顶',
    bad_karma: '踩',
    reply: '回复',
    replies: '回复|回复',
    save: '保存',
    delete: '删除',
    edit: '编辑',
    post: '发布',
    pinned: '已固定',
    pin: '别针',
    unpin: '取消固定',
    locked: '锁定',
    lock: '锁',
    unlock: '解锁',
    add_comment: '添加评论...',
    comment_cant_reply_locked: '无法回复，因为评论话题已锁定',
    comment_cant_edit_locked: '无法编辑，因为评论话题已被锁定',
    comment_max_pin_limit: '已达到最大置顶评论上限',
    comment_below_threshold: '评论低于阈值。点击查看。',
    comment_login_prompt: '登录以加入讨论！',
    comment_error_add: '未能添加评论',
    comment_error_edit: '未能编辑评论',
    comment_error_delete: '无法删除评论',
    comment_error_rate: '未能对评论进行评级',
    comment_error_update: '更新评论失败',
    comments_error_fetch: '无法加载更多评论',
    discussion: '讨论',
    load_more_comments: '加载更多评论',
    comment_confirm_delete: '你确定要删除此评论吗？',
    comment_deleted: '评论已成功删除',

    // Mod admin
    mod_profile: '{ugc} 个人资料',
    media: '媒体',
    file_manager: '文件管理器',
    step: '第{step}步',
    save_next: '保存并下一步',
    auth_required: '需要进行身份验证',
    tools_only_1:
      '你不能在这里上传 {game} 的 {ugc}，它们必须使用游戏开发者创建的工具上传，这样文件才能得到验证。有关更多信息，请参阅为该游戏编写的 {guides}。',
    tools_only_2: '以下是游戏开发者提供的说明：',
    tools_only_3:
      '我们建议您返回 {ugc} 列表并从那里浏览链接。如果问题仍然存在，请前往我们的 {help_center} 获取更多信息。',
    unsaved_heading: '您有未保存的更改',
    unsaved_confirm: '有些更改尚未保存，你还想离开吗？',

    headline_mod_profile: '创建您的 {ugc} 个人资料',
    headline_media: '将媒体添加到您的 {ugc} 个人资料',
    headline_files: '上传您的 {ugc} 文件',
    headline_permissions: '为您的 {ugc} 设置重用权限',
    headline_dependencies: '添加任何 {ugc} 依赖项',

    // Basics
    basics: '基础知识',
    logo: '徽标',
    logo_prompt_1: '将代表您的 {ugc} 徽标的图片文件。',
    logo_prompt_2: '512x288 或更大',
    logo_prompt_3: '建议纵横比 16:9',
    logo_prompt_4: '最大 8MB',
    summary_placeholder: '在 1 段中告诉我们您的 {ugc} 对游戏所做的更改。',
    homepage: '主页',
    profile_url: '个人资料网址',
    visibility: '能见度',
    visibility_tip:
      '上线后，所有人都可以看到公开 {ugc}。隐藏的 {ugc} 仅对团队成员和订阅者可见。',
    upload_images: '上传图片',
    mature_content: '包含成人内容',
    mature_content_alcohol: '酒精',
    mature_content_drugs: '毒品',
    mature_content_violence: '暴力',
    mature_content_explicit: '明确的',
    community: '社区',
    allow_comments: '允许评论',
    allow_dependants: '允许受抚养人',
    create_mod: '创建 {ugc}',
    error_mod_create: '创建 {ugc} 失败',
    error_mod_update: '更新 {ugc} 失败',
    error_tags_update: '更新标签失败',
    success_create_mod: '成功创建 {modname}',
    success_update_mod: '成功更新 {modname}',
    description_tip:
      '您的 {ugc} 的可选描述，其中可能包括 “关于”、“功能”、“用法”、“常见问题” 等详细信息。支持和鼓励 HTML',

    // Media
    models: '模型',
    media_zip_hint_1:
      '上传最多 {count} 张图片或单个 zip 图像存档以添加到图库中。',
    media_zip_hint_2: '只有压缩文件中有效的 jpg 和 png 图像才会被处理。',
    error_media_update: '更新媒体失败',
    error_media_add_links: '添加媒体链接失败',
    error_media_remove_links: '删除媒体链接失败',
    success_media_updated: '成功更新媒体',

    // File manager
    add_file: '添加一个文件',
    edit_file: '编辑文件',
    select_zip: '选择 zip 文件',
    upload: '上传',
    file_id: '文件编号：',
    virus_none: '未检测到病毒',
    virus_waiting: '等待扫描',
    virus_scanning: '正在扫描',
    virus_too_large: '文件太大而无法扫描',
    virus_not_found: '文件未找到',
    virus_error: '扫描文件时出错',
    virus_found: '检测到病毒',
    virus_risk: '此文件包含可执行文件，打开时应小心',
    version_placeholder: '版本号，即 1.0.0',
    release_notes_placeholder: '在单独的行中输入每项更改',
    game_moderator: '游戏主持人',
    metadata_blob: '元数据 blob',
    metadata_blob_placeholder:
      '输入特定的元数据，例如此版本兼容的游戏版本、如何安装等。支持 UTF-8 有效数据。',
    select_platforms: '平台（选择所有适用的选项）',
    file_pending: '待批准',
    file_approved: '已批准',
    file_approved_live: '已获批准并上线',
    file_approved_not_live: '已批准但未上线',
    file_denied: '已拒绝',
    file_targeted: '有针对性',
    live_file: '此文件当前处于活动状态。',
    file_is_live: '文件已上线',
    make_file_live: '将此版本上线',
    make_file_live_platforms: '在经批准的平台上发布此版本',
    file_processing:
      '您的文件正在处理中。您可以继续浏览该站点，但请不要关闭选项卡或浏览器窗口，直到它完成。',
    file_instructions_1:
      '压缩并上传您的 {ugc} 的基本文件夹，或者如果它是存在于现有游戏文件夹中的文件集合，则应压缩这些文件：',
    file_instructions_2: '必须是 ZIP 文件',
    file_instructions_3: '文件大小不能超过 20 GB',
    file_instructions_4:
      '文件名不能包含以下任何字符：\\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5: '除非游戏对此进行管理，否则不支持覆盖文件的 {ugc}',
    file_instructions_6:
      '除非游戏对其进行管理，否则不支持跨越多个游戏目录的 {ugc}',
    file_terms: '我同意 {terms} 和 {privacy}。',
    error_get_files: '无法获取文件',
    error_upload_file: '上传文件失败',
    error_update_file: '更新文件失败',
    error_file_required: '文件为必填项',
    error_platform_required: '必须选择至少一个平台',
    success_file_uploaded: '文件已成功上传',
    success_file_moderation: '版主将审查需要审核的平台',
    success_file_updated: '文件已成功更新',
    error_no_approved_platforms: '没有平台可以上线',
    error_no_approved_platforms_text: '此文件没有经批准且当前未上线的平台',

    // Reuse permissions form
    reuse_permissions: {
      heading: '重用权限',
      allow_redistribution:
        '允许其他人将我的 {ugc} 资产上传到其他网站，但我必须记入原始创作者的身份',
      allow_porting:
        '允许其他人将我的 {ugc} 资产转换为在其他游戏中使用，但我必须获得原始创作者的资格',
      allow_patching_no_credit:
        '允许其他人修改我的 {ugc} 资产（包括发布错误修复或改进）并将其作为新的 {ugc} 发布',
      require_credit: '我必须被认定为原创作者',
      require_permission: '首先需要我的许可',
      require_repackage_no_credit: '允许其他人在自己的 {1} 中使用我的 {0} 资产',
      allow_resell:
        '允许其他人在任何商业作品的任何 {ugc} /文件中使用我的 {ugc} 资产',
      original_assets: '我的 {ugc} 包含 100% 的原创作品或可以重新分配的资产',
      error_update: '更新重用权限失败',
      success_update: '成功更新了重用权限',
      prompt:
        '设置如何使用您的 {ugc} 资产创建他人内容的权限。授予他人使用或修改您的文件的权限，即表示您承认无法追溯撤销对已创作品的此类授权。',
    },

    // Dependencies form
    dependencies_prompt:
      '为此 {1} 选择所需的 {0} 才能正常运行（如果未显示 {1}，则表示它不允许依赖关系，您应联系创建者将其打开）：',
    remove_dependency: '移除依赖关系',
    dependants: '受抚养人',
    error_update_dependencies: '更新依赖关系失败',
    success_update_dependencies: '成功更新依赖项设置',
    dependants_warning_1: '一旦允许，请注意，连接了受抚养人的 {ugc}：',
    dependants_warning_2: '无法禁用受抚养人',
    dependants_warning_3: '无法删除',
    dependants_warning_4: '无法获利',
    dependants_confirm_enable:
      '这是为了确保连接的 {ugc} 的持续兼容性。请确认启用。',
    dependants_confirm_disable:
      '你确定要禁止受抚养人吗？这意味着其他 {0} 将无法将您的 {1} 添加为依赖关系。',
  },
  'zh-TW': {
    language: '語言',

    // Nav
    browse: '瀏覽',
    library: '圖書館',
    account: '我的賬戶',
    back: '返回',
    more: '更多的',
    add_mod: '添加 {ugc}',
    edit_mod: '在 {模組 .io} 上進行編輯',
    help_center: '幫助中心',

    // Guides
    guide: '指南|指南',
    views: '意見',
    table_of_contents: '目錄',
    reading_time: '閱讀時間',
    date_added: '新增日期',
    date_updated: '更新日期',
    minutes_read: '閱讀分鐘數',
    published: '已發表',

    // Users
    joined: '已加入',
    last_online: '最後在線時間',
    online: '在線的',
    followers: '追隨者',
    partner: '夥伴',
    profile_hidden: '該用戶的個人資料已隱藏',
    follow: '跟隨',
    unfollow: '取消追蹤',

    // Mods
    mod: '模組',
    level: '水平|等級',
    content: '內容',
    subscribed: '已訂閱',
    tags: '標籤',
    summary: '摘要',
    contributors: '作者',
    description: '說明',
    dependencies: '依賴項',
    of: '/',
    ago: '前',
    and: '和',
    by: '經過',
    reset_filters: '重置過濾器',
    show_more: '展示更多',
    show_less: '顯示較少',
    no_ugc_found: '未找到 {ugc_name}',
    no_subscriptions: '你還沒有訂閱任何 {ugc_name}',
    mod_disclaimer_heading: '法律免責聲明',
    mod_disclaimer_privacy: '隱私權政策',
    thumbs_up_received: '已收到豎起大拇指',
    thumbs_up: '豎起大拇指',
    total_downloads: '總下載量',
    releases: '發布',
    bytes: '位元組',

    // Actions
    login: '登入',
    logout: '登出',
    search: '搜尋',
    clear_all: '全部清除',
    download: '下載',
    subscribe: '訂閱',
    unsubscribe: '取消訂閱',
    resubscribe: '重新訂閱',
    activate: '啟用',
    deactivate: '停用',
    like: '喜歡',
    dislike: '不喜歡',
    report: '報告一個問題',
    submit: '提交',
    cancel: '取消',
    select: '選擇',
    previous: '上一頁',
    next: '下一頁',
    confirm: '確認',
    allow: '允許',

    // Filters
    all: '全部',
    available: '可用',
    filesize: '檔案大小',
    downloads: '下載次數',
    todays_downloads: '本日下載次數',
    subscribers: '訂閱者',
    rating: '評分',
    sort_by: '排序方式',
    creator: '建立者',
    apply: '申請',
    reset: '重置',
    featured: '特色',
    toggle_filters: '切換過濾器',
    hidden: '隱',
    public: '公眾',
    pending: '待辦的',
    deleted: '已刪除',
    last_day: '最後一天',
    last_week: '上個星期',
    last_month: '上個月',
    last_3_months: '過去 3 個月',
    last_year: '去年',

    // Login
    signup: '以電子郵件登入或註冊',
    login_with: '使用 {service} 登入',
    email_placeholder: '電子郵件地址',
    code_placeholder: '5 位代碼',
    have_code: '我有代碼',
    email_code: '透過電子郵件傳送代碼',
    send_new_code: '傳送新代碼',
    check_email: '請檢查你電子郵件中的代碼，並在此處輸入。',
    login_disclaimer: '使用者為「{game}」產生的內容由 mod.io 管理。',
    terms_disclaimer: `按下「登入」即表示您同意 {studioterms}mod.io 的{terms}和{privacy}，並確認您已年滿 {min_age} 歲。`,
    terms_disclaimer_studio: '{studio} 的{terms}、',
    terms_disclaimer_studio_separator: '、',
    terms_disclaimer_studio_and: '和',
    code_error: '無法要求代碼。',
    or: '或者',
    login_prompt: '您嘗試造訪的頁面需要身份驗證。請登入以繼續。',

    // Terms
    terms: '使用條款',
    privacy: '隱私權政策',
    aup: '可接受的使用政策',
    monetization: '獲利條款',
    game: '遊戲條款',
    api: 'API存取條款',
    effective: '生效日期：',

    // Report
    report_heading: '檢舉此 mod',
    report_intro:
      '您可以報告內容問題、違反 mod.io {terms} 的行為或使用下面的表單提交 {dmca}。',
    report_details:
      '確保包含所有相關信息，以促進報告的解決。請注意，此資訊將與遊戲管理員分享，並可能與發布涉嫌侵權內容的使用者共用。',
    report_not_working_prompt: '「不起作用」報告將直接與內容創作者分享。',
    dmca: 'DMCA 投訴',
    reason: '檢舉的原因',
    urls: '違規網址',
    urls_placeholder: '網址 (每行 1 個)',
    company_or_name: '公司或你的姓名',
    email: '電子郵件',
    address: '地址',
    country: '國家/地區',
    country_placeholder: '選取國家/地區',
    details: '侵權或違規的細節',
    details_placeholder:
      '為了幫助解決您的報告，請提供盡可能多的詳細資訊和背景。',
    dmca_1:
      '{mod_io} 尊重他人的智慧財產權，也要求每個使用我們網站和服務的人也這麼做。如果有人認為自己的作品在我們的網站或服務中遭到複製，從而構成著作權侵犯，請使用下方的表格來通知 mod.io 的著作權代理人。',
    dmca_2:
      '提出侵犯著作權的申訴是一件嚴肅的法律事務。在你繼續之前，或許可以考慮直接聯繫涉及此事的個人來解決問題。這可能只是一個簡單的誤會，或許不用牽涉到正式的法律程序也能處理。',
    dmca_3:
      '請注意，根據數位千禧年著作權法案第 512(f) 款，如有任何人明知不實仍陳述某些材料或活動侵犯著作權，均可能承擔損害賠償責任。另外，請注意，這份法律通知中提供的資訊可能會與第三方分享或公開。',
    claim_check: '透過勾選以下方塊並提交本申訴，我依法據實陳述',
    copyright_owner: '我是著作權所有人',
    copyright_auth_owner: '在此種情況下，我有權代表著作權所有人行事。',
    authorization: '授權',
    authorization_check:
      '我有正當理由相信，本申訴所指材料之使用，未經著作權所有人、其代理人或法律授權。',
    acknowledgement: '知悉書',
    acknowledgement_check:
      '我知悉，根據數位千禧年著作權法案第 512(f) 款，任何明知不實仍陳述某些材料或活動侵犯著作權者，均可能承擔損害賠償責任。',
    required: '必填',
    signature: '簽名',
    signature_placeholder: '在此框中鍵入你的全名，作為你的數位簽名。',
    report_success_title: '你的檢舉已送達',
    report_success_text:
      '如果你的訊息需要回覆，請耐心等待。我們會努力在收到檢舉後 48 小時內回覆。',
    report_error_title: '提交檢舉失敗',

    // Table
    name: '名稱',
    added: '建立時間',
    updated: '更新時間',
    file: '檔案',
    version: '版本',
    scan: '病毒掃描',
    actions: '動作',
    locally_installed: '已安裝於本機',

    // Releases
    download_from_game_client: '從遊戲用戶端下載 {mod}',
    release_notes: '版本說明',
    no_release_notes: '未提供版本說明',
    previous_releases: '之前的版本',
    view_previous_releases: '查看先前的版本',
    no_previous_releases: '沒有之前的版本',
    no_files_published: '未發佈檔案',
    download_files_manually: '手動下載文件',
    platforms: '平台 | 平台',

    // Ratings
    overwhelmingly_positive: '壓倒性好評',
    overwhelmingly_negative: '壓倒性負評',
    very_positive: '非常多好評',
    very_negative: '非常多負評',
    mostly_positive: '多數好評',
    mostly_negative: '多數負評',
    negative: '負評',
    positive: '好評',
    mixed: '褒貶不一',
    unrated: '未評分',

    // Sort
    sort_comments: '評論',
    sort_total_mods: '模組總數',
    sort_trending: '熱門',
    sort_highest_rated: '收視率最高',
    sort_most_popular: '最受歡迎',
    sort_most_subscribers: '大多數訂閱者',
    sort_recently_added: '最近添加的',
    sort_last_updated: '最近更新時間',
    sort_alphabetical: '按字母順序',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '不工作',
    report_rude_content: '粗魯的內容',
    report_illegal_content: '非法內容',
    report_stolen_content: '被盜內容',
    report_false_information: '虛假信息',
    report_other: '其他',

    // Report not working reasons
    report_crash: '遊戲崩潰',
    report_no_load: '不加載',
    report_conflicts: '與其他 {ugc} 衝突',
    report_missing_deps: '缺少依賴項',
    report_install_issues: '安裝問題',
    report_bugs: '越野車行為',
    report_incompatability: '與遊戲版本不相容',
    report_corrupt: '文件損壞',

    // Errors
    error_ref: '錯誤代碼： ',
    login_fail: '登錄失敗',
    login_fail_message: '登錄失敗。請等待一分鐘，然後重試。',
    subscribe_fail: '未能訂閱',
    unsubscribe_fail: '未能取消訂閱',
    error: '錯誤',
    default_error_message: '發生錯誤，請盡快重試。',
    follow_error: '未能關注',
    unfollow_error: '取消追蹤失敗',

    agreement_not_found: '未找到協議',
    form_chars_remaining: '{count}剩下的字符。',
    form_not_enough_chars: '{count}需要更多字符。',
    filter: '篩選',
    loading: '加載',
    next_version: '下一個版本',
    no_results_found: '未找到結果',
    page_not_found: '找不到網頁！',
    previous_version: '以前的版本',
    take_me_home: '帶我回家',
    validation_alpha_num: '字段只能包含數字或字母',
    validation_size: '字段必須為{size}字符',
    validation_url: '字段必須是一個URL',
    validation_min: '最少 {min} 個字符',
    validation_max: '最大{max}字符',
    validation_email: '字段必須是一封電子郵件',
    validation_checked: '必須檢查字段',
    validation_required: '需要字段',
    validation_name: '欄位只能包含字母和與名稱相關的符號',
    validation_image: '文件必須是有效的 jpeg 或 png 圖像',
    validation_youtube: '欄位必須是有效的 Youtube 網址',
    validation_sketchfab: '欄位必須是有效的草圖構造網址',
    validation_zip: '檔案必須是 ZIP',
    validation_filesize: '檔案大小不得超過 {size}',

    comment: '評論',
    team_member: '隊員',
    author: '作者',
    good_karma: 'ding',
    bad_karma: '踩',
    reply: '回覆',
    replies: '回覆|回覆',
    save: '保存',
    delete: '刪除',
    edit: '編輯',
    post: '發佈',
    pinned: '已固定',
    pin: '引腳',
    unpin: '解鎖',
    locked: '鎖定',
    lock: '鎖',
    unlock: '解鎖',
    add_comment: '添加評論...',
    comment_cant_reply_locked: '因為評論主題被鎖定，無法回复',
    comment_cant_edit_locked: '由於評論線程被鎖定，無法編輯',
    comment_max_pin_limit: '已達到最大固定留言限制',
    comment_below_threshold: '在臨界值以下的評論。按一下以檢視。',
    comment_login_prompt: '登錄以加入討論！',
    comment_error_add: '未能添加評論',
    comment_error_edit: '未能編輯評論',
    comment_error_delete: '無法刪除評論',
    comment_error_rate: '評分評論失敗',
    comment_error_update: '更新註解失敗',
    comments_error_fetch: '無法加載更多評論',
    discussion: '討論',
    load_more_comments: '加載更多評論',
    comment_confirm_delete: '你確定要刪除此評論嗎？',
    comment_deleted: '評論已成功刪除',

    // Mod admin
    mod_profile: '{ugc} 個人資料',
    media: '媒體',
    file_manager: '文件管理器',
    step: '第{step}步',
    save_next: '儲存與下一步',
    auth_required: '需要驗證',
    tools_only_1:
      '您無法在這裡上傳 {game} 的 {ugc}，它們必須使用遊戲開發人員創建的工具上傳，以便可以驗證文件。有關更多信息，請參閱為此遊戲撰寫的 {guides}。',
    tools_only_2: '以下是遊戲開發人員提供的說明：',
    tools_only_3:
      '我們建議您返回 {ugc} 列表並從那裡瀏覽鏈接。如果問題仍然存在，請前往我們的 {help_center} 以獲取更多信息。',
    unsaved_heading: '您有未儲存的變更',
    unsaved_confirm: '有些更改尚未保存，您還想離開嗎？',

    headline_mod_profile: '建立您的 {ugc} 個人資料',
    headline_media: '將媒體新增至您的 {ugc} 個人資料',
    headline_files: '上傳您的 {ugc} 文件',
    headline_permissions: '為您的 {ugc} 設定重複使用權限',
    headline_dependencies: '新增任何 {ugc} 依賴項',

    // Basics
    basics: '基礎知識',
    logo: '標誌',
    logo_prompt_1: '將代表您的 {ugc} 標誌的圖像文件。',
    logo_prompt_2: '512x288 或更大',
    logo_prompt_3: '建議 16:9 寬高比',
    logo_prompt_4: '最大 8 MB',
    summary_placeholder: '在 1 段中告訴我們您的 {ugc} 對遊戲所做的更改。',
    homepage: '主頁',
    profile_url: '個人資料網址',
    visibility: '能見度',
    visibility_tip:
      '一旦上線，所有人都可以看到公共 {ugc}。隱藏的 {ugc} 僅對團隊成員和訂閱者看到。',
    upload_images: '上傳圖片',
    mature_content: '包含成人內容',
    mature_content_alcohol: '酒精',
    mature_content_drugs: '藥物',
    mature_content_violence: '暴力',
    mature_content_explicit: '明確',
    community: '社區',
    allow_comments: '允許留言',
    allow_dependants: '允許受養人',
    create_mod: '建立 {ugc}',
    error_mod_create: '無法建立 {ugc}',
    error_mod_update: '無法更新 {ugc}',
    error_tags_update: '更新標籤失敗',
    success_create_mod: '成功建立 {modname}',
    success_update_mod: '已成功更新 {模式名稱}',
    description_tip:
      '您的 {ugc} 的可選描述，其中可包含「關於」，「功能」，「使用情況」，「常見問題」等詳細信息。支持和鼓勵 HTML',

    // Media
    models: '模型',
    media_zip_hint_1:
      '上傳最多 {count} 張圖像或單個壓縮圖像存檔以添加到圖庫中。',
    media_zip_hint_2: '只會處理 zip 文件中的有效的 jpg 和 png 圖像。',
    error_media_update: '無法更新媒體',
    error_media_add_links: '無法新增媒體連結',
    error_media_remove_links: '無法移除媒體連結',
    success_media_updated: '已成功更新媒體',

    // File manager
    add_file: '新增檔案',
    edit_file: '編輯檔案',
    select_zip: '選擇 zip 文件',
    upload: '上傳',
    file_id: '檔案識別碼：',
    virus_none: '未偵測到任何病毒',
    virus_waiting: '等待掃描',
    virus_scanning: '掃描進行中',
    virus_too_large: '檔案太大無法掃描',
    virus_not_found: '找不到檔案',
    virus_error: '掃描檔案時發生錯誤',
    virus_found: '偵測到病毒',
    virus_risk: '此文件包含可執行文件，打開時應注意',
    version_placeholder: '版本號碼，即 1.0.0',
    release_notes_placeholder: '在單獨的行上輸入每個變更',
    game_moderator: '遊戲主持人',
    metadata_blob: '中繼資料區塊',
    metadata_blob_placeholder:
      '輸入特定的元數據，例如此版本相容的遊戲版本，如何安裝它等。支持 UTF-8 有效數據。',
    select_platforms: '平台（選擇所有適用的）',
    file_pending: '等待核准',
    file_approved: '已核准',
    file_approved_live: '已核准並現場',
    file_approved_not_live: '已核准但未實時',
    file_denied: '拒絕',
    file_targeted: '針對性',
    live_file: '此檔案目前正在執行中。',
    file_is_live: '檔案已上線',
    make_file_live: '讓這個發行版本進行',
    make_file_live_platforms: '將此版本推出適用於已核准的平台',
    file_processing:
      '您的檔案正在處理中。您可以繼續瀏覽網站，但在完成之前請勿關閉標籤或瀏覽器窗口。',
    file_instructions_1:
      '壓縮並上傳 {ugc} 的基本文件夾，或者如果它是存在於先前存在的遊戲文件夾中的文件夾中的文件集合，則應將這些文件壓縮這些文件：',
    file_instructions_2: '必須是一個 ZIP 文件',
    file_instructions_3: '檔案大小不能超過 20GB',
    file_instructions_4:
      '檔案名稱不能包含下列任何字元：\\ / ? " &lt; &gt; &#124; : *',
    file_instructions_5: '除非遊戲管理此操作，否則不支持覆寫文件的 {ugc}',
    file_instructions_6: '除非遊戲管理此操作，否則不支持跨多個遊戲目錄的 {ugc}',
    file_terms: '我同意 {terms} 和 {privacy}。',
    error_get_files: '無法取得檔案',
    error_upload_file: '無法上傳檔案',
    error_update_file: '無法更新檔案',
    error_file_required: '文件是必需的',
    error_platform_required: '您必須至少選擇一個平台',
    success_file_uploaded: '檔案上傳成功',
    success_file_moderation: '主持人將審核需要審核的平台',
    success_file_updated: '檔案已成功更新',
    error_no_approved_platforms: '沒有平台可以直播',
    error_no_approved_platforms_text: '此文件沒有目前未正在使用的核准平台',

    // Reuse permissions form
    reuse_permissions: {
      heading: '重複使用權限',
      allow_redistribution:
        '允許其他人將我的 {ugc} 資產上傳到其他網站，但我必須被認為是原始創作者',
      allow_porting:
        '允許其他人將我的 {ugc} 資產轉換為其他遊戲中使用，但我必須被認為是原始創作者',
      allow_patching_no_credit:
        '允許其他人修改我的 {ugc} 資產（包括發布錯誤修復或改進）並將其發布為新的 {ugc}',
      require_credit: '我必須被認為是原始創作者',
      require_permission: '首先要求我的許可',
      require_repackage_no_credit: '允許其他人在自己的 {1} 中使用我的 {0} 資產',
      allow_resell:
        '允許其他人在任何商業作品中使用我的 {ugc} 資產中的任何 {ugc}/文件中',
      original_assets: '我的 {ugc} 包含 100％ 的原始作品，或可以重新分發的資產',
      error_update: '無法更新重複使用權限',
      success_update: '成功更新重複使用權限',
      prompt:
        '設定您的 {ugc} 資產如何用於其他人內容創建的權限。授予其他人使用或修改您的文件的權限，即表示您確認對已創建的作品無法回溯撤銷該等授權。',
    },

    // Dependencies form
    dependencies_prompt:
      '選擇所需的 {0} 才能正確運作的 {1}（如果沒有顯示 {1}，這意味著它不允許依賴關係，您應該聯繫創建者以打開它）：',
    remove_dependency: '移除相依性',
    dependants: '受養人',
    error_update_dependencies: '無法更新相依性',
    success_update_dependencies: '成功更新相依性設定',
    dependants_warning_1: '一旦允許，請注意，{ugc} 與家屬相關聯：',
    dependants_warning_2: '無法停用受屬人',
    dependants_warning_3: '無法刪除',
    dependants_warning_4: '無法獲利',
    dependants_confirm_enable:
      '這是為了確保連接的 {ugc} 的持續兼容性。請確認以啟用。',
    dependants_confirm_disable:
      '您確定要禁止受養人？這意味著其他 {0} 將無法將您的 {1} 添加為依賴項。',
  },
}
