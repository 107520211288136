import { useRoute, useModal } from '@composables'
import { onBeforeRouteLeave } from 'vue-router'
import { watch, ref } from 'vue'

export default function (updateTab, cb) {
  const { hideModal, isModalOpen, showModal } = useModal()

  const { push } = useRoute()
  const toRoute = ref(null)
  const dirty = ref(false)
  const toTab = ref(null)

  function handleTabUpdate(tabValue) {
    if (dirty.value) {
      showModal()
      toTab.value = tabValue
    } else {
      updateTab(tabValue)
    }
  }

  onBeforeRouteLeave((to, from, next) => {
    if (dirty.value) {
      toRoute.value = to
      showModal()
      next(false)
    } else {
      next()
    }
  })

  watch(dirty, (value) => {
    if (value) {
      onbeforeunload = (e) => e.preventDefault()
    } else {
      onbeforeunload = null
    }
  })

  function confirmDiscardChanges() {
    dirty.value = false
    hideModal()
    if (cb) {
      cb()
    }

    if (toRoute.value) push(toRoute.value)
    if (toTab.value) updateTab(toTab.value)
  }

  return {
    isUnsavedModalOpen: isModalOpen,
    hideUnsavedModal: hideModal,
    confirmDiscardChanges,
    handleTabUpdate,
    dirty,
  }
}
