import { isNumber, clone } from '@helpers/utils.js'
import { computed, ref } from 'vue'
import {
  PAGINATION_LIMIT_OPTIONS,
  PAGINATION_DEFAULT_TOTAL,
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_PAGE,
} from '@config/options.js'
const state = ref({})

export default function (
  id,
  { offset = null, page = null, limit = null, simple = false } = {}
) {
  if (!id) {
    throw new Error('usePagination requires id')
  }

  if (!state.value[id]) {
    resetPagination()
  }

  if (offset && !page) {
    page = offset / limit + 1
    setPagination({ page, limit, simple })
  } else if (page) {
    setPagination({ page, limit, simple })
  } else if (limit) {
    setPagination({ limit, simple })
  } else if (simple) {
    setPagination({ simple })
  }

  const totalPages = computed(() =>
    Math.ceil(state.value[id].total / state.value[id].limit)
  )

  const pagination = computed(() => {
    if (!state.value[id]) {
      setPagination({ page, limit })
    }
    return state.value[id]
  })

  const getPagination = computed(() => ({
    _limit: state.value[id].limit,
    _offset: calculateOffset(),
  }))

  const getLimit = computed(() => getPagination.value._limit)

  function calculateOffset() {
    const page =
      state.value[id] && state.value[id].page === 0
        ? state.value[id].page
        : state.value[id].page - 1

    return state.value[id].limit * page
  }

  const canNext = computed(
    () => state.value[id].hasNextPage || state.value[id].page < totalPages.value
  )

  const canPrev = computed(() => state.value[id].page > 1)

  function next() {
    if (!canNext.value) return false

    setPagination({ page: state.value[id].page + 1 })
    return true
  }

  function prev() {
    if (!canPrev.value) return false

    setPagination({ page: state.value[id].page - 1 })
    return true
  }

  function inMemoryPagination(data, cloneData = true) {
    setPagination({ total: data.length })
    return _inMemoryPaginate(data, cloneData)
  }

  function resetPagination() {
    const simple = !!state.value[id]?.simple
    state.value[id] = {
      page: PAGINATION_DEFAULT_PAGE,
      total: PAGINATION_DEFAULT_TOTAL,
      limit: simple ? state.value[id]?.limit : PAGINATION_DEFAULT_LIMIT,
      simple,
    }
  }

  function setPagination({ page, limit, total, simple, hasNextPage } = {}) {
    state.value[id] = _createPagination({
      page: page ? parseInt(page) : null,
      limit: limit ? parseInt(limit) : null,
      total: isNumber(total) ? parseInt(total) : null,
      simple,
      hasNextPage,
    })
  }

  function _inMemoryPaginate(data, cloneData) {
    const resultsCopy = cloneData ? clone(data) : data
    const offset = calculateOffset()
    return resultsCopy.slice(calculateOffset(), state.value[id].limit + offset)
  }

  function _createPagination({
    page = null,
    limit = null,
    total = null,
    simple = false,
    hasNextPage,
  } = {}) {
    page = page || state.value[id]?.page || PAGINATION_DEFAULT_PAGE

    total =
      total || total === 0
        ? total
        : state.value[id]?.total || PAGINATION_DEFAULT_TOTAL

    limit = limit || state.value[id]?.limit || PAGINATION_DEFAULT_LIMIT

    simple = state.value[id]?.simple || simple

    hasNextPage = hasNextPage ?? state.value[id]?.hasNextPage

    const newPageCount = Math.ceil(total / limit)

    if (!simple && newPageCount < page && total !== 0) {
      page = newPageCount
    }

    return {
      hasNextPage,
      simple,
      total,
      limit,
      page,
    }
  }

  return {
    limitOptions: PAGINATION_LIMIT_OPTIONS,
    inMemoryPagination,
    calculateOffset,
    resetPagination,
    setPagination,
    getPagination,
    pagination,
    totalPages,
    getLimit,
    canNext,
    canPrev,
    next,
    prev,
  }
}
