import FontAwesomeIcon from '@plugins/fontawesome.js'
import storeDevTools from '@plugins/storeDevTools.js'
import directives from '@plugins/directives.js'
import App from '@views/layouts/ModioApp.vue'
import i18n from '@plugins/localisation.js'
import { createApp } from 'vue'
import router from '@router'

// style imports
import './css/app.css'
const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(storeDevTools)

// global error handler
app.config.errorHandler = function (err) {
  console.error(err)
}
/**
 * Autoloads components:
 * Base components
 */
const files = require.context('./components/Base', true, /\.vue$/i)
files
  .keys()
  .map((key) =>
    app.component(key.split('/').pop().split('.')[0], files(key).default)
  )

const sharedFiles = require.context(
  '../shared/components/Base',
  true,
  /\.vue$/i
)
sharedFiles
  .keys()
  .map((key) =>
    app.component(key.split('/').pop().split('.')[0], sharedFiles(key).default)
  )

app.component('FontAwesomeIcon', FontAwesomeIcon)

directives(app)

router.isReady().then(() => app.mount('#app'))
