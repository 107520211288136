import { checkMonTokenTs } from '@helpers/monetizationUtils.js'
import { requestAll } from '@helpers/requestAll.js'
import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'
import qs from 'qs'
import {
  PRIVATE_HIDDEN_BASE_URL,
  MONETIZATION_BASE_URL,
  PUBLIC_BASE_URL,
} from '@config'

const { get, post, put, del } = createClient()

/* API request to get a ui session from the api used for tilia widget */
export async function getSessionRequest(code, gameId) {
  let query = {}

  if (code) {
    query = {
      scope_type: 'PAYOUT',
      code,
    }

    if (gameId) query.game_id = gameId
  }

  const { data } = await post('/me/session', qs.stringify(query), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return data
}

/* API request to get the users onboarding status */
export async function getUsersStatus() {
  const { data } = await get('/me/status')
  return data
}

/* API request to get the users purchased mods */
export function getMyPurchasesRequest() {
  return requestAll(`${PUBLIC_BASE_URL}/me/purchased`)
}

/* API request to get a ui authentication from the api used to interact with monetization api */
export async function getAuthenticateRequest(gameId, modId) {
  let query = {}

  if (modId) query.mod_id = modId
  if (gameId && !modId) query.game_id = gameId

  const { data } = await post('/me/authenticate', qs.stringify(query), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return data
}

/* API request to register a user for onboarding via the api */
export async function registerRequest() {
  const { data } = await post('/me/register', null, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return data
}

/* API request to checkout marketplace via the api */
export async function checkoutRequest(update) {
  const { data } = await post('/checkout', qs.stringify(update))
  return data
}

/* API request to commit the checkout via the api */
export async function checkoutCompleteRequest(update) {
  const { data } = await post('/checkout/complete', qs.stringify(update))
  return data
}

/* API request to create a buyer's wallet for a game via the api */
export async function createBuyerGameWalletRequest(gameId) {
  const { data } = await post(
    '/me/wallets',
    qs.stringify({ type: 'buyer', game_id: gameId })
  )
  return data
}

/* API request to create a user's wallet for onboarding a game via the api */
export async function createGameWalletRequest(gameId) {
  const { data } = await post(
    '/me/wallets',
    qs.stringify({ type: 'game', game_id: gameId })
  )
  return data
}

/* API request to create a creator's wallet via the api */
export async function createCreatorWalletsRequest() {
  const { data } = await post('/me/wallets', qs.stringify({ type: 'member' }))
  return data
}

/* API request to register a user to monetization via the api as a mod/game admin */
export async function modRegisterUserRequest(gameNameId, modNameId, userId) {
  const { data } = await post(
    `/games/@${gameNameId}/mods/@${modNameId}/users/${userId}/register`,
    qs.stringify({})
  )
  return data
}

/* API request to create a creator's wallet via the api as a mod/game admin */
export async function createModWalletsRequest(gameNameId, modNameId, userId) {
  const { data } = await post(
    `/games/@${gameNameId}/mods/@${modNameId}/users/${userId}/wallets`,
    qs.stringify({})
  )
  return data
}

/* API request to get the users profile refund status */
export async function getAccountRefundStatusRequest(gameId, userId, query) {
  const user = Number.isInteger(userId) ? userId : `@${userId}`
  const game = Number.isInteger(gameId) ? gameId : `@${gameId}`

  const { data } = await get(
    `/games/${game}/users/${user}/profile${queryString(query)}`
  )
  return data
}

/* API request to get the user's profile via the monetization api */
export async function getAccountProfileRequest(gameEndpoint) {
  await checkMonTokenTs(gameEndpoint)
  const { data } = await get(`${MONETIZATION_BASE_URL}/accounts/profile`, {
    meta: { gameEndpoint },
  })
  return data
}

/* API request to get the users wallet via the monetization api */
export async function getAccountWalletsRequest(gameEndpoint, teamId) {
  const query = teamId ? `?team_id=${teamId}` : ''
  await checkMonTokenTs(gameEndpoint)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/balances${query}`,
    {
      meta: { gameEndpoint },
    }
  )
  return data
}

/* API request to get the users payment methods via the monetization api */
export async function getAccountPaymentMethodsRequest() {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/payment-methods`
  )
  return data
}

/* API request to get the users revenue via the monetization api */
export async function getAccountRevenueRequest(query) {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/items/metrics/${queryString(query)}`
  )
  return data
}

/* API request to get the users transaction metrics via the monetization api */
export async function getAccountTransactionMetricsRequest(query) {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/transactions/metrics${queryString(
      query
    )}`
  )
  return data
}

/* API request to get the teams revenue via the monetization api */
export async function getTeamRevenueRequest(
  teamId,
  gameEndpoint,
  modEndpoint,
  query
) {
  await checkMonTokenTs(gameEndpoint, modEndpoint)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/items/metrics/${queryString(
      query
    )}`,
    {
      meta: { gameEndpoint, modEndpoint },
    }
  )
  return data
}

/* API request to get the teams transactions via the monetization api */
export async function getTeamTransactionMetricsRequest(
  teamId,
  gameEndpoint,
  modEndpoint,
  query
) {
  await checkMonTokenTs(gameEndpoint, modEndpoint)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/transactions/metrics/${queryString(
      query
    )}`,
    {
      meta: { gameEndpoint, modEndpoint },
    }
  )
  return data
}

/* API request to get the users items via the monetization api */
export async function getAccountItemsRequest(query = {}, id) {
  await checkMonTokenTs()
  const queryType = id ? `/${id}` : queryString(query)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/items${queryType}`
  )
  return data
}

/* API request to get the users transactions via the monetization api */
export async function getAccountTransactionsRequest(query = {}, id) {
  await checkMonTokenTs()
  const queryType = id ? `/${id}` : queryString(query)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/transactions${queryType}`
  )
  return data
}

/* API request to get the user's refunds via the monetization api */
export async function getAccountRefundsRequest(query = {}, id) {
  await checkMonTokenTs()
  const queryType = id ? `/${id}` : queryString(query)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/accounts/refunds${queryType}`
  )
  return data
}

/* API request to refund own transaction via the monetization api */
export async function refundTransactionRequest(transaction_id, refund_reason) {
  await checkMonTokenTs()
  const { data } = await post(
    '/me/refund',
    qs.stringify({ transaction_id, refund_reason })
  )
  return data
}

/* API request to refund another user's transaction via the monetization api */
export async function adminRefundTransactionRequest(
  userId,
  transaction_id,
  refund_reason
) {
  await checkMonTokenTs()
  const { data } = await post(
    `/admin/users/${userId}/refund`,
    qs.stringify({ transaction_id, refund_reason })
  )
  return data
}

/* API game request to refund a user transaction via the monetization api */
export async function gameRefundTransactionRequest(
  gameId,
  userId,
  transaction_id,
  refund_reason
) {
  await checkMonTokenTs()
  const { data } = await post(
    `/games/${gameId}/users/${userId}/refund`,
    qs.stringify({ transaction_id, refund_reason })
  )
  return data
}

/* API request to get the users payouts via the monetization api */
export async function getAccountPayoutsRequest(query = {}) {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/payouts${queryString(query)}`
  )
  return data
}

/* API request to get the users payout fee via the monetization api */
export async function getAccountPayoutFeeRequest(method, amount) {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/payouts/fee?payment_method_id=${method}&payment_amount=${amount}`
  )
  return data
}

/* API request to payout user via the api */
export async function payoutRequest(update) {
  const query = { ...update }

  const { data } = await post(`/me/payout`, qs.stringify(query), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return data
}

/* API request to payout game wallet via the api */
export async function payoutGameRequest(gameNameId, update) {
  const query = { ...update }

  const { data } = await post(
    `/games/@${gameNameId}/payout`,
    qs.stringify(query),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

/* API request to get the team's transactions via the monetization api */
export async function getTeamTransactionsRequest(
  teamId,
  gameEndpoint,
  modEndpoint,
  query = {},
  id
) {
  await checkMonTokenTs(gameEndpoint, modEndpoint)
  const queryType = id ? `/${id}` : queryString(query)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/transactions${queryType}`,
    {
      meta: { gameEndpoint, modEndpoint },
    }
  )
  return data
}

// GAME
/* API request update game marketplace */
export async function updateGameMonetizationRequest(gameNameId, update, type) {
  const { data } = await put(
    `/games/@${gameNameId}/${type}`,
    qs.stringify(update)
  )
  return data
}

/* API request toggle game marketplace */
export async function toggleGameMonetizationRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/monetization/toggle`,
    qs.stringify(update)
  )
  return data
}

/* API request to update a game's IAP config */
export async function updateGameIAPConfigRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/monetization/iap/config`,
    qs.stringify(update)
  )
  return data
}

/* API request to add a new monetization team to game via the monApi */
export async function addMonetizationTeamToGame(gameNameId, splits, section) {
  const { data } = await post(
    `/games/@${gameNameId}/${section}/team`,
    qs.stringify(splits)
  )
  return data
}

/* API request to add a new monetization user to game via the monApi */
export async function addMonetizationUsersToGame(gameNameId, users) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/team/users`,
    qs.stringify({ users })
  )
  return data
}

/* API request to get monetization user list from game */
export async function getMonetizationUsersGame(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/monetization/team/users`)
  return data
}

/* API request to add entity users to game via the monApi */
export async function addEntityUsersToGame(gameNameId, users) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/entity/users`,
    qs.stringify({ users })
  )
  return data
}

/* API request to get entity user list from game */
export async function getEntityUsersGame(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/monetization/entity/users`)
  return data
}

/* API request to get user's entities for a game */
export async function getMyEntities(gameNameId) {
  const { data } = await get(`/me/entities/@${gameNameId}`)
  return data
}

/* API request to get marketplace splits from game */
export async function getGameTeamMarketplaceSplits(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/marketplace/team/splits`)
  return data
}

/* API request to get conversion rates */
export async function getConversionRatesRequest(gameNameId) {
  const { data } = await get(
    `/games/@${gameNameId}/monetization/conversion-rates`
  )
  return data
}

/* API request to get a game's SKU token packs */
export async function getGameTokenPacksRequest(gameNameId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/monetization/token-packs`
  )
  return data
}

/* API request to get a game's default SKU token packs */
export async function getDefaultGameTokenPacksRequest(gameNameId) {
  const { data } = await get(
    `/games/@${gameNameId}/monetization/default-token-packs`
  )
  return data
}

/* API request to bulk create/update a game's SKU token packs */
export async function updateGameTokenPacksRequest(gameNameId, token_packs) {
  const { data } = await put(
    `/games/@${gameNameId}/monetization/token-packs`,
    qs.stringify({ token_packs })
  )
  return data
}

/* API request to create/update a game's SKU token pack */
export async function updateGameTokenPackRequest(gameNameId, id, update) {
  const { data } = await put(
    `/games/@${gameNameId}/monetization/token-packs/${id}`,
    qs.stringify(update)
  )
  return data
}

/* API request to register KYB for game */
export async function registerGameKybRequest(gameNameId) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/register`,
    null,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

/* API request to onboard KYB for a game */
export async function onboardingGameKybRequest(gameNameId, user) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/entity`,
    qs.stringify(user)
  )
  return data
}

/* API request to get onboarding KYB status for a game */
export async function getOnboardingGameKybRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/monetization/entity`)
  return data
}

/* API request to get financial contact information for a game */
export async function getFinancialInformationRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/monetization/team/contact`)
  return data
}

/* API request to update financial contact information for a game */
export async function updateFinancialInformationRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/monetization/team/contact`,
    qs.stringify(update)
  )
  return data
}

/* API request to get marketplace for a game */
export async function getGameMarketplaceRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/marketplace`)
  return data
}

// MOD
/* API request toggle mod monetization settings */
export async function updateModMonetizationToggle(
  gameNameId,
  modNameId,
  update,
  code
) {
  const query = { ...update }
  if (code) query.code = code

  const { data } = await put(
    `/games/@${gameNameId}/mods/@${modNameId}/monetization/toggle`,
    qs.stringify(query)
  )
  return data
}

export async function setModOnboardedRequest(gameNameId, modNameId) {
  const { data } = await put(`/games/@${gameNameId}/mods/@${modNameId}/onboard`)
  return data
}

/* API request set mod price */
export async function updateModPrice(gameNameId, modNameId, price) {
  const { data } = await put(
    `/games/@${gameNameId}/mods/@${modNameId}/price`,
    qs.stringify({ price: price })
  )
  return data
}

/* API request to set mod free */
export async function setModFreeRequest(gameNameId, modNameId, code) {
  const query = {}
  if (code) {
    query.code = code
  }

  const { data } = await put(
    `/games/@${gameNameId}/mods/@${modNameId}/free`,
    qs.stringify(query)
  )
  return data
}

/* API request to add a new monetization team to mod via the monApi */
export async function addMonetizationTeamToMod(gameNameId, modNameId, team) {
  const { data } = await post(
    `/games/@${gameNameId}/mods/@${modNameId}/monetization/team`,
    qs.stringify(team)
  )
  return data
}

/* API request to add monetization users to mod via the monApi */
export async function addMonetizationUsersToMod(
  gameNameId,
  modNameId,
  users,
  entities
) {
  const { data } = await post(
    `/games/@${gameNameId}/mods/@${modNameId}/monetization/team/users`,
    qs.stringify({ users, entities })
  )
  return data
}

/* API request to get monetization user list from mod */
export async function getMonetizationUsersMod(gameNameId, modNameId) {
  const { data } = await get(
    `/games/@${gameNameId}/mods/@${modNameId}/monetization/team/users`
  )
  return data
}

// ADMIN
/* API request to toggle monetization for a game */
export async function adminToggleGameMonetizationRequest(gameNameId, val) {
  const { data } = await put(
    `/admin/games/@${gameNameId}/monetization/toggle`,
    qs.stringify({ effect: val }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

/* API request to get the admin transaction items */
export async function getAdminAccountTransactionsRequest(query = {}, id) {
  await checkMonTokenTs()
  const queryType = id ? `/${id}` : queryString(query)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/admins/transactions${queryType}`
  )
  return data
}

/* API request to get admin revenue via the monetization api */
export async function getAdminRevenueRequest(query) {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/admins/items/metrics/${queryString(query)}`
  )
  return data
}

/* API request to get admin transaction metrics via the monetization api */
export async function getAdminTransactionMetricsRequest(query = {}) {
  await checkMonTokenTs()
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/admins/transactions/metrics${queryString(query)}`
  )
  return data
}

/* API request to get admin fees metrics via the monetization api */
export async function getAdminFeesMetrics() {
  await checkMonTokenTs()
  const { data } = await get(`${MONETIZATION_BASE_URL}/admins/metrics/fees`)
  return data
}

/* API request to get the admin SKU token packs */
export async function getAdminTokenPacksRequest() {
  const { data } = await get('/admin/monetization/token-packs')
  return data
}

/* API request to create an admin SKU token pack */
export async function createAdminTokenPackRequest(update) {
  const { data } = await post(
    '/admin/monetization/token-packs',
    qs.stringify(update)
  )
  return data
}

/* API request to update an admin SKU token pack */
export async function updateAdminTokenPackRequest(id, update) {
  const { data } = await put(
    `/admin/monetization/token-packs/${id}`,
    qs.stringify(update)
  )
  return data
}

/* API request to toggle the default status of an admin SKU token pack */
export async function toggleAdminTokenPackRequest(id, update) {
  const { data } = await put(
    `/admin/monetization/token-packs/${id}/default`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete an admin SKU token pack */
export async function deleteAdminTokenPackRequest(id) {
  const { data } = await del(`/admin/monetization/token-packs/${id}`)
  return data
}

/* API request to get list of invoices for a team  */
export async function getTeamInvoicesRequest(teamId, query) {
  await checkMonTokenTs(true)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/invoices${queryString(query)}`,
    {
      meta: { gameEndpoint: true },
    }
  )
  return data
}

/* API request to pay an invoice  */
export async function payInvoiceRequest(
  teamId,
  invoiceId,
  payment_method_id,
  gameEndpoint
) {
  await checkMonTokenTs(gameEndpoint)
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/invoices/${invoiceId}`,
    qs.stringify({ payment_method_id }),
    {
      meta: { gameEndpoint },
    }
  )
  return data
}

/* API request to register interest for monetization */
export async function gameRegisterInterestRequest(gameNameId, formData) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/interest`,
    qs.stringify(formData)
  )
  return data
}

/* API request to reset mod purchases for a mod */
export async function purchaseResetForModRequest(modId, buyerId) {
  const postData = {
    mod_id: modId,
    buyer_id: buyerId,
  }

  const { data } = await post(
    `${PRIVATE_HIDDEN_BASE_URL}/monetization/reset-purchases`,
    qs.stringify(postData)
  )
  return data
}

/* API request to reset mod purchases for a buyer */
export async function purchaseResetForBuyerRequest(buyerId) {
  const postData = {
    buyer_id: buyerId,
  }

  const { data } = await post(
    `${PRIVATE_HIDDEN_BASE_URL}/monetization/reset-purchases`,
    qs.stringify(postData)
  )
  return data
}

/* API request to generate an invoice */
export async function generateInvoiceRequest(teamId) {
  await checkMonTokenTs(true)
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/test/teams/${teamId}/invoices`,
    undefined,
    {
      meta: { gameEndpoint: true },
    }
  )

  return data
}

/* API request to reset invoice */
export async function invoiceResetRequest(invoiceId) {
  await checkMonTokenTs(true)
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/test/invoice/${invoiceId}/reset`,
    undefined,
    {
      meta: { gameEndpoint: true },
    }
  )
  return data
}

/* API request to reset wallet balance for a buyer */
export async function walletResetRequest(walletId) {
  await checkMonTokenTs()
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/test/wallet/${walletId}/reset`
  )

  return data
}

/* API request to add funds to wallet */
export async function fundWalletRequest(gameEndpoint, walletId, amount) {
  await checkMonTokenTs(gameEndpoint)
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/test/wallet/${walletId}/fund`,
    {
      amount,
    },
    {
      meta: { gameEndpoint },
    }
  )

  return data
}

/* API request to clear pending transactions */
export async function clearPendingTransactionsRequest(teamId) {
  await checkMonTokenTs()
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/test/teams/${teamId}/items/clear`
  )

  return data
}

/* API request to generate csv data */
export async function generateTransactionsExportsRequest(teamId, query) {
  await checkMonTokenTs(true)
  const { data } = await post(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/transactions/exports`,
    qs.stringify(query),
    {
      meta: { gameEndpoint: true },
    }
  )

  return data
}

/* API request to retrieve csv data */
export async function getTransactionsExportsRequest(teamId, query) {
  await checkMonTokenTs(true)
  const { data } = await get(
    `${MONETIZATION_BASE_URL}/teams/${teamId}/exports${queryString(query)}`,
    {
      meta: { gameEndpoint: true },
    }
  )

  return data
}
