<template>
  <div class="tw-w-full md:tw-space-y-2">
    <tooltip-hover
      v-for="navigation in visibleNavigations"
      :key="navigation.link.route"
      hide-on-sm
      position="right"
      background="primary-hover"
      outside-main
      :text="navigation.name"
    >
      <base-link
        :link-primary="false"
        :hover-primary="false"
        :to="{ name: navigation.link.route }"
        class="tw-group tw-block tw-relative"
      >
        <div
          class="tw-flex tw-items-center"
          @click="(e) => redirect(navigation, e)"
        >
          <div
            class="tw-flex tw-items-center tw-justify-center tw-size-11 tw-px-3 tw-global--border-radius group-hover:md:tw-bg-primary-hover group-focus:md:tw-bg-primary-hover"
            :class="{ 'md:tw-bg-primary-hover': inView(navigation) }"
          >
            <font-awesome-icon
              :icon="navigation.icon"
              fixed-width
              class="tw-text-h5"
            />
          </div>
          <p class="tw-text-sm tw-font-medium md:tw-hidden">
            {{ navigation.name }}
          </p>
        </div>
        <base-badge
          v-if="navigation.link.route === PROFILE_CONTENT_ROUTE && hasNewItem"
          border
          color="danger"
          rounded="small"
          text="NEW"
          class="tw-absolute tw-bottom-3 md:tw--bottom-1.5 tw-right-1.5"
        />
      </base-link>
    </tooltip-hover>
  </div>
  <recent-games class="tw-hidden md:tw-block" />
</template>

<script>
import { GAME_BROWSE_ROUTE, PROFILE_CONTENT_ROUTE } from '@config/routeNames.js'
import { useNavigation, useRoute, useTheming, useModal } from '@composables'
import TooltipHover from '@components/Message/TooltipHover.vue'
import RecentGames from '@components/Navbar/RecentGames.vue'
import { authStore, myTeamsStore } from '@stores'
import { secsToMs } from '@helpers/utils.js'
import { Duration } from 'luxon'
import { computed } from 'vue'
export default {
  components: {
    TooltipHover,
    RecentGames,
  },
  setup() {
    const { visibleNavigations, matchRoute } = useNavigation()
    const { lowDangerWCAGContrast } = useTheming()
    const { routeName, resolve } = useRoute()
    const { showUserLogin } = useModal()

    const { isLoggedIn } = authStore()
    const { myTeams } = myTeamsStore()

    const hasNewItem = computed(() => {
      const latestTime = [...myTeams.value].sort(
        (a, b) => b.access?.date_added - a.access?.date_added
      )?.[0]?.access?.date_added
      if (!latestTime) return false

      return (
        Duration.fromMillis(Date.now() - secsToMs(latestTime)).shiftTo('hours')
          .hours <= 1
      )
    })

    function inView(navigation) {
      // true if navigation link route matches current route name
      // true if navigation show field includes current route name
      return (
        (navigation.highlight && !matchRoute(navigation.highlight)) ||
        navigation.link.route === routeName.value
      )
    }

    function redirect(navigation, event) {
      if (!isLoggedIn.value && navigation.link.route !== GAME_BROWSE_ROUTE) {
        event.preventDefault()
        const route = resolve({ name: navigation.link.route })
        showUserLogin(route)
      }
    }

    return {
      PROFILE_CONTENT_ROUTE,
      lowDangerWCAGContrast,
      visibleNavigations,
      hasNewItem,
      redirect,
      inView,
    }
  },
}
</script>
