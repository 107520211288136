import { MODAL_LOGIN, MODAL_MERGE_ACCOUNTS } from '@config/globalModals.js'
import { globalModals, setGlobalModal } from '@composables/useModal.js'
import { SS_GAME_SSO_CONFIG } from '@config/localstorage.js'
import useAsync from '@shared/js/composables/useAsync.js'
import { getGameSSOConfigRequest } from '@services'
import { SSO_NOT_SETUP } from '@config/errors.js'
import { useSessionStorage } from '@vueuse/core'
import { PORTAL_STUDIO } from '@config/auth.js'
import { authStore } from '@stores'
import { readonly } from 'vue'

const ssoConfigState = useSessionStorage(SS_GAME_SSO_CONFIG, {})

export default function () {
  const { errorRef, loading, error, data, run } = useAsync((gameNameId) =>
    getGameSSOConfigRequest(gameNameId)
  )

  async function checkAuth(
    game,
    action,
    showLoginModal = true,
    showStudioModal = true
  ) {
    const { isLoggedIn, hasConnection } = authStore()

    if (game?.sso_mapping && !hasConnection(game.sso_mapping)) {
      if (!ssoConfigState.value[game.name_id]) {
        await run(game.name_id)

        if (!error.value) {
          setSSOConfig(game.name_id, data.value)
        } else if (errorRef.value === SSO_NOT_SETUP) {
          ssoConfigState.value[game.name_id] = {}
        }
      }

      if (ssoConfigState.value[game.name_id]?.prompt_user_login) {
        if (
          showStudioModal &&
          globalModals.value?.[0]?.modal !== MODAL_MERGE_ACCOUNTS
        ) {
          setGlobalModal({
            modal: MODAL_LOGIN,
            data: { portal: PORTAL_STUDIO, action },
          })
        }
        return false
      }
    }

    if (!isLoggedIn.value && showLoginModal) {
      if (globalModals.value?.[0]?.modal !== MODAL_MERGE_ACCOUNTS) {
        setGlobalModal({ modal: MODAL_LOGIN })
      }
      return false
    }

    return true
  }

  function setSSOConfig(gameNameId, config) {
    ssoConfigState.value[gameNameId] = {
      ...config,
      type: PORTAL_STUDIO,
      label: config.name,
      logo: config.icon,
      icon: 'gamepad',
    }
  }

  return {
    ssoConfig: readonly(ssoConfigState),
    setSSOConfig,
    checkAuth,
    loading,
  }
}
