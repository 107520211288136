import { LS_MONETIZATION_ONBOARDING } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { computed, readonly, ref } from 'vue'

const gameOnboardingState = useLocalStorage(LS_MONETIZATION_ONBOARDING, {})
const stepComponent = ref(null)
const options = ref(null)

export default function (gameNameId) {
  const componentIndex = computed(() =>
    options.value?.findIndex((x) => x.component === stepComponent.value)
  )

  const showPrevBtn = computed(() => componentIndex.value !== 0)
  const showNextBtn = computed(() =>
    options.value ? componentIndex.value !== options.value.length - 1 : null
  )

  function prevStep() {
    changeStep(options.value[componentIndex.value - 1].component)
  }

  function nextStep() {
    const step = options.value[componentIndex.value + 1].component
    changeStep(step)
    setGameOnboarding(step)
  }

  function changeStep(component) {
    if (!options.value.some((o) => o.component === component)) {
      removeGameOnboarding()
      return
    }

    stepComponent.value = component
    _updateOptions()
  }

  function clearStepList() {
    stepComponent.value = null
  }

  function setOptions(value) {
    options.value = value
    _updateDefault()
  }

  function _updateOptions() {
    options.value = options.value.map((o, index) => {
      return {
        component: o.component,
        text: o.text,
        disabled:
          componentIndex.value !== index && index > componentIndex.value,
        completed:
          componentIndex.value !== index && index < componentIndex.value,
      }
    })
  }

  function _updateDefault() {
    if (!stepComponent.value) {
      changeStep(options.value[0].component)
    }
  }

  function setGameOnboarding(state) {
    if (gameNameId) {
      gameOnboardingState.value = {
        ...gameOnboardingState.value,
        [gameNameId]: state,
      }
    }
  }

  function removeGameOnboarding() {
    if (gameNameId) {
      const newState = { ...gameOnboardingState.value }
      delete newState[gameNameId]
      gameOnboardingState.value = newState
    }
  }

  return {
    gameOnboarding: readonly(gameOnboardingState),
    stepOptions: readonly(options),
    step: readonly(stepComponent),
    removeGameOnboarding,
    clearStepList,
    showPrevBtn,
    showNextBtn,
    setOptions,
    changeStep,
    prevStep,
    nextStep,
  }
}
