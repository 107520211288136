<template>
  <base-file-drop :disabled="disabled" @add:files="onFileDrop">
    <template #default="{ dragOver }">
      <component
        :is="hasFiles ? 'div' : 'label'"
        :for="hasFiles ? null : inputId"
        class="tw-flex tw-flex-col tw-w-full game-tile-radius tw-overflow-hidden tw-group"
        :class="[
          {
            'tw-input--focus-within':
              !hasFiles && !hasErrors && !disabled && !dragOver,
            'tw-cursor-pointer': !hasFiles && !disabled,
            'tw-border-danger tw-border-2': hasErrors && !dragOver,
            'game-primary-button-border-color tw-border-2': dragOver,
          },
          customClass,
        ]"
        v-bind="$attrs"
      >
        <slot />

        <base-button
          v-if="button"
          :id="inputId"
          full-width
          :danger="hasErrors"
          :primary="!secondary"
          :secondary="secondary"
          icon="upload"
          icon-size="lg"
          :disabled="disabled"
          :radius="radius"
          :status="status"
          :progress="progress"
          @click="clickInput"
        >
          <span>{{ label }}</span>
        </base-button>

        <input
          :id="inputId"
          ref="fileInput"
          :disabled="disabled || readonly"
          type="file"
          class="tw-hidden"
          :multiple="multiple"
          :accept="accept"
          @change="onFileChange"
        />
      </component>
    </template>
  </base-file-drop>

  <div
    v-if="hasErrors || hint"
    class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
  >
    <base-input-errors :errors="errors" align="left" />

    <base-input-hint v-if="hint" :id="`${inputId}Help`" align="center">
      {{ hint }}
    </base-input-hint>
  </div>
</template>
<script>
import BaseFileDrop from '@shared/components/Misc/BaseFileDrop.vue'
import { toRefs, ref, computed, inject } from 'vue'
import { genHtmlId } from '@helpers/utils.js'
export default {
  components: {
    BaseFileDrop,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Upload',
    },
    hint: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    hasFiles: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String,
      default: 'bottom',
    },
    status: {
      type: String,
      default: null,
    },
    progress: {
      type: Number,
      default: 0,
    },
    button: {
      type: Boolean,
      default: true,
    },
    testId: {
      type: String,
      default: '',
    },
  },

  emits: ['file:upload'],
  setup(props, { emit }) {
    const { multiple, errors } = toRefs(props)

    const inputId = genHtmlId()
    const fileInput = ref(null)
    const readonly = inject('saving', false)

    const hasErrors = computed(() => !!errors.value?.length)

    function onFileChange(e) {
      if (!e.target.files[0]) return

      const payload = multiple.value ? e.target.files : e.target.files[0]
      emit('file:upload', payload)
      e.target.value = ''
    }

    function onFileDrop(files) {
      const payload = multiple.value ? files : files[0]
      emit('file:upload', payload)
    }

    function clickInput() {
      fileInput.value.click()
    }

    return {
      onFileChange,
      onFileDrop,
      clickInput,
      hasErrors,
      fileInput,
      readonly,
      inputId,
    }
  },
}
</script>
