<template>
  <div
    v-if="activeBanners.length"
    class="tw-flex tw-flex-col tw-w-full tw-space-y-4"
  >
    <inline-message
      v-for="(item, index) in activeBanners"
      :key="index"
      :has-close="item.noDismiss"
      :has-icon="false"
      :info="item.isInfo"
      :success="item.isSuccess"
      :warning="item.isWarning"
      :danger="item.isError"
      @close:message="toggle(item.name)"
    >
      <template #default>
        <panel-header
          v-if="item.title"
          xsmall
          :text="item.title"
          :status="item.status"
          :success="item.status && item.isSuccess"
          :info="item.status && item.isError"
          :warning="item.status && item.isError"
          :danger="item.status && item.isError"
        />
        <div class="tw-content tw-w-full">
          <component :is="item.customComponent" v-if="item.customComponent" />

          <p v-if="item.text" class="tw-text-p-md tw-mt-4">{{ item.text }}</p>

          <router-link v-if="item.cta" :to="item.cta.to" class="tw-underline">
            {{ item.cta.text }}
          </router-link>
        </div>
      </template>
      <template #buttons>
        <base-link
          v-if="
            MODIO_ENV !== 'test' &&
            item.status &&
            item.status.toLowerCase() !== 'live' &&
            gameAdmin
          "
          :to="{ name: GAME_ADMIN_SETTINGS_ROUTE }"
          class="tw-link-btn tw-btn-secondary"
        >
          Resolve
        </base-link>
      </template>
    </inline-message>
  </div>
</template>

<script>
import { useBanners, useRoute, usePermissions } from '@composables'
import InlineMessage from '@components/Message/InlineMessage.vue'
import { GAME_ADMIN_SETTINGS_ROUTE } from '@config/routeNames.js'
import PanelHeader from '@components/PanelHeader.vue'
import TestEnv from '@components/Error/TestEnv.vue'
import Preview from '@components/Game/Preview.vue'
import { MODIO_ENV } from '@config'
import { gameStore } from '@stores'
import { computed } from 'vue'

export default {
  components: {
    InlineMessage,
    PanelHeader,
    Preview,
    TestEnv,
  },
  setup() {
    const { hasPermissionByNameId } = usePermissions()
    const { activeBanners, toggle } = useBanners()
    const { getPublicGame } = gameStore()
    const { getGameId } = useRoute()

    const game = getPublicGame(getGameId().value)

    const gameAdmin = computed(() =>
      hasPermissionByNameId({
        resource: 'game',
        nameId: game.value?.name_id,
        scope: 'admin',
      })
    )

    return {
      GAME_ADMIN_SETTINGS_ROUTE,
      activeBanners,
      MODIO_ENV,
      gameAdmin,
      toggle,
      game,
    }
  },
}
</script>
