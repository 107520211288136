<template>
  <div class="tw-flex tw-flex-col tw-animate-pulse">
    <div
      class="tw-flex tw-items-center tw-justify-center tw-px-2 tw-rounded"
      :class="[
        buttonWidth,
        buttonHeight,
        altBg ? 'tw-bg-theme' : 'tw-bg-theme-1',
      ]"
    >
      <div
        class="tw-w-full tw-max-w-20 tw-h-2 tw-rounded-full tw-bg-theme"
        :class="altBg ? 'tw-bg-theme-1' : 'tw-bg-theme'"
      ></div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue'
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    inputBtn: {
      type: Boolean,
      default: false,
    },
    fullWidthAt: {
      type: String,
      default: '',
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { inputBtn, fullWidth, large, medium, small, fullWidthAt } =
      toRefs(props)

    const buttonWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-w-full'
      } else if (inputBtn.value) {
        if (large.value) {
          return 'tw-w-9 xs:tw-w-11'
        } else if (small.value) {
          return 'tw-w-6'
        } else {
          return 'tw-w-8'
        }
      } else {
        if (fullWidthAt.value === 'xl') {
          return `tw-w-full xl:tw-w-30`
        } else if (fullWidthAt.value === 'lg') {
          return `tw-w-full lg:tw-w-30`
        } else if (fullWidthAt.value === 'md') {
          return `tw-w-full md:tw-w-30`
        } else if (fullWidthAt.value === 'sm') {
          return `tw-w-full sm:tw-w-30`
        } else if (fullWidthAt.value === 'xs') {
          return `tw-w-full xs:tw-w-30`
        } else {
          return 'tw-w-30'
        }
      }
    })

    const buttonHeight = computed(() => {
      if (large.value) {
        return 'tw-h-9 xs:tw-input--height-large'
      } else if (medium.value) {
        return 'tw-h-8'
      } else if (small.value) {
        return 'tw-h-6'
      } else {
        return 'tw-input--height'
      }
    })

    return {
      buttonHeight,
      buttonWidth,
    }
  },
}
</script>
