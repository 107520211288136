import { areBitsSet, clone, isArray, msToSecs } from '@helpers/utils.js'
import { LOCALSTORAGE_EXPIRE_DAYS } from '@config'
import { useLocalStorage } from '@vueuse/core'
import { useCacheBust } from '@composables'
import { ref, computed } from 'vue'
import {
  TILIA_STATUS_APPROVED,
  TILIA_STATUS_REJECTED,
  TILIA_STATUS_PENDING,
  TILIA_STATUS_REVIEW,
  TILIA_STATUS_ACTION,
  TILIA_STATUS_MEMBER,
} from '@config/monetization.js'
import {
  LS_MONACCESSTOKEN,
  LS_PENDING_EMAIL,
  LS_MESSAGE_POLL,
  LS_ACCESSTOKEN,
  LS_UPDATEDAT,
  LS_USER,
} from '@config/localstorage.js'
import {
  MEMBER_LEVEL_MANAGER,
  MEMBER_LEVEL_MEMBER,
  MEMBER_LEVEL_LEADER,
  STATUS_ACCEPTED,
  STATUS_DELETED,
} from '@config/options.js'
import {
  userSubscriptionStore,
  notificationStore,
  revenueSplitStore,
  subscriptionStore,
  mutedUsersStore,
  purchasesStore,
  myTeamsStore,
  messageStore,
  ratingStore,
  karmaStore,
} from '@stores'

const DEFAULT_LANG = 'en'
const accessTokenState = useLocalStorage(LS_ACCESSTOKEN, null)
const monAccessTokenState = useLocalStorage(LS_MONACCESSTOKEN, null)
const updatedAtState = useLocalStorage(LS_UPDATEDAT, null)
const userState = useLocalStorage(LS_USER, null)
const hasGuidePermissionState = ref(false)
const pendingEmailState = useLocalStorage(LS_PENDING_EMAIL, null)
const initialLoginState = ref(false)
const portalState = ref(null)
const platformState = ref(null)

export default function authStore() {
  const { subscriptions, clearSubscriptions } = subscriptionStore()
  const { clearUserSubscriptions } = userSubscriptionStore()
  const { clearMyTeamsStore } = myTeamsStore()
  const { clearRatings } = ratingStore()
  const { clearKarma } = karmaStore()
  const { clearMutedUsers } = mutedUsersStore()
  const { clearNotifications } = notificationStore()
  const { clearPurchases } = purchasesStore()
  const { clearRevenueTeams } = revenueSplitStore()

  const accessToken = computed(() => JSON.parse(accessTokenState.value))
  const monAccessToken = computed(() => JSON.parse(monAccessTokenState.value))
  const user = computed(() => JSON.parse(userState.value))
  const lastUpdatedAt = computed(() => updatedAtState.value)
  const pendingEmail = computed(() => pendingEmailState.value)
  const hasGuidePermission = computed(() => hasGuidePermissionState.value)
  const initialLogin = computed(
    () => initialLoginState.value && isLoggedIn.value
  )
  const portal = computed(() => portalState.value)
  const platform = computed(() => platformState.value)

  const userOnboardingStatus = computed(
    () => user.value?.info?.monetization_status
  )

  const isMonRegistered = computed(() =>
    areBitsSet(userOnboardingStatus.value, TILIA_STATUS_PENDING)
  )

  const isInReview = computed(
    () =>
      areBitsSet(userOnboardingStatus.value, TILIA_STATUS_REVIEW) ||
      areBitsSet(userOnboardingStatus.value, TILIA_STATUS_ACTION)
  )

  const isRejected = computed(() =>
    areBitsSet(userOnboardingStatus.value, TILIA_STATUS_REJECTED)
  )

  const isOnboarded = computed(() =>
    areBitsSet(userOnboardingStatus.value, TILIA_STATUS_APPROVED)
  )

  const hasNotOnboarded = computed(
    () =>
      !userOnboardingStatus.value ||
      (!isInReview.value && !isRejected.value && !isOnboarded.value)
  )

  const hasMemberWallets = computed(() =>
    areBitsSet(userOnboardingStatus.value, TILIA_STATUS_MEMBER)
  )

  const isLoggedIn = computed(
    () => !!user.value?.info?.id && !!accessToken.value
  )

  // TODO: SR what about STATUS_BANNED? That's also closed - and should be blocked from logging in or at minimum posting?
  const isAccountClosed = computed(
    () => user.value?.info?.status === STATUS_DELETED
  )

  function closeAccount() {
    if (user.value?.info)
      setUserInfo({ ...user.value.info, status: STATUS_DELETED })
  }

  function openAccount() {
    if (user.value?.info)
      setUserInfo({ ...user.value.info, status: STATUS_ACCEPTED })
  }

  /**
   * @param {'admin' | 'manager' | 'member'} level
   */
  function isSuperAdmin(level) {
    let access

    switch (level) {
      case 'admin':
        access = MEMBER_LEVEL_LEADER
        break

      case 'manager':
        access = MEMBER_LEVEL_MANAGER
        break

      default:
        access = MEMBER_LEVEL_MEMBER
        break
    }

    return user.value?.info?.level >= access
  }

  function setUser(newUser) {
    if (!newUser) {
      throw new Error()
    }

    const mergeInProgress = user.value?.connections?.find((c) => c.merge_code)
    if (mergeInProgress) {
      mergeInProgress.date_added = msToSecs(Date.now())
      newUser.connections = isArray(newUser.connections)
        ? [...newUser.connections, mergeInProgress]
        : [mergeInProgress]
    }

    userState.value = JSON.stringify(newUser)
  }

  function setToken(accessToken) {
    if (!accessToken) {
      throw new Error()
    }
    accessTokenState.value = JSON.stringify(accessToken)
  }

  function setMonToken(_monAccessToken, scope) {
    if (!_monAccessToken) {
      throw new Error()
    }
    monAccessTokenState.value = JSON.stringify({
      ...monAccessToken.value,
      [scope]: _monAccessToken,
    })
  }

  function clearMonToken() {
    monAccessTokenState.value = null
  }

  function setUserMonetizationStatus(monetization_status) {
    setUserInfo({
      ...user.value.info,
      monetization_status,
    })
  }

  function setUnauthedUser(user) {
    userState.value = JSON.stringify(user)
  }

  function setUserInfo(data) {
    const userCopy = clone(user.value)
    userCopy.info = data
    userState.value = JSON.stringify(userCopy)
  }

  function setUserConnections(data) {
    const userCopy = clone(user.value)
    userCopy.connections = data
    userState.value = JSON.stringify(userCopy)
  }

  function setUserAvatar(data) {
    const userCopy = clone(user.value)
    userCopy.avatar = data
    userState.value = JSON.stringify(userCopy)
  }

  function clearUser() {
    clearSubscriptions()
    clearMutedUsers()
    clearUserSubscriptions()
    clearMyTeamsStore()
    clearRatings()
    clearKarma()
    clearUpdatedAt()
    setPendingEmail('')
    clearMutedUsers()
    clearPurchases()
    clearRevenueTeams()
    messageStore().clearInbox()
    localStorage.setItem(LS_MESSAGE_POLL, JSON.stringify([]))
    clearNotifications()
    userState.value = JSON.stringify({})
    accessTokenState.value = null
    clearMonToken()
    useCacheBust().setCacheLimit()
  }

  function setUpdatedAt() {
    updatedAtState.value = Date.now()
  }

  function clearUpdatedAt() {
    updatedAtState.value = null
  }

  function setInitialLogin(value) {
    initialLoginState.value = value
  }

  function setPortal(value) {
    portalState.value = value
  }

  function setPlatform(value) {
    platformState.value = value
  }

  function getUserId() {
    return user.value?.info?.id
  }

  function getUserLanguage() {
    return user.value?.info?.language || DEFAULT_LANG
  }

  /* Determines if localstorage requires refresh */
  function requiresRefresh() {
    const current = Date.now()
    const msid = 8.64e7 * LOCALSTORAGE_EXPIRE_DAYS
    if (updatedAtState.value) {
      return parseInt(current) - parseInt(updatedAtState.value) > msid
    } else {
      updatedAtState.value = Date.now()
      return false
    }
  }

  function setPendingEmail(email) {
    pendingEmailState.value = email
  }

  function hasConnection(ssoId) {
    return !!user.value?.connections?.some(
      (c) => c.sso_id && c.sso_id === ssoId
    )
  }

  return {
    setUserMonetizationStatus,
    userOnboardingStatus,
    setUserConnections,
    hasGuidePermission,
    hasMemberWallets,
    isMonRegistered,
    getUserLanguage,
    setInitialLogin,
    isAccountClosed,
    requiresRefresh,
    setUnauthedUser,
    setPendingEmail,
    hasNotOnboarded,
    clearUpdatedAt,
    monAccessToken,
    subscriptions,
    lastUpdatedAt,
    setUserAvatar,
    clearMonToken,
    hasConnection,
    pendingEmail,
    initialLogin,
    setUpdatedAt,
    closeAccount,
    isSuperAdmin,
    openAccount,
    isOnboarded,
    setMonToken,
    accessToken,
    setUserInfo,
    setPlatform,
    isInReview,
    isRejected,
    isLoggedIn,
    setPortal,
    getUserId,
    clearUser,
    platform,
    setToken,
    setUser,
    portal,
    user,
  }
}
