<template>
  <tr
    v-dragscroll.x="!touchScreen"
    class="tw-w-full tw-table-row tw-button-transition"
    :class="[
      altBackground ? 'tw-bg-dark-3' : 'tw-bg-dark',
      {
        'tw-group tw-cursor-pointer': linkTo || rowAction,
        'hover:tw-bg-dark': (linkTo || rowAction) && altBackground,
        'hover:tw-bg-dark-3': (linkTo || rowAction) && !altBackground,
        'tw-h-12 lg:tw-h-15': !smallRow,
      },
    ]"
    @dragscrollstart="() => (scrolled = true)"
  >
    <td
      v-for="(cell, index) in cells"
      :key="index"
      class="tw-bg-inherit group-hover:tw-border-dark-3 tw-border-b tw-border-dark-1 tw-py-2 tw-table-cell tw-align-middle tw-min-h-10 tw-relative"
      :class="[
        cell.cellClass,
        cellStyles(cell),
        active && cell.inputCell ? 'tw-pr-4' : 'tw-px-3',
        {
          'md:tw-sticky md:tw-left-0 tw-z-1': cell.type === 'checkbox',
          'md:tw-shadow-cell-right md:tw-shadow-dark-1':
            cell.type === 'checkbox' && hasLeftScroll,
          'tw-sticky tw-right-0': cell.actionCell,
          'tw-shadow-cell-left tw-shadow-dark-1':
            cell.actionCell && hasRightScroll,
          'tw-bg-primary': active,
          'tw-border-t': rowIndex === 0,
          'tw-min-w-40 md:tw-min-w-80':
            cell.fit !== true &&
            ((index === 0 && cell.type !== 'checkbox') ||
              (index === 1 && cells[0].type === 'checkbox')),
        },
      ]"
      @click="(e) => handleClick(e, index)"
    >
      <a
        v-if="!cell.actionCell && !!getCellLink(index)"
        :href="getCellLink(index)"
        draggable="false"
        class="tw-absolute tw-inset-0"
        @click.prevent
      />
      <row-cell
        :class="smallRow ? 'tw-min-h-8' : 'tw-min-h-10'"
        :active="active"
        :cell="cell"
        :row-index="rowIndex"
      />
    </td>
  </tr>
</template>

<script>
import { isNumber, isString, isFunction } from '@helpers/utils.js'
import RowCell from '@components/Table/TableCells/RowCell.vue'
import { inject, computed, toRefs, ref } from 'vue'
import { useTable, useRoute } from '@composables'
import { dragscroll } from 'vue-dragscroll'
export default {
  components: {
    RowCell,
  },
  directives: {
    dragscroll,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    rowSchema: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    smallRow: {
      type: Boolean,
      default: false,
    },
    altBackground: {
      type: Boolean,
      default: false,
    },
    hasRightScroll: {
      type: Boolean,
      default: false,
    },
    hasLeftScroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['mounted'],
  setup(props) {
    const { rowSchema, item } = toRefs(props)
    const { getCells } = useTable()
    const scrolled = ref(false)
    const { push, resolve } = useRoute()

    const cells = getCells({ rowSchema, item })

    const linkTo = computed(() => {
      let to = rowSchema.value
        .find((x) => x.to && !x.attrs?.disabled)
        ?.to(item.value)
      if (!to)
        to = rowSchema.value.find(
          (x) =>
            isFunction(x) && x(item.value).to && !x(item.value).attrs?.disabled
        )?.(item.value)?.to
      return isFunction(to) ? to(item.value) : to
    })

    const groupRowAction = rowSchema.value
      .find((x) => x.group)
      ?.group?.find((x) =>
        isFunction(x) ? x(item.value).rowAction : x.rowAction
      )

    const rowSchemaItem = rowSchema.value.find((x) => x.rowAction)
    const hasRowAction = isFunction(rowSchemaItem?.rowAction)
      ? rowSchemaItem.rowAction(item.value)
      : rowSchemaItem?.rowAction

    const rowAction = groupRowAction
      ? inject(
          isFunction(groupRowAction)
            ? groupRowAction(item.value)?.onClick
            : groupRowAction?.onClick
        )
      : hasRowAction && rowSchemaItem?.onClick
        ? inject(rowSchemaItem.onClick)
        : undefined
    const touchScreen = 'ontouchstart' in document.documentElement

    const activeId = inject('activeId', false)
    const active = computed(
      () => isNumber(item.value.id) && activeId.value === item.value.id
    )

    function cellStyles(cell) {
      if (cell.preStyleText) {
        if (cell.value === 'N/A') {
          return 'tw-text-warning'
        } else if (
          cell.value === 'Declined' ||
          cell.value === 'Hidden' ||
          cell.value === 'Deleted' ||
          cell.value === 'Banned' ||
          cell.value === 'Disabled'
        ) {
          return 'tw-text-danger'
        } else if (cell.value === 'Live' || cell.value === 'Enabled') {
          return 'tw-text-success'
        }
      }

      return cell.code ? 'tw-font-mono' : ''
    }

    function handleClick(_e, index) {
      if (scrolled.value) {
        scrolled.value = false
        return
      }

      if (
        cells.value[index].actionCell ||
        cells.value[index].type === 'checkbox' ||
        cells.value[index].inputCell
      ) {
        return
      }

      if (!linkTo.value && rowAction) {
        rowAction(item.value)
        return
      } else if (!linkTo.value) {
        return
      }

      if (
        cells.value[index].to &&
        index !== rowSchema.value.findIndex((x) => x.to)
      ) {
        const cellLinkTo = cells.value[index].to(item.value)
        if (isString(cellLinkTo)) window.location.href = cellLinkTo
        else push(cellLinkTo)

        return
      }

      if (isString(linkTo.value)) window.location.href = linkTo.value
      else push(linkTo.value)
    }

    function getCellLink(index) {
      if (cells.value[index].to) {
        const link = cells.value[index].to(item.value)

        if (typeof link === 'string') return link
        return resolve(link).href
      }

      if (linkTo.value) {
        if (typeof linkTo.value === 'string') return linkTo.value
        return resolve(linkTo.value).href
      }
    }

    return {
      touchScreen,
      handleClick,
      getCellLink,
      cellStyles,
      rowAction,
      scrolled,
      active,
      linkTo,
      cells,
    }
  },
}
</script>
